import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {Alert} from "../../models/vestrata/Alert";
import {takeUntil} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {NotificationService} from "../../api/services/notification.service";
import {retrieveNotifications} from "../../ngrx/actions/notification.actions";

@Component({
  selector: 'ves-shared-notification-dispatcher',
  template: ''
})
/*
 * This component will play the role of all A&N
 * retrieving, filtering and dispatching via
 * NGRX actions
 */
export class SharedNotificationDispatcherComponent implements OnInit, OnDestroy, AfterViewInit{

  _onDestroy = new Subject();

  notificationList: Alert[] = [];

  constructor(private notificationService: NotificationService, private store: Store<any>) { }

  ngOnInit() {
    this.notificationService.startPeriodicNotifications(120)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        data => {
          console.log(data);
          this.store.dispatch(retrieveNotifications({payload:data}))
          // Filtering all alerts by category & subCategories
          // then dispatch for every action

        },
      )
  }

  ngAfterViewInit() {
    this.listenToReloadRequest();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private listenToReloadRequest() {
    this.store.select('reloadNotifications').pipe(takeUntil(this._onDestroy)).subscribe(
      state => {
        console.log('***Ask-Reload Notifications***',state);
        this.reloadNotifications();
      }
    );
  }

  private reloadNotifications() {
    this.notificationService.getNotifications()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        data => {
          this.store.dispatch(retrieveNotifications({payload:data}));
        }
      )
  }
}
