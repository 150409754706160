import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ves-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit {

  @Input() audioUrl: string;

  @Input() title: string;

  @Input() small = false;

  constructor() { }

  ngOnInit() {
  }

}
