import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {SnackbarMessage} from '../models/snackbar-message';

@Injectable({ providedIn: 'root' })
export class SharedSnackbarService {

  private _message = new Subject<SnackbarMessage>();
  readonly newMessageEvent$ = this._message.asObservable();

  constructor() {}

  push(message: SnackbarMessage) {
    this._message.next(message);
  }
}
