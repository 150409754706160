import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {VersionStatus} from '../../../models/vestrata/Version';
import {TranslateService} from '@ngx-translate/core';
import * as moment_ from 'moment';
import {FourEyeComponent} from "../../4eye-component";
import {MatDatepicker} from '@angular/material';
import {DateAdapter} from "@angular/material/core";

const moment = moment_;

@Component({
  selector: 'ves-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent extends FourEyeComponent<any> implements OnInit, OnChanges {

  @Input() id: string;
  @Input() labelText: string;
  @Input() reset: boolean = false;
  @Input() placeholder: string;
  @Input() type: 'date' | 'month' = 'date';
  @Input() min: any;
  @Input() max: any;

  equals(a: Date, b: Date): boolean {
    return a === b;
  }

  _placeholder: string;
  date: Date;

  @ViewChild('date-picker-container') container: ElementRef;
  @ViewChild('icon-container') icon: ElementRef;
  @ViewChild('myDatepicker') datePicker;

  focused: boolean = false;

  constructor(private renderer: Renderer2, private dateAdapter: DateAdapter<Date>,
              private translateService: TranslateService) {
    super();
    const lang = navigator.language || window.navigator.language;
    this.dateAdapter.setLocale(lang);
  }

  ngOnInit() {
    if (this.pendingStatus && this.pendingStatus !== VersionStatus.EDITED && this.pendingStatus !== VersionStatus.SAVED &&
      this.pendingStatus !== VersionStatus.APPROVED) {
      this.locked = true;
    }

    if (this.placeholder) {
      this._placeholder = this.translateService.instant(this.placeholder);
    } else {
      this._placeholder = this.translateService.instant('datepicker.date');
    }
    if (this._editingValue) {
      this.date = new Date(this._editingValue);
    }
    if (this.min) {
      this.min = moment(this.min).toDate();
      console.log(this.min);
    }
    if (this.max) {
      this.min = moment(this.min).toDate();
      console.log(this.min);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editingValue && changes.editingValue.currentValue) {
      this.date = new Date(this._editingValue);
    }

    if (changes.min && changes.min.currentValue) {
      this.min = moment(this.min).toDate();
      console.log(this.min);
    }

    if (changes.max && changes.max.currentValue) {
      this.max = moment(this.max).toDate();
      console.log(this.max);
    }
  }

  onFocus() {
    if (!this.getState().readonly) {
      this.focused = true;
      this.datePicker.open();
    }
  }

  onPickerClose() {
    this.focused = false;
    if (this.date) {
      this._editingValue = moment(this.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
      this.editingValueChange.emit(this._editingValue);
    }
  }

  resetDate() {
    this._editingValue = '';
    if(this.reset) {
      this.editingValueChange.emit(this._editingValue);
      this.date = new Date(this._editingValue);
    }
  }

  chosenYearHandler(event: any) {
    this.date = event;
  }

  chosenMonthHandler(event: any, datepicker: MatDatepicker<Date>) {
    this.date = event;
    datepicker.close();
  }

}
