import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ves-target-market-option',
  templateUrl: './target-market-option.component.html',
  styleUrls: ['./target-market-option.component.scss']
})
export class TargetMarketOptionComponent implements OnInit {

  @Input() label: string;
  @Input() value: string;
  @Input() type = 0;
  @Input() locked = true; // v1 theres no editing of target market.

  constructor( private translate: TranslateService) { }

  ngOnInit(): void {
  }

}
