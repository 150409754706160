import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ves-shared-currency-box',
  templateUrl: './shared-currency-box.component.html',
  styleUrls: ['./shared-currency-box.component.scss']
})
export class SharedCurrencyBoxComponent implements OnInit {

  @Input() currency: any;

  constructor() { }

  ngOnInit(): void {}
}
