import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ves-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnInit {

  @Input() title: string;

  @Input() label: string;

  @Input() style: 'azure' | 'jade' | 'butter' = 'azure';

  @Input() size: 'small' | 'big' = 'big';

  constructor() { }

  ngOnInit(): void {
  }
}
