import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {ApiHttpService} from '../api.http.service';
import {forkJoin, Observable} from 'rxjs';
import {SubmissionDto} from '../../models/vestrata/SubmissionDto';
import {DiscretionaryStatistic} from "../../models/vestrata/DiscretionaryStatistic";


@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public serviceUrl: string;

  constructor(private http: HttpClient,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getModelSubmission(): Observable<SubmissionDto> {
    return this.http.get<SubmissionDto>(this.serviceUrl + 'dashboard/portfolio-model-distribution', ApiHttpService.getHeaders());
  }

  getDiscretionaryStatistics(lang: string): Observable<DiscretionaryStatistic[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('lang', lang);
    return this.http.get<DiscretionaryStatistic[]>(this.serviceUrl + 'dashboard/discretionary', ApiHttpService.getOptionsWithParams(httpParams));
  }

}
