import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "@ngx-config/core";
import {VesConfService} from "./conf.service";
import {Observable} from "rxjs";
import {Metric} from "../../models/vestrata/Metric";
import {ApiHttpService} from "../api.http.service";
import {TimePeriod} from "../../models/vestrata/TimePeriod";
import {MetricData} from "../../models/vestrata/MetricData";
import {Market} from "../../models/vestrata/Market";

@Injectable({
  providedIn: 'root'
})
export class MarketService {

  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getAllMarket(): Observable<Market[]> {
    return this.http.get<Market[]>(this.serviceUrl + 'market',  ApiHttpService.getHeaders());
  }

  getMarketById(alertId: string): Observable<Market> {
    return this.http.get<Market>(this.serviceUrl + 'market/' + alertId,  ApiHttpService.getHeaders());
  }

}
