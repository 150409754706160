import {Injectable} from '@angular/core';
import {CanDeactivate, Router} from '@angular/router';
import {ComponentCanDeactivate} from 'vestrata-components-lib';
import {TranslateService} from '@ngx-translate/core';

// this guard is is to catch page changes for use with unsaved changes to prevent loss.

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private translate: TranslateService ) {
  }

  canDeactivate(component: ComponentCanDeactivate): boolean {
    console.log(component);
    if (!component.equals()) {
      if (confirm(this.translate.instant('unsaved-info.message'))) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
