import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {FourEyeComponent} from "../../4eye-component";

@Component({
  selector: 'ves-video-box',
  templateUrl: './video-box.component.html',
  styleUrls: ['./video-box.component.scss']
})
export class VideoBoxComponent extends FourEyeComponent<string> implements OnInit, OnChanges {

  securedUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    if (this._editingValue) {
      this.securedUrl = this.sanitizeVideoUrl(this._editingValue);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes?.editingValue?.currentValue) {
      this.securedUrl = this.sanitizeVideoUrl(this._editingValue);
    } else {
      this.securedUrl = null;
    }
  }

  equals(a: string, b: string): boolean {
    return a === b;
  }

  sanitizeVideoUrl(url: string): SafeResourceUrl {
    if (url != null && url.trim() !== '') {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = url.match(regExp);
      if (match && match[2].length === 11) {
        url = url.replace(url, 'https://www.youtube.com/embed/' + match[2]);
      } else {
        return null;
      }
      // if (url.startsWith('https://youtu.be/')) {
      //   url = url.replace('https://youtu.be/', 'https://www.youtube.com/embed/');
      // } else {
      //   url = url.replace('watch?v=', 'embed/');
      // }
      // this.currentTranslation.video = url;
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

}
