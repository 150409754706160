import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, ViewEncapsulation} from '@angular/core';


import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CurrencyPipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {PortfolioModel, PortfolioModelVersion} from "../../models/vestrata/PortfolioModel";
import {ICostCalculatorInterface} from "../../api/interface.services/ICostCalculatorInterface";
import {CostCalculation} from "../../models/vestrata/CostCalculation";
import {AmountPipe} from "../../pipes/amount.pipe";

@Component({
  selector: 'app-shared-model-costs',
  templateUrl: './shared-model-costs.component.html',
  styleUrls: ['./shared-model-costs.component.scss'],
  providers: [CurrencyPipe],
  encapsulation: ViewEncapsulation.None,
})
export class SharedModelCostsComponent implements OnInit, OnChanges, OnDestroy {

  private static readonly INITIAL_AMOUNT = 10000;
  private static readonly MONTHS_COST = 60;

  private _onDestroy = new Subject();

  @Input() model: PortfolioModel;
  @Input() isBank = false;
  @Input() ICostCalculatorInterface: ICostCalculatorInterface;
  version: PortfolioModelVersion;
  costCalculation: CostCalculation = new CostCalculation();

  currency: string;
  summaryParams: any;
  currentLang: string;
  cp: AmountPipe
  constructor(private translate: TranslateService) {
    this.cp = new AmountPipe(translate);
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe(event => {
      if (event.lang) {
        this.currentLang = this.translate.currentLang;
        this.summaryParams.amount = this.cp.transform(this.costCalculation.initialInvestment, this.model.currency);
      }
    });
  }

  ngOnInit() {
    if (this.model) {
      this.reload();
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange  }) {
    this.reload();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  reload() {
    this.currency = this.model.currency;
    if (this.model.pending != null && this.model.pending.data != null)  {
      this.version = this.model.pending.data;
    } else if (this.model.current != null && this.model.current.data != null) {
      this.version = this.model.current.data;
    }

    this.ICostCalculatorInterface.getCostCalculator(this.version, SharedModelCostsComponent.INITIAL_AMOUNT, SharedModelCostsComponent.MONTHS_COST, this.model.id)
      .pipe(takeUntil(this._onDestroy)).subscribe(item => {
        console.log('costs');
        console.log(item);
        this.costCalculation = item;
        this.summaryParams = {
          amount: this.cp.transform(this.costCalculation.initialInvestment, this.model.currency),
          years: SharedModelCostsComponent.MONTHS_COST / 12,
          return: this.version.annualReturnTarget,
        };
    });
  }
}
