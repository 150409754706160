import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import { Chart } from 'chart.js';

@Component({
  selector: 'ves-mandates',
  templateUrl: './mandates.component.html',
  styleUrls: ['./mandates.component.scss']
})
export class MandatesComponent implements OnInit, OnDestroy, AfterViewInit {
  private _onDestroy = new Subject();
  chartLabels = [];
  colors = [];
  values = [];

  @ViewChild('minorCanvas') minorCanvas: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // Manage Chart
    this.setChart();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  setChart() {
    this.values.push(25.45);
    this.values.push(30.45);
    this.values.push(44.10);
    this.chartLabels.push('Discretionary');
    this.chartLabels.push('Advisory');
    this.chartLabels.push('Execution');
    this.colors.push('#0087D0');
    this.colors.push('#004D64');
    this.colors.push('#00D6CE');
    this.initMinorChart(this.values, this.chartLabels, this.colors);
  }

  initMinorChart(values: any[], chartLabels: string[], colors: string[]): Chart {
    const borderColors = values.map(v => '#ffffff');
    return new Chart(this.minorCanvas.nativeElement.getContext('2d'), {
      type: 'doughnut',
      data: {
        datasets: [{
          labels: chartLabels,
          data: values,
          backgroundColor: colors,
          borderColor: borderColors,
          hoverBorderColor: borderColors,
          borderWidth: 4
        }]
      },
      options: {
        tooltips: {
          enabled: false

        }
      }
    });
  }

}
