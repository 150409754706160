import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ves-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {

  @Input() size: 'xs' | 'm' = 'xs';

  @Input() value;

  @Input() color: 'default' | 'primary' | 'secondary' | 'tertiary' | 'white' = 'default'

  constructor() { }

  ngOnInit(): void {
  }

}
