
export class Metric {
  id: string;
  data: MetricLocal [] = [];
  timePeriods: MetricTimePeriod[] = [];
  mandatory: boolean;
}

export class MetricTimePeriod {
  timePeriodId: string;
  mandatory: boolean;
}
export class MetricLocal {
  lang: string;
  name: string;
  description: string;
}
export enum CalendarMetricName {
  MONTH = 'M',
  YEAR = 'Y',
  QUARTER  ='Q'
}
export enum TrailingMetricName {
  MONTH1 = 'M1',
  MONTH3 = 'M3',
  MONTH6 = 'M6',
  YEAR1 = 'Y1',
  YEAR3 = 'Y3',
  YEAR5 = 'Y5',
  YEAR10 = 'Y10',
  SINCE_INCEPTION = 'SI'

}
