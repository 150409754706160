import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Alert, AlertLocal} from '../../../models/vestrata/Alert';
import {
  BlocOption, FilterChild,
  FilterOption
} from '../../../basic-components/components-5/two-level-bar-filter/two-level-bar-filter.component';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../../api/services/alert.service';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {askReload} from '../../../ngrx/actions/alert.actions';
import {takeUntil} from 'rxjs/operators';
import {cloneDeep} from 'lodash';

@Component({
  selector: 'ves-shared-alert-list',
  templateUrl: './shared-alert-list.component.html',
  styleUrls: ['./shared-alert-list.component.scss']
})
export class SharedAlertListComponent implements OnInit, OnDestroy, AfterViewInit {

  private static ALERTSIZE = 175;

  @Output() alertNumberChange = new EventEmitter<number>();
  @Output() advancedViewChange = new EventEmitter<boolean>();
  @Input() portal: string;
  alerts: any[];
  currentAlerts: any[] = [];
  filteredAlerts: Alert[];
  blocFilterOptions: BlocOption[] = [];
  sortField: string;
  sortOrder = false; // false for asc true for desc
  showMuted = false;
  _onDestroy = new Subject();
  isAdvanced = false;

  categoryTypes: any[] = [];
  categoryCount: number;

  pageSize: number;
  currentPage: number;
  pages: Alert[][] = [];

  constructor(private store: Store<any>, private translate: TranslateService, private alertService: AlertService,
              private snackbar: SharedSnackbarService) { }

  ngOnInit() {
    this.subscribeToAlertStore();
  }

  ngAfterViewInit() {
    const container = document.getElementById('alerts-container');
    console.log('ALERTS VIEW INIT');
    console.log('scroll:', container.scrollWidth);
    console.log('offset', container.offsetWidth);
    console.log('client', container.clientWidth);
    const width = container.scrollWidth;
    this.pageSize = Math.floor(width / SharedAlertListComponent.ALERTSIZE);
    this.askForLoadAlerts();
  }

  private askForLoadAlerts() {
    this.store.dispatch(askReload());
  }

  private subscribeToAlertStore() {
    this.store.select('retrieveAlerts').pipe(takeUntil(this._onDestroy)).subscribe(
      state => {
        // Extract category types and making a new array of them
        const filteredArr = state.allAlerts.map(t =>  {
          return {label: t.category, active: false};
        });
        this.categoryTypes = filteredArr.reduce((thing, current) => {
          const x = thing.find(item => item.label === current.label);
          if (!x) {
            return thing.concat([current]);
          } else {
            return thing;
          }
        }, []);

        console.log('***STATE Alerts***', state);
        this.alerts = cloneDeep(state.allAlerts);

        this.configureFlatAlertList();
        this.alertNumberChange.emit(this.alerts.length);
      }
    );
  }
  // -----ACTIONS------------
  showLessMore() {
    this.isAdvanced = !this.isAdvanced;
    this.advancedViewChange.emit(this.isAdvanced);
  }
  sortByField(field: string) {
    this.sortOrder = this.sortField === field ? !this.sortOrder : false;
    this.sortField = field;
    this.filteredAlerts.sort((a, b) => this.compare(a, b, field) );
  }
  compare( a, b, field ) {
    if ( a[field] < b[field] ) {
      return this.sortOrder ? -1 : 1;
    }
    if ( a[field] > b[field] ) {
      return this.sortOrder ? 1 : -1;
    }
    return 0;
  }
  // muteAlert(alert: Alert){
  //   if (alert.state === 'MUTE'){
  //     return;
  //   }
  //   this.alertService.muteAlert(alert).pipe(takeUntil(this._onDestroy))
  //     .subscribe(
  //       data => {
  //         alert.isMuted = true;
  //       },
  //       error => {
  //         this.snackbar.push(new SnackbarMessage(this.translate.instant('notifications.cannot-mute-alert'), 'error'));
  //       }
  //     )
  // }
  navigateForAction(alert: Alert) {
    // this.performAlertAction.emit(alert);
    this.alertService.takeAlertAction(alert, this.portal);
  }
  // -------------------------


  configureFlatAlertList() {
    this.resetFilterCounts();
    this.alerts.forEach( alrt => {
      alrt.title = this.getAlertFromLocal(alrt).title;
      alrt.text = this.getAlertFromLocal(alrt).text;
      alrt.categoryColorClass = this.getCategoryColorClass(alrt);
      this.updateFilterOptions(alrt);
    });
    this.filterAlerts();

  }
  // TODO This function must be simplified
  private filterAlerts() {
    // first filter is to show muted or not
    this.filteredAlerts = this.alerts.filter(alrt => this.showMuted || !alrt.isMuted);

    const categories = [];
    this.blocFilterOptions.forEach(bloc => {
      if (bloc.options && bloc.options.length > 0) {
        bloc.options.forEach(option => {
          if (option.checked) {
            categories.push({category: option.value, subcats: []});
          }
          option.childs.forEach(child => {
            if (child.checked) {
              let prnt = categories.find(cat => cat.category === option.value);
              if (!prnt) {
                prnt = {category: option.value, subcats: []};
                categories.push(prnt);
              }
              prnt.subcats.push(child.value);
            }
          });

        });
      }
    });
    if (categories.length > 0) {
      let tempList = [];
      categories.forEach(cat => {
        tempList = tempList.concat(this.filteredAlerts.filter(alert =>
          alert.category === cat.category && (cat.subcats.length ===  0 || cat.subcats.indexOf(alert.subCategory) >= 0 )
        ));
      });
      this.filteredAlerts = tempList;

    }
    if (this.filteredAlerts.length > this.pageSize) {
      this.setPages(Math.ceil(this.filteredAlerts.length / this.pageSize));
    } else {
      this.setPages(Math.ceil(1));
    }

  }
  getCategoryColorClass(alert: Alert) {
    if (alert.isMuted) {
      return  'cat-muted';
    }

    return 'cat-' + alert.category.toLowerCase();

  }
  getAlertFromLocal(alert): AlertLocal {
    const local = this.translate.currentLang;
    let ret = alert.localized.find(s => s.lang === local );
    if (!ret) {
      ret = alert.localized.find(s => s.lang === 'en' );
    }
    return ret;
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private updateFilterOptions(alert: Alert) {
    // test if no bloc Existent
    if (this.blocFilterOptions.length === 0) {
      const blocOption = new BlocOption();
      blocOption.label = 'notifications.categories'; blocOption.options = [];
      this.blocFilterOptions.push(blocOption);
    }

    // check for parent if existing
    let parentCategory = this.blocFilterOptions[0].options.find(option => alert.category === option.value);
    if (!parentCategory) {
      parentCategory = new FilterOption(alert.category, alert.category, 0, true, true, []);
      this.blocFilterOptions[0].options.push(parentCategory);
    }
    parentCategory.totalCount++;
    // check if subCategegory
    let filterChild = parentCategory.childs.find(child => child.value === alert.subCategory);
    if (!filterChild) {
      filterChild =  new FilterChild(alert.subCategory, alert.subCategory, true, 0);
      parentCategory.childs.push(filterChild);
    }
    filterChild.totalCount++;
  }

  private resetFilterCounts() {
    if (!this.blocFilterOptions || this.blocFilterOptions.length === 0) {
      return;
    }
    this.blocFilterOptions.forEach(bloc => {
      bloc.options.forEach(option => {
        option.totalCount = 0;
        option.childs.forEach(child => child.totalCount = 0);
      });
    });
  }

  onFiltersChange(event) {
    console.log(event);
    this.blocFilterOptions = event;
    this.categoryTypes.forEach((items) => items.active = false);
    this.filterAlerts();
  }

  showMutedChange(event) {
    this.showMuted = event;
    this.filterAlerts();
  }

  canPerformAction(alert: Alert) {
    return this.alertService.getAlertAction(alert, this.portal);
  }

  onChangeCategoryTypeSelected(active: any, type: any) {
    let tempList = [];

    if (this.categoryTypes.find(item => !item.active && item.label === type.label)) {
      this.categoryTypes.forEach((items) => {
        items.active = false;
      });
      this.categoryTypes.find(item => item.label === type.label).active = true;
      this.alerts.forEach(() => {
        tempList = this.alerts.filter(alert => alert.category === type.label);
      });
    }

    if (tempList.length > 0) {
      this.filteredAlerts = tempList;
    } else {
      this.filteredAlerts = this.alerts;
    }
  }

  categoryCounting(type: any) {
    let counter = [];
    this.alerts.forEach(() => {
      counter = this.alerts.filter(alert => alert.category === type);
    });
    return this.categoryCount = counter.length;
  }

  setPages(num) {
    this.pages = [];
    for ( let i = 0; i < num; i++) {
      this.pages.push(this.alerts.slice( (i * this.pageSize), this.pageSize));
    }
    this.changePage(0);
  }

  changePage(index) {
    this.currentPage = index;
    const start = index * this.pageSize;
    const end = (index + 1 ) * this.pageSize;

    if (end > this.filteredAlerts.length) {
      this.currentAlerts = this.filteredAlerts.slice(start);
    } else {
      this.currentAlerts = this.filteredAlerts.slice(start , end);
    }
  }
}
