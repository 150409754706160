import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ves-tag-label',
  templateUrl: './tag-label.component.html',
  styleUrls: ['./tag-label.component.scss']
})
export class TagLabelComponent implements OnInit {

  @Input() label: string;
  @Input() img: string;
  @Input() color: 'jade' | 'azure' | 'cinnamon' | 'cyan' | 'infinite' |'fossil' = 'fossil';

  constructor() { }

  ngOnInit() {
  }

}
