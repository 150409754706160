import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  Insight,
  InsightLocal, InsightVersion,
} from '../../../models/vestrata/Insight';
import {Permission} from '../../../models/vestrata/Permission';
import {UtilsService} from '../../../api/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {TabLanguage} from '../../../models/vestrata/TopMenuItem';
import {AuthenticationService} from '../../../api/services/authentication.service';
import {Article, ArticleVersion} from '../../../models/vestrata/Article';

@Component({
  selector: 'ves-shared-insights-maintenance',
  templateUrl: './shared-insights-maintenance.component.html',
  styleUrls: ['./shared-insights-maintenance.component.scss']
})
export class SharedInsightsMaintenanceComponent implements OnInit {

  @Input() permission: Permission;

  tabs: TabLanguage [] = [];
  currentLanguage: string;
  insight: Insight;
  editingInsight: InsightVersion;

  constructor(private utilsService: UtilsService,
              private translate: TranslateService,
              private authService: AuthenticationService
  ) {
    // this.permission = this.permission ? this.permission : this.authService.getPermissions().ips.insights;
    this.tabs.push(new TabLanguage('en'));
  }

  ngOnInit() {
    this.currentLanguage = this.translate.currentLang;
  }

  clickOnTranslation(lang) {
    this.currentLanguage = lang;
  }

  removeLanguage(lang) {
    this.utilsService.removeFromIterable(this.tabs, lang);

    this.tabs.forEach((item, index) => {
      if (item.name === lang) {
        this.tabs.splice(index, 1);
      }
    });

    this.editingInsight.locales.forEach((sl, index) => {
      if (sl.lang === lang) {
        this.editingInsight.locales.splice(index, 1);
      }
    });
  }

  addLanguage(language: string) {
    if (this.editingInsight.locales.filter(s => s.lang === language).length === 0) {
      const insightLocal = new InsightLocal();
      insightLocal.lang = language;
      insightLocal.title = '';
      insightLocal.externalUrl = '';
      insightLocal.description = '';
      this.editingInsight.locales.push(insightLocal);
      this.tabs.push(new TabLanguage(language));
    }
  }

  onEditingInsightLoad(article: ArticleVersion<any>) {
    this.editingInsight = article as InsightVersion;
    this.editingInsight.locales.forEach(l => {
      if (this.tabs.filter(t => t.name === l.lang).length === 0) {
        this.tabs.push(new TabLanguage(l.lang));
      }
    });
  }

  onArticleLoad(article: Article<any>) {
    this.insight = article as Insight;
  }
}
