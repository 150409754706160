import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { Chart } from 'chart.js';
import {cloneDeep} from 'lodash';
import {ModelBenchmark} from "../../../models/vestrata/AdvisoryModel";
import {UtilsService} from "../../../api/utils.service";
import {BenchmarkInfo} from "../../../models/vestrata/BenchmarkInfo";
import {BenchmarkPercentage} from "../../../models/vestrata/Benchmark";

@Component({
  selector: 'ves-mifid-benchmarks-chart',
  templateUrl: './mifid-benchmarks-chart.component.html',
  styleUrls: ['./mifid-benchmarks-chart.component.scss']
})
export class MifidBenchmarksChartComponent implements OnInit, OnChanges, AfterViewInit {


  @Input() modelBenchmarks: BenchmarkPercentage[];
  data: BenchmarkPercentage[];
  @Input() showRemaining = false;
  @ViewChild('canvas') canvas: ElementRef;
  private chart: Chart;
  private colors: string[];

  constructor(private utils: UtilsService) { }

  ngOnInit(): void {
    /*this.sortData();
    console.log('Benchmark chart init');
    this.colors = this.data.map((mb, index) => this.getHexColor(mb.benchmark, index));
    if (this.chart) {
      this.chart = this.initChart(
        this.data.map(mb => mb.benchmark ? mb.benchmark.name : 'Remaining'),
        this.data.map(mb => mb.percentage),
        this.colors);
    }*/
  }

  ngAfterViewInit(): void {
    this.sortData();
      if (this.data) {
        this.colors = this.data.map((mb, index) => this.getHexColor(mb.benchmark, index));
        this.chart = this.initChart(
          this.data.map(mb => mb.benchmark ? mb.benchmark.name : 'Remaining'),
          this.data.map(mb => mb.percentage),
          this.colors);
      }

  }

  private sortData() {
    this.data = Object.assign([], this.modelBenchmarks);
    if (!this.data || this.data.length === 0) {
      return
    }
    this.data.sort((a,b) => b.percentage - a.percentage );
    if (this.showRemaining) {
      let sum = 0;
      this.data.forEach(a => sum += a.percentage);
      if ((100 - sum) > 0) {
        const mb = new BenchmarkPercentage();
        mb.percentage = 100 - sum;
        this.data.push(mb);
      }
    }
  }

  getHexColor(benchmark: BenchmarkInfo, index: number) {
    if (!benchmark) {
      return '#a36b00';
    }

    return this.shadeColor('#175c8c', index * 40);

  }

  initChart(labels: any[], values: any[], colors: string[]): Chart {
    const borderColors = values.map(v => '#ffffff');
    if (this.canvas == null) { return null; }
    return new Chart(this.canvas.nativeElement.getContext('2d'), {
      type: 'doughnut',
      data: {
        datasets: [{
          labels: labels,
          data: values,
          backgroundColor: colors,
          borderColor: borderColors,
          hoverBorderColor: borderColors,
          borderWidth: 4
        }]
      },
      options: {
        tooltips: {
          displayColors: true,
          callbacks: {
            label(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const index = tooltipItem.index;
              return dataset.labels[index] + ": " + dataset.data[index] + " %";
            },
          },
        }
      }
    });
  }

  shadeColor(color, percent: number) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt('' + (R * (100 + percent) / 100));
    G = parseInt('' + (G * (100 + percent) / 100));
    B = parseInt('' + (B * (100 + percent) / 100));

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Benchmark chart changes', changes);
    if ( changes && changes.modelBenchmarks) {
      this.sortData();
      console.log('Benchmark chart changes');
      this.colors = this.data.map((mb, index) => this.getHexColor(mb.benchmark, index));
      if (this.chart) {
        this.chart = this.initChart(
          this.data.map(mb => mb.benchmark ? mb.benchmark.name : 'Remaining'),
          this.data.map(mb => mb.percentage),
          this.colors);
      }
    }
  }
}
