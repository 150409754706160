import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { VersionStatus } from '../../../models/vestrata/Version';
import {FourEyeComponent} from "../../4eye-component";
import {SearchOption} from "../../components-5/search-bar/search-bar.component";

@Component({
  selector: 'ves-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends FourEyeComponent<string> implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  // set the Id to be different from the other for testing purpose
  @Input() id: string;
  // set the input Type available values (0 to 10) explanation at the end with the enum InputType
  @Input() type: InputType = InputType.GENERAL;
// if rows = 1 then simple input text | is rows > 1 the textarea
  @Input() rows: number = 1;
  // enable the Quill Editor (rich text editor)
  @Input() richText: boolean = false;
  // set the maxlength to prevent inputting more then maxlength characters (available for text, textarea and quillEditor
  @Input() maxlength: number = 75;

  // set the label associated to the input (it will be translated)
  @Input() labelText: string;
  // set the Mandatory flag to add an asterisk on the Label to make it more visible
  @Input() mandatory: boolean = false;
  // set the placeholder associated to the input (it will be translated)
  @Input() placeholder: string = '';
  // Display the maxlengh tooltip if needed.
  @Input() showMaxLength = true;

  // set the autocomplete for the simple input text
  @Input() autocomplete: string = '';

  // used if text input want to transform to autocomplete search input
  @Output() textOut = new EventEmitter<any>();
  @Output() selectOption = new EventEmitter<SearchOption>();
  @Input() viewSearchId = false;
  @Input() options: SearchOption[] = [];
  @Input() viewSearchInput = false;
  filteredOptions: SearchOption[];
  isOpen = false;

  // used if you want to set a specific image in front of the input
  @Input() imageUrl: string;

  @Input() width: number;
  @Input() alignCenter: boolean = false;
  @Input() alignRight: boolean = false;
  @Input() optional: boolean = false;
  @Input() min: number;
  @Input() max: number;

  @Output() validated     = new EventEmitter<string>();

  @ViewChild('label') label: ElementRef;
  @ViewChild('image') image: ElementRef;
  @ViewChild('input') input: ElementRef;

  focused = false;

  private _onDestroy = new Subject();
  private readonly imageSrc = '../../../../assets/images/components/components-2/';

  constructor( private renderer: Renderer2,
               private translateService: TranslateService) {
    super();
  }

  ngOnInit() {}

  equals(a: string, b: string): boolean {
    return a === b;
  }


  ngOnChanges(changes: SimpleChanges): void {

    if ( this.type === InputType.NUMBER) { this.showMaxLength = false; }

    if (changes && changes.options && changes.options.currentValue.length) {
      console.log('changes', changes);
      this.filterOptions();
    }
  }

  ngAfterViewInit(): void {
    // ID is mandatory.
    if (!this.id) {
      throw new ReferenceError('You must set a unique ID to use ' + InputComponent.name);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onFocus() {
    this.focused = true;
    this.applyFocused();
  }

  getTranslation() {
    return !this.getState().readonly && this.placeholder ? this.translateService.instant(this.placeholder) : '';
  }

  getAlign() {
    let ret = '';
    if (this.alignCenter) {
      ret = 'center';
    } else if (this.alignRight) {
      ret = 'right';
    }
    return ret;
  }

  onlyNumberKey(event) {
    if (this.type === InputType.NUMBER) {
      const charCode = (event.query) ? event.query : event.key.charCodeAt(0);
      if (charCode === 45 || charCode === 46) {
        return true;
      } else if (charCode > 31
        && (charCode < 48 || charCode > 57)) {
        return false;
      }
    }
    return true;
  }

  onFocusOut(value: string) {
    this.removeFocused();
    this.focused = false;
    this.validated.emit(value);
  }

  hasImage(): boolean {
    return this.imageUrl != null || (this.type !== InputType.GENERAL && this.type !== InputType.PASSWORD  && this.type !== InputType.EMAIL  && this.type !== InputType.NUMBER && this.type !== InputType.AUTOCOMPLETE_SEARCH );
  }

  isSocial() {
    return this.type !== InputType.GENERAL && this.type !== InputType.PASSWORD && this.type !== InputType.WEBLINK  && this.type !== InputType.EMAIL  && this.type !== InputType.NUMBER && this.type !== InputType.AUTOCOMPLETE_SEARCH;
  }

  getDataType(): string {
    let dataType = 'text';
    switch (this.type) {
      case InputType.PASSWORD: { dataType = 'password'; break; }
      case InputType.EMAIL: { dataType = 'email'; break; }
      case InputType.NUMBER: { dataType = 'number'; break; }
      case InputType.AUTOCOMPLETE_SEARCH: { dataType = 'autocomplete'; break; }
    }
    return dataType;
  }

  getInputImage(): string {
    let image = this.imageUrl;
    switch (this.type) {
      case InputType.WEBLINK:
        image = this.imageSrc;

        if (this.focused) {
          image += 'icon-weblink-focus.svg';
        } else if (this.getState().state === VersionStatus.EDITED) {
          image += 'icon-weblink-edited.svg';
        } else if (this.getState().state === VersionStatus.SAVED) {
          image += 'icon-weblink-saved.svg';
        } else if (this.getState().state === VersionStatus.REQUEST) {
          image += 'icon-weblink-submitted.svg';
        } else if (this.getState().readonly) {
          image += 'icon-weblink-disabled.svg';
        } else {
          image += 'icon-weblink.svg';
        }
        break;
      case InputType.FACEBOOK:
        image = this.imageSrc + 'icon-facebook.svg'; break;
      case InputType.INSTAGRAM:
        image = this.imageSrc + 'icon-instagram.svg'; break;
      case InputType.TWITTER:
        image = this.imageSrc + 'icon-twitter.svg'; break;
      case InputType.LINKEDIN:
        image = this.imageSrc + 'icon-linkedin.svg'; break;
      case InputType.YOUTUBE:
        image = this.imageSrc + 'icon-youtube.svg'; break;
    }
    return image;
  }

  onChange(value: any) {
    if (!(value instanceof Event)) {
      // Convert value to number if needed.
      if (value && this.type === InputType.NUMBER ) {
        value = +value;
      }
      this.editingValueChange.emit(value);
    }
  }


  isBlocked(): boolean {
    return this.getState().readonly || this.getState().state === VersionStatus.REQUEST;
  }

  getImageStyleClass(status: VersionStatus, blocked: boolean) {
    let imageClass = this.getState().state;
    if (this.isSocial()) {
      imageClass += ' social';
    }
    return imageClass;
  }

  private applyFocused() {
    // Apply style only in 4eyes mode or if style is focused/;
    if (!this.getState().readonly) {
      if (this.label) {
        this.renderer.addClass(this.label.nativeElement, 'focused');
      }
      if (this.image) {
        this.renderer.addClass(this.image.nativeElement, 'focused');
      }
      if (this.input) {
        this.renderer.addClass(this.input.nativeElement, 'focused');
      }
    }

}

  private removeFocused() {
    if (!this.getState().readonly) {
      if (this.label) {
        this.renderer.removeClass(this.label.nativeElement, 'focused');
      }
      if (this.image) {
        this.renderer.removeClass(this.image.nativeElement, 'focused');
      }
      if (this.input) {
        this.renderer.removeClass(this.input.nativeElement, 'focused');
      }
    }
  }

  textChanged($event) {
    if ($event.editor.getLength() > this.maxlength) {
      $event.editor.deleteText(this.maxlength, $event.editor.getLength());
    }
  }

  onChangeRich() {
    this.editingValueChange.emit(this._editingValue);
  }

  getWidth(): string {
    let ret = '';
    if (this.width) {
      ret = this.width + 'px';
    }
    return ret;
  }

  onClick(item: SearchOption) {
    this.isOpen = false;
    this.selectOption.emit(item);
    this._editingValue = item.text;
  }
  notFound() {
    this.isOpen = false;
  }
  filterOptions() {
    console.log('searchText', this._editingValue);
    if (this._editingValue && this._editingValue.length > 1) {
      this.isOpen = true;
      this.filteredOptions = [];
      this.filteredOptions = this.options;
    } else {
      this.isOpen = false;
    }
  }
  textChangedSearch() {
    this.textOut.emit(this._editingValue);
  }
  toggleSearch() {
    this.viewSearchInput = !this.viewSearchInput;
  }

}

export enum InputType {
  GENERAL,
  WEBLINK,
  FACEBOOK,
  INSTAGRAM,
  TWITTER,
  LINKEDIN,
  YOUTUBE,
  PASSWORD,
  NUMBER = 8,
  EMAIL,
  AUTOCOMPLETE_SEARCH = 10
}
