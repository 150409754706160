import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropdownItem} from '../multivalue-dropdown/multivalue-dropdown.component';

@Component({
  selector: 'ves-multivalue-subitem',
  templateUrl: './multivalue-subitem.component.html',
  styleUrls: ['./multivalue-subitem.component.scss']
})
export class MultivalueSubitemComponent implements OnInit {

  @Input() item: DropdownItem;
  @Input() level: number;
  @Output() itemStatusChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onItemStatusChanged(event: {selected: boolean, item: DropdownItem}) {
    event.item.selected = event.selected;
    this.itemStatusChanged.emit({selected: event.selected, item: event.item});
  }

  getTextClass(item: DropdownItem): string {
    let clazz = '';
    if (this.level === 2) {
      clazz = 'level2';
    } else if (item.selected) {
      clazz = 'selected';
    }
    return clazz;
  }

}
