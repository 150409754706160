import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class MSComponentService {

  static lazyLoad(containerParent: any, containerId: any, componentTagName: string, componentId: string) {
    const morningstar = (<any> window).morningstar;
    return morningstar.loader
      .lazyLoad(containerParent, containerId, componentTagName, componentId);
  }

  static lazyRemove(containerId: string) {
    const morningstar = (<any> window).morningstar;
    morningstar.loader.lazyRemove(containerId);
  }


  static initComponent(maasToken: string, currentLang: string, cb: any ) {
    const morningstar = (<any> window).morningstar;
    morningstar.loader
      .load({
        lazy: {
          lazyLoadComponent: true,
          lazyDownloadComponent: true,
          bundlesToDownload: {
            ecSecurityReportLoader: true,
            ecXray: true
          }
        },
        componentSource: {
          instid: 'INSO'
        },
        configuration: {
          environment: 'prod',
          namespace: 'investasolutionec.OPP643403.app',
          overrides: {
            settings: {
              languageId: currentLang,
              idType: 'ISIN',
            }
          }
        },
        apiTokens: {
          apiGatewayToken: maasToken
        },
        css: {
          includeComponentCss: true,
          mds: {
            style: true,
            icons: true,
            version: '2.4.0'
          }
        }
      }).then(function (manifest) {
      console.log('Config loaded!');

      cb();
    })
      .catch((error) => {
        console.error('Error', error);
        cb();
      });
  }
}
