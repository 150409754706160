import {InvestmentObjective, InvestmentObjectiveLocal, InvestmentObjectiveLocalWithId} from './InvestmentObjective';
import { Version } from './Version';
import {VestrataDocument, VestrataDocumentInfo} from './VestrataDocument';

export class Strategy {
  id: string;
  partnerId: string;
  current: Version<StrategyData>;
  pending: Version<StrategyData>;
  numberModels: number;
  numberObjectives: number;
  creationDate: string;
  createdBy: string;
  blockedInvestmentObjectiveIds: string[];
  partnerName: string;
  partnerPicture: string;

  static getStrategyName(strategy: Strategy, currentLang: string): string {
    let strategyName: string;
    if (strategy.current != null) {
      if (strategy.current.data.strategyLocal != null) {
        strategyName = strategy.current.data.strategyLocal.find(sl => sl.lang === currentLang) ? strategy.current.data.strategyLocal.find(sl => sl.lang === currentLang).name : strategy.current.data.strategyLocal.find(sl => sl.lang === 'en').name;
      }
    }
    return strategyName;
  }

  static getStrategyPendingName(strategy: Strategy, currentLang: string): string {
    let strategyName: string;
    if (strategy.pending != null) {
      if (strategy.pending.data.strategyLocal != null) {
        strategy.pending.data.strategyLocal.forEach(sl => {
          if (sl.lang === currentLang) {
            strategyName = sl.name;
          }
        });
      }
    }
    return strategyName;
  }

  static getIOs(strategy: Strategy, currentLang: string): InvestmentObjectiveLocalWithId[] {
    const ret: InvestmentObjectiveLocalWithId[] = [];
    if (strategy.current != null && strategy.current.data != null  && strategy.current.data.investmentObjectives != null) {
      strategy.current.data.investmentObjectives.forEach(io => {
        io.info.forEach(ioinfo => {
          if (ioinfo.lang === currentLang) {
            const newIo = new InvestmentObjectiveLocalWithId();
            newIo.id = io.id;
            newIo.name = ioinfo.name;
            newIo.description = ioinfo.description;
            newIo.lang = ioinfo.lang;
            ret.push(newIo);
          }
        });
      });
    }
    return ret;
  }
}

export class StrategyData {
  strategyLocal: StrategyLocal[] = [];
  investmentObjectives: InvestmentObjective[] = [];
  esgCriteria: boolean;
  strategyType: string;
  status: string[];
}

export class StrategyLocal {
  lang: string;
  name: string;
  intro: string;
  strategyHighlights: StrategyHighlightItem[] = [];
  imageUrl: string;
  videoUrl: string;
  videoEffectiveDate: string;
  slides: string[] = [];
  riskDisclosures: VestrataDocumentInfo[] = [];
  strategyReports: VestrataDocumentInfo[] = [];

}

export class StrategyHighlightItem {
  header: string;
  body: string;
  isAccordionOpen = false;
}

export class StrategyFilter {
  type: string;
  esg: boolean;
  status: string;
  partner: string;
  partners: string[];
  partnerOrder: string;
  currencyOrder: string;
  riskRankOrder: string;
}

export enum StrategyType {
  ACTIVE = 'active',
  PASSIVE = 'passive',
  SMARTBETA = 'smart-beta',
  ALL = 'all-inv-styles'
}
export enum StrategyStatus {
  ALL = 'all-str',
  COMPLETE = 'complete-str',
  INCOMPLETE = 'incomplete-str',
}
