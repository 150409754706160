import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ves-shared-tradingview-technical-analysis',
  templateUrl: './shared-tradingview-technical-analysis.component.html',
  styleUrls: ['./shared-tradingview-technical-analysis.component.scss']
})
export class SharedTradingviewTechnicalAnalysisComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() symbol = 'NASDAQ:MSFT';
  @Input() chartUrl: string;

  widgetTechnicalAnalysisConfig: WidgetTechnicalAnalysisConfig = new WidgetTechnicalAnalysisConfig();
  divForWidget: any;
  private _onDestroy = new Subject();

  containerId: string;

  constructor(private translateService: TranslateService) {
    this.containerId = 'tv-technical-analysis';
  }

  ngOnInit() {
    if (!this.chartUrl) {
      this.chartUrl = location.origin + '/market-views/market-overview/full-chart';
    }
    this.widgetTechnicalAnalysisConfig.container_id = this.containerId;
    this.widgetTechnicalAnalysisConfig.locale = this.translateService.currentLang;
    this.widgetTechnicalAnalysisConfig.symbol = this.symbol;
    this.widgetTechnicalAnalysisConfig.largeChartUrl = this.chartUrl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.symbol && !changes.symbol.firstChange) {
      console.log('**************** TECHNICAL ANALYSIS **********************');
      console.log(changes);
      this.widgetTechnicalAnalysisConfig.symbol = this.symbol;
      this.ngAfterViewInit();
    }
  }

  ngAfterViewInit() {
    this.divForWidget = document.getElementById(this.containerId);
    if (this.divForWidget && this.divForWidget.hasChildNodes()) {
      this.divForWidget.removeChild(this.divForWidget.childNodes[0]);
    }
    if (this.divForWidget != null) {
      const myScript = document.createElement('script');
      console.log('****************** TECHNICAL ANALYSIS CONFIG ********************');
      console.log(this.widgetTechnicalAnalysisConfig);
      myScript.innerHTML = JSON.stringify(this.widgetTechnicalAnalysisConfig);
      myScript.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
      this.divForWidget.appendChild(myScript);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    const myDiv = document.getElementById(this.containerId);
    if (myDiv && myDiv.hasChildNodes()) {
      myDiv.removeChild(myDiv.childNodes[0]);
    }
  }
}

export class WidgetTechnicalAnalysisConfig {
  width = '100%';
  height = '100%';
  symbol: string;
  locale: string;
  colorTheme = 'light';
  isTransparent = true;
  largeChartUrl: string;
  container_id: string;
  interval = '1m';
  showIntervalTabs = true;
}
