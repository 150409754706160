import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Page} from '../../models/vestrata/Page';
import {Instrument, InstrumentProdSearch} from '../../models/vestrata/Instrument/Instrument';
import {ApiHttpService} from '../api.http.service';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngx-config/core';
import {VesConfService} from './conf.service';
import {InstrumentSearchFilter} from '../../models/vestrata/Instrument/Instrument';

@Injectable({
  providedIn: 'root'
})
export class InstrumentService {
  private static readonly ROOT_ENDPOINT = 'instrument/';
  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getInstrumentsWithSearchCriteria(instrumentSearch: InstrumentSearchFilter): Observable<Page<Instrument>> {
    return this.http.post<Page<Instrument>>(this.serviceUrl + InstrumentService.ROOT_ENDPOINT, instrumentSearch, ApiHttpService.getHeaders());
  }

  getInstrumentsWithSearch(instrumentSearch: InstrumentSearchFilter): Observable<Page<Instrument>> {
    return this.http.post<Page<Instrument>>(this.serviceUrl + InstrumentService.ROOT_ENDPOINT + 'search', instrumentSearch, ApiHttpService.getHeaders());
  }

  getInstrumentById(id: string): Observable<Instrument> {
    return this.http.get<Instrument>(this.serviceUrl + InstrumentService.ROOT_ENDPOINT + id, ApiHttpService.getHeaders());
  }

  loadListFilters(): Observable<any> {
    return this.http.get<any>(this.serviceUrl + InstrumentService.ROOT_ENDPOINT + 'load-filters', ApiHttpService.getHeaders());
  }

  getInstrumentCount(): Observable<number> {
    return this.http.get<number>(this.serviceUrl + InstrumentService.ROOT_ENDPOINT + 'count', ApiHttpService.getHeaders());
  }

  getInstrumentRelatedModels(id: string): Observable<InstrumentProdSearch> {
    return this.http.get<InstrumentProdSearch>(this.serviceUrl + InstrumentService.ROOT_ENDPOINT + 'related/' + id, ApiHttpService.getHeaders());
  }
}
