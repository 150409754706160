import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "@ngx-config/core";
import {VesConfService} from "./conf.service";
import {Benchmark, BenchmarkSearchFilter} from "../../models/vestrata/Benchmark";
import {Observable} from "rxjs";
import {Page} from "../../models/vestrata/Page";
import {ApiHttpService} from "../api.http.service";
import {BehavioralFilter, ClientPerimeterFilter} from "../../models/vestrata/client-perimeter/ClientPerimeterFilter";
import {AllKeys, ClientPerimeterRootResult} from "../../models/vestrata/client-perimeter/ClientPerimeterRootResult";

@Injectable({
  providedIn: 'root'
})
export class ClientPerimeterService {
  public static  readonly CP_TYPE_GLOBAL = 'global';
  public static  readonly CP_TYPE_TI = 'trade_idea';
  public static  readonly CP_TYPE_BOB = 'book_business';

  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getMyFilter(): Observable<ClientPerimeterFilter> {
    return this.http.get<ClientPerimeterFilter>(this.serviceUrl + 'client-perimeter/my-filter', ApiHttpService.getHeaders());
  }

  loadResult(filter: ClientPerimeterFilter, type: string, page: string): Observable<ClientPerimeterRootResult> {
    this.cleanFilters(filter, type);
    return this.http.post<ClientPerimeterRootResult>(this.serviceUrl + 'client-perimeter/result/' + type + '/' + page, filter, ApiHttpService.getHeaders());
  }

  private cleanFilters(filter: ClientPerimeterFilter, type: string) {
    if (filter?.generalFilters?.serviceModel?.includes(AllKeys.SM_DISCRETIONARY)) {
      filter.generalFilters.advisoryModel = [];
      filter.behavioralFilters = new BehavioralFilter();
    }
    if (filter?.generalFilters?.serviceModel?.includes(AllKeys.SM_MANAGED_ADVISORY)) {
      filter.generalFilters.portfolioModel = [];
    }
    if (filter?.generalFilters?.serviceModel?.includes(AllKeys.SM_XO) || filter?.generalFilters?.serviceModel?.includes(AllKeys.SM_ACTIVE_ADVISORY)) {
      filter.generalFilters.portfolioModel = [];
      filter.generalFilters.advisoryModel = [];
    }
    if (filter?.generalFilters?.serviceModel?.includes(AllKeys.SM_XO)) {
      filter.behavioralFilters = new BehavioralFilter();
    }
  }
}
