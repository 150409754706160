import {Component, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ves-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input()
  arrowPosition?: 'right' | 'center';

  @Input()
  text: TooltipData;

  @Input()
  tooltipPosition?: 'center' | 'left';

  @Output()
  size?: 'big' | 'small';
  constructor() {
  }

  getTooltipSize(text){
    let size = '';
    if (!text.items) {
      size = 'small';
    } else {
      size = 'big';
    }
    return size;
  }

  getTooltipClass(tooltipPosition, size) {
    let classValue = '';
    if (tooltipPosition === 'center' && size === 'big') {
      classValue = 'tooltip-arrow-center';
    } else if (tooltipPosition === 'center' && size === 'small') {
      classValue = 'tooltip-caption';
    } else if (tooltipPosition === 'left' && size === 'big') {
      classValue = 'tooltip-arrow-right';
    } else if (tooltipPosition === 'left' && size === 'small') {
      classValue = 'tooltip-caption-right';
    }
    //console.log(tooltipPosition, size);
    return classValue;
  }

  ngOnInit() {
  }
}

export class TooltipData {
  title: string;
  items: string [];
}
