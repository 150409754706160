import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ves-instrument-data',
  templateUrl: './instrument-data.component.html',
  styleUrls: ['./instrument-data.component.scss']
})
export class InstrumentDataComponent implements OnInit {

  @Input() type = 0;
  @Input() label: string;
  @Input() info: any;
  @Input() data: InstrumentData[] = [];
  @Input() currency: string;
  @Input() isAmount = false; // amount to trigger correct pipe

  isOpen = false;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    // console.log('********** INSTRUMENT DATA ************');
    // console.log(this.data);
  }

  toggleData() {
    this.isOpen = !this.isOpen;
  }

  getFoldIcon() {
    let url = '../../../../assets/images/components/components-7/';
    if (this.isOpen) {
      url += 'focus/arrow-fold.svg';
    } else {
      url += 'arrow-unfold.svg';
    }
    return url;
  }

  isNumber(info): boolean {
    return typeof info === 'number';
  }
}

export class InstrumentData {
  label: string;
  value: string;
}
