import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FourEyeComponent} from '../../4eye-component';
import {Permission} from '../../../models/vestrata/Permission';

@Component({
  selector: 'ves-btn-workflow',
  templateUrl: './btn-workflow.component.html',
  styleUrls: ['./btn-workflow.component.scss'],
})
export class BtnWorkflowComponent implements OnInit {

  public static readonly SUBMITTED = 'Submitted';
  public static readonly APPROVED = 'Approved';
  public static readonly REJECTED = 'Rejected';
  public static readonly REVOKED = 'Revoked';
  public static readonly CANCELLED = 'Cancelled';
  public static readonly NOT_VALIDATED = 'Not_Validated';
  public static readonly PENDING_CHANGE = 'Pending Change';
  public static readonly CHANGE_ACKNOWLEDGED = 'Change Acknowledged';

  @Input() permission: Permission;
  @Input() ips: boolean = false;
  @Input() ipsMpsStatus: string;
  @Input() value: string;
  @Input() displayOnly = false;

  @Output() valueChanged = new EventEmitter<string>();
  expanded = false;

  constructor() {}

  ngOnInit() {
  }

  getStyleClass(status: string): string {
    let style = '';
    switch (status) {
      case undefined: style = 'ready important'; break;
      case BtnWorkflowComponent.SUBMITTED: style = 'submitted'; break;
      case BtnWorkflowComponent.APPROVED: style = 'approved'; break;
      case BtnWorkflowComponent.REJECTED:
      case BtnWorkflowComponent.REVOKED:
      case BtnWorkflowComponent.CANCELLED: style = 'invalid important'; break;
      case BtnWorkflowComponent.NOT_VALIDATED: style = 'invalid important'; break;
      case BtnWorkflowComponent.PENDING_CHANGE: style = 'submitted'; break;
      case BtnWorkflowComponent.CHANGE_ACKNOWLEDGED: style = 'approved'; break;
    }
    if (!this.permission.write && !this.permission.approve) {
      style = 'locked';
    }
    return style;
  }

  getFirstLineText(status: string): string {
    let text = '';

    switch (status) {
      case undefined: text = 'btn-workflow.submit'; break;
      case BtnWorkflowComponent.SUBMITTED: text = 'btn-workflow.submitted'; break;
      case BtnWorkflowComponent.APPROVED: text = 'btn-workflow.approved'; break;
      case BtnWorkflowComponent.NOT_VALIDATED: text = 'btn-workflow.not-validated'; break;
      case BtnWorkflowComponent.REJECTED: text = 'btn-workflow.rejected'; break;
      case BtnWorkflowComponent.REVOKED: text = 'btn-workflow.revoked'; break;
      case BtnWorkflowComponent.CANCELLED: text = 'btn-workflow.cancelled'; break;
      case BtnWorkflowComponent.PENDING_CHANGE: text = 'btn-workflow.pending-change'; break;
      case BtnWorkflowComponent.CHANGE_ACKNOWLEDGED: text = 'btn-workflow.change-acknowledged'; break;
    }
    return text;
  }

  getSecondLineText(status: string): string {
    let text = '';
    if (!this.ips) {
      switch (status) {
        case BtnWorkflowComponent.SUBMITTED:
          text = 'btn-workflow.cancel';
          break;
        case BtnWorkflowComponent.REJECTED:
          text = 'btn-workflow.resubmit';
          break;
        case BtnWorkflowComponent.APPROVED:
          text = 'btn-workflow.revoke';
          break;
        case BtnWorkflowComponent.CANCELLED:
          text = 'btn-workflow.submit';
          break;
        case BtnWorkflowComponent.REVOKED:
          text = 'btn-workflow.resubmit';
          break;
        case BtnWorkflowComponent.PENDING_CHANGE:
          text = 'btn-workflow.cancel';
          break;
        case BtnWorkflowComponent.CHANGE_ACKNOWLEDGED:
          text = 'btn-workflow.revoke';
          break;
      }
    } else {
      switch (status) {
        case BtnWorkflowComponent.SUBMITTED:
          if (this.ipsMpsStatus != null && this.ipsMpsStatus === 'approved' ) {
            text = 'btn-workflow.approve';
          } else {
            text = 'btn-workflow.reject';
          }
          break;
        case BtnWorkflowComponent.REJECTED:
          text = '';
          break;
        case BtnWorkflowComponent.APPROVED:
          text = 'btn-workflow.revoke';
          break;
      }
    }
    return text;
  }

  isExpandable(status: string): boolean {
    return !this.displayOnly && ([BtnWorkflowComponent.SUBMITTED, BtnWorkflowComponent.APPROVED, 'cancelled'].includes(status) || (!this.ips && (status === BtnWorkflowComponent.REVOKED || status === BtnWorkflowComponent.PENDING_CHANGE)));
  }

  getDropdownSource(status: string, expanded: boolean): string {
    let src = '../../../assets/images/components/components-10/';
    if (expanded) {
      src += 'dropdown-list-arrow-unfold.svg';
    } else {
      switch (status) {
        case BtnWorkflowComponent.SUBMITTED: src += 'dropdown-list-arrow-fold-pending-blue.svg'; break;
        case BtnWorkflowComponent.REJECTED:
        case BtnWorkflowComponent.CANCELLED: src += 'dropdown-list-arrow-fold-pending-cinnamon.svg'; break;
        case BtnWorkflowComponent.REVOKED: src += 'dropdown-list-arrow-fold-pending-cinnamon.svg'; break;
        case BtnWorkflowComponent.APPROVED: src += 'dropdown-list-arrow-fold-pending-green.svg'; break;
        case BtnWorkflowComponent.CHANGE_ACKNOWLEDGED: src += 'dropdown-list-arrow-fold-pending-green.svg'; break;
        case BtnWorkflowComponent.PENDING_CHANGE: src += 'dropdown-list-arrow-fold-pending-blue.svg'; break;
      }
    }
    return src;
  }

  onMainAction(editingValue: string) {
    if (editingValue == null) {
      if (this.permission && this.permission.write && !this.displayOnly) {
        this.valueChanged.emit(BtnWorkflowComponent.SUBMITTED);
      }
    } else {
      this.expanded = !this.expanded;
    }
  }

  onSecondaryAction(editingValue: string) {
    let emit;
    if (!this.ips) {
      switch (editingValue) {
        case BtnWorkflowComponent.SUBMITTED:
          emit = BtnWorkflowComponent.CANCELLED;
          break;
        case BtnWorkflowComponent.REJECTED:
          emit = BtnWorkflowComponent.SUBMITTED;
          break;
        case BtnWorkflowComponent.CANCELLED:
          emit = BtnWorkflowComponent.SUBMITTED;
          break;
        case BtnWorkflowComponent.APPROVED:
          emit = BtnWorkflowComponent.REVOKED;
          break;
        case BtnWorkflowComponent.REVOKED:
          emit = BtnWorkflowComponent.SUBMITTED;
          break;
        case BtnWorkflowComponent.PENDING_CHANGE:
          emit = BtnWorkflowComponent.SUBMITTED;
          break;
        case BtnWorkflowComponent.CHANGE_ACKNOWLEDGED:
          emit = BtnWorkflowComponent.REVOKED;
          break;
      }
    } else {
      switch (editingValue) {
        case BtnWorkflowComponent.SUBMITTED:
          if (this.ipsMpsStatus != null && this.ipsMpsStatus === 'approved' ) {
            emit = BtnWorkflowComponent.APPROVED;
          } else {
            emit = BtnWorkflowComponent.REJECTED;
          }
          break;
        case BtnWorkflowComponent.APPROVED:
          emit = BtnWorkflowComponent.REVOKED;
          break;
      }
    }

    if (emit) {
      console.log('********** WORKFLOW *****************');
      console.log(emit);
      console.log(this.permission);
      if (!this.displayOnly) {
        switch (emit) {
          case BtnWorkflowComponent.SUBMITTED:
            if (this.permission.write || this.permission.approve) {
              this.valueChanged.emit(emit);
            }
            break;
          case BtnWorkflowComponent.APPROVED:
            if (this.permission.write || this.permission.approve) {
              this.valueChanged.emit(emit);
            }
            break;
          case BtnWorkflowComponent.NOT_VALIDATED:
          case BtnWorkflowComponent.REJECTED:
            if (this.permission.approve) {
              this.valueChanged.emit(emit);
            }
            break;
          case BtnWorkflowComponent.REVOKED:
            if (this.permission.approve) {
              this.valueChanged.emit(emit);
            }
            break;
          case BtnWorkflowComponent.CANCELLED:
            if (this.permission.write || this.permission.approve) {
              this.valueChanged.emit(emit);
            }
            break;
          case BtnWorkflowComponent.PENDING_CHANGE:
            if (this.permission.write || this.permission.approve) {
              this.valueChanged.emit(emit);
            }
            break;
          case BtnWorkflowComponent.CHANGE_ACKNOWLEDGED:
            if (this.permission.write || this.permission.approve) {
              this.valueChanged.emit(emit);
            }
            break;
        }
      }
    }
  }

}
