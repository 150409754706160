import {Alert} from "../../models/vestrata/Alert";
import {Action, createReducer, on} from "@ngrx/store";
import * as NotificationActions from '../actions/notification.actions';

export  class NotificationState {
  allNotifications: Alert[];
}
export const initialStateNotification =  {
  allNotifications: []
};

export const reloadStateNotification =  {};

const notificationReducer = createReducer(
  initialStateNotification,
  // on(NotificationActions.retrieveNotifications, (state: NotificationState, { payload }) => (allNotifications: payload ))
  on(NotificationActions.retrieveNotifications, (state: NotificationState, { payload }) => {
    return { ...state, allNotifications: payload };
  }),
);

const reloadReducerNotifications = createReducer(
  reloadStateNotification,
  on(NotificationActions.askReloadNotifications, (state: NotificationState) => {
    return { ...state};
  })
);

export function retrieveNotificationsReducer(state: NotificationState | undefined, action: Action) {
  return notificationReducer(state, action);
}

export function reloadNotificationReducer(state: NotificationState | undefined, action: Action) {
  return reloadReducerNotifications(state, action);
}
