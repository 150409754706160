import {Component, Input, OnInit} from '@angular/core';
import {AllocationChanged} from '../../../models/vestrata/Allocation';

@Component({
  selector: 'ves-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  @Input() status: string;

  @Input() light = false;

  @Input() size = 'normal';

  constructor() { }

  ngOnInit(): void {}

  getStyleClass(status, light): string {
    let style = light ? 'light-' : '';
    switch (status) {
      case StatusValue.NULL:
      case AllocationChanged.NOCHANGE:
      case StatusValue.CLOSED: style += 'neutral'; break;
      case StatusValue.NA:
      case StatusValue.SWITCH:
      case StatusValue.APPROVED:
      case StatusValue.SOFTCLOSE: style += 'note'; break;
      case AllocationChanged.NEW:
      case StatusValue.HOLD: style += 'info'; break;
      case AllocationChanged.UP:
      case StatusValue.BUY: style += 'success'; break;
      case AllocationChanged.DOWN:
      case StatusValue.SELL : style += 'warning'; break;
      case AllocationChanged.REMOVED:
      case StatusValue.SANCTIONED : style += 'alert'; break;
      case StatusValue.RESTRICTED : style += 'restricted'; break;
      case StatusValue.PRESALE: style += 'success'; break;
      case StatusValue.POSTSALE: style += 'info'; break;
      default: style += 'neutral';
    }
    return style;
  }

}

class StatusValue {
  public static readonly NULL;
  public static readonly HOLD = 'hold';
  public static readonly BUY = 'buy';
  public static readonly SELL = 'sell';
  public static readonly SWITCH = 'switch';
  public static readonly CLOSED = 'closed';
  public static readonly SOFTCLOSE = 'softclose';
  public static readonly RESTRICTED = 'restricted';
  public static readonly SANCTIONED = 'sanctioned';
  public static readonly PRESALE = 'pre_sale';
  public static readonly POSTSALE = 'post_sale';
  public static readonly NA = 'na';
  public static readonly APPROVED = 'approved';
}
