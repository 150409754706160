import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ves-target-market-check',
  templateUrl: './target-market-check.component.html',
  styleUrls: ['./target-market-check.component.scss']
})
export class TargetMarketCheckComponent implements OnInit {

  @Input() label: string;
  @Input() locked = true; // v1 theres no editing of target market.
  @Input() value = false;
  @Input() hasInput = false;
  @Input() _input: number;
  @Input() type = 0;

  @Input() set input(v: number) {
    this._input = v;
  }
  get input() {
    return this._input;
  }

  @Output() valueChange = new EventEmitter<boolean>();

  constructor( private translate: TranslateService) { }

  ngOnInit(): void {
  }

  checkClick(event) {
    this.value = event;
    this.valueChange.emit(this.value);
  }
}
