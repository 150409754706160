import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ves-shared-countries-selector',
  templateUrl: './shared-countries-selector.component.html',
  styleUrls: ['./shared-countries-selector.component.scss']
})
export class SharedCountriesSelectorComponent implements OnInit, OnChanges {

  @Input() currentLanguage: string;
  @Output() currentLanguageChange = new EventEmitter<string>();
  currentLanguageItem: any;
  @Input() languages: any[];
  showAvailable = false;

  constructor(private translateService: TranslateService) {
    this.languages = [{
      locale: 'en',
      src: '../../../assets/images/languages-menu/en/en.png',
      srcset: '../../../assets/images/languages-menu/en/en.png 1x,' +
        '../../../assets/images/languages-menu/en/en@2x.png 2x,' +
        '../../../assets/images/languages-menu/en/en@3x.png 3x'
    }, {
      locale: 'fr',
      src: '../../../assets/images/languages-menu/fr/fr.png',
      srcset: '../../../assets/images/languages-menu/fr/fr.png 1x,' +
        '../../../assets/images/languages-menu/fr/fr@2x.png 2x,' +
        '../../../assets/images/languages-menu/fr/fr@3x.png 3x'
    }, /*{
      locale: 'de',
      src: '../../../assets/images/languages-menu/de/de.png',
      srcset: '../../../assets/images/languages-menu/de/de.png 1x,' +
          '../../../assets/images/languages-menu/de/de@2x.png 2x,' +
          '../../../assets/images/languages-menu/de/de@3x.png 3x'
    }, {
      locale: 'it',
      src: '../../../assets/images/languages-menu/it/it.png',
      srcset: '../../../assets/images/languages-menu/it/it.png 1x,' +
          '../../../assets/images/languages-menu/it/it@2x.png 2x,' +
          '../../../assets/images/languages-menu/it/it@3x.png 3x'
    }, {
      locale: 'es',
      src: '../../../assets/images/languages-menu/es/es.png',
      srcset: '../../../assets/images/languages-menu/es/es.png 1x,' +
          '../../../assets/images/languages-menu/es/es@2x.png 2x,' +
          '../../../assets/images/languages-menu/es/es@3x.png 3x'
    }*/];
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.currentLanguage && changes.currentLanguage.currentValue) {
      this.select(this.currentLanguage, false);
    }
  }

  toggleAvailable() {
    this.showAvailable = !this.showAvailable;
  }

  getCurrentLanguageData(currentLanguage: string): any {
    return this.languages.find(l => l.locale === (currentLanguage || 'en'));
  }

  select(language: string, toggle: boolean = true) {
    this.currentLanguage = language;
    this.currentLanguageItem = this.getCurrentLanguageData(this.currentLanguage);
    this.currentLanguageChange.emit(language);
    if (toggle) {
      this.toggleAvailable();
    }
  }

}
