import {Tag} from './Insight';
import {SearchRequest} from './SearchRequest';

export class VestrataDocument {
  id: string;
  organisationId: string;
  name: string;
  hash?: string;
  language?: string;
  reference: string;
  blobName: string;
  url: string;
  contentType: string;
  type: string;
  summary?: string;
  title?: string;
  modificationDate: string;
  modifiedBy: string;
  effectiveDate: string;
  expiryDate: string;
  tags: Tag[];
  usedIntoList: UsedInto[];

  constructor() {
    this.name = '';
    this.language = '';
    this.reference = '';
    this.contentType = '';
    this.type = '';
    this.summary = '';
    this.title = '';
  }

  static docTypes(): string[] {
    return [VestrataDocTypes.COMPLIANCE,VestrataDocTypes.MARKETING,
      VestrataDocTypes.INSIGHT,VestrataDocTypes.RESEARCH,];
  }
}
export class VestrataDocumentInfo {
  id: string;
  organisationId: string;
  name: string;
  blobName: string;
  url: string;
  contentType: string;
  type: string;
  summary?: string;
  title?: string;
  modificationDate: string;
  modifiedBy: string;
  effectiveDate: string;
  expiryDate: string;

  constructor(vestrataDoc: VestrataDocument) {
    this.id = vestrataDoc.id;
    this.organisationId = vestrataDoc.organisationId;
    this.name = vestrataDoc.name;
    this.blobName = vestrataDoc.blobName;
    this.contentType = vestrataDoc.contentType;
    this.type = vestrataDoc.type;
    this.summary = vestrataDoc.summary;
    this.title = vestrataDoc.title;
    this.modificationDate = vestrataDoc.modificationDate;
    this.modifiedBy = vestrataDoc.modifiedBy;
    this.effectiveDate = vestrataDoc.effectiveDate;
    this.expiryDate = vestrataDoc.expiryDate;
    this.url = vestrataDoc.url;
  }

}
export class UsedInto {
  relatedId: string;
  type: string;
  localTitles: any;
}
export enum VestrataDocTypes {
  COMPLIANCE = 'compliance',
  // PRE_MARKETING = 'pre_marketing',
  // POST_MARKETING = 'post_marketing',
  MARKETING = 'marketing',
  INSIGHT = 'insight',
  RESEARCH = 'research'
}
export class DocumentSearchFilter {
  searchRequest: SearchRequest;
  contentTypes: string[];
  types: string[];
  minEffectiveDate: Date;
  maxEffectiveDate: Date;
  archive: boolean;
}
export enum FileTypes {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  OFFICE_DOCUMENT = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  MS_WORD = 'application/msword',
  OFFICE_PRESENTATION = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PPT = 'application/vnd.ms-powerpoint',
  OPEN_PRESENTATION = 'application/vnd.oasis.opendocument.presentation',
  PDF = 'application/pdf',
  MP4 = 'video/mp4',
  MPEG = 'audio/mpeg',
  MP3 = 'audio/mp3',
  YOUTUBE = 'YouTube',
  SLIDESHOW = 'Slideshow'
}

export class DocumentUploadTypes {

  static all(): string[] {
    return [FileTypes.JPG, FileTypes.JPEG, FileTypes.PNG, FileTypes.EXCEL,
      FileTypes.OFFICE_DOCUMENT, FileTypes.MS_WORD, FileTypes.OFFICE_PRESENTATION,
      FileTypes.PPT, FileTypes.OPEN_PRESENTATION, FileTypes.PDF,
      FileTypes.MP4, FileTypes.MPEG, FileTypes.MP3];
  }
  static images(): string[] {
    return [FileTypes.JPG, FileTypes.JPEG, FileTypes.PNG];
  }
  static documents(): string[] {
    return [FileTypes.EXCEL, FileTypes.OFFICE_DOCUMENT, FileTypes.MS_WORD, FileTypes.OFFICE_PRESENTATION,
      FileTypes.PPT, FileTypes.OPEN_PRESENTATION, FileTypes.PDF];
  }
  static words(): string[] {
    return [FileTypes.OFFICE_DOCUMENT, FileTypes.MS_WORD];
  }
  static presentations(): string[] {
    return [FileTypes.OFFICE_PRESENTATION, FileTypes.PPT, FileTypes.OPEN_PRESENTATION];
  }
  static sheets(): string[] {
    return [FileTypes.EXCEL];
  }
  static audios(): string[] {
    return [FileTypes.MPEG, FileTypes.MP3];
  }
  static videos(): string[] {
    return [FileTypes.MP4];
  }
  static pdf(): string[] {
    return [FileTypes.PDF];
  }
  static slideshow(): string[] {
    return [FileTypes.SLIDESHOW];
  }
  static youtube(): string[] {
    return [FileTypes.YOUTUBE];
  }
}
