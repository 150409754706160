import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ves-two-level-bar-filter',
  templateUrl: './two-level-bar-filter.component.html',
  styleUrls: ['./two-level-bar-filter.component.scss']
})
export class TwoLevelBarFilterComponent implements OnInit {
  public isOpen: boolean = false;
  opendParentIndex = -1;
  @Input() blocOptions: BlocOption[];
  @Input() placeHolder;
  @Input() prefixHolder;
  @Input() blocSeperator = false;
  selectedElementCount = 0;
  @Output() selectionChange = new EventEmitter<any>();

  @Input() noTitleCase = false;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }


  toggleFilter() {
    this.isOpen = !this.isOpen;
  }

  selectOptionParent(checked, bloc,blockIndex, parent) {
    this.selectedElementCount = checked ? this.selectedElementCount+1: this.selectedElementCount-1;
    bloc.selectedCount = checked ? bloc.selectedCount+1 : bloc.selectedCount-1;

    /*if (checked) {
      this.selectedFilters[blockIndex].parents = this.selectedFilters[blockIndex].parents ? this.selectedFilters[blockIndex].parents : [];
      this.selectedFilters[blockIndex].parents.push(parent);
    }else {
      this.selectedFilters[blockIndex].parents.splice(this.selectedFilters[blockIndex].parents.indexOf(parent), 1);
    }*/

    this.selectionChange.emit(this.blocOptions);

  }

  selectOptionChild(checked, bloc,blockIndex, parent, child) {
    // update green counts
    this.selectedElementCount = checked ? this.selectedElementCount+1: this.selectedElementCount-1;
    parent.selectedCount = checked ? parent.selectedCount+1 : parent.selectedCount-1;
    // look for existing selected parent
    /*let existingParent = this.selectedFilters[blockIndex].parents.find(prnt => parent === prnt);
    if (!existingParent){
      this.selectedFilters[blockIndex].parents.push(parent);
      existingParent =parent;
    }
    existingParent = existingParent? existingParent: parent;
    //
    if (checked) {
      existingParent.childs = existingParent.childs ? this.selectedFilters[blockIndex].parents.childs : [];
      this.selectedFilters[blockIndex].parents.childs.push(child);
      parent.checked = true;
    }else {
      this.selectedFilters[blockIndex].parents.childs.splice(this.selectedFilters[blockIndex].childs.indexOf(child), 1);
    }*/

    this.selectionChange.emit(this.blocOptions);
  }

}

export class BlocOption {
  label: string; // translable
  value : any; // real value to filter with
  selectedCount = 0;
  options: FilterOption[];
}
export class FilterOption{
  name: string;
  value : any;
  totalCount: number;
  selectable: boolean;
  selectedCount = 0;
  indentChilds: boolean;
  checked: boolean;
  childs: FilterChild[];

  constructor(name, value,totalCount, selectable, indentChilds, childs, checked? : boolean) {
    this.name = name;
    this.value = value;
    this.totalCount = totalCount;
    this.selectable = selectable;
    this.indentChilds = indentChilds;
    this.childs = childs;
    this.checked = checked;
  }
}
export class FilterChild{
  name: string;
  value : any;
  selectable: boolean;
  totalCount: number;
  checked: boolean;
  constructor(name, value, selectable, totalCount) {
    this.name = name;
    this.value = value;
    this.selectable = selectable;
    this.totalCount = totalCount;
  }
}
