import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DocumentService} from '../../../api/services/document.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {SnackbarMessage} from '../../../components/shared-snackbar/models/snackbar-message';
import {Subject} from 'rxjs';
import {SharedSnackbarService} from '../../../components/shared-snackbar/services/shared-snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {SharedLoaderService} from '../../../components/shared-loader/service/shared-loader.service';
import {FourEyeComponent} from "../../4eye-component";

@Component({
  selector: 'ves-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent extends FourEyeComponent<string> implements OnInit, OnDestroy {

  private readonly MAX_PICTURE_SIZE = 10000000;
  private _onDestroy = new Subject();

  @Output() uploaded = new EventEmitter<string>();

  constructor(
    private translateService: TranslateService,
    private loader: SharedLoaderService,
    private snackbar: SharedSnackbarService,
    private documentService: DocumentService) {
    super();
  }

  equals(a: string, b: string): boolean {
    return a === b;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onUpload(fs: any) {
    const photosTooBig = [...fs].filter((file: File) => file.size > this.MAX_PICTURE_SIZE);
    if (fs.length > 0) {
      if (photosTooBig.length > 0) {
        let message = this.translateService.instant(photosTooBig.length === 1 ? 'upload-file.file-too-large' : 'upload-file.files-too-large');
        for (const file of photosTooBig) {
          message += file.name + ', ';
        }
        this.snackbar.push(new SnackbarMessage(message, 'error'));
      } else {
        if (this._editingValue) { this.removeFile(); }
        this.loader.showFullLoader('upload-file.uploading');

        return this.documentService.uploadDocument(fs[0]).pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe(returnUrl => {
          this._editingValue = returnUrl.url;
          this.uploaded.emit(this._editingValue);
          this.snackbar.push(new SnackbarMessage(this.translateService.instant('thumbnail.upload-success'), 'info'));
        }, error => {
          this.snackbar.push(new SnackbarMessage(error, 'error'));
        });
      }
    }
  }

  removeFile() {
    return this.documentService.deleteDocument(this._editingValue).pipe(takeUntil(this._onDestroy)).subscribe(returnUrl => {
      this._editingValue = null;
    }, error => {
      this.snackbar.push(new SnackbarMessage(error, 'error'));
    });
  }

}
