import {Article, ArticleLocal, ArticleVersion} from './Article';
import {TradeIdea} from './TradeIdea';

export class Research extends Article<ResearchVersion> {

}

export class ResearchVersion extends ArticleVersion<ResearchLocal> {
  type: ResearchType;
  sectors: string[] = [];
  tradeIdeas: TradeIdea[] = [];
  security: string[] = [];
}

export class ResearchLocal extends ArticleLocal {
    externalUrl = '';
    document = '';
}

export enum ResearchType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL'
}

export enum ResearchMainCategory {
  ASSET_ALLOCATION = 'ASSETALLOCATION',
  MARKET_OUTLOOK = 'MARKET_OUTLOOK',
  RESEARCH = 'RESEARCH',
  CMAS = 'CMAS',
}
