import {Version} from './Version';
import {VestrataDocumentInfo} from "./VestrataDocument";
import {InstrumentInfo} from "./Instrument/Instrument";

export class TradeIdea {
  id: string;
  organisationId: string;
  organisationName: string;
  pending: Version<TradeIdeaVersion>;
  current: Version<TradeIdeaVersion>;
  creationDate: string;
  createdBy: string;
  lastUpdate: Date;
  nextReviewDate: Date;
  needReview: boolean;
  closingDate: Date;
  closingNote: string;
  stats: TradeIdeaStats;
  reviews: TradeIdeaReview[];
  status: string;
}

export class TradeIdeaVersion {
  category: TradeIdeaCategory;
  bannerImage: string;
  locales: TradeIdeaLocal[] = [];
  implementationVehicles: ImplementationVehicle[];
}

export class ImplementationVehicle {
  locales: ImplementationVehicleLocal[] = [];
  side: string;
  minInvestement: number;
  minAllocation: number;
  maxAllocation: number;
  targetPrice: number;
  type: string; // automatically calculated
  sellSideProducts: InstrumentInfo[];
  buySideProducts: InstrumentInfo[];
  preSalesDocuments: VestrataDocumentInfo[];
  postSalesDocuments: VestrataDocumentInfo[];

  performance: TradeIdeaPerformance;

  instrumentPerformances: any;

}
export class TradeIdeaPerformance {

  misaligned: boolean;
  misalignmentDate: string;
  asofDate: string;
  inceptionReturn: number;
  oneMonthReturn: number;
  oneWeekReturn: number;
  deltaAUM: number;
  currency: string;
  portfolioCount: number;
  portfolios: string[];

}

export class ImplementationVehicleLocal {
  lang: string;
  name: string;
  investRational: string;
}

export class TradeIdeaLocal {
  lang: string;
  name: string;
  description: string;
  investRational: string;

  videoUrl: string;
  videoStillValid: boolean;
  videoEffectiveDate: Date;
  slides: string[] = [];
  documents: VestrataDocumentInfo[];

}

export class TradeIdeaStats {
  minAum: number;
  maxAum: number;
  deltaAum: number;
  clients: number;
  deltaClients: number;
}

export class TradeIdeaReview{
  note: string;
  reviewDate: Date;
  reviewedBy: string;
  nextReviewDate: Date;
}
export class TradeIdeaCategory {
  id: string;
  organisationId: string;
  organisationName: string;
  current: Version<TradeIdeaCategoryVersion>;
  pending: Version<TradeIdeaCategoryVersion>;
}

export class TradeIdeaCategoryVersion {
  localizedCategories: TradeIdeaCategoryLocal[] = [];
}

export class TradeIdeaCategoryLocal {
  lang: string;
  name: string;

  constructor(lang: string, name?: string) {
    this.lang = lang;
    this.name = name;
  }
}

export class TradeIdeaListFilter {

  textSearch;

  categories: string[];
  status: string[];

  creationOrder = 'desc';
  nextReviewOrder: string;
  aumOrder: string;
}

export enum TI_STATUS {
  TI_IN_PROGRESS = 'inprogress',
  TI_LAUNCHED = 'launched',
  TI_TO_REVIEW = 'toreview',
  TI_CLOSED = 'closed',
}

export enum MARKETING_MATERIALS_TYPE {
  PDF = 'PDF',
  EXCEL = 'Excel',
  WORD = 'Word',
  POWERPOINT = 'Powerpoint',
  AUDIO = 'Audio',
  VIDEO = 'Video',
  WEBLINK = 'Weblink',
  SLIDESHOW = 'Slideshow',
  IMAGE = "Image",
  YOUTUBE = 'YouTube'
}
