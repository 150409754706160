import { Version } from './Version';
import {Country} from './Country';

export class PartnerProfile {
  current: Version<PartnerData>;
  pending: Version<PartnerData>;
  displayRanking: number;
  modelLoadType: ModelLoadType;
}

export class PartnerData {
  logoUrl: string;
  partnerLocal: PartnerLocal [];
}

export class PartnerLocal {
  lang: string;
  description: string;
  webSiteUrl: string;

  stats: ProfileStat [] = [];
  sections: ProfileSection [] = [];
  locations: ProfileLocation [] = [];

  videoUrl: string;
  socialMedia: SocialMediaLinks;
}

export class SocialMediaLinks {
  youtube: string;
  twitter: string;
  linkedin: string;
  facebook: string;
  instagram: string;
}

export class ModelLoadType {
  type: string;
  externalSystemId: string;
}

export class ProfileStat {
  value: string;
  name: string;
  ranking: number;
}

export class ProfileLocation {
  country: string;
  newCountry: Country;
  value: number;
  ranking: number;
}

export class ProfileSection {
  header: string;
  content: string;
  ranking: number;
  imageUrl: string;
}
