import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FourEyeComponent} from '../../../../basic-components/4eye-component';
import {InsightCuration} from '../../../../models/vestrata/Insight';
import {Version} from '../../../../models/vestrata/Version';
import {isEqual} from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {Organisation} from '../../../../models/vestrata/Organisation';
import {Article, ArticleLocal, ArticleType, ArticleVersion} from '../../../../models/vestrata/Article';
import {Tutorial} from '../../../../models/vestrata/Tutorial';

@Component({
  selector: 'ves-insights-row',
  templateUrl: './insights-row.component.html',
  styleUrls: ['./insights-row.component.scss']
})
export class InsightsRowComponent extends FourEyeComponent<ArticleVersion<any>> implements OnInit, OnChanges {

  @Input() id: string;
  @Input() version: Version<ArticleVersion<any>>;
  @Input() insightOrg: string;
  @Input() insightOrgName: string;
  @Input() organisation: Organisation;
  @Input() isIps = false;
  @Input() isAdmin = false;
  @Input() insightCuration: InsightCuration[] = [];
  @Input() byVestrata = false;
  @Input() article: Article<ArticleVersion<ArticleLocal>>;
  @Input() type: ArticleType = ArticleType.INSIGHT;

  @Output() viewEdit = new EventEmitter<boolean>();
  @Output() delete = new EventEmitter<boolean>();
  @Output() singlePreview = new EventEmitter<any>();
  @Output() promoteClick = new EventEmitter<any>();
  @Output() enableClick = new EventEmitter<any>();
  @Output() fourEyesDeleting = new EventEmitter<boolean>();

  curationAvailable = false;
  myCuration: InsightCuration;
  articleLocal: ArticleLocal;
  languages: any[] = [];
  local: string;


  constructor(private translate: TranslateService) {
    super();
  }

  protected equals(a: ArticleVersion<any>, b: ArticleVersion<any>): boolean {
    return isEqual(a, b);
  }

  ngOnInit() {
    console.log('insight row permission');
    console.log(this.permission);
    console.log(this.id);

    this.local = this.translate.currentLang;
    this.languages = this.getLanguageList();
    this.articleLocal = this.getInsightFromLocal();
    this.checkCuration();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.strategy) {
      this.articleLocal = this.getInsightFromLocal();
      this.languages = this.getLanguageList();
      this.checkCuration();
    }
  }

  checkCuration() {
    if (this.organisation) {
      if ((this.isIps && this.permission.approve) || this.isAdmin) {
        this.curationAvailable = this.isAdmin || !(this.organisation.id !== this.insightOrg && !this.organisation.bank.insightsCuration);
        if (this.insightCuration && this.insightCuration.length > 0) {
          this.myCuration = this.insightCuration.find(c => c.bankId === this.organisation.id);
        }
        if (!this.myCuration) {
          this.myCuration = new InsightCuration();
          this.myCuration.enabled = false;
          this.myCuration.promoted = false;
        }
      }
    }
  }

  onLanguageChange(lang) {
    this.articleLocal = this.getInsightFromLocal(lang);
  }

  getInsightFromLocal(local: string = this.translate.currentLang): ArticleLocal {
    let ret: ArticleLocal = this.pendingValue.locales.find(s => s.lang === local );
    if (!ret) {
      ret = this.pendingValue.locales.find(s => s.lang === 'en' );
    }
    return ret;
  }

  getLanguageList(): any[] {
    const languages: any[] = [];
    this._editingValue.locales.forEach(sl => {
      if (sl.lang === 'fr') {
        languages.push({
          locale: 'fr',
          src: '../../../assets/images/languages-menu/fr/fr.png',
          srcset: '../../../assets/images/languages-menu/fr/fr.png 1x,' +
            '../../../assets/images/languages-menu/fr/fr@2x.png 2x,' +
            '../../../assets/images/languages-menu/fr/fr@3x.png 3x'
        });
      } else if (sl.lang === 'en') {
        languages.push({
          locale: 'en',
          src: '../../../assets/images/languages-menu/en/en.png',
          srcset: '../../../assets/images/languages-menu/en/en.png 1x,' +
            '../../../assets/images/languages-menu/en/en@2x.png 2x,' +
            '../../../assets/images/languages-menu/en/en@3x.png 3x'
        });
      }
    });
    return languages;
  }
  singlePreviewClick() {
    this.singlePreview.emit();
  }

  editApproveClick() {
    this.viewEdit.emit();
  }

  deleteClick() {
    this.delete.emit(true);
  }

  getRowClass(): string {
    let ret = 'own-no-curation';
    if (this.isTutorial()) {
      ret = 'tutorial-grid';
    } else {
      if (this.organisation) {
        if (this.insightOrg === this.organisation.id) {
          // own insight
          if ((this.isIps && this.permission.approve) || this.isAdmin) {
            if (this.hasCurrentApprovedVersion()) {
              ret = 'own-with-curation';
            } else {
              ret = 'own-not-validated';
            }
          }
        } else {
          // partner/vestrata insight
          if ((this.isIps && this.permission.approve && this.organisation.bank.insightsCuration) || this.isAdmin) {
            ret = (this.isAdmin && this.byVestrata) ? 'admin-partner-with-curation':'partner-with-curation';
          } else if (this.isIps && this.permission.approve && !this.organisation.bank.insightsCuration) {
            ret = 'partner-vestrata-curation';
          } else {
            ret = 'partner-no-curation';
          }
        }
      }
    }

    // add 4eyes status
    if (this.fourEyesMode) {
      ret = ret + ' ' + this.getState().state;
    }
    return ret;
  }

  updatePromoted() {
    this.myCuration.promoted = !this.myCuration.promoted;
    this.promoteClick.emit(this.myCuration.promoted);
  }

  updateEnable(event: any) {
    this.enableClick.emit(event);
  }

  hasCurrentApprovedVersion(): boolean {
    return this.currentValue && this.currentValue.mainCategory != null && this.permission.approve;
  }

  checkDeleteStatus() {
    return (!this.currentValue && this.permission.write) || (this.currentValue && this.permission.approve);
  }

  foureyesDelete(isApproving: boolean) {
    this.fourEyesDeleting.emit(isApproving);
  }

  isTutorial(): boolean {
    return this.type === ArticleType.TUTORIAL;
  }

  getTutorial(): Tutorial {
    return this.article as Tutorial;
  }
}
