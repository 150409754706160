import {ChangeAcknowledge} from "./PortfolioModel";

export class Version <T> {
  data: T;
  status: VersionStatus;
  submittedId: string;
  submittedBy: string;
  submittedDate: string;
  submittedEmail: string;
  reviewedId: string;
  reviewedBy: string;
  reviewedEmail: string;
  reviewedDate: string;
  note: string;
  active: boolean;
  BankName: string;
}


export enum VersionStatus {
  APPROVED = 'approved',
  EDITED = 'edited',
  SAVED = 'saved',
  REQUEST = 'requestApproval',
  REJECTED = 'rejected',
  DELETE = 'requestDeletion',
  REJECT_DELETE = 'rejectDeletion',
  LOCKED = 'locked'
}
