import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Distribution, Instrument} from '../../../models/vestrata/Instrument/Instrument';
import {Subject} from "rxjs";


@Component({
  selector: 'ves-dividend-table',
  templateUrl: './dividend-table.component.html',
  styleUrls: ['./dividend-table.component.scss']
})
export class DividendTableComponent implements OnInit, OnDestroy {
  private static readonly FILTER_EXDATE = 'exDate';
  private static readonly FILTER_AMOUNT = 'amount';
  private static readonly FILTER_PAYDATE = 'payDate';
  private static readonly FILTER_TYPE = 'type';
  private static readonly FILTER_YIELD = 'yield';
  private static readonly FILTER_REINVEST = 'reinvest';
  private static readonly FILTER_CURRENCY = 'currency';
  private static readonly SORT_UP = 'up';
  private static readonly SORT_DOWN = 'down';

  private _onDestroy = new Subject();
  @Input() instrument: Instrument;
  @Input() distributions: Distribution[] = [];
  @Input() type: string;

  headers: string[];

  headerSort = {
    exDate: DividendTableComponent.SORT_DOWN,
    amount: DividendTableComponent.SORT_DOWN,
    payDate: DividendTableComponent.SORT_DOWN,
    type: DividendTableComponent.SORT_DOWN,
    yield: DividendTableComponent.SORT_DOWN,
    reinvest: DividendTableComponent.SORT_DOWN,
    currency: DividendTableComponent.SORT_DOWN
  };

  constructor() { }

  ngOnInit(): void {
    console.log('*********** DIVIDENDS ********************');
    console.log(this.instrument);
    console.log(this.distributions);
    console.log(this.type);
    if (this.type) {
      this.setHeaders();
    }
    this.distributions.sort((a, b) => a.exDate < b.exDate ? 1 : -1);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  setHeaders() {
    this.headers = [];
    switch (this.type) {
      case 'Fund': {
        this.headers = Object.keys(this.headerSort).filter(a => a !== 'type' && a !== 'yield');
        break;
      }
      case 'Equity': {
        this.headers = Object.keys(this.headerSort).filter(a => a !== 'reinvest');
        break;
      }
      default: {
        this.headers = Object.keys(this.headerSort).filter(a => a !== 'reinvest' && a !== 'yield' && a !== 'type');
      }
    }
  }

  sort(key: string) {
    if (!key) { return; }
    const newValue = this.headerSort[key] === DividendTableComponent.SORT_DOWN ?
      DividendTableComponent.SORT_UP :
      DividendTableComponent.SORT_DOWN;
    Object.keys(this.headerSort).forEach(k => this.headerSort[k] = DividendTableComponent.SORT_DOWN);
    this.headerSort[key] = newValue;

    this.distributions.sort((pr1, pr2) => {
      let ret = 0;
      if (key === DividendTableComponent.FILTER_TYPE
        || key === DividendTableComponent.FILTER_EXDATE
        || key === DividendTableComponent.FILTER_PAYDATE
        || key === DividendTableComponent.FILTER_CURRENCY) {
        ret = pr1[key].localeCompare(pr2[key]);
      } else if (key === DividendTableComponent.FILTER_AMOUNT
        || key === DividendTableComponent.FILTER_REINVEST
        || key === DividendTableComponent.FILTER_YIELD) {

        if (this.headerSort[key] === DividendTableComponent.SORT_DOWN) {
          ret = pr1 < pr2 ? 1 : -1;
        } else {
          ret = pr1 > pr2 ? 1 : -1;
        }
      }

      return this.headerSort[key] === DividendTableComponent.SORT_DOWN ? ret * -1 : ret;
    });
  }
}
