import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment_ from 'moment';
import { InsightCuration, InsightVersion, Tag } from '../../../models/vestrata/Insight';
import { Version } from '../../../models/vestrata/Version';
import {Article, ArticleVersion} from '../../../models/vestrata/Article';
import {TutorialVersion} from '../../../models/vestrata/Tutorial';
import {AuthenticationService} from '../../../api/services/authentication.service';
const moment = moment_;

@Component({
  selector: 'ves-shared-insights-card',
  templateUrl: './shared-insights-card.component.html',
  styleUrls: ['./shared-insights-card.component.scss']
})
export class SharedInsightsCardComponent implements OnInit {

  @Input() article: Version<ArticleVersion<any>>;
  @Input() curations: InsightCuration[] = [];
  @Input() insightOrg: string;
  @Input() myOrgId = '';
  @Output() viewArticle = new EventEmitter<boolean>();
  promoted = false;

  constructor(private translate: TranslateService,
              private authService: AuthenticationService) { }

  ngOnInit() {
    this.promoted = this.checkPromoted();
  }

  checkPromoted(): boolean {
    let ret = false;
    if (this.curations?.length > 0) {
      const curated = this.curations.find(c => c.bankId === this.myOrgId);
      if (curated && curated.promoted) {
        ret = true;
      }
    }
    return ret;
  }

  getArticleCategory() {
    let ret = '';
    let counter = 0;
    if (this.article.data.assetCategory.length > 0 ) {
      this.article.data.assetCategory.forEach( c => {
        if (counter <= this.article.data.assetCategory.length && counter !== 0) {
          ret = ret + ' | ' + c;
        } else {
          ret = ret + c;
        }
        counter ++;
      });
      ret = ret.toLowerCase();
    } else if (this.article.data.themeCategory.length > 0 ) {
      this.article.data.themeCategory.forEach( c => {
        if (counter <= this.article.data.themeCategory.length && counter !== 0) {
          ret = ret + ' | ' + this.translate.instant('article-display.theme.' + c);
        } else {
          ret = ret + this.translate.instant('article-display.theme.' + c);
        }
        counter++;
      });
      ret = ret.toLowerCase();
    } else if (this.article.data.assetAllocationCategory ) {
      ret = this.article.data.assetAllocationCategory.toUpperCase();
      counter++;
    } else {
      ret = this.translate.instant('article.main-categories.' + this.article.data.mainCategory);
    }
    // set to lower case as some categories are in upper and other lower. Css will set style
    return ret;
  }

  getArticleDate() {
    let ret = '';
    // console.log(this.insight);
    if (this.article.data.startDate && this.article.data.startDate.length > 0) {
      ret = this.article.data.startDate;
    } else if (this.article.reviewedDate) {
      ret = this.article.reviewedDate;
    } else {
      ret = this.article.submittedDate;
    }
    /*if (ret !== '') {
      ret = moment(ret).format('MMMM Do, YYYY');
    }*/
    return ret;
  }

  getArticleTitle() {
    return !!this.article.data.locales.find(i => i.lang === this.translate.currentLang) ?
      this.article.data.locales.find(i => i.lang === this.translate.currentLang).title :
      this.article.data.locales.find(i => i.lang === 'en').title;
  }

  getLocalDescription() {
    return !!this.article.data.locales.find(i => i.lang === this.translate.currentLang) ?
      this.article.data.locales.find(i => i.lang === this.translate.currentLang).description :
      this.article.data.locales.find(i => i.lang === 'en').description;
  }

  articleClick() {
    this.viewArticle.emit(true);
  }

  getTagLocal(tag: Tag) {
    return !!tag.data.find(t => t.lang === this.translate.currentLang) ?
      tag.data.find(t => t.lang === this.translate.currentLang).name :
      tag.data.find(t => t.lang === 'en').name;
  }

  hasCPD(article: ArticleVersion<any>) {
    return !!(article as TutorialVersion)?.cpd;
  }

  isCompleted(article: ArticleVersion<any>) {
    return (article as TutorialVersion)?.completed?.find(c => c.id === this.authService.getMyId());
  }

  isNew(article: Version<ArticleVersion<any>>) {
    return moment().diff(article?.reviewedDate) < 7;
  }
}
