import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "@ngx-config/core";
import {VesConfService} from "./conf.service";
import {Observable} from "rxjs";
import {Page} from "../../models/vestrata/Page";
import {ApiHttpService} from "../api.http.service";
import {Benchmark, BenchmarkSearchFilter} from "../../models/vestrata/Benchmark";

@Injectable({
  providedIn: 'root'
})
export class BenchmarkService {
  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  searchBenchmark(benchmarkSearchFilter: BenchmarkSearchFilter): Observable<Page<Benchmark>> {
    return this.http.post<Page<Benchmark>>(this.serviceUrl + 'benchmark/', benchmarkSearchFilter, ApiHttpService.getHeaders());
  }

  getBenchmarkById(id: string): Observable<Benchmark> {
    return this.http.get<Benchmark>(this.serviceUrl + 'benchmark/' + id, ApiHttpService.getHeaders());
  }

  getBenchmarkByIds(ids: string[]): Observable<Benchmark[]> {
    return this.http.post<Benchmark[]>(this.serviceUrl + 'benchmark/list', ids, ApiHttpService.getHeaders());
  }

  loadListFilters(): Observable<any> {
    return this.http.get<any>(this.serviceUrl + 'benchmark/' + 'load-filters', ApiHttpService.getHeaders());
  }

}
