import {Component, Input, OnInit} from '@angular/core';
import {finalize, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';
import {Benchmark} from "../../../models/vestrata/Benchmark";
import {PerformanceFilter} from "../../shared-performance-filter/shared-performance-filter.component";
import {BenchmarkService} from "../../../api/services/benchmark.service";
import {SharedLoaderService} from "../../shared-loader/service/shared-loader.service";
import {SharedSnackbarService} from "../../shared-snackbar/services/shared-snackbar.service";
import {PerformanceService} from "../../../api/services/performance.service";
import {SnackbarMessage} from "../../shared-snackbar/models/snackbar-message";
import {InstrumentData} from "../../../basic-components/components-3/instrument-data/instrument-data.component";

@Component({
  selector: 'ves-shared-benchmark-details',
  templateUrl: './shared-benchmark-details.component.html',
  styleUrls: ['./shared-benchmark-details.component.scss']
})
export class SharedBenchmarkDetailsComponent implements OnInit {

  @Input() benchmarkId: string;
  benchmark: Benchmark;

  performanceFilter = new PerformanceFilter();
  startDate = moment();
  endDate = moment();
  private readonly DATE_FORMAT = 'YYYY-MM-DD';

  performance: any[];
  private readonly PAST_PERFORMANCES_COLORS = ['#003057', '#248ED9'];
  mainColors = this.PAST_PERFORMANCES_COLORS;
  labels: string[];
  private initialValue = 10000;

  private _onDestroy = new Subject();
  constructor(private benchSrv: BenchmarkService,
              private loader: SharedLoaderService,
              private snackbar: SharedSnackbarService,
              private translate: TranslateService,
              private performanceService: PerformanceService) { }

  ngOnInit(): void {

    this.loadBenchmark();

  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private loadBenchmark() {
    this.loader.showBarLoader();
    this.benchSrv.getBenchmarkById(this.benchmarkId)
      .pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader()))
      .subscribe(
        data => {
          this.benchmark = data;
          if (this.benchmark.type === 'ms-category') {
            this.benchmark.inceptionDate = '2001-01-01'; // to get categories start date
          }
          this.init()
        },
        error => {
          this.snackbar.push(new SnackbarMessage(this.translate.instant(error.error.message), 'error'));
        }
      )
  }

  getMsID() {
    if (!this.benchmark)
      return null;

    return Benchmark.getIdFromExternalIds(this.benchmark, 'MS');
  }

  getIdentifiers() {
    const data: InstrumentData[] = [];
    this.benchmark?.externalIds.forEach(i => {
      const row = new InstrumentData();
      row.label = i.idType;
      row.value = i.id;
      data.push(row);
    });
    return data;
  }

  onSlidingBarDateChanged(startDate: string) {
    this.startDate = moment(startDate, 'L');
    this.performanceFilter.startDate = this.startDate.format(this.DATE_FORMAT);
    this.performanceFilter.endDate = this.endDate.format(this.DATE_FORMAT);
    this.getPerformance();
  }

  private init() {
    this.labels = [];
    this.labels.push(this.translate.instant('instrument-details.growth.instrument') + ' > ' + this.benchmark.name);
    this.performanceFilter = new PerformanceFilter();
    this.performanceFilter.startDate = this.benchmark.inceptionDate;
    this.performanceFilter.endDate = moment().format((this.DATE_FORMAT));
    this.getPerformance();

  }

  private getPerformance() {
    this.performanceService.getPerformance(PerformanceService.PERFORMANCE_TYPE_BENCHMARK, this.benchmark.id, this.initialValue, this.performanceFilter.startDate, this.performanceFilter.endDate, 'D')
      .subscribe(
        data => {
          this.performance = [data.benchmarkValues];
          console.log(data)
        },
        error => {console.log(error)}
      );
  }
}
