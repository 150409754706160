import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {SearchRequest} from '../../models/vestrata/SearchRequest';
import {MARKETING_MATERIALS_TYPE} from '../../models/vestrata/TradeIdea';
import {
  InstrumentInfo,
  InstrumentProdSearch,
  InstrumentSearchFilter
} from '../../models/vestrata/Instrument/Instrument';
import {SearchOption} from '../../basic-components/components-5/search-bar/search-bar.component';
import {finalize, take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AssetClass} from '../../models/vestrata/MajorAssetClass';
import {Observable} from 'rxjs/internal/Observable';
import {Permission} from '../../models/vestrata/Permission';
import {DocumentUploadTypes, VestrataDocument, VestrataDocumentInfo} from '../../models/vestrata/VestrataDocument';
import {InstrumentService} from '../../api/services/instrument.service';
import {CommonService} from '../../api/services/common.service';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, style, transition, trigger} from '@angular/animations';
import {AuthenticationService} from '../../api/services/authentication.service';

@Component({
  selector: 'ves-shared-product-search',
  templateUrl: './shared-product-search.component.html',
  styleUrls: ['./shared-product-search.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({transform: 'translateY(-150%)', 'pointer-events': 'none'}),
        animate('0.5s ease', style({transform: 'translateY(0%)', 'pointer-events': 'auto'}))
      ]),
      transition(':leave', [
        style({transform: 'translateY(0%)', 'pointer-events': 'auto'}),
        animate('0.5s ease', style({transform: 'translateY(-150%)', 'pointer-events': 'none'}))
      ])
    ])
  ]
})
export class SharedProductSearchComponent implements OnInit, OnDestroy {
  showSearchBar: boolean;
  @Input() openSearchBar: boolean;
  @Output() closeSearchBarEvent = new EventEmitter<boolean>();
  searchFilter = new InstrumentSearchFilter();

  btnText: string;
  searchOptions: SearchOption[] = [];
  private _onDestroy = new Subject();
  $assetClasses: Observable<AssetClass[]>;
  instrument: InstrumentProdSearch;
  selectedInstrument: SearchOption;
  permission = new Permission();
  combinedResearchsInsights: any[];
  currentLanguage: string;

  markMaterialsProduct = [];
  documentIdToView: string;
  allowedTypes: string[] = DocumentUploadTypes.all();
  viewFileDialog = false;
  docDialogMode = 'view';
  tempUploadedDocument: VestrataDocument;
  documents: VestrataDocumentInfo[];

  arrayUpdated = false;
  sliderArray: any = [[]];
  array: any;
  chunkSize = 5;
  slideIndex = 1;
  parent = document.getElementsByClassName('slider');
  showSlider: boolean;

  constructor(private instrumentService: InstrumentService,
              private commonService: CommonService,
              private loader: SharedLoaderService,
              private translate: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              public auth: AuthenticationService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.permission = this.auth.getPermissions().ips.tradeIdeas;
    this.currentLanguage = this.translate.currentLang;
    this.$assetClasses = this.commonService.getMajorAssetClasses();
  }

  toggleSearchBar() {
    this.openSearchBar = !this.openSearchBar;
  }

  closeSearchBar() {
    this.openSearchBar = false;
    this.showSearchBar = false;
    this.closeSearchBarEvent.emit(false);
  }

  executeLoad() {
    console.log('searchFilter', this.searchFilter);
    this.loader.showBarLoader();
    this.searchOptions = [];

    this.instrumentService.getInstrumentsWithSearch(this.searchFilter).pipe(takeUntil(this._onDestroy), take(1), finalize(() => this.loader.dismissLoader())).subscribe(
      data => {
        // this.searchOptions = [];

        // Initialization trade idea slider on first load page
        console.log('this.arrayUpdated', this.arrayUpdated);
        if (this.arrayUpdated && this.instrument && this.instrument?.tradeIdeas.length > this.chunkSize) {
          this.showSlider = true;
          this.showSlides(this.slideIndex);
        }
        data?.content.forEach(r => {
          this.searchOptions.push({id: 'Instrument - ' + this.getIsin(r), text: r?.name, extras: r});
        });
        console.log('searchOptions', this.searchOptions);
      }
    );
  }

  selectElement(option: SearchOption) {
    console.log(option);
    if (option.extras.id !== this.instrument?.instrument?.id) {
      this.loader.showBarLoader();
      this.instrumentService.getInstrumentRelatedModels(option.extras.id).pipe(takeUntil(this._onDestroy), finalize(() => {
        if (this.showSlider || !this.instrument?.instrument) {
          this.loader.dismissLoader();
        }
      })).subscribe(
        data => {
          this.instrument = data;
          this.selectedInstrument = option;
          console.log('instrument', this.instrument);
          this.getResearchInsight();
          this.checkMarMaterialsType();
          this.sliderArray = this.getChunks(this.instrument?.tradeIdeas, this.chunkSize);
          this.arrayUpdated = true;

          // Initialization trade idea slider on first load page
          if (this.arrayUpdated) {
            this.showSlider = true;
            this.showSlides(this.slideIndex);
          }
        }
      );
    }
  }

  reachBeginning(index: number) {
    return index === 0;
  }

  reachEnd(tradeIdea: any, index: number) {
    return index === tradeIdea?.length - 1;
  }

  getChunks(arr, size) {
    const chunkarray = [];
    if (arr) {
      for (let i = 0; i < arr.length; i += size) {
        chunkarray.push(arr.slice(i, i + size));
      }
      return chunkarray;
    }

  }

  showSlides(n) {
    let i;
    if (n > this.parent.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = this.parent.length;
    }
    for (i = 0; i < this.parent.length; i++) {
      this.renderer.setStyle(this.parent[i], 'display', 'none');
    }
    this.renderer.setStyle(this.parent[this.slideIndex - 1], 'display', 'flex');
  }

  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
    // this.arrowsPartnerEvent.emit(n);
  }

  getIsin(instrument): string {
    return instrument ? InstrumentInfo.getIdFromInstrumentIds(instrument, 'ISIN') : undefined;
  }

  getProductType(types: string[]) {
    if (types) {
      return types[types.length - 1];
    }
  }

  getResearchInsight() {
    this.combinedResearchsInsights = [];
    if (this.instrument && this.instrument?.researches) {
      this.instrument?.researches.forEach(el => {
        this.combinedResearchsInsights.push({type: 'research', data: el});
      });
    }
    if (this.instrument && this.instrument?.insights) {
      this.instrument?.insights.forEach(el => {
        this.combinedResearchsInsights.push({type: 'insight', data: el});
      });
    }
    console.log('combinedResearchsInsights', this.combinedResearchsInsights);
  }

  getLocalData(data) {
    let local = null;
    if (data != null) {
      data.locales.forEach(p => {
        if (p.lang === this.currentLanguage) {
          local = p;
        }
      });
    }
    return local;
  }

  onFilterChange(event: any) {
    this.initEmptySearchFilter();
    this.searchFilter.searchRequest.text = event;
    if (event.length) {
      this.executeLoad();
    } else {
      this.selectedInstrument = null;
      this.instrument = null;
      this.markMaterialsProduct = [];
      this.showSlider = false;
      this.arrayUpdated = false;
    }
  }

  initEmptySearchFilter() {
    this.searchFilter.searchRequest = new SearchRequest();
    this.searchFilter.searchRequest.text = '';
  }

  checkMarMaterialsType() {
    this.instrument?.tradeIdeas.forEach(elem => {
      const materialsType: any[] = [this.getLocalDocuments(elem)];
      const materialsTypeVideo = elem?.current?.data?.locales.find(el => el.documents === materialsType[0]);

      if (elem?.current?.data?.locales.find(e => e?.documents)) {
        materialsType.forEach(el => {
          if (el.find(el => (el?.blobName?.includes('.pdf') || el?.name?.includes('.pdf')))) {
            el.forEach(e => this.markMaterialsProduct.push({doc: e, type: MARKETING_MATERIALS_TYPE.PDF}));
          }
          if (el.find(el => el?.blobName?.includes('.xlsx') || el?.name?.includes('.xlsx'))) {
            el.forEach(e => this.markMaterialsProduct.push({doc: e, type: MARKETING_MATERIALS_TYPE.EXCEL}));
          }
          if (el.find(el => el?.blobName?.includes('.docx') || el?.name?.includes('.docx'))) {
            el.forEach(e => this.markMaterialsProduct.push({doc: e, type: MARKETING_MATERIALS_TYPE.WORD}));
          }
          if (el.find(el => el?.blobName?.includes('.pptx') || el?.name?.includes('.pptx'))) {
            el.forEach(e => this.markMaterialsProduct.push({doc: e, type: MARKETING_MATERIALS_TYPE.POWERPOINT}));
          }
          if (el.find(el => el?.blobName?.includes('.mp3') || el?.name?.includes('.mp3'))) {
            el.forEach(e => this.markMaterialsProduct.push({doc: e, type: MARKETING_MATERIALS_TYPE.AUDIO}));
          }
          if (el.find(el => el?.blobName?.includes('.mp4') || el?.name?.includes('.mp4'))) {
            el.forEach(e => this.markMaterialsProduct.push({doc: e, type: MARKETING_MATERIALS_TYPE.VIDEO}));
          }
        });
      }

      if (materialsTypeVideo?.videoUrl && materialsTypeVideo.videoUrl?.includes('youtube.com') || this.getLocalVideoUrl(elem)) {
        this.markMaterialsProduct.push({
          type: MARKETING_MATERIALS_TYPE.YOUTUBE,
          doc: {
            url: materialsTypeVideo?.videoUrl || this.getLocalVideoUrl(elem),
            effectiveDate: materialsTypeVideo?.videoEffectiveDate,
            contentType: MARKETING_MATERIALS_TYPE.YOUTUBE,
            name: MARKETING_MATERIALS_TYPE.YOUTUBE,
            type: MARKETING_MATERIALS_TYPE.YOUTUBE
          }
        });
      }
      if (materialsTypeVideo?.slides.length || this.getLocalSlides(elem)?.length) {
        this.markMaterialsProduct.push({
          type: MARKETING_MATERIALS_TYPE.SLIDESHOW,
          doc: {
            url: this.getLocalSlides(elem),
            contentType: MARKETING_MATERIALS_TYPE.SLIDESHOW,
            name: MARKETING_MATERIALS_TYPE.SLIDESHOW,
            type: MARKETING_MATERIALS_TYPE.SLIDESHOW
          }
        });
      }
    });

    console.log('markMaterialsProduct', this.markMaterialsProduct);
  }

  receiveViewFileEvent($event) {
    if ($event[1]?.id) {
      this.docDialogMode = $event[0];
      this.documentIdToView = $event[1]?.id;
      this.viewFileDialog = $event[2];
    }
    if ($event[1]?.url) {
      this.docDialogMode = $event[0];
      this.documentIdToView = $event[1];
      this.viewFileDialog = $event[2];
    }
  }

  getLocalSlides(elem) {
    if (!elem || !elem.current?.data.locales) {
      return;
    }
    return this.commonService.getLocalFieldFromVersion(elem.current?.data, this.currentLanguage, 'slides');
  }

  getLocalVideoUrl(elem) {
    if (!elem || !elem.current?.data.locales) {
      return;
    }
    return this.commonService.getLocalFieldFromVersion(elem.current?.data, this.currentLanguage, 'videoUrl');
  }

  getLocalDocuments(elem) {
    if (!elem || !elem.current?.data.locales) {
      return;
    }
    return this.commonService.getLocalFieldFromVersion(elem.current?.data, this.currentLanguage, 'documents');
  }

  onCloseDocViewer() {
    this.viewFileDialog = false;
    this.documentIdToView = null;
    this.tempUploadedDocument = null;
  }

  onSaveUploadedFile(event) {
    this.viewFileDialog = false;
    this.documents.unshift(event);
  }

  viewElement(ID: string, type: string) {
    console.log('id', ID);
    if (type === 'research-insight') {
      this.router.navigate(['/market-views/library/insights/details'], {relativeTo: this.route, queryParams: {id: ID}});
    }
    if (type === 'recommendations') {
      this.router.navigate(['/market-views/house-view/recommendations/details'], {relativeTo: this.route, queryParams: {id: ID}});
    }
    this.closeSearchBar();
  }

  createElement(type: string) {
    if (type === 'trade-idea' || type === 'marketing-materials') {
      this.router.navigate(['/market-views/house-view/trade-ideas/details'], {relativeTo: this.route});
    }
    if (type === 'insight') {
      this.router.navigate(['/market-views/library/insights/details'], {relativeTo: this.route});
    }
    if (type === 'recommendations') {
      this.router.navigate(['/market-views/house-view/recommendations/details'], {relativeTo: this.route});
    }
    this.closeSearchBar();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  scrollToElement($element?): void {
    console.log('element', $element);
    if ($element) {
      $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
  }
}
