import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from 'vestrata-components-lib';
import {from, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Store} from "@ngrx/store";
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  private _onDestroy = new Subject();
  loggedIn$: Observable<boolean>;
  menu: any = [
    {
      name: 'menu.home',
      src: '../../../assets/images/menu/icon-dashboard/icon-dashboard.png',
      srcset: '../../../assets/images/menu/icon-dashboard/icon-dashboard.png 1x, ' +
        '../../../assets/images/menu/icon-dashboard/icon-dashboard@2x.png 2x,' +
        '../../../assets/images/menu/icon-dashboard/icon-dashboard@3x.png 3x',
      urls: ['home'],
      mainUrl : 'home',
      hasNotif: false,
      hasAlert: false
    },
    {
      name: 'menu.strategies',
      src: '../../../assets/images/menu/icon-strategies/icon-strategies.png',
      srcset: '../../../assets/images/menu/icon-strategies/icon-strategies.png 1x, ' +
        '../../../assets/images/menu/icon-strategies/icon-strategies@2x.png 2x,' +
        '../../../assets/images/menu/icon-strategies/icon-strategies@3x.png 3x',
      urls: ['strategies'],
      mainUrl : 'strategies'
    },
    {
      name: 'menu.portfolio-models',
      src: '../../../assets/images/menu/icon-portfolio-models/icon-portfolio-models.png',
      srcset: '../../../assets/images/menu/icon-portfolio-models/icon-portfolio-models.png 1x, ' +
        '../../../assets/images/menu/icon-portfolio-models/icon-portfolio-models@2x.png 2x,' +
        '../../../assets/images/menu/icon-portfolio-models/icon-portfolio-models@3x.png 3x',
      urls: ['portfolio-models/models', 'portfolio-models/instruments', 'portfolio-models/benchmarks'],
      mainUrl : 'portfolio-models/models'
    },
    {
      name: 'menu.bank-approvals',
      src: '../../../assets/images/menu/icon-bank/icon-bank.png',
      srcset: '../../../assets/images/menu/icon-bank/icon-bank.png 1x, ' +
        '../../../assets/images/menu/icon-bank/icon-bank@2x.png 2x,' +
        '../../../assets/images/menu/icon-bank/icon-bank@3x.png 3x',
      urls: ['approvals'],
      mainUrl : 'approvals'
    },
    {
      name: 'menu.insights',
      src: '../../../assets/images/menu/icon-insights/icon-insights.png',
      srcset: '../../../assets/images/menu/icon-insights/icon-insights.png 1x, ' +
        '../../../assets/images/menu/icon-insights/icon-insights@2x.png 2x,' +
        '../../../assets/images/menu/icon-insights/icon-insights@3x.png 3x',
      urls: ['insights'],
      mainUrl : 'insights'
    },
    {
      name: 'menu.document',
      src: '../../../assets/images/menu/icon-document-repository/icon-document-repository.png',
      srcset: '../../../assets/images/menu/icon-document-repository/icon-document-repository.png 1x, ' +
        '../../../assets/images/menu/icon-document-repository/icon-document-repository@2x.png 2x,' +
        '../../../assets/images/menu/icon-document-repository/icon-document-repository@3x.png 3x',
      urls: ['documents'],
      mainUrl : 'documents'
    }
  ];
  hasPlatformPermissions: boolean;

  constructor(private translate: TranslateService,
              private router: Router,
              private authentication: AuthenticationService,
              private keycloak: KeycloakService,
              private store: Store<any>
  ) {
    translate.addLangs(['en', 'fr', 'it']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it/) ? browserLang : 'en');
    this.loggedIn$ = from(this.keycloak.isLoggedIn());
    this.hasPlatformPermissions = this.authentication.hasPlaformPermissions(AuthenticationService.KEY_PARTNER_PERMISSIONS);

  }

  ngOnInit(): void {
    this.subscribeToNotifications();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  private subscribeToNotifications() {
    this.store.select('retrieveAlerts').pipe(takeUntil(this._onDestroy)).subscribe(
      state => {
        this.menu[0].hasAlert = state.allAlerts && state.allAlerts.length > 0;
      }
    );

    this.store.select('retrieveNotifications').pipe(takeUntil(this._onDestroy)).subscribe(
      state => {
        this.menu[0].hasNotif = state.allNotifications && state.allNotifications.length > 0;
      }
    );
  }
}
