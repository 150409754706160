import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SharedComponentsService} from '../../../api/services/shared-components.service';
import {VersionStatus} from '../../../models/vestrata/Version';
import {isEqual} from 'lodash';
import {FourEyeComponent} from '../../4eye-component';

@Component({
  selector: 'ves-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})

export class DropdownComponent extends FourEyeComponent<any> implements OnInit, OnChanges {

  @Input() id: string;
  @Input() type = 0;
  @Input() showLabel = true;

  @Input()
  public labelName: string;

  @Input()
  public placeholderMsg: string;

  @Input()
  dataDropdown: any;

  imgArrow: string;
  isOpen = false;
  isSelect = false;

  fullValue: any;
  /*
    @Input()
    dataDropdown: any = [
      {
        name: 'England',
        value: 'EN'
      },
      {
        name: 'France',
        value: 'FR'
      },
    ];
    */

  constructor() {
    super();
    this.labelName = 'dropdown-component.country';
    this.placeholderMsg = 'dropdown-component.placeholder-msg';
  }

  onClick(language: any) {
    console.log(language);
    if (this.type === 0) {
      this._editingValue = language;
      this.editingValueChange.emit(this._editingValue);
      this.isOpen = false;
      this.isSelect = true;
    } else if (this.type === 1) {
      this.fullValue = language;
      this._editingValue = language.value;
      this.editingValueChange.emit(this._editingValue);
      this.isOpen = false;
      this.isSelect = true;
    }
  }

  openDrop() {
    if (!this.locked && this.getState().state !== VersionStatus.REQUEST) {
      this.isOpen = !this.isOpen;
    }
  }

  getClass(isSelect: boolean, isOpen: boolean, status: string, blocked: boolean): string {
    let classValue = '';
    if (this.isOpen === false && this.isSelect === false) {
      classValue = 'dropdown-inactive ';
      this.imgArrow = '../../../../assets/images/components/components-2/arrow-fold-white.svg';
    } else if (this.isOpen && this.isSelect === false) {
      classValue = 'dropdown-active ';
      this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-unfold.svg';
    } else if (this.isOpen === false && this.isSelect) {
      classValue = 'dropdown-active-completed ';
      this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-fold.svg';
    } else if (this.isOpen && this.isSelect) {
      classValue = 'dropdown-active-completed-open ';
      this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-unfold.svg';
    }
    classValue += this.getStyleClass(status, blocked);
    return classValue;
  }

  getStyleClass(status: string, blocked: boolean): string {
    let styleClass = '';
    if (this.fourEyesMode) {
      switch (status) {
        case VersionStatus.EDITED :
          styleClass = 'edited';
          this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-fold-white.svg';
          break;
        case VersionStatus.SAVED :
          styleClass = 'saved';
          this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-fold-white.svg';
          break;
        case VersionStatus.REQUEST :
          styleClass = 'submitted';
          this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-fold-white.svg';
          break;
      }
    }

    if (blocked && status !== VersionStatus.REQUEST) {
      styleClass = 'locked';
    }

    return styleClass;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes) {
      if (changes.dropdownData ) {
        this.init();
      }
    }
  }

  init() {
    if (this.type === 1 ) {
      if (this.dataDropdown && this.dataDropdown.length > 0) {
        this.fullValue = this.dataDropdown.find(a => {
          return a.value === this._editingValue; } );
        if (!this.fullValue || !this.fullValue.name) {
          this.fullValue = this.dataDropdown[0];
        } else {
          this.isSelect = true;
        }
      } else {
        this.fullValue = { label: '', value: false };
      }
    }
  }

  ngOnInit() {
    this.init();
  }

  protected equals(a: any, b: any): boolean {
    return isEqual(a, b);
  }

}
