import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {debounceTime, finalize, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ProductApprovalChangedStatus,
  ProductApprovalDto,
  ProductSearchFilter
} from '../../models/vestrata/ProductApproval';
import {
  BlocOption,
  FilterOption
} from '../../basic-components/components-5/two-level-bar-filter/two-level-bar-filter.component';
import {Paging, SearchRequest} from '../../models/vestrata/SearchRequest';
import {Permission} from '../../models/vestrata/Permission';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {SharedSnackbarService} from '../shared-snackbar/services/shared-snackbar.service';
import {ProductCatalogService} from '../../api/services/product-catalog.service';
import {AuthenticationService} from '../../api/services/authentication.service';
import {SnackbarMessage} from '../shared-snackbar/models/snackbar-message';
import {Instrument} from "../../models/vestrata/Instrument/Instrument";
import {NavigationMenuService} from "../../basic-components/unknown-components/navigation-menu/navigation-menu.service";
import {ProductRecommendationListDto} from "../../models/vestrata/ProductRecommendation";
import {VersionStatus} from "../../models/vestrata/Version";
import {NavigationData} from "../../basic-components/unknown-components/navigation-menu/navigation-menu.component";

@Component({
  selector: 'ves-product-catalog-list',
  templateUrl: './product-catalog-list.component.html',
  styleUrls: ['./product-catalog-list.component.scss']
})
export class ProductCatalogListComponent implements OnInit, OnDestroy {


  private _onDestroy = new Subject();
  productList: ProductApprovalDto[] = [];
  searchFilter = new ProductSearchFilter();
  loadNumber: number;
  remaining: number;
  loadMoreNumber: number;
  currentPage = 0;
  allStatus: any[] = [];
  productTypesOptions: BlocOption[] = [];
  currentStatusFilter: any;

  search: SearchRequest = new SearchRequest();

  permission: Permission = new Permission();
  navigationData = new NavigationData();
  isApproveDeletion = false;
  viewDeleteCheck = false;
  instanceToDelete: ProductApprovalDto;
  isLoadMore = false;

  // Specific data
  readonly readonly;
  eligibilitiesBlocs: BlocOption[] = [];
  statusBloc: BlocOption[] = [];
  assetClassesBloc: BlocOption[] = [];
  productTypesBloc: BlocOption[] = [];

  selectedFilters: BlocOption[] = [];
  filteringText: string;

  // Paginator //
  pages = 0;
  results = 0;
  resultNumber = 25;
  pageNumber = 0;
  // Tri & Filtering
  private static readonly SORT_UP = 'up';
  private static readonly SORT_DOWN = 'down';
  headerSort = {
    type: ProductCatalogListComponent.SORT_DOWN,
    name: ProductCatalogListComponent.SORT_DOWN,
    isin: ProductCatalogListComponent.SORT_DOWN,
    manufacturedDate: ProductCatalogListComponent.SORT_DOWN,
    addedToCatalog: ProductCatalogListComponent.SORT_DOWN,
    exposure: ProductCatalogListComponent.SORT_DOWN,
    status: ProductCatalogListComponent.SORT_DOWN,
  };
  headers = Object.keys(this.headerSort);

  private static readonly FILTER_PROD_TYPE = 'productType';
  private static readonly FILTER_ASSET_CLASS = 'assetClass';
  private static readonly FILTER_STATUS = 'status';
  private static readonly FILTER_CLIENT_ELIGIBILITY = 'clientEligibility';
  private static readonly FILTER_PRODUCT_ELIGIBILITY = 'productEligibility';

  constructor(public translate: TranslateService,
              private loader: SharedLoaderService,
              private snackbar: SharedSnackbarService,
              private catalogService: ProductCatalogService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthenticationService,
              private activatedRoute: ActivatedRoute,
              private menuService: NavigationMenuService) {
    this.readonly = !!this.route.snapshot.data.readonly;
  }

  ngOnInit() {
    if (this.authService.getPermissions() && this.authService.getPermissions().ips && this.authService.getPermissions().ips.productCatalogue && !this.readonly) {
      this.permission = this.authService.getPermissions().ips.productCatalogue;
    } else {
      this.permission = new Permission();
    }
    console.log('productList', this.productList);

    this.initEmptySearchFilter();
    this.loadAndBuildFilters();
    this.executeLoad();
    this.setMenuData();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.menuService.resetNavigationData();
  }
  private initEmptySearchFilter() {
    this.searchFilter.productTypes = [];
    this.searchFilter.assetClasses = [];
    this.searchFilter.clientEligibility = [];
    this.searchFilter.productEligibility = [];
    this.searchFilter.status = [];
    this.searchFilter.searchRequest = new SearchRequest();
    this.searchFilter.searchRequest.paging = new Paging();
    this.searchFilter.searchRequest.paging.page = this.pageNumber;
    this.searchFilter.searchRequest.paging.size = this.resultNumber;
    this.searchFilter.searchRequest.criteria = {};
    this.searchFilter.searchRequest.text = '';
    this.searchFilter.approvedOnly = this.readonly;
  }

  /*searchClicked(event: any) {
    this.searchFilter.searchRequest.text = event;
    this.initPaging();
    this.executeLoad();
  }*/

  executeLoad() {
    this.loader.showBarLoader();
    this.catalogService.searchProductCatalog(this.searchFilter)
      .pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe(results => {
      this.productList = results.content;
      this.pages = results.totalPages;
      this.results = results.totalElements;
      this.setMenuData();
    }, err => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant(err.error.message), 'error'));

    });
  }


  initPaging() {
    this.searchFilter.searchRequest.paging = new Paging();
    this.searchFilter.searchRequest.paging.page = 0;
    this.searchFilter.searchRequest.paging.size = 10;
  }

  deleteClick() {
    this.snackbar.push(new SnackbarMessage(this.translate.instant('under-construction.info')));
  }

  viewClick(product) {
    const relatedId = product.type === 'portfoliomodel' ? product.pfm.id : product.instrument.id;
    this.router.navigate(['details'],
      {relativeTo: this.activatedRoute, queryParams: {poa: product.id,  relatedProduct: relatedId, productType: product.type }});
  }

  getMaxExposure(prod: ProductApprovalDto) {
    if (prod.current && prod.current.data) {
      return prod.current.data.maxExposure;
    }
    if (prod.pending && prod.pending.data) {
      return prod.pending.data.maxExposure;
    }
    return null;
  }

  fourEyesDelete(instance, isApproving: boolean) {
    this.instanceToDelete = instance;
    this.viewDeleteCheck = true;
    this.isApproveDeletion = isApproving;
  }
  cancelDelete() {
    this.instanceToDelete = null;
    this.viewDeleteCheck = false;
  }

  submitOrApproveDeletion(instance) {
    if (this.isApproveDeletion) {
      this.approveDeleteInstance(instance);
      return;
    }
    this.submitDeleteInstance(instance);
  }

  submitDeleteInstance(instance) {
    this.loader.showBarLoader();
    this.catalogService.submitDeletion(instance.id).pipe(takeUntil(this._onDestroy),
      finalize(() => {
        this.cancelDelete();
        this.loader.dismissLoader();
      }))
      .subscribe(data => {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('four-eyes-deletion.submit-delete'), 'info'));
        this.executeLoad();
      });
  }

  approveDeleteInstance(instance) {
    this.loader.showBarLoader();
    this.catalogService.approveDeletion(instance.id).pipe(takeUntil(this._onDestroy),
      finalize(() => {
        this.cancelDelete();
        this.loader.dismissLoader();
      }))
      .subscribe(_ => {
        this.executeLoad();
        this.snackbar.push(new SnackbarMessage(this.translate.instant('four-eyes-deletion.approved-deletion'), 'info'));
      });
  }
  rejectDeleteInstance(instance) {
    this.loader.showBarLoader();
    this.catalogService.declineDeletion(instance.id).pipe(takeUntil(this._onDestroy),
      finalize(() => {
        this.cancelDelete();
        this.loader.dismissLoader();
      }))
      .subscribe(data => {
        this.executeLoad();
        this.snackbar.push(new SnackbarMessage(this.translate.instant('four-eyes-deletion.reject-deletion'), 'info'));
      });
  }

  loadAndBuildFilters() {
    this.catalogService.loadFilters(this.readonly).pipe(takeUntil(this._onDestroy))
      .subscribe(
        data => {
          console.log('******DATA-FILTER****', data);

          const prodTypesBloc = new BlocOption();
          prodTypesBloc.options = [];
          prodTypesBloc.value = ProductCatalogListComponent.FILTER_PROD_TYPE;
          data.productTypes?.forEach(item => {
            prodTypesBloc.options.push(new FilterOption(item.key, item.key, item.count, true, false, null));
          });
          this.productTypesBloc.push(prodTypesBloc);

          const assetBloc = new BlocOption();
          assetBloc.options = [];
          assetBloc.value = ProductCatalogListComponent.FILTER_ASSET_CLASS;
          data.assetClasses?.forEach(item => {
            assetBloc.options.push(new FilterOption(item.key, item.key, item.count, true, false, null));
          });
          this.assetClassesBloc.push(assetBloc);

          const statusBloc = new BlocOption();
          statusBloc.options = [];
          statusBloc.value = ProductCatalogListComponent.FILTER_STATUS;
          data.status?.forEach(item => {
            if (!this.readonly || item.key !== 'notApproved') {
              statusBloc.options.push(new FilterOption('product-catalog.' + item.key, item.key, item.count, true, false, null));
            }
          });
          this.statusBloc.push(statusBloc);

          const clientEligibilityBloc = new BlocOption();
          clientEligibilityBloc.options = [];
          clientEligibilityBloc.label = 'Client';
          clientEligibilityBloc.value = ProductCatalogListComponent.FILTER_CLIENT_ELIGIBILITY;
          data.clientEligibility?.forEach(item => {
            clientEligibilityBloc.options.push(new FilterOption('product-catalog.' + item.key, item.key, item.count, true, false, null));
          });

          const productEligibilityBloc = new BlocOption();
          productEligibilityBloc.options = [];
          productEligibilityBloc.label = 'Account';
          productEligibilityBloc.value = ProductCatalogListComponent.FILTER_PRODUCT_ELIGIBILITY;
          data.productEligibility?.forEach(item => {
            productEligibilityBloc.options.push(new FilterOption('bank-investement.' + item.key, item.key, item.count, true, false, null));
          });

          this.eligibilitiesBlocs.push(clientEligibilityBloc);
          this.eligibilitiesBlocs.push(productEligibilityBloc);


        }
      );
  }
  onFilterChange(event: any) {
    this.initEmptySearchFilter();

    if (event && Array.isArray(event)) {
      for (const option of event) {
        const index = this.selectedFilters.findIndex(b => b.value === option.value);
        if (index !== -1) {
          this.selectedFilters.splice(index, 1);
        }
        if ((option as BlocOption).selectedCount > 0) {
          this.selectedFilters.push(option);
        }
      }
    } else {
      if (event) {
        this.filteringText = event;
      }
    }

    this.searchFilter.searchRequest.text = this.filteringText;
    for (const option of this.selectedFilters) {
      const filters = option.options.filter(o => o.checked);
      if (option.value === ProductCatalogListComponent.FILTER_PROD_TYPE) {
        this.searchFilter.productTypes = filters.map(f => f.value);
      } else if (option.value === ProductCatalogListComponent.FILTER_ASSET_CLASS) {
        this.searchFilter.assetClasses = filters.map(f => f.value);
      } else if (option.value === ProductCatalogListComponent.FILTER_STATUS) {
        this.searchFilter.status = filters.map(f => f.value);
      } else if (option.value === ProductCatalogListComponent.FILTER_CLIENT_ELIGIBILITY) {
        this.searchFilter.clientEligibility = filters.map(f => f.value);
      } else if (option.value === ProductCatalogListComponent.FILTER_PRODUCT_ELIGIBILITY) {
        this.searchFilter.productEligibility = filters.map(f => f.value);
      }
    }

    this.executeLoad();
  }

  onSizeChanged(size: number) {
    this.resultNumber = size;
    this.onFilterChange(null);
  }

  onPageChanged(page: number) {
    this.pageNumber = page - 1;
    this.onFilterChange(null);
  }

  getProductType(prod: ProductApprovalDto) {
    return prod.instrument?.productType && prod.instrument?.productType.length > 0 ?
      prod.instrument?.productType[prod.instrument?.productType.length - 1] :
      this.translate.instant('instrument.unknown');
  }

  getInstrumentId(instrument: Instrument) {
    if (!instrument || !instrument.instrumentIds) {
      return;
    }
    let ret = instrument.instrumentIds.find(i => i.idType === 'ISIN');
    if (!ret) {
      ret = instrument.instrumentIds[0];
    }
    return ret;
  }

  lineBgStyle(prod: ProductApprovalDto) {
    if (prod.pending?.status === VersionStatus.SAVED) {
      return 'saved-line';
    }
    if (prod.pending?.status === VersionStatus.REQUEST) {
      return 'requested-line';
    }
  }
  get4EyesStatus(rec: ProductApprovalDto) {
    if (rec && rec.pending) {
      switch (rec.pending.status) {
        case VersionStatus.REQUEST:
        case VersionStatus.REJECTED: return 'submitted';
        case VersionStatus.SAVED: return VersionStatus.SAVED;
      }
    }
  }

  onAddNew() {
    this.router.navigate(['search'], {relativeTo: this.route});
  }

  private setMenuData() {
    this.navigationData.permission = this.permission;
    this.navigationData.interface = this;
    this.navigationData.fourEyeView = false;
    this.navigationData.pageTitle = this.buildPageTitle();
    this.menuService.setNavigationData(this.navigationData);
  }

  private buildPageTitle() {
    let title = '';
    if (this.results) {
      title += this.results + ' | ';
      title += this.translate.instant('governance.product-catalog');
    }
    return title;
  }
}
