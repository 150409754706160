import { MSHoldings } from './MSHoldings';

export class MSPortfolio {
  name: string;
  totalValue: number;
  currencyId: string;
  holdings: MSHoldings[];
  benchmark: MSPortfolioBenchmark;
  externalId: string;
}

export class MSPortfolioBenchmark {
  type: string;
  holdings: MSHoldings[];
}
