import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ves-short-contact',
  templateUrl: './short-contact.component.html',
  styleUrls: ['./short-contact.component.scss']
})
export class ShortContactComponent implements OnInit {

  @Input() value: string;
  @Input() type: 'tel' | 'mail';

  constructor() { }

  ngOnInit() {
  }

  getHrefFromType(type: string, value: string): string {
    let href = '';
    switch (type) {
      case 'tel': href = 'tel:' + value; break;
      case 'mail': href = 'mailto:' + value; break;
    }
    return href;
  }

  getImageFromType(type: string): string {
    let src = '../../../assets/images/components/shared/';
    switch (type) {
      case 'tel': src += 'pictogram-phone.svg'; break;
      case 'mail': src += 'pictogram-email.svg'; break;
    }
    return src;
  }

}
