export class TopMenuItem {
  name: string;
  urls: string[];
  mainUrl: string;
  value: number;
}

export class SimpleTopMenuItem {
  title: string;
  subtitle: string;
}

export class SubMenuItem {
  name: string;
  isActive: boolean;
  isDisable: boolean;
}

export class TabLanguage {
  name: string;
  constructor(name: string) {
    this.name = name;
  }
}
