import {
  Article,
  ArticleCategoriesDto,
  ArticleLocal,
  ArticleModelDto,
  ArticleMongoSearch, ArticleSearchCriteria,
  ArticleStrategyDto,
  ArticleVersion
} from './Article';

export class Insight extends Article<InsightVersion> {
  curations: InsightCuration[] = [];
  embed: boolean;
  byVestrata: boolean;
}

export class InsightVersion extends ArticleVersion<InsightLocal> {
  shared: boolean;
  strategyCategory?: string [] = [];
  modelCategory?: string [] = [];
}

export class InsightLocal extends ArticleLocal {
  externalUrl = '';
}

export class InsightCuration {
  bankId: string;
  enabled: boolean;
  promoted: boolean;
  curatorOrgId: string;
  curatorId: string;
  curatorEmail: string;
  curationDate: string;
  tags: string [] = [];
}

export enum InsightMainCategory {
  MARKET = 'MARKET',
  THEMES = 'THEMATIC',
  // ASSETALLOCATION = 'ASSETALLOCATION',
  PRODUCT = 'PRODUCT',
}

export class TagGroup {
  id: string;
  data: TagGroupLocal[] = [];
  tags: Tag[] = [];
}

export class TagGroupLocal {
  lang: string;
  name: string;
}

export class Tag {
  id: string;
  data: TagLocal[] = [];
}

export class TagLocal {
  lang: string;
  name: string;
}

export class InsightCategoriesDto extends ArticleCategoriesDto {
  mainCategory: InsightMainCategory;
  partners: InsightPartnerDto[] = [];
  strategyCategories: ArticleStrategyDto[] = [];
  modelCategories: ArticleModelDto[] = [];
}

export class InsightPartnerDto {
  orgId: string;
  name: string;
}

export class InsightMongoSearch extends ArticleMongoSearch {
  static readonly  searchOrg = 'organisationId';
  static readonly orgName = 'organisationName';
  static readonly assetAllocation = 'current.data.assetAllocationCategory';
  static readonly strategy = 'current.data.strategyCategory';
  static readonly model = 'current.data.modelCategory';
  static readonly curations = 'curations';
  static readonly bank = 'bankId';
  static readonly enable = 'enabled';
  static readonly promote = 'promoted';
}

export class InsightSearchCriteria extends ArticleSearchCriteria {
  organisationId: string;
  partner = 'all';
  assetAllocationCategory = 'all';
  strategyCategory = 'all';
  modelCategory = 'all';
  bankId = 'all';
  enabled = true;
  promoted: boolean;
}
