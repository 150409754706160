<div *ngIf="hasPlatformPermissions && (this.loggedIn$ | async)" class="app-component-scrollable-container">
  <ves-shared-vertical-menu [menu]="menu"></ves-shared-vertical-menu>
  <div class="app-component-layout-main menu-displayed">
    <router-outlet></router-outlet>
    <ves-shared-main-footer></ves-shared-main-footer>
  </div>
</div>
<ves-shared-snackbar></ves-shared-snackbar>
<ves-shared-loader></ves-shared-loader>
<ves-shared-alert-dispatcher></ves-shared-alert-dispatcher>
<ves-shared-notification-dispatcher></ves-shared-notification-dispatcher>

