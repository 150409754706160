import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Permission} from "../../../models/vestrata/Permission";
import {VersionStatus} from "../../../models/vestrata/Version";

@Component({
  selector: 'ves-full-btn',
  templateUrl: './full-btn.component.html',
  styleUrls: ['./full-btn.component.scss']
})
export class FullBtnComponent implements OnInit, OnChanges {

  @Output()
  eachClick = new EventEmitter<any>();

  @Input() id: string;
  @Input() label: string;
  @Input() imgUrl: string;
  @Input() imgUrlBlocked: string;
  @Input() imgUrlHover: string;
  @Input() btnType = 'readwrite';
  @Input() permission: Permission;
  @Input() pendingStatus: VersionStatus;
  @Input() disabled = false;

  currentImage: string;

  constructor(private translate: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data) {
      this.currentImage = this.imgUrl;
    }
  }

  isNotLocked() {
    if (this.disabled) {
      return false;
    }

    let ret = true;
    if (this.permission != null) {
      if (this.btnType === 'approver') {
        ret = this.permission.approve;
      } else {
        ret = this.permission.write;
      }
    }
    ret = ret && !(this.pendingStatus === VersionStatus.REQUEST);
    return ret;
  }

  onHover() {
    this.currentImage = this.imgUrlHover;
  }

  onHoverOut() {
    this.currentImage = this.imgUrl;
  }

  click() {
    if (this.permission) {
      if ((this.permission.write && !(this.pendingStatus === VersionStatus.REQUEST)) ||
        (this.btnType === 'approver' && this.permission.approve)) {
        this.eachClick.emit();
      }
    } else {
      // If no permission has been passed through the button default behavior is click emitted.
      this.eachClick.emit();
    }
  }

  ngOnInit() {
    this.currentImage = this.imgUrl;
    if (this.label && this.label !== '') { this.label =  this.translate.instant(this.label ); }
  }
}
/*
export class BtnData {
  readonly id: string;
  label: string;
  imgUrl: string;
  imgUrlHover: string;

  constructor(id: string) {
    this.id = id;
  }
}
*/
