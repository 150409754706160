import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {AuthenticationService} from '../api/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppAuthGuard extends KeycloakAuthGuard {
  constructor(protected router: Router, protected keycloak: KeycloakService, protected authentication: AuthenticationService) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    console.log(window.location.origin + state.url);
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      console.log('not authenticated - redirect');
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
      return;
    }

    // Redirect if you try to access portal you dont have rights for.
    const currentClient = this.keycloak.getKeycloakInstance().clientId;
    if (this.authenticated && !this.authentication.hasPlaformPermissions(currentClient)) {
      console.log('not enough rights - redirect');
      if (!this.authentication.redirect()) {
        console.log('not able to redirect, disconnect');
        this.authentication.disconnect();
      }
    }

    // Get the roles & specialties required from the route.
    const requiredRoles = route.data.roles;
    const requiredSpecialties = route.data.specialties;
    console.log(this.roles);
    console.log(requiredRoles);
    console.log(requiredSpecialties);
    console.log(this.authentication.getSpecialties());

    const rolesCheck = (requiredRoles instanceof Array) && requiredRoles.length > 0;
    const specsCheck = (requiredSpecialties instanceof Array) && requiredSpecialties.length > 0;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!rolesCheck && !specsCheck) {
      return true;
    }

    const hasRoles = rolesCheck ? requiredRoles && requiredRoles.some((role) => this.roles.includes(role)) : true;
    const hasSpecialties = specsCheck ? requiredSpecialties && requiredSpecialties.some((specialty) => this.authentication.getSpecialties().includes(specialty)) : true;

    // Allow the user to proceed if any of the required roles are present.
    if (hasRoles && hasSpecialties) {
      return true;
    } else {
      return this.router.parseUrl('/home');
    }
  }
}
