import {Injectable} from "@angular/core";
import moment from "moment";
import {isEqual} from 'lodash';
import {Role} from '../models/vestrata/Role';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() { }

  private readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private readonly URL_REGEX = /^(https?:\/\/)?([a-z0-9\-\.]+\.[a-z0-9]+)+(\/[a-z0-9\.\-#]*)*(\?.*)?$/;
  removeFromIterable(it: any[], value: any): number {
    let removedIndex = -1;
    it.forEach( (item, index) => {
      if (isEqual(item, value)) {
        removedIndex = index;
        it.splice(index, 1);
      }
    });
    return removedIndex;
  }

  isEmail(email: string) {
    return this.EMAIL_REGEX.test(email.toLowerCase());
  }
  isUrl(url: string) {
    return this.URL_REGEX.test(url.toLowerCase());
  }

  isDesktop(windowWidth: number): boolean {
    return windowWidth >= 1024;
  }

  extractNameFromPath(path: string): string {
    return path.split('\\').pop().split('/').pop();
  }

  // Non static version of format date to use directly in HTML files.
  // May be consider to create a pipe for this.
  formatStringDate(date: string, format?: string) {
    return UtilsService.formatDate(date, format);
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str) || !str.trim());
  }

  isEmpty(str) {
    return (!str || 0 === str.length);
  }

  isBlankOrEmpty(str) {
    return this.isBlank(str) || this.isEmpty(str);
  }

  /* Format string to a specific format or ''DD/MM/YYYY - h:mm:ss A'' by default */
  static formatDate(date: string, format: string = 'DD/MM/YYYY - h:mm:ss A'): string {
    // TO DO NICE TO HAVE FORMAT US format
    // return moment(date.substring(0, date.lastIndexOf('['))).format('DD/MM/YYYY h:mm:ss A');
    let ret = '';
    if (date != null && date !== '') {
      ret = moment(date).format(format);
    }
    return ret;
  }

  static manageString(value: string): string {
    if (value == null || value == undefined) {
      value = '';
    }
    return value.trim();
  }

  public static parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  }

  static getFullLanguageName(lang: string): string {
    let retLanguage: string = 'english';
    if (lang === 'fr') {
      retLanguage = 'français';
    } else if (lang === 'en') {
      retLanguage = 'english';
    }
    return retLanguage.toUpperCase();
  }

  static swap(direction: string, input: any[], index1: number) {
    const temp = input[index1];
    let index2 = 0;
    if (direction === 'up' ) {
      index2 = index1 - 1;
    }
    if (direction === 'down' ) {
      index2 = index1 + 1;
    }

    input[index1] = input[index2];
    input[index2] = temp;
  }
  shadeColor(color, percent: number) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt('' + (R * (100 + percent) / 100));
    G = parseInt('' + (G * (100 + percent) / 100));
    B = parseInt('' + (B * (100 + percent) / 100));

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
  }
  hexToHsl(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    var r = parseInt(result[1], 16);
    var g = parseInt(result[2], 16);
    var b = parseInt(result[3], 16);

    r /= 255, g /= 255, b /= 255;
    var max = Math.max(r, g, b), min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2;

    if(max == min){
      h = s = 0; // achromatic
    } else {
      var d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch(max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }

    s = s*100;
    s = Math.round(s);
    l = l*100;
    l = Math.round(l);
    h = Math.round(360*h);

    return  {hue: h, sat: s, light: l};
  }

  hslToHex(h, s, l) {
    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    const hexR = this.toHex(r);
    const hexG = this.toHex(r);
    const hexB = this.toHex(r);

    return '#' + hexR + hexG + hexB;
  }

  toHex(x) {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? ('0' + hex) : hex;
  }

  static mapRoles(stringRoles: string[], roles: Role[]): Role[] {
    if (!stringRoles || !roles) {
      return [];
    }
    return roles.filter(r => !!stringRoles.find(s => s === r.value));
  }

  static getPropertyName(obj, expression) {
    const res = {};
    Object.keys(obj).map(k => { res[k] = () => k; });
    return expression(res)();
  }

}
