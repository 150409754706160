import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Permission} from "../../models/vestrata/Permission";

@Component({
  selector: 'ves-shared-accordion',
  templateUrl: './shared-accordion.component.html',
  styleUrls: ['./shared-accordion.component.scss']
})
export class SharedAccordionComponent implements OnInit {

  @Input()  isAccordionOpen: boolean = false;
  @Input() isOpenable = true;
  deleteEnable: boolean = true;

  @Input() index: number;
  @Input() permission: Permission;
  @Input() viewDelete: boolean = true;
  @Input() viewDownload: boolean = false;
  @Input() docUrl: string;
  @Output() eventDeleteClick = new EventEmitter<number>();
  @Output() eventDownloadClick = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  manageAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
  }

  remove() {
    this.eventDeleteClick.emit(this.index);
  }

  download() {
    this.eventDownloadClick.emit(this.index);
  }

}
