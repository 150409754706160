import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ves-last-review',
  templateUrl: './last-review.component.html',
  styleUrls: ['./last-review.component.scss']
})
export class LastReviewComponent implements OnInit, OnChanges, OnDestroy {

  private _onDestroy = new Subject();

  @Input() label: string;
  @Input() type: string;
  @Input() date: any;
  @Input() name: string;

  formattedDate: any;
  formattedHour: any;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe(event => {
      if (event.lang) {
        this.formatDate(event.lang);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date && changes.date.currentValue) {
      this.formatDate(this.translate.currentLang);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  formatDate(lang: string): void {
    moment.locale(lang);
    this.formattedDate = moment(this.date).format('L');
    this.formattedHour = moment(this.date).format('LTS');
  }

  getImageByType(type: string): string {
    let src = '../../../assets/images/components/components-7/';
    switch (type) {
      case 'created':
        src += 'icon-created.svg';
        break;
      case 'updated':
        src += 'icon-updated.svg';
        break;
      case 'uploaded':
        src += 'icon-uploaded.svg';
        break;
      case 'downloaded':
        src += 'icon-downloaded.svg';
        break;
      case 'changed':
        src += 'icon-changed.svg';
        break;
      case 'validated':
        src += 'icon-validated.svg';
        break;
      case 'edited':
        src += 'icon-edited.svg';
        break;
      case 'declined':
        src += 'icon-declined.svg';
        break;
      case 'submitted':
        src += 'icon-submitted.svg';
        break;
      case 'approved':
        src += 'icon-published.svg';
        break;
      case 'deletion':
        src += 'icon-declined.svg';
        break;
    }
    return src;
  }
}
