import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngx-config/core';
import {VesConfService} from './conf.service';
import {BuildInfo} from '../../models/vestrata/BuildInfo';
import {Observable, of} from 'rxjs';
import {Organisation} from '../../models/vestrata/Organisation';
import {ApiHttpService} from '../api.http.service';
import {Instrument} from '../../models/vestrata/Instrument/Instrument';
import {SearchRequest} from '../../models/vestrata/SearchRequest';
import {Page} from '../../models/vestrata/Page';
import {AssetClass} from '../../models/vestrata/MajorAssetClass';
import {ProductType} from '../../models/vestrata/ProductType';
import {TradeIdeaCategoryVersion} from '../../models/vestrata/TradeIdea';
import {Region} from '../../models/vestrata/Region';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public serviceUrl: string;
  private globalAssetClasses: AssetClass[] = [];

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getFrontEndBuildInfo(): BuildInfo {
    const myBuild = new BuildInfo();
    myBuild.commitId = this.config.getSettings('system.commitId');
    myBuild.pipelineId = this.config.getSettings('system.pipelineId');
    myBuild.buildtime = this.config.getSettings('system.buildDatetime');
    return myBuild;
  }

  getOrganisation(): Observable<Organisation> {
    return this.http.get<Organisation>(this.serviceUrl + 'organisation/mine', ApiHttpService.getHeaders());
  }

  getInstrumentsWithSearchCriteria(searchCriteria: SearchRequest): Observable<Page<Instrument>> {
    return this.http.post<Page<Instrument>>(this.serviceUrl + 'instrument/', searchCriteria, ApiHttpService.getHeaders());
  }

  getMajorAssetClasses(): Observable<AssetClass[]> {
    if (this.globalAssetClasses.length === 0) {
      return this.http.get<AssetClass[]>(this.serviceUrl + 'global/assetclass-tree', ApiHttpService.getHeaders());
    } else {
      return of(this.globalAssetClasses);
    }
  }

  getRegions(): Observable<Region[]> {
    return this.http.get<Region[]>(this.serviceUrl + 'global/region', ApiHttpService.getHeaders());
  }
  /*
    This method return the list of supported locals
    available for user to add data. ( not the platform supported languages ).
   */
  getAvailableAddingDataLocal(): Observable<string[]> {
    return of(['en', 'fr']);
  }

  getMajorAssetClassFromInstrument(instrument: any) { // can be Instrument or InstrumentInfo
    let ret = '';
    if (instrument.assetClasses && instrument.assetClasses.length > 0) {
      ret = instrument.assetClasses[0];
    }
    return ret;
  }
  // For now minor class will be the last element in the tree
  getMinorAssetClassFromInstrument(instrument: any) { // can be Instrument or InstrumentInfo
    let ret = this.getMajorAssetClassFromInstrument(instrument) + ' - Other';
    if (instrument.assetClasses && instrument.assetClasses.length > 1) {
      ret = instrument.assetClasses[instrument.assetClasses.length - 1];
    }
    return ret;
  }

  getMajorAssetClassFromBenchmark(benchmark: any) { // can be benchmark or benchmarkInfo
    let ret = '';
    if (benchmark.assetClasses && benchmark.assetClasses.length > 0) {
      ret = benchmark.assetClasses[0];
    }
    return ret;
  }
  // For now minor class will be the last element in the tree
  getMinorAssetClassFromBenchmark(benchmark: any) { // can be Benchmark or BenchmarkInfo
    let ret = this.getMajorAssetClassFromInstrument(benchmark) + ' - Other';
    if (benchmark.assetClasses && benchmark.assetClasses.length > 1) {
      ret = benchmark.assetClasses[benchmark.assetClasses.length - 1];
    }
    return ret;
  }

  getProductTypes(): Observable<ProductType[]> {
    return this.http.get<ProductType[]>(this.serviceUrl + 'product', ApiHttpService.getHeaders());
  }

  getProductTypeById(id: string): Observable<ProductType> {
    return this.http.get<ProductType>(this.serviceUrl + 'product/' + id, ApiHttpService.getHeaders());
  }

  getProductTypeBySubId(subId: string): Observable<ProductType> {
    return this.http.get<ProductType>(this.serviceUrl + 'product/sub/' + subId, ApiHttpService.getHeaders());
  }

  getLocalFieldFromVersion(version: any, lang: string, field: string = 'name', localArrayName: string = 'locales', fallbackEnglish: boolean = true) {
    let value: string;
    if (version) {
      const localData = version[localArrayName].find(c => c.lang === lang);
      if (fallbackEnglish) {
        value = localData ? localData[field] : version[localArrayName].find(c => c.lang === 'en')[field];
      } else {
        value = localData ? localData[field] : null;
      }
    }
    return value;
  }
}
