import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {Observable} from 'rxjs';
import {ApiHttpService} from '../api.http.service';
import {map} from 'rxjs/operators';
import {Allocation, AllocationChanged} from '../../models/vestrata/Allocation';
import {InstrumentInfo} from '../../models/vestrata/Instrument/Instrument';
import {Organisation} from '../../models/vestrata/Organisation';

@Injectable({
  providedIn: 'root'
})
export class SharedComponentsService {
  private serviceUrl: string;

  constructor(
    private http: HttpClient,
    private confService: VesConfService
  ) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getCountries(locale: string): Observable<any> {
    return this.http.get(this.serviceUrl + 'global/countries/' + locale, ApiHttpService.getHeaders());
  }

  private formatCountries(countries: any[][]): any[]{

      const array = [];
      for (let i = 0; i < countries.length; i++ ){
        array.push(
          {
            value: countries[i][0],
            name: countries[i][1]
          });
      }
      return array;
    }

    getFormatedCountries(locale: string): Observable<any[]>{
     return this.getCountries(locale).pipe(map( c => {
       return this.formatCountries(c);
     }));
    }

  getAllocationsDifferences(oAllocations: Allocation[], nAllocations: Allocation[]): any[] {
    const oldAllocations = oAllocations ? oAllocations : [];
    const newAllocations = nAllocations ? nAllocations : [];

    const tmpDiff = [];
    for (const oldAlloc of oldAllocations) {
      const newAlloc = this.getAllocationByIsin(oldAlloc, newAllocations);
      if (newAlloc && newAlloc.allocation !== 0) {
        let diff = newAlloc.allocation - oldAlloc.allocation;
        diff = +diff.toFixed(2);
        if (diff !== 0) {
          const s = diff > 0 ? AllocationChanged.UP : AllocationChanged.DOWN;
          tmpDiff.push({oldAllocation: oldAlloc, newAllocation: newAlloc, status: s, difference: diff});
        } else {
          tmpDiff.push({oldAllocation: oldAlloc, newAllocation: newAlloc, status: AllocationChanged.NOCHANGE, difference: diff});
        }
      } else {
        if (oldAlloc.allocation !== 0) {
          tmpDiff.push({oldAllocation: oldAlloc, newAllocation: null, status: AllocationChanged.REMOVED});
        }
      }
    }

    for (const newAlloc of newAllocations) {
      const oldAlloc = this.getAllocationByIsin(newAlloc, oldAllocations);
      if (!oldAlloc) {
        tmpDiff.push({oldAllocation: null, newAllocation: newAlloc, status: AllocationChanged.NEW});
      }
    }

    return tmpDiff;
  }

  private getAllocationByIsin(allocation: Allocation, allocations: Allocation[]): Allocation {
    let returnValue;
    const isin = InstrumentInfo.getIdFromInstrumentIds(allocation.instrument, 'ISIN');
    if (isin) {
      returnValue = allocations.find(a => {
        return a.instrument.instrumentIds.find(aId => aId.id === isin);
      });
    }
    return returnValue;
  }

  getOrganisation(): Observable<Organisation> {
    return this.http.get<Organisation>(this.serviceUrl + 'organisation/mine', ApiHttpService.getHeaders());
  }
}
