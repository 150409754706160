import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'ves-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.scss']
})
export class StickerComponent implements OnInit, OnChanges {

  @Input() id: string;

  @Input() mode: 'xl' | 's' | 'm' | 'l' | 'xs' | 'xxs' = 's';

  @Input() title: string;

  @Input() subtitle: string;

  @Input() imgPath: string;

  @Input() backgroundColor: string;

  @Input() borderColor: string;

  @Input() image: string;

  styleObject() {
    if (this.mode !== 'xs') {
      return { background: this.backgroundColor };
    } else {
      return { background: this.backgroundColor, border: '1px solid' + this.borderColor };
    }
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
  }

}
