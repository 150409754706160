import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TutorialVersion} from '../../models/vestrata/Tutorial';
import {Version} from '../../models/vestrata/Version';
import {Permission} from '../../models/vestrata/Permission';
import {ArticleLocal} from '../../models/vestrata/Article';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ves-tutorial-row',
  templateUrl: './tutorial-row.component.html',
  styleUrls: ['./tutorial-row.component.scss']
})
export class TutorialRowComponent implements OnInit, OnChanges {

  @Input() version: Version<TutorialVersion>;
  @Input() permission: Permission;
  @Output() onDisplay = new EventEmitter<Version<TutorialVersion>>();
  languages: any[] = [];
  tutorialLocal: ArticleLocal;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes?.version?.currentValue) {
      this.languages = this.getLanguageList();
      this.tutorialLocal = this.getTutorialLocal();
    }
  }

  getLanguageList(): any[] {
    const languages: any[] = [];
    this.version.data.locales.forEach(sl => {
      if (sl.lang === 'fr') {
        languages.push({
          locale: 'fr',
          src: '../../../assets/images/languages-menu/fr/fr.png',
          srcset: '../../../assets/images/languages-menu/fr/fr.png 1x,' +
            '../../../assets/images/languages-menu/fr/fr@2x.png 2x,' +
            '../../../assets/images/languages-menu/fr/fr@3x.png 3x'
        });
      } else if (sl.lang === 'en') {
        languages.push({
          locale: 'en',
          src: '../../../assets/images/languages-menu/en/en.png',
          srcset: '../../../assets/images/languages-menu/en/en.png 1x,' +
            '../../../assets/images/languages-menu/en/en@2x.png 2x,' +
            '../../../assets/images/languages-menu/en/en@3x.png 3x'
        });
      }
    });
    return languages;
  }

  onLanguageChange(lang) {
    this.tutorialLocal = this.getTutorialLocal(lang);
  }

  getTutorialLocal(local: string = this.translate.currentLang): ArticleLocal {
    console.log('GET TUTORIAL LOCALE', this.version);
    let ret: ArticleLocal = this.version.data.locales.find(s => s.lang === local );
    console.log(ret);
    if (!ret) {
      ret = this.version.data.locales.find(s => s.lang === 'en' );

    }
    return ret;
  }

  open() {
    this.onDisplay.next(this.version);
  }

}
