import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange} from '@angular/core';

import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {PortfolioModel, PortfolioModelVersion} from "../../models/vestrata/PortfolioModel";
import {Permission} from "../../models/vestrata/Permission";
import {Strategy} from "../../models/vestrata/Strategy";
import {Contact} from "../../models/vestrata/Contact";
import {InvestmentObjectiveLocalWithId} from "../../models/vestrata/InvestmentObjective";
import {IpsService} from "../../api/services/ips.service";
import {Benchmark, BenchmarkPercentage} from "../../models/vestrata/Benchmark";

@Component({
  selector: 'app-readonly-model-construction',
  templateUrl: './readonly-model-construction.component.html',
  styleUrls: ['./readonly-model-construction.component.scss']
})
export class ReadOnlyModelConstructionComponent implements OnInit, OnChanges, OnDestroy  {

  @Input() model: PortfolioModel;
  @Input() currentModel: PortfolioModel = new PortfolioModel();

  @Input() editingData: PortfolioModelVersion;
  @Input() pendingData: PortfolioModelVersion;
  currentData: PortfolioModelVersion;
  @Input() permission: Permission;
  @Output() eventPortfolioNameChange = new EventEmitter<string>();

  strategiesList: Strategy[];
  strategySlider: any[] = [];
  currentStrategy: Strategy;
  currentPortfolioName: string;

  currentVersionBlocked = false;
  currentContact: Contact;
  pendingContact: Contact;

  objectiveSlider: any[] = [];
  currencySlider: any[] = [];
  styleSlider: any[] = [];
  currentObjective: any;
  spectrumSlider: any = [];
  constraintsSlider: any = [];

  private _onDestroy = new Subject();

  editingContact: Contact = new Contact();
  viewBenchmarkDetails = false;
  detailsBenchmark: Benchmark;
  constructor(private ipsService: IpsService,
              private translateService: TranslateService) { }

  ngOnInit() {}

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    console.log(this.editingData);

    if (changes.model && changes.model.currentValue) {
      if (this.model.current != null && this.model.current.data != null) {
        if (!this.model.current.data.contact) {
          this.model.current.data.contact = new Contact();
        }
        this.currentData = this.model.current.data;
      }

      this.currentStrategy = this.model.strategy;
      this.strategySlider = [];
      this.strategySlider.push({
        name: Strategy.getStrategyName(this.currentStrategy, this.translateService.currentLang),
        object: this.currentStrategy,
        id: this.currentStrategy.id,
        esgCriteria: this.currentStrategy.current.data.esgCriteria,
        strategyType: this.currentStrategy.current.data.strategyType
      });

      this.currentPortfolioName = this.model.strategyName;

      this.model.investmentObjective.info.forEach( iol => {
        if (iol.lang === this.translateService.currentLang) {
          this.objectiveSlider.push({
            name: iol.name,
            object: iol,
            id: this.model.investmentObjectiveId
          });

          this.currentObjective = iol;

        }

      });

      this.styleSlider = [];
      this.styleSlider.push({
        name: this.model.style,
        object: this.model.style,
        id: this.model.style
      });

      this.currencySlider = [];
      this.currencySlider.push({
        name: this.model.currency,
        object: this.model.currency,
        id: this.model.currency
      });

      this.spectrumSlider = [];
      this.spectrumSlider.push({
        name: this.model.esgSpectrum,
        object: this.model.esgSpectrum,
        id: this.model.esgSpectrum
      });

      this.constraintsSlider = [];
      this.constraintsSlider.push({
        name: this.model.esgConstraints,
        object: this.model.esgConstraints,
        id: this.model.esgConstraints
      });
    }

    this.setContactInfo();
  }

  setContactInfo() {
    if (this.currentData && !this.currentData.contact) {
      this.currentData.contact = new Contact();
    }
  }

  createContact() {
    if (this.model != null && this.model.current != null && this.model.current.data != null) {
      this.model.current.data.contact = new Contact();
    }
  }

  getModelName(): string {
    let modelName = '';

    this.strategiesList.forEach( st => {
      if (st.id === this.model.strategyId) {
        st.current.data.strategyLocal.forEach(stl => {
          if (stl.lang === this.translateService.currentLang) {
            modelName = stl.name + ' - ';
          }
        });

        st.current.data.investmentObjectives.forEach(io => {
          if (io.id === this.model.investmentObjectiveId) {
            io.info.forEach(iol => {
              if (iol.lang === this.translateService.currentLang) {
                modelName = modelName + iol.name;
              }
            });
          }
        });
      }
    });
    modelName = modelName + ' | ' + this.model.currency;
    return modelName;
  }
  consoleOutput() {
    console.log('------------------------------------');
    console.log('model',this.model);
    console.log('contact',this.currentContact);
    console.log('strategiesList',this.strategiesList);
    console.log('------------------------------------');
  }

  hasApprovedVersion(): boolean {
    return true;
  }

  displayBenchmark(modelBench: BenchmarkPercentage) {
    this.viewBenchmarkDetails = true;
    this.detailsBenchmark = modelBench.benchmark;
  }
  getBenchmarkPopUpTitle() {
    return this.translateService.instant('benchmarks.view-benchmark') + '>' +
      Benchmark.getIdFromExternalIds(this.detailsBenchmark, 'MS') + ' | ' + this.detailsBenchmark?.name
  }
}
