import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DocumentService} from '../../../api/services/document.service';
import {SharedSnackbarService} from '../../../components/shared-snackbar/services/shared-snackbar.service';
import {SnackbarMessage} from '../../../components/shared-snackbar/models/snackbar-message';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SharedLoaderService} from '../../../components/shared-loader/service/shared-loader.service';
import {FourEyeComponent} from '../../4eye-component';

@Component({
  selector: 'ves-gallery-single',
  templateUrl: './gallery-single.component.html',
  styleUrls: ['./gallery-single.component.scss']
})
export class GallerySingleComponent extends FourEyeComponent<string> implements OnInit, OnDestroy {

  private readonly MAX_PICTURE_SIZE = 10000000;

  allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  @Input() bannerImage = false;
  @Input() showNewImage: boolean;

  hover = false;

  @Output() uploaded = new EventEmitter<string>();
  private _onDestroy = new Subject();

  constructor(
    private loader: SharedLoaderService,
    private translateService: TranslateService,
    private snackbar: SharedSnackbarService,
    private documentService: DocumentService) {
    super();
  }

  ngOnInit() {
  }

  equals(a: string, b: string): boolean {
    return a === b;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  choseBannerImage() {
    if (this.showNewImage) {
      return 'gallery-single-empty-new';
    } else {
      return 'gallery-single-empty';
    }
  }

  uploadFile(fs: any) {
    if (fs && fs[0] && !this.allowedFileTypes.includes(fs[0].type)) {
      this.snackbar.push(new SnackbarMessage(this.translateService.instant('upload-file.upload-file-invalid-error'), 'error'));
      return;
    }
    const photosTooBig = [...fs].filter((file: File) => file.size > this.MAX_PICTURE_SIZE);
    if (photosTooBig.length > 0) {
      let message = this.translateService.instant(photosTooBig.length === 1 ? 'upload-file.file-too-large' : 'upload-file.files-too-large');
      // for (const file of photosTooBig) {
      //   message += file.name + ', ';
      // }
      this.snackbar.push(new SnackbarMessage(message, 'error'));
    } else {
      if (this._editingValue) { this.removeFile(); }
      this.loader.showFullLoader('upload-file.uploading');

      return this.documentService.uploadDocument(fs[0]).pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe(returnUrl => {
        this._editingValue = returnUrl.url;
        console.log('fire the upload event');
        this.uploaded.emit(this._editingValue);
        // this.snackbar.push(new SnackbarMessage('', 'info'));
      }, error => {
        console.log('error:' + error);
        // this.snackbar.push(new SnackbarMessage(error, 'error'));
      });
    }
  }

  removeFile() {
    return this.documentService.deleteDocument(this._editingValue).pipe(takeUntil(this._onDestroy)).subscribe(returnUrl => {
      this._editingValue = null;
    }, error => {
      this.snackbar.push(new SnackbarMessage(error, 'error'));
    });
  }

  onHover() {
    if (!this.getState().readonly) {
      this.hover = true;
    }
  }

  onHoverOut() {
    if (!this.getState().readonly) {
      this.hover = false;
    }
  }

  /*getStyleClass(status: VersionStatus, blocked: boolean): string {
    let styleClass = '';
    if (this.fourEyesMode) {
      switch (status) {
        case VersionStatus.EDITED :
          styleClass = 'edited';
          break;
        case VersionStatus.SAVED :
          styleClass = 'saved';
          break;
        case VersionStatus.REQUEST:
          styleClass = 'submitted';
          break;
      }
    }
    if (blocked && status !== VersionStatus.REQUEST) {
      styleClass = 'locked';
    }

    return styleClass;
  }*/

}
