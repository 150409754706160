import { Component, OnInit, Input, OnChanges, SimpleChange, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {MSHoldings} from "../../models/morningstar/MSHoldings";
import {PortfolioModel} from "../../models/vestrata/PortfolioModel";
import {MSComponentService} from "../../api/services/mscomponent.service";
import {MSPortfolio, MSPortfolioBenchmark} from "../../models/morningstar/MSPortfolio";
import {InstrumentInfo} from "../../models/vestrata/Instrument/Instrument";
import {Allocation} from "../../models/vestrata/Allocation";

@Component({
  selector: 'shared-MS-xray',
  templateUrl: './shared-MS-xray.component.html',
  styleUrls: ['./shared-MS-xray.component.scss']
})
export class SharedMSXrayComponent implements OnInit, OnChanges, OnDestroy {

  @Input() portfolioModel: PortfolioModel;
  @Input() allocations:  Allocation[];
  public morningstarToken: string = "";
  public holdings: MSHoldings[] = [];
  public BMholdings: MSHoldings[] = [];
  script: any = {};

  constructor(private translate: TranslateService) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    MSComponentService.lazyRemove('ec-xray-container');
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.portfolioModel || this.allocations) {
      this.getMSHoldings();
      this.getXray(this.portfolioModel, this.holdings);
    }

  }

  getMSHoldings() {
    this.holdings = [];
    this.BMholdings=[];

    if (this.allocations != null)
      this.allocations.forEach(alloc => {
        let holdings: MSHoldings = new MSHoldings();
        holdings.identifier = InstrumentInfo.getIdFromInstrumentIds(alloc.instrument, 'ISIN');
        holdings.identifierType = "isin";
        holdings.weight = alloc.allocation;
        this.holdings.push(holdings);
      });
  }

  getXray(portfolioModel: PortfolioModel, holdings: MSHoldings[]){

    let portfolio = new MSPortfolio();
    portfolio.name=PortfolioModel.getModelName(portfolioModel, this.translate.currentLang);
    portfolio.holdings = holdings;
    portfolio.currencyId = portfolioModel.currency;
    portfolio.totalValue = 10000;
    portfolio.benchmark = new MSPortfolioBenchmark();
    portfolio.benchmark.type = "standard";
    portfolio.benchmark.holdings = [];

    MSComponentService.lazyLoad(document.getElementById('ecXray-container'), 'ec-xray-container', 'ec-xray', 'ecXray')
    .then(function () {
        const morningstar = (<any>window).morningstar;
        morningstar.asterix.instanceRegistry.get('ecXray').setParameter('portfolio', portfolio);
        morningstar.asterix.instanceRegistry.get('ecXray').setParameter('languageId', this.translate.currentLang);
    })
    .catch((error) => {
      console.error('Error', error);
    });
  }
}
