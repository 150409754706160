import {PartnerProfile} from './Partner';
import {BankProfile} from './Bank';
import {Country} from './Country';
import {Contact} from "./Contact";
import {PortfolioModel} from "./PortfolioModel";
import {Strategy} from "./Strategy";
import { Currency } from './Currency';

export class Organisation {
  id: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  region: string;
  postalCode: string;
  country: string;
  primaryContact: Contact;

  languages: string[] = [];
  currencies: string[] = [];
  defaultCurrency: string;

  approvedDomains: string [] = [];
  embedInsights: boolean;

  partner?: PartnerProfile;
  bank?: BankProfile;
}
export class OrganisationIpsDto  extends Organisation {
  pfmCount: number;
  strategies: Strategy[];
  currencies: string[];
  partner?: PartnerProfile;
}
export class OrganisationDto extends Organisation {
  currencyNames: Currency[];
}
