import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../../models/vestrata/Contact';
import {FourEyeComponent} from '../../basic-components/4eye-component';
import {isEqual} from 'lodash';

@Component({
  selector: 'ves-shared-primary-contact',
  templateUrl: './shared-primary-contact.component.html',
  styleUrls: ['./shared-primary-contact.component.scss']
})
export class SharedPrimaryContactComponent extends FourEyeComponent<Contact> implements OnInit {

  @Input() blocked = false;
  @Input() title = 'primary-contact.header';
  @Input() noContactLabel = 'primary-contact.no-name';
  @Input() noContactIcon;
  @Input() topIcon =  '../../../assets/images/components/shared/pictogram-primary-contact.svg';
  viewEdit = false;

  status: string;


  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.currentValue) {
      this.currentValue = new Contact();
    }
  }

  toggleEdit() {
    this.viewEdit = !this.viewEdit;
  }

  equals(a: Contact, b: Contact): boolean {
    return isEqual(a, b);
  }

  cancelEdit() {
    this.editingValue.name = this.pendingValue.name;
    this.editingValue.email = this.pendingValue.email;
    this.editingValue.phone = this.pendingValue.phone;
    this.toggleEdit();
  }

  updateContact() {
    this.toggleEdit();
  }

}
