import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrganisationTeam} from '../../../models/vestrata/OrganisationTeam';
import {Role, Specialty} from '../../../models/vestrata/Role';
import {UtilsService} from '../../../api/utils.service';
import {Portal} from '../../../models/vestrata/Portal';
import {Version} from '../../../models/vestrata/Version';
import {UserVersion} from '../../../models/vestrata/User';
import {TranslateService} from '@ngx-translate/core';
import {Permission} from '../../../models/vestrata/Permission';
import {UserService} from '../../../api/services/user.service';
import {OrganisationTeamService} from '../../../api/services/organisation-team.service';

@Component({
  selector: 'ves-user-portal-info',
  templateUrl: './user-portal-info.component.html',
  styleUrls: ['./user-portal-info.component.scss']
})
export class UserPortalInfoComponent implements OnInit {

  readonly PRODUCT_SPECIALIST_VALUE = Role.PRODUCT_SPECIALIST.value;
  readonly PORTAL;

  @Input() enabled = false;
  @Output() enabledChange = new EventEmitter<boolean>();

  @Input() ownUser = true;

  @Input() portal: Portal;

  @Input() locked = true;

  @Input() editingUser: Version<UserVersion>;
  @Input() pendingUser: Version<UserVersion>;
  @Input() currentUser: Version<UserVersion>;
  @Input() permission: Permission;
  @Input() roles: Role[];
  @Input() teams: OrganisationTeam[];
  @Input() specialties: Specialty[];

  @Output() roleAssign = new EventEmitter<Portal>();
  @Output() teamAssign = new EventEmitter<Portal>();
  @Output() infoAssign = new EventEmitter<Portal>();

  constructor(private translate: TranslateService,
              private userService: UserService,
              public teamService: OrganisationTeamService) {
    this.PORTAL = Portal;
  }

  ngOnInit(): void {
  }

  getUserRolesByPlatform(platform: Portal, user: Version<UserVersion>): Role[] {
    const userRoles = user?.data?.roles ? UtilsService.mapRoles(user?.data?.roles, this.roles) : [];
    return this.userService.sortRoles(this.userService.getRolesByPlatform(platform, userRoles));
  }

  getPortalStatus(platform: Portal, user: Version<UserVersion>): boolean {
    return !!user?.data?.portalsEnabled?.includes(platform);
  }

  portalStatusUpdate(enable: any) {
    this.enabled = enable;
    this.enabledChange.emit(this.enabled);
  }

  assignRole() {
    this.roleAssign.emit(this.portal);
  }

  assignTeam() {
    this.teamAssign.emit(this.portal);
  }

  assign() {
    this.infoAssign.emit(this.portal);
  }

}
