import {Component, OnDestroy, OnInit} from '@angular/core';
import {VesBackEndService} from '../../api/services/backend.service';
import {BuildInfo} from "../../models/vestrata/BuildInfo";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'ves-shared-main-footer',
  templateUrl: './shared-main-footer.component.html',
  styleUrls: ['./shared-main-footer.component.scss']
})
export class SharedMainFooterComponent implements OnInit, OnDestroy {

  backEndBuildInfo: BuildInfo;
  frontEndBuildInfo: BuildInfo;

  viewBuildInfo: boolean = false;

  year: number;

  private _onDestroy = new Subject();

  constructor(public backendService: VesBackEndService) {
    this.year = new Date().getFullYear();
  }

  ngOnInit() {

  }

  refreshInfo() {
      this.backendService.getBuildInfo().pipe(takeUntil(this._onDestroy)).subscribe( b => {
        this.backEndBuildInfo = b;
        this.frontEndBuildInfo = this.backendService.getFrontEndBuildInfo();
        console.log("BUILD INFO");
        console.log(this.backEndBuildInfo);
        console.log(this.frontEndBuildInfo);
        this.viewBuildInfo = !this.viewBuildInfo;
      });
  }
  openBuild() {
    if (this.backEndBuildInfo == null) {
      this.refreshInfo();
    } else {
      this.viewBuildInfo = !this.viewBuildInfo;
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
