
export class InvestmentObjective {
    id: string;
    rank: number;
    info: InvestmentObjectiveLocal [] = [];

  static getIOName(investmentObjective: InvestmentObjective, currentLang: string): string {
    let ioName: string;
    if (investmentObjective.info != null) {
      ioName = investmentObjective.info.find(si => si.lang === currentLang) ? investmentObjective.info.find(si => si.lang === currentLang).name : investmentObjective.info.find(si => si.lang === 'en').name;
    }
    return ioName;
  }
}

export class InvestmentObjectiveLocal {
  lang: string;
  name: string;
  description: string;
}

export class InvestmentObjectiveLocalWithId  extends InvestmentObjectiveLocal{
  id: string;
}
