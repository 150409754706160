import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FourEyeComponent} from '../../4eye-component';
import {isEqual} from 'lodash';
import {VersionStatus} from '../../../models/vestrata/Version';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../api/utils.service';

@Component({
  selector: 'ves-multivalue-dropdown',
  templateUrl: './multivalue-dropdown.component.html',
  styleUrls: ['./multivalue-dropdown.component.scss']
})
export class MultivalueDropdownComponent extends FourEyeComponent<any[]> implements OnInit, OnChanges {

  @Input() id: string;
  @Input() showLabel = false;
  @Input() labelName: string;
  @Input() placeholder: string;
  @Input() data: any[];

  imgArrow: string;
  isOpen = false;

  constructor(private translate: TranslateService,
              private utils: UtilsService) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes?.data?.currentValue || changes?.editingValue?.currentValue) {
      this.initData();
    }

    console.log(this._editingValue);
    console.log(this.pendingValue);
    console.log(this.currentValue);
    console.log(this.pendingStatus);
  }

  openDrop() {
    if (!this.locked && this.getState().state !== VersionStatus.REQUEST) {
      this.isOpen = !this.isOpen;
    }
  }

  getClass(isSelect: boolean, isOpen: boolean, status: string, blocked: boolean): string {
    let classValue = '';
    if (this.isOpen === false && isSelect === false) {
      classValue = 'dropdown-inactive ';
      this.imgArrow = '../../../../assets/images/components/components-2/arrow-fold-white.svg';
    } else if (this.isOpen && isSelect === false) {
      classValue = 'dropdown-active ';
      this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-unfold.svg';
    } else if (this.isOpen === false && isSelect) {
      classValue = 'dropdown-active-completed ';
      this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-fold.svg';
    } else if (this.isOpen && isSelect) {
      classValue = 'dropdown-active-completed-open ';
      this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-unfold.svg';
    }
    classValue += this.getStyleClass(status, blocked);
    return classValue;
  }

  getStyleClass(status: string, locked: boolean): string {
    let styleClass = '';
    if (this.fourEyesMode) {
      switch (status) {
        case VersionStatus.EDITED :
          styleClass = 'edited';
          this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-fold-white.svg';
          break;
        case VersionStatus.SAVED :
          styleClass = 'saved';
          this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-fold-white.svg';
          break;
        case VersionStatus.REQUEST :
          styleClass = 'submitted';
          this.imgArrow = '../../../../../../assets/images/components/components-2/arrow-fold-white.svg';
          break;
      }
    }

    if (locked && status !== VersionStatus.REQUEST) {
      styleClass = 'locked';
    }

    return styleClass;
  }

  getPlaceHolderValues() {
    const values = [];
    if (this._editingValue?.length > 0) {
      const first3 = this._editingValue.slice(0, 3).filter(i => i?.name);
      first3.forEach((v) => {
        values.push(this.translate.instant(v.name) + '; ');
      });
    } else if (this.placeholder) {
      values.push(this.translate.instant(this.placeholder));
    }
    return values;
  }

  protected equals(a: any, b: any): boolean {
    return isEqual(a, b);
  }

  onItemStatusChanged(event: {selected: boolean, item: DropdownItem}) {
    const item = event.item;
    item.selected = event.selected;
    if (!this._editingValue) {
      this._editingValue = [];
    }
    if (event.selected) {
      this._editingValue.push(item.value);
    } else {
      this.utils.removeFromIterable(this._editingValue, item.value);
    }
    item.subItems?.forEach(subItem => this.onItemStatusChanged({selected: item.selected, item: subItem}));
    this.editingValueChange.emit(this._editingValue);
  }

  getSubItemsSelectedNumber(item: DropdownItem, firstLevel = true) {
    let count = 0;
    if (item.subItems?.length > 0) {
      item.subItems.forEach(i2 => {
        count += this.getSubItemsSelectedNumber(i2, false);
      });
    }
    if (item.selected && !firstLevel) {
      count++;
    }
    return count;
  }

  resetAll() {
    this._editingValue.length = 0;
    this.selectedDataReset(this.data);
    this.editingValueChange.emit(this._editingValue);
  }

  private selectedDataReset(data: DropdownItem[]) {
    if (data?.length > 0) {
      data.forEach(d => {
        d.selected = false;
        this.selectedDataReset(d.subItems);
      });
    }
  }

  private initData() {
    if (this?.data?.length > 0 && this._editingValue?.length > 0) {
      this._editingValue.forEach(v => {
        this.setSelectedData(v, this.data);
      });
    }
  }

  private setSelectedData(value: any, data: DropdownItem[]) {
    let dataItem = null;
    if (data && value) {
      data.forEach(item => {
        dataItem = isEqual(item.value, value) ? item : null;
        if (!dataItem && item.subItems?.length > 0) {
          dataItem = this.setSelectedData(value, item.subItems);
        }

        if (dataItem) {
          dataItem.selected = true;
          return;
        }
      });
    }
    return dataItem;
  }
}

export class DropdownItem {
  name: string;
  value: any;
  subItems: DropdownItem[];
  selected = false;
  open = false;
}
