import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {
  ProductRecommendation,
  ProductRecommendationListDto, ProductRecommendationPerformance,
  ProductRecommendationSearchFilter
} from '../../models/vestrata/ProductRecommendation';
import {Observable} from 'rxjs';
import {ApiHttpService} from '../api.http.service';
import {Page} from '../../models/vestrata/Page';
import {InstrumentInfo} from '../../models/vestrata/Instrument/Instrument';
import {ProductApprovalDto, ProductSearchFilter} from "../../models/vestrata/ProductApproval";

@Injectable({
  providedIn: 'root'
})
export class ProductRecommendationService {

  private static readonly ROOT_ENDPOINT = 'recommendations/';
  public serviceUrl: string;

  constructor(private http: HttpClient,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  createProductRecommendation(recommendation: ProductRecommendation): Observable<ProductRecommendation> {
    return this.http.post<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT, recommendation, ApiHttpService.getHeaders());
  }

  updateProductRecommendation(recommendation: ProductRecommendation): Observable<ProductRecommendation> {
    return this.http.put<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + recommendation.id, recommendation, ApiHttpService.getHeaders());
  }

  getById(id: string): Observable<ProductRecommendation> {
    return this.http.get<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + id, ApiHttpService.getHeaders());
  }

  getProductRecommendationWithSearchCriteria(searchCriteria: ProductRecommendationSearchFilter): Observable<Page<ProductRecommendationListDto>> {
    return this.http.post<Page<ProductRecommendationListDto>>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'search', searchCriteria, ApiHttpService.getHeaders());
  }

  searchInstrument(text: string): Observable<InstrumentInfo[]> {
    const searchFilter = {textSearch: text, currencies: null};
    return this.http.post<InstrumentInfo[]>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'instruments', searchFilter, ApiHttpService.getHeaders());
  }

  submitForApproval(recommendationId: string): Observable<ProductRecommendation> {
    return this.http.post<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'submit/' + recommendationId, ApiHttpService.getHeaders());
  }

  cancelApproval(recommendationId: string): Observable<ProductRecommendation> {
    return this.http.post<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'unlock/' + recommendationId,
      ApiHttpService.getHeaders());
  }

  resetRecommendation(recommendationId: string): Observable<ProductRecommendation> {
    return this.http.post<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'reset/' + recommendationId,
      ApiHttpService.getHeaders());
  }

  approveProductRecommendationChange(recommendationId: string): Observable<ProductRecommendation> {
    return this.http.post<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'approve/' + recommendationId,
      ApiHttpService.getHeaders());
  }

  declineProductRecommendationChange(recommendationId: string, note: string): Observable<ProductRecommendation> {
    return this.http.post<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'decline/' + recommendationId,
      note,  ApiHttpService.getHeaders());
  }

  getByInstrumentId(instrumentId: string): Observable<ProductRecommendation> {
    return this.http.get<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'by-instrument/' + instrumentId, ApiHttpService.getHeaders());
  }

  replaceRecommendation(recommendationId: string): Observable<ProductRecommendation> {
    return this.http.get<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'replace/' + recommendationId,
      ApiHttpService.getHeaders());
  }

  getRecommendationPerformance(instrumentId, targetPrice, startDate, endDate): Observable<ProductRecommendationPerformance[]> {
    return this.http.get<ProductRecommendationPerformance[]>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT +
      'performance/' + instrumentId + '/' + targetPrice + '/' + startDate + '/' + endDate,  ApiHttpService.getHeaders());
  }

  closeProductRecommendationChange(recommendationId: string, note: string): Observable<ProductRecommendation> {
    return this.http.post<ProductRecommendation>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'close/' + recommendationId,
      note,  ApiHttpService.getHeaders());
  }

  loadListFilters(): Observable<any> {
    return this.http.get<any>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'load-filters',
      ApiHttpService.getHeaders());
  }

  getLatestRecommendations(): Observable<Page<ProductRecommendationListDto>> {
    return this.http.get<Page<ProductRecommendationListDto>>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'latest', ApiHttpService.getHeaders());
  }
  searchRecommendationPerformance(filters: any): Observable<Page<ProductRecommendationListDto>> {
    return this.http.post<Page<ProductRecommendationListDto>>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'investment/search', filters, ApiHttpService.getHeaders());
  }

  // Recommendation Review
  getRecommendationReviewWithFilter(filter: ProductRecommendationSearchFilter): Observable<Page<ProductRecommendationListDto>> {
    return this.http.post<Page<ProductRecommendationListDto>>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'review-search' , filter, ApiHttpService.getHeaders());
  }
  loadRecommendationReviewFilters(): Observable<any> {
    return this.http.get<any>(this.serviceUrl + ProductRecommendationService.ROOT_ENDPOINT + 'review-load-filters',
      ApiHttpService.getHeaders());
  }
}
