
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'ves-shared-performance-filter',
  templateUrl: './shared-performance-filter.component.html',
  styleUrls: ['./shared-performance-filter.component.scss']
})
export class SharedPerformanceFilterComponent implements OnInit, OnDestroy, OnChanges {

  private readonly DATE_FORMAT = 'YYYY-MM-DD';
  private readonly PROJECTED_YEARS = '10';

  private _onDestroy = new Subject();

  @Input() performanceFilter: PerformanceFilter;

  @Input() price = false;
  @Input() growth = true;
  @Input() projected = false;
  startDate = moment();
  @Input() priceInfo: PriceInfo;
  endDate = moment();
  @Input() launchDate: string;
  @Output() filterChange = new EventEmitter<PerformanceFilter>();
  @Input() currency: string;

  minStartDate: string;
  maxStartDate: string;
  maxEndDate: string;
  minEndDate: string;

  now = moment();

  constructor() { }

  ngOnInit(): void {
    this.startDate = moment(this.performanceFilter.startDate);
    this.endDate = moment(this.performanceFilter.endDate);
    console.log('************** performance filter ***********************');
    console.log(this.price);
    console.log(this.growth);
    console.log(this.projected);
    console.log(this.startDate);
    console.log(this.endDate);
    console.log(this.launchDate);
    console.log(this.performanceFilter);
    this.setMinAndMaxDate();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onCategoryClick(event, type) {
    console.log(type);
    switch (type) {
      case 'price': {
        if (this.performanceFilter.category !== 'price') {
          this.performanceFilter.category = 'price';
          this.filterChanged();
        }
        break;
      }
      case 'growth': {
        if (this.performanceFilter.category !== 'growth') {
          this.performanceFilter.category = 'growth';
          this.filterChanged();
        }
        break;
      }
      case 'projected': {
        if (this.performanceFilter.category !== 'projected') {
          this.performanceFilter.category = 'projected';
          this.filterChanged();
        }
        break;
      }
    }
  }


  onRangeDateChanged(event: any) {
    if (this.performanceFilter.category !== 'projected') {
      if (this.startDate === this.now) {
        this.startDate = moment(this.launchDate);
      } else {
        this.startDate = moment(this.startDate);
      }
      this.endDate = moment(this.endDate);
      this.filterChanged();
      this.setMinAndMaxDate();
    } else {
      this.startDate = this.now;
      this.endDate = moment().add(this.PROJECTED_YEARS, 'year');
      this.filterChanged();
      this.setMinAndMaxDate();
    }
  }

  private setMinAndMaxDate() {
    if (this.performanceFilter.category !== 'projected') { // used for both price and growth
      this.minStartDate = this.launchDate;
      this.maxStartDate = this.endDate.format(this.DATE_FORMAT);
      this.minEndDate = this.startDate.format(this.DATE_FORMAT);
      this.endDate = moment();
      this.maxEndDate = this.now.format(this.DATE_FORMAT);
    } else {
      this.minStartDate = this.now.format(this.DATE_FORMAT);
      this.maxStartDate = this.now.format(this.DATE_FORMAT);
      this.minEndDate = this.now.format(this.DATE_FORMAT);
      this.maxEndDate = null;
    }
  }

  onSlidingBarDateChanged(startDate: string) {
    this.startDate = moment(startDate, 'L');
    this.setMinAndMaxDate();
    this.filterChanged();
  }

  filterChanged() {
    this.performanceFilter.startDate = this.startDate.format(this.DATE_FORMAT);
    this.performanceFilter.endDate = this.endDate.format(this.DATE_FORMAT);
    this.filterChange.emit(this.performanceFilter);
  }
}

export class PerformanceFilter {
  category: string;
  startDate: string;
  endDate: string;
}

export class PriceInfo {
  min: number;
  minDate: string;
  max: number;
  maxDate: string;
}
