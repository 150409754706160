import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NavigationData} from '../../../basic-components/unknown-components/navigation-menu/navigation-menu.component';
import {Permission} from '../../../models/vestrata/Permission';
import {
  InstrumentId,
  InstrumentSearchFilter
} from '../../../models/vestrata/Instrument/Instrument';
import {AssetClass} from '../../../models/vestrata/MajorAssetClass';
import {
  BlocOption,
  FilterOption
} from '../../../basic-components/components-5/two-level-bar-filter/two-level-bar-filter.component';
import {AuthenticationService} from '../../../api/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedLoaderService} from '../../shared-loader/service/shared-loader.service';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {CommonService} from '../../../api/services/common.service';
import {InstrumentService} from '../../../api/services/instrument.service';
import {TranslateService} from '@ngx-translate/core';
import {NavigationMenuService} from '../../../basic-components/unknown-components/navigation-menu/navigation-menu.service';
import {finalize, switchMap, takeUntil} from 'rxjs/operators';
import {Page} from '../../../models/vestrata/Page';
import * as moment from 'moment';
import {SnackbarMessage} from '../../shared-snackbar/models/snackbar-message';
import {Paging, SearchRequest} from '../../../models/vestrata/SearchRequest';

@Component({
  selector: 'ves-shared-instrument-list',
  templateUrl: './shared-instrument-list.component.html',
  styleUrls: ['./shared-instrument-list.component.scss']
})
export class SharedInstrumentListComponent implements OnInit, OnDestroy {

  private static readonly FILTER_TYPE = 'type';
  private static readonly FILTER_NAME = 'name';
  private static readonly FILTER_CURRENCY = 'currency';
  private static readonly FILTER_ASSET = 'asset';
  private static readonly FILTER_PROVIDER = 'providers';
  private static readonly FILTER_ID = 'id';
  private static readonly FILTER_ALL = 'ALL';
  private static readonly SORT_UP = 'up';
  private static readonly SORT_DOWN = 'down';
  private _onDestroy = new Subject();

  navigationData = new NavigationData();
  permission: Permission;

  instruments: any[];

  $assetClasses: Observable<AssetClass[]>;
  searchFilter = new InstrumentSearchFilter();

  // Paginator //
  pages = 0;
  results = 0;
  resultNumber = 25;
  pageNumber = 0;

  // Tri & Filtering
  headerSort = {
    type: SharedInstrumentListComponent.SORT_DOWN,
    name: SharedInstrumentListComponent.SORT_DOWN,
    currency: SharedInstrumentListComponent.SORT_DOWN,
    id: SharedInstrumentListComponent.SORT_DOWN,
    asset: SharedInstrumentListComponent.SORT_DOWN,
    provider: SharedInstrumentListComponent.SORT_DOWN
  };
  headers = Object.keys(this.headerSort);

  filterTypes: BlocOption[] = [];
  filterAssetClasses: BlocOption[] = [];
  filterProvider: BlocOption[] = [];
  selectedFilters: BlocOption[] = [];
  filteringText: string;
  typeQueryParam: string;

  constructor(private authService: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router,
              private loader: SharedLoaderService,
              private snackbar: SharedSnackbarService,
              private commonService: CommonService,
              private instrumentService: InstrumentService,
              private menuService: NavigationMenuService,
              private translate: TranslateService) {

    this.translate.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe(params => {
      const allFilterTranslations = params.translations['instrument-library'].all;
      const allFilterOption = this.filterProvider[0].options.find(f => f.value === SharedInstrumentListComponent.FILTER_ALL);
      if (allFilterOption) {
        allFilterOption.name = allFilterTranslations;
      }
    });
  }

  ngOnInit(): void {
    this.permission = { read: false, write: false, approve: false, publish: false }; // TODO update to correct permissions for each portal.
    this.$assetClasses = this.commonService.getMajorAssetClasses();

    this.route.queryParamMap.pipe(takeUntil(this._onDestroy),
      switchMap(params => {
        this.loader.showFullLoader();
        this.typeQueryParam = params.get('type');
        this.initEmptySearchFilter();
        if (this.typeQueryParam) {
          console.log(this.typeQueryParam);
          this.searchFilter.productTypes.push(this.typeQueryParam);
        }
        return this.instrumentService.getInstrumentsWithSearch(this.searchFilter);
      })).subscribe((results: Page<any>) => {
      this.loader.dismissLoader();
      this.instruments = results.content;
      this.loadAndBuildFilters();
      this.sort(SharedInstrumentListComponent.FILTER_TYPE);
      this.pages = results.totalPages;
      this.results = results.totalElements;
      this.setMenuData();
    }, error => {
      this.loader.dismissLoader();
    });
    this.setMenuData();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.menuService.resetNavigationData();
  }

  onAddNew() {
    this.router.navigate(['search'], {relativeTo: this.route});
  }

  getInstrumentId(instrument: any): InstrumentId {
    if (!instrument || !instrument.instrumentIds) {
      return;
    }
    let ret = instrument.instrumentIds.find(i => i.idType === 'ISIN');
    if (!ret) {
      ret = instrument.instrumentIds[0];
    }
    return ret;
  }

  getAssetClasses(instrument: any): string {
    if (!instrument) {
      return '';
    }

    let ret = '';
    instrument.assetClasses.forEach((ac, index) => {
      if (index === instrument.assetClasses.length - 1) {
        ret += ac;
      } else {
        if (index === 0) {
          ret += '<span class=\'light\'>' + ac + ' › </span>';
        } else {
          ret += ac + ' › ';
        }
      }
    });
    return ret;
  }

  openDetails(id: string) {
    this.router.navigate(['details'], {relativeTo: this.route, queryParams: {id}});
  }

  sort(key: string) {
    if (!key) { return; }
    const newValue = this.headerSort[key] === SharedInstrumentListComponent.SORT_DOWN ?
      SharedInstrumentListComponent.SORT_UP :
      SharedInstrumentListComponent.SORT_DOWN;
    Object.keys(this.headerSort).forEach(k => this.headerSort[k] = SharedInstrumentListComponent.SORT_DOWN);
    this.headerSort[key] = newValue;

    this.searchFilter.searchRequest.sorts = [];
    let prop = key;
    if (key === 'id') {
      this.instruments.sort((pr1, pr2) => {
        let ret = 0;
        if (key === SharedInstrumentListComponent.FILTER_ID) {
          ret = this.getInstrumentId(pr1).id.localeCompare(this.getInstrumentId(pr2).id);
        }
        return this.headerSort[key] === SharedInstrumentListComponent.SORT_DOWN ? ret * -1 : ret;
      });
    } else {
      if (key === 'asset') {
        prop = 'assetClasses.0';
      }
      if (key === 'provider') {
        prop = 'issuer';
      }
      if (key === 'type') {
        prop = 'productType.1';
      }
      this.searchFilter.searchRequest.sorts.push({
        property: prop,
        direction: newValue === SharedInstrumentListComponent.SORT_DOWN ? -1 : 1
      });
      this.executeLoad();
    }
    /** this.instruments.sort((pr1, pr2) => {
      let ret = 0;

      if (key === SharedInstrumentListComponent.FILTER_TYPE
        || key === SharedInstrumentListComponent.FILTER_NAME
        || key === SharedInstrumentListComponent.FILTER_CURRENCY && pr1 && pr2) {
        ret = pr1[key].localeCompare(pr2[key]);
      } else if (key === SharedInstrumentListComponent.FILTER_ID) {
        ret =  this.getInstrumentId(pr1).id.localeCompare(this.getInstrumentId(pr2).id);
      } else if (key === SharedInstrumentListComponent.FILTER_ASSET && pr1 && pr2) {
        ret = this.getAssetClasses(pr1).localeCompare(this.getAssetClasses(pr2));
      } else if (key === 'provider' && pr1.issuer && pr2.issuer ) {
        ret = pr1.issuer.localeCompare(pr2.issuer);
      }
      return this.headerSort[key] === SharedInstrumentListComponent.SORT_DOWN ? ret * -1 : ret;
    });**/
  }


  onFilterChange(event: any) {
    this.initEmptySearchFilter();

    if (event && Array.isArray(event)) {
      for (const option of event) {
        const index = this.selectedFilters.findIndex(b => b.value === option.value);
        if (index !== -1) {
          this.selectedFilters.splice(index, 1);
        }
        if ((option as BlocOption).selectedCount > 0) {
          this.selectedFilters.push(option);
        }
      }
    } else {
      this.filteringText = event;
    }

    this.searchFilter.searchRequest.text = this.filteringText;
    for (const option of this.selectedFilters) {
      const filters = option.options.filter(o => o.checked);
      if (option.value === SharedInstrumentListComponent.FILTER_TYPE) {
        this.searchFilter.productTypes = filters.map(f => f.value);
      } else if (option.value === SharedInstrumentListComponent.FILTER_ASSET) {
        this.searchFilter.assetClasses = filters.map(f => f.value);
      } else if (option.value === SharedInstrumentListComponent.FILTER_PROVIDER) {
        this.searchFilter.providers = filters.map(f => f.value);
      }
    }

    this.executeLoad();
  }

  private executeLoad() {
    this.loader.showBarLoader();
    return this.instrumentService.getInstrumentsWithSearch(this.searchFilter)
      .pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe(results => {
        this.instruments = results.content;
        this.pages = results.totalPages;
        this.results = results.totalElements;
        this.setMenuData();
        this.loader.dismissLoader();
      }, err => {
        this.snackbar.push(new SnackbarMessage(this.translate.instant(err?.error?.message), 'error'));
      });
  }

  private initEmptySearchFilter() {
    this.searchFilter.productTypes = [];
    this.searchFilter.assetClasses = [];
    this.searchFilter.providers = [];
    this.searchFilter.searchRequest = new SearchRequest();
    this.searchFilter.searchRequest.paging = new Paging();
    this.searchFilter.searchRequest.paging.page = this.pageNumber;
    this.searchFilter.searchRequest.paging.size = this.resultNumber;
    this.searchFilter.searchRequest.criteria = {};
    this.searchFilter.searchRequest.text = '';
  }

  loadAndBuildFilters() {
    this.instrumentService.loadListFilters().pipe(takeUntil(this._onDestroy))
      .subscribe(
        data => {
          this.filterAssetClasses = [];
          this.filterTypes = [];
          const assetBloc = new BlocOption();
          assetBloc.options = [];
          assetBloc.value = SharedInstrumentListComponent.FILTER_ASSET;
          Object.keys(data.assetClasses).forEach(key => {
            assetBloc.options.push(new FilterOption(key, key, data.assetClasses[key], true, false, null));
          });
          this.filterAssetClasses.push(assetBloc);

          const typeBloc = new BlocOption();
          typeBloc.options = [];
          typeBloc.value = SharedInstrumentListComponent.FILTER_TYPE;
          Object.keys(data.productTypes).forEach(key => {
            typeBloc.options.push(new FilterOption(key, key, data.productTypes[key], true, false, null));
          });
          this.filterTypes.push(typeBloc);

          const providerBloc = new BlocOption();
          providerBloc.options = [];
          providerBloc.value = SharedInstrumentListComponent.FILTER_PROVIDER;
          Object.keys(data.providers).forEach(key => {
            providerBloc.options.push(new FilterOption(key, key, data.providers[key], true, false, null));
          });
          this.filterProvider.push(providerBloc);
        }
      );
  }

  onIncludeClosed($event: any) {
    this.executeLoad();
  }

  private setMenuData() {
    this.navigationData.permission = this.permission;
    this.navigationData.interface = this;
    this.navigationData.fourEyeView = false;
    this.navigationData.pageTitle = this.buildPageTitle();
    this.menuService.setNavigationData(this.navigationData);
  }

  private buildPageTitle() {
    let title = '';

    if (this.instruments) {
      title += this.results + ' | ';
      title += this.results > 1 ?
        this.translate.instant('instrument-library.instruments') :
        this.translate.instant('instrument-library.instrument');
    }
    return title;
  }

  onSizeChanged(size: number) {
    this.resultNumber = size;
    this.searchFilter.searchRequest.paging.page = this.pageNumber;
    this.searchFilter.searchRequest.paging.size = this.resultNumber;
    this.executeLoad();
  }

  onPageChanged(page: number) {
    this.pageNumber = page - 1;
    this.searchFilter.searchRequest.paging.page = this.pageNumber;
    this.searchFilter.searchRequest.paging.size = this.resultNumber;
    this.executeLoad();
  }

  getProductType(instrument: any) {
    return instrument.productType ? instrument.productType[instrument.productType.length - 1] : this.translate.instant('instrument-library.unknown');
  }
}
