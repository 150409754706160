export class TimePeriod {

  public static readonly CALENDAR = 'calendar';
  public static readonly TRAILING = 'trailing';

  id: string;
  type: string;
  data: TimePeriodLocal[] = [];
}

export class TimePeriodLocal {
  lang: string;
  name: string;
  description: string;
}
