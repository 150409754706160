import {Component,  Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FourEyeComponent} from '../../4eye-component';

@Component({
  selector: 'ves-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent extends FourEyeComponent<boolean> implements OnInit, OnChanges {

  @Input() instance: string;
  @Input() advanced: boolean;
  @Input() approverMode = false;
  @Input() lockOverride = false;

  advancedText: string;
  status: string;

  constructor( private translate: TranslateService) {
    super();
  }

  ngOnInit(): void {
    if (!this.instance) { this.instance = 'one-and-only'; }
    if (this.advanced) { this.setAdvancedText(); }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes) {
    }
  }


  checkLockOverride() {
    let ret = false;
    if (this.approverMode && !this.permission.approve) {
      ret = true;
    } else if (this.lockOverride) {
      ret = true;
    }
    return ret;
  }

  setAdvancedText() {
    if (this.advanced && this._editingValue) {
      this.advancedText = this.translate.instant('toggle.open');
    } else {
      this.advancedText = this.translate.instant('toggle.closed');
    }
  }

  toggle(event: any) {
    if (!this.locked) {
      this._editingValue = event.target.checked;
      if (this.advanced) {
        this.setAdvancedText();
      }
      this.editingValueChange.emit(this._editingValue);
    }
  }

  equals(a: boolean, b: boolean): boolean {
    return a === b;
  }

  isDisabled() {
    return this.getState().readonly || this.lockOverride;
  }
}
