import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { Chart } from 'chart.js';
import {Subject} from 'rxjs';

@Component({
  selector: 'ves-performance-chart',
  templateUrl: './performance-chart.component.html',
  styleUrls: ['./performance-chart.component.scss']
})
export class PerformanceChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  private _onDestroy = new Subject();
  private readonly TIME_UNIT_YEAR = 'year';
  private readonly TIME_UNIT_QUARTER = 'quarter';
  private readonly TIME_UNIT_MONTH = 'month';
  private readonly TIME_UNIT_WEEK = 'week';
  private readonly TIME_UNIT_DAY = 'day';

  @ViewChild('canvas') canvas: ElementRef;
  private chart: Chart;

  @Input() performances: any[];
  @Input() labels: string[] = [];
  @Input() colors: string[] = [];
  @Input() timeUnit: string;
  @Input() dataFrequency = 'month';

  @Input() multiY = false;
  @Input() y2Performances: any[];
  @Input() y2Labels: string[] = [];
  @Input() y2Colors: string[] = [];
  @Input() yAxisLabels: string [] = [];
  @Input() maxYValues: number[] = [];
  @Input() legendAlign = 'center';
  @Input() fill = false;

  currentTimeUnit: string;

  gradient: any;

  constructor() {

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.performances) {
      if (changes.performances) {
        if (this.chart) {
          let values = [];
          for (const array of changes.performances.currentValue) {
            const v = array.map(item => {
              return {
                x: item.date,
                y: item.value
              };
            });
            values.push(v);
          }
          this.chart.config.data.datasets = this.buildDataSet(values, this.labels, this.colors);
          if (this.multiY) {
            values = [];
            for (const array of changes.y2Performances.currentValue) {
              const v = array.map(item => {
                return {
                  x: item.date,
                  y: item.value
                };
              });
              values.push(v);
            }
            const y2Data = this.buildDataSet(values, this.y2Labels, this.y2Colors, 'right');
            y2Data.forEach(y => this.chart.config.data.datasets.push(y));
          }
        }
      }

      if (this.chart) {
        if (this.timeUnit) {
          this.currentTimeUnit = this.timeUnit;
        } else {
          this.currentTimeUnit = this.getTimeUnit(this.chart.config.data.datasets);
        }
        console.log(this.currentTimeUnit);
        this.chart.config.options.scales.xAxes[0].time.unit = this.currentTimeUnit;
        this.chart.update();
      }
    }
  }

  ngAfterViewInit(): void {
    const values = [];
    console.log(this.performances);
    for (const array of this.performances) {
      const v = array.map(item => {
        return {
          x: item.date,
          y: item.value
        };
      });
      values.push(v);
    }
    this.chart = this.initChart(values, this.labels, this.colors);
  }

  buildDataSet(values: any[][], chartLabels: string[], colors: string[], yAxis?: string): any[] {
    const set = [];
    values.forEach((value, index) => {
      set.push({
        label: chartLabels[index] ? chartLabels[index] : '',
        yAxisID: yAxis ? yAxis : 'left',
        fill: this.fill,
        data: value,
        borderColor: colors[index],
        backgroundColor: 'rgba(191,203,213,0.5)',
        type: 'line',
        borderWidth: 2,
        pointRadius: 0
      });
    });

    return set;
  }

  initChart(values: any[][], chartLabels: string[], colors: string[]): Chart {
    if (this.canvas == null) { return null; }
    const set = this.buildDataSet(values, chartLabels, colors);
    this.currentTimeUnit = this.getTimeUnit(set);
    let yAxis: any[] = [];
    if (this.multiY) {
      yAxis = [{
        scaleLabel: {
          display: !!(this.yAxisLabels && this.yAxisLabels[0]),
          labelString: this.yAxisLabels && this.yAxisLabels[0] ? this.yAxisLabels[0] : ''
        },
        id: 'left',
        position: 'left',
        ticks: {
          beginAtZero: true,
          max: this.maxYValues && this.maxYValues[0] ? this.maxYValues[0] : false
        }
      }, {
        scaleLabel: {
          display: !!(this.yAxisLabels && this.yAxisLabels[1]),
          labelString: this.yAxisLabels && this.yAxisLabels[1] ? this.yAxisLabels[1] : ''
        },
        id: 'right',
        position: 'right',
        gridLines: {
          display: false
        },
        ticks: {
          beginAtZero: true,
          max: this.maxYValues && this.maxYValues[1] ? this.maxYValues[1] : false
        }
      }];
    } else {
      yAxis = [{
        scaleLabel: {
          display: false,
        },
        id: 'left',
        position: 'left'
      }];
    }
    return new Chart(this.canvas.nativeElement.getContext('2d'), {
      data: {
        datasets: set
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            type: 'time',
            distribution: 'linear',
            time: {
              unit: this.currentTimeUnit, // month / quarter / year
              displayFormats: {
                quarter: 'MMM YY',
                month: 'MMM YY'
              }
            }
          }],
          yAxes: yAxis
        },
        legend: {
          align: this.legendAlign
        }
      }
    });
  }

  private getTimeUnit(datasets: any[]): string {
    console.log('***************** TIME UNIT *******************');
    console.log(datasets);
    console.log(this.dataFrequency)
    let tu = this.TIME_UNIT_YEAR;
    let biggerArray = [];
    for (const item of datasets) {
      if (item.data.length > biggerArray.length) {
        biggerArray = item.data;
      }
    }
    // 4 years data
    if (this.dataFrequency === 'day') {
      // number of working days used to set range
      console.log('Checking Day');
      console.log(biggerArray.length);
      if (biggerArray.length > 365) {
        tu = this.TIME_UNIT_YEAR;
      } else if (biggerArray.length > 180) {
        tu = this.TIME_UNIT_QUARTER;
      } else if (biggerArray.length > 30) {
        tu = this.TIME_UNIT_MONTH;
      } else if (biggerArray.length > 5) {
        tu = this.TIME_UNIT_WEEK;
      } else {
        tu = this.TIME_UNIT_DAY;
      }
    } else {
      if (biggerArray.length > 48) {
        tu = this.TIME_UNIT_YEAR;
        // 2 to 4 years data
      } else if (biggerArray.length > 24) {
        tu = this.TIME_UNIT_QUARTER;
        // Below 2 years data
      } else {
        tu = this.TIME_UNIT_MONTH;
      }
    }
    console.log(tu);
    return tu;
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

}
