import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {IconUrl} from '../../components-4/thumb-btn/thumb-btn.component';
import {TooltipData} from '../../components-4/tooltip/tooltip.component';
import {Subject} from 'rxjs';
import {Version, VersionStatus} from '../../../models/vestrata/Version';
import {UtilsService} from '../../../api/utils.service';
import {Permission} from '../../../models/vestrata/Permission';
import {TranslateService} from '@ngx-translate/core';
import {SharedSnackbarService} from '../../../components/shared-snackbar/services/shared-snackbar.service';
import {SnackbarMessage} from '../../../components/shared-snackbar/models/snackbar-message';
import * as moment from 'moment';
import {AuthenticationService} from '../../../api/services/authentication.service';

@Component({
  selector: 'ves-actions-menu-new',
  templateUrl: './actions-menu-new.component.html',
  styleUrls: ['./actions-menu-new.component.scss']
})
export class ActionsMenuNewComponent implements OnInit, OnDestroy, OnChanges {

  @Input() interface: any;
  @Input() currentVersion: Version<any>;
  @Input() pendingVersion: Version<any>;
  @Input() permission: Permission;
  @Input() editingValue: boolean;
  currentSubmitIcon = '../../../../assets/images/components/components-4/single-icon-approval.svg';

  version: Version<any>;
  @Input() fourEyeView = true;

  singlePreview = new IconUrl('preview1', false);
  singleSave = new IconUrl('save1', false);
  singleCancel = new IconUrl('cancel1', false);
  singleReset = new IconUrl('reset1', false);
  singleUnlock = new IconUrl('unlock1', false);

  previewTooltip = new TooltipData();
  unlockTooltip = new TooltipData();
  resetTooltip = new TooltipData();
  saveTooltip = new TooltipData();
  cancelTooltip = new TooltipData();

  private _onDestroy = new Subject();
  private currentUrl: string;
  private readonly imagePath: string = '../../../../../../assets/images/components/components-4';

  displayView = false;
  displayLocked = false;
  addNewView = false;
  addNewLocked = false;
  settingsView = false;
  settingsLocked = false;
  libraryView = false;
  libraryLocked = false;
  configurationView = false;
  configurationLocked = false

  currentView = false;
  currentLocked = false;
  isCurrent = false;

  pendingView = false;
  pendingLocked = false;
  isPending = true;

  approvalView = false;
  approvalLocked = false;
  fourEyeRight = false;

  viewDecline = false;
  dialogNote = '';
  dialogDate = '';
  viewClose = false;
  viewReview = false;


  saveView = false;
  saveLocked = false;
  cancelView = false;
  cancelLocked = false;
  resetView = false;
  resetLocked = false;
  unlockView = false;
  unlockLocked = false;
  previewView = false;
  previewLocked = false;
  viewRightInfo = false;
  submitView = false;

  closeView = false;
  closeLocked = false;
  reviewView = false;
  reviewLocked = false;
  replaceView = false;
  replaceLocked = false;

  // set method names from parent that control the actions menu.
  onSave = 'onSave';
  onCancel = 'onCancel';
  onReset = 'onReset';
  onDisplay = 'onDisplay';
  onAddNew = 'onAddNew';
  onSettings = 'onSettings';
  onLibrary = 'onLibrary';
  onConfiguration = 'onConfiguration';
  onCurrent = 'onCurrent';
  onPending = 'onPending';
  onDecline = 'onDecline';
  onApprove = 'onApprove';
  onSubmitForApproval = 'onSubmitForApproval';
  onUnlock = 'onUnlock';
  onPreview = 'onPreview';
  equals = 'equals';
  onClose = 'onClose';
  onReview = 'onReview';
  onReplace = 'onReplace';

  constructor(private authService: AuthenticationService,
              private snackbar: SharedSnackbarService,
              private translate: TranslateService) {
    this.singlePreview.imgUrl         = this.imagePath + '/single-icon-preview.svg';
    this.singlePreview.imgUrlHover    = this.imagePath + '/hover/single-icon-preview.svg';
    this.singlePreview.imgUrlLocked   = this.imagePath + '/disabled/single-icon-preview.svg';
    this.singleSave.imgUrl            = this.imagePath + '/single-icon-save.svg';
    this.singleSave.imgUrlHover       = this.imagePath + '/hover/single-icon-save.svg';
    this.singleSave.imgUrlLocked      = this.imagePath + '/disabled/single-icon-save.svg';
    this.singleCancel.imgUrl          = this.imagePath + '/single-icon-cancel.svg';
    this.singleCancel.imgUrlHover     = this.imagePath + '/hover/single-icon-cancel.svg';
    this.singleCancel.imgUrlLocked    = this.imagePath + '/disabled/single-icon-cancel.svg';
    this.singleReset.imgUrl           = this.imagePath + '/single-icon-reset.svg';
    this.singleReset.imgUrlHover      = this.imagePath + '/hover/single-icon-reset.svg';
    this.singleReset.imgUrlLocked     = this.imagePath + '/disabled/single-icon-reset.svg';
    this.singleUnlock.imgUrl          = this.imagePath + '/single-icon-unlock.svg';
    this.singleUnlock.imgUrlHover     = this.imagePath + '/hover/single-icon-unlock.svg';
    this.singleUnlock.imgUrlLocked    = this.imagePath + '/disabled/single-icon-unlock.svg';
    this.previewTooltip.title = 'top-menu-component.preview';
    this.unlockTooltip.title = 'top-menu-component.unlock';
    this.resetTooltip.title = 'top-menu-component.reset';
    this.saveTooltip.title = 'top-menu-component.save';
    this.cancelTooltip.title = 'top-menu-component.cancel'; }

  ngOnInit() {
    // console.log(this.currentVersion);
    // console.log(this.pendingVersion);
    // console.log(this.fourEyeView);
    // console.log(this.permission);
    this.setVersion();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.setVersion();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  click(button: string) {
    // console.log('***************BUTTON CLICK********************');
    // console.log(button);
    if (button === 'onDisplay') {
      this.interface.onDisplay();
    } else if (button === 'onAddNew') {
      this.interface.onAddNew();
    } else if (button === 'onSettings') {
      this.interface.onSettings();
    } else if (button === 'onLibrary') {
      this.interface.onLibrary();
    } else if (button === 'onConfiguration') {
      this.interface.onConfiguration();
    } else if (button === 'onCurrent') {
      this.isCurrent = true;
      this.isPending = false;
      this.approvalLocked = true;
      this.interface.onCurrent();
    } else if (button === 'onPending') {
      this.isCurrent = false;
      this.isPending = true;
      this.approvalLocked = false;
      this.interface.onPending();
    } else if (button === 'onUnlock') {
      this.interface.onUnlock();
    } else if (button === 'onDecline') {
      this.viewDecline = true;
    } else if (button === 'onApprove') {
      this.interface.onApprove();
    } else if (button === 'onSave') {
      this.interface.onSave();
    } else if (button === 'onPreview') {
      this.interface.onPreview();
    } else if (button === 'onCancel') {
      this.interface.onCancel();
    } else if (button === 'onReset') {
      this.interface.onReset();
    } else if (button === 'onSubmit') {
      this.interface.onSubmitForApproval();
    } else if (button === '4Eyes') {
      if (this.fourEyeRight) {
        this.viewRightInfo = !this.viewRightInfo;
      }
    } else if (button === 'onReview') {
      this.dialogDate = moment().format('YYYY-MM-DD');
      this.viewReview = true;
    } else if (button === 'onClose') {
      this.dialogDate = moment().format('YYYY-MM-DD');
      this.viewClose = true;
    } else if (button === 'onReplace') {
      this.interface.onReplace();
    }
  }

  cancelDialog() {
    this.viewDecline = false;
    this.viewClose = false;
    this.viewReview = false;
  }

  confirmDialog() {
    // console.log('declining change');
    if (this.dialogNote && this.dialogNote.length > 0 && this.dialogNote !== ' ') {
      if (this.viewDecline) { this.interface.onDecline(this.dialogNote); }
      if (this.viewClose) { this.interface.onClose(this.dialogDate, this.dialogNote); }
      if (this.viewReview) { this.interface.onReview(this.dialogDate, this.dialogNote); }
      this.cancelDialog();
    } else {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('actions-menu.decline-no-note')));
    }

  }

  getDialogHead(): string {
    if (this.viewDecline) { return this.translate.instant('actions-menu.decline-head'); }
    if (this.viewClose) { return this.translate.instant('actions-menu.close'); }
    if (this.viewReview) { return this.translate.instant('actions-menu.review'); }
  }

  getDialogButton(): string {
    if (this.viewDecline) { return this.translate.instant('actions-menu.decline'); }
    if (this.viewClose) { return this.translate.instant('actions-menu.close'); }
    if (this.viewReview) { return this.translate.instant('actions-menu.review'); }
  }

  getDateLabel(): string {
    if (this.viewClose) { return this.translate.instant('actions-menu.close-date'); }
    if (this.viewReview) { return this.translate.instant('actions-menu.review-date'); }
  }

  getFormattedDate(date): string {
    return UtilsService.formatDate(date);
  }

  setVersion() {
    // console.log(this.pendingVersion);
    // console.log(this.interface.equals());
    if (this.interface && this.interface[this.equals] && this.interface.equals()) {
      if (this.pendingVersion && this.pendingVersion.status) {
        this.version = this.pendingVersion;
      } else if (this.currentVersion) {
        this.version = this.currentVersion;
      } else {
        this.version = new Version<any>();
        this.version.status = VersionStatus.EDITED;
      }
    } else {
      this.version = new Version<any>();
      this.version.status = VersionStatus.EDITED;
    }
    this.setActionMenu();
  }

  public setActionMenu() {
    // console.log(this.version);
    // console.log(this.permission);
    if (this.interface) {
      this.replaceView = this.interface[this.onReplace] && this.permission && this.permission.write && this.version.status === VersionStatus.APPROVED;
      this.closeView = this.interface[this.onClose] && this.permission && this.permission.write && this.version.status === VersionStatus.APPROVED;
      this.reviewView = this.interface[this.onReview] && this.permission && this.permission.write && this.version.status === VersionStatus.APPROVED;
      this.cancelView = this.interface[this.onCancel];
      this.previewView = this.interface[this.onPreview];
      // set settings buttons
      this.addNewView = this.interface[this.onAddNew] && this.permission && this.permission.write;
      this.displayView = this.interface[this.onDisplay] && (this.interface.isIps || this.interface.isAdmin);
      this.settingsView = this.interface[this.onSettings] && this.permission && (this.permission.write || this.permission.approve);
      this.libraryView = this.interface[this.onLibrary] && this.permission &&
        (this.permission.write || this.permission.approve);
      this.configurationView = this.interface[this.onConfiguration] && this.permission && this.permission.write;
      console.log('ACTIONS MENU 4EYE VIEW');
      console.log(this.fourEyeView);

      if (!this.fourEyeView) {
        this.saveView = this.interface[this.onSave] && this.permission && this.permission.write;
        this.saveLocked = this.interface[this.onSave] && this.permission.write
          && this.version.status !== VersionStatus.EDITED && !this.closeView && !this.reviewView;
        this.resetView = this.interface[this.onReset] && this.permission && this.permission.write && !this.closeView && !this.reviewView;
        this.resetLocked = this.resetView && this.interface[this.onReset]
          && !(this.version.status === VersionStatus.SAVED);
      } else {

        this.saveView = this.interface[this.onSave] && this.permission && this.permission.write
          && this.version.status !== VersionStatus.REQUEST && this.version.status !== VersionStatus.DELETE
          && !this.replaceView && !this.closeView && !this.reviewView;
        this.saveLocked = this.saveView && this.version.status !== VersionStatus.EDITED ||
          (this.version.status === VersionStatus.EDITED && this.interface[this.equals] && this.interface.equals());
        this.resetView = this.interface[this.onReset] && this.permission && this.permission.write
          && this.version.status !== VersionStatus.REQUEST && this.version.status !== VersionStatus.DELETE
          && !this.replaceView && !this.closeView && !this.reviewView;
        this.resetLocked = this.resetView && !(this.version.status === VersionStatus.SAVED) || this.currentVersion == null;
        this.cancelView = this.interface[this.onCancel];

        // set 4eye buttons - default off - set below
        this.approvalView = false;
        this.currentView = false;
        this.pendingView = false;
        this.unlockView = false;

        console.log('************** 4EYES CHECK ****************');
        console.log(this.version);
        this.submitView = this.version?.status === 'saved' && this.fourEyeView && this.permission.write;
        this.fourEyeRight = this.permission && (this.permission.write || this.permission.approve) && this.version && (this.version.status === VersionStatus.APPROVED ||
          this.version.status === VersionStatus.SAVED || this.version.status === VersionStatus.REJECTED ||
          this.version.status === VersionStatus.REQUEST || this.version.status === VersionStatus.DELETE ||
          this.version.status === VersionStatus.REJECT_DELETE);
        console.log(this.version.status === VersionStatus.REJECT_DELETE);
        console.log(this.fourEyeRight);

        if (this.version && this.version.status === VersionStatus.REQUEST) {
          const myUserId = this.authService.getMyId();
          this.currentView = this.interface[this.onCurrent];
          this.pendingView = this.interface[this.onPending];

          // console.log(myUserId);
          if (myUserId !== this.version.submittedId) {
            if (this.interface[this.onApprove] && this.permission.approve) {
              this.approvalView = this.interface[this.onApprove] && this.interface[this.onDecline];
              this.approvalLocked = this.isCurrent;
            } else if (this.interface[this.onUnlock]) {
              this.unlockView = this.interface[this.onUnlock];
              this.unlockLocked = true;
            }
          } else if (this.interface[this.onUnlock]) {
            this.unlockView = true;
            this.unlockLocked = false;
          }
        } else {
          this.currentView = false;
          this.pendingView = false;
          this.unlockView = false;
        }
      }
    } else {
      this.replaceView = false;
      this.closeView =  false;
      this.reviewView = false;
      this.cancelView = false;
      this.previewView = false;

      // set settings buttons
      this.addNewView =  false;
      this.displayView =  false;
      this.settingsView =  false;
      this.libraryView =  false;
      this.configurationView = false;

      // set 4eye buttons - default off - set below
      this.approvalView = false;
      this.currentView = false;
      this.pendingView = false;
      this.unlockView = false;
    }

  }

  onSubmitHover() {
    this.currentSubmitIcon = '../../../../assets/images/components/components-4/hover/single-icon-approval.svg';
  }

  onSubmitHoverOut() {
    this.currentSubmitIcon = '../../../../assets/images/components/components-4/single-icon-approval.svg';
  }
}
