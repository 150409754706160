import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {isEqual} from 'lodash';
import {FourEyeComponent} from "../../../basic-components/4eye-component";
import {VestrataDocument, VestrataDocumentInfo} from '../../../models/vestrata/VestrataDocument';
import * as moment from 'moment';
import {VesConfService} from '../../../api/services/conf.service';
import {DocumentService} from '../../../api/services/document.service';

@Component({
  selector: 'ves-shared-document-display-list',
  templateUrl: './shared-document-display-list.component.html',
  styleUrls: ['./shared-document-display-list.component.scss']
})
export class SharedDocumentDisplayListComponent extends FourEyeComponent<VestrataDocumentInfo[]> implements OnInit, OnChanges {


  @Input() emptyImage = '/assets/images/components/no-components/placeholder-links.svg';
  @Input() showEmpty = true;
  @Input() type: 'small' | 'medium' | 'large' | 'display' = 'large';
  @Input() allowRemove = true;
  @Input() hideExpired = false;
  docToViewId: string;
  viewDocViewer = false;
  filetredList: VestrataDocumentInfo[];

  constructor(private confService: VesConfService,
              private documentService: DocumentService) {
    super();
  }

  ngOnInit(): void {
    this.checkForExpired();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.checkForExpired();
  }

  protected equals(a: VestrataDocumentInfo[], b: VestrataDocumentInfo[]): boolean {
    return isEqual(a, b);
  }
  openDetails(doc: VestrataDocumentInfo) {
    if (!doc.id && doc.url) {
      window.open(doc.url, '_blank');
    } else {
      this.docToViewId = doc.id;
      this.viewDocViewer = true;
    }
  }

  removeDocument(i: number) {
    this._editingValue.splice(i, 1);
  }

  // --- Accordion-----
  openedDocAccordions = [];
  isDocAccordionOpen(docList, i) {
    return docList.indexOf(i) > -1;
  }
  manageAccordion(docList, i: number) {
    if (this.isDocAccordionOpen(docList, i)) {
      docList.splice(docList.indexOf(i), 1);
    } else {
      docList.push(i);
    }
  }
  // ----------------------------------------------------------
  checkForExpired() {
    if (!this.hideExpired || !this._editingValue) {
      this.filetredList = this._editingValue;
      return;
    }
    this.filetredList = this._editingValue.filter(item => !item.expiryDate ||  moment().isBefore(item.expiryDate));
  }

  downloadSixFile(doc: VestrataDocumentInfo) {
    this.documentService.getSixDocBlob(doc).subscribe(url => {
      // Do nothing.
    });
  }
}
