import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";

@Component({
  selector: 'ves-number-up-down',
  templateUrl: './number-up-down.component.html',
  styleUrls: ['./number-up-down.component.scss']
})
export class NumberUpDownComponent implements OnInit, OnDestroy {
  @Input() value: number;
  @Input() type: 'arrows' | 'signs' = 'signs';

  private _onDestroy = new Subject();
  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  getAbsoluteValue(value: number) {
    return Math.abs(value);
  }

}
