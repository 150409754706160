import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Organisation} from "../../models/vestrata/Organisation";
import {PartnerData, PartnerLocal, SocialMediaLinks} from "../../models/vestrata/Partner";
import {Strategy} from "../../models/vestrata/Strategy";

@Component({
  selector: 'app-shared-partner-profile-preview',
  templateUrl: './shared-partner-profile-preview.component.html',
  styleUrls: ['./shared-partner-profile-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SharedPartnerProfilePreviewComponent implements OnInit, OnChanges {

  @Input() organisation: Organisation;
  @Input() partnerDataToView: PartnerData;
  @Input() strategiesList: Strategy[] = [];
  @Input() lang: string;
  @Output()  goBackEvent = new EventEmitter<boolean>();

  securedUrl: SafeResourceUrl = null;
  currentLanguage: string;

  constructor(private translateService: TranslateService,
              private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.currentLanguage = this.translateService.currentLang;
    this.getSecureUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lang) {
      this.currentLanguage = this.lang;
      this.getSecureUrl();
    }
  }

  getSecureUrl() {
    if (this.getPartnerProfileLocal() != null && this.getPartnerProfileLocal().videoUrl != null) {
      this.securedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getPartnerProfileLocal().videoUrl);
    } else {
      this.securedUrl = null;
    }
  }

  getPartnerDataLocalFromLanguage(lang: string, data: PartnerData): PartnerLocal {
    let local: PartnerLocal = new PartnerLocal();
    if (data != null && data.partnerLocal != null) {
      data.partnerLocal.forEach(p => {
        if (p.lang === lang) {
          local = p;
        }
      });
    }
    if (local.socialMedia == null) {
      local.socialMedia = new SocialMediaLinks();
    }
    return local;
  }

  getPartnerProfileLocal() {
    return this.getPartnerDataLocalFromLanguage(this.currentLanguage, this.partnerDataToView);
  }

  isSocialMedia(): boolean {
    const socialMedia: SocialMediaLinks = this.getPartnerProfileLocal().socialMedia;
    console.log('socialMedia ---> ', socialMedia)
    return (
        socialMedia.linkedin !== null || socialMedia.twitter !== null || socialMedia.youtube !== null || socialMedia.facebook !== null || socialMedia.instagram !== null
      || socialMedia.linkedin !== '' || socialMedia.twitter !== '' || socialMedia.youtube !== '' || socialMedia.facebook !== '' || socialMedia.instagram !== ''
    );
  }

  goBack() {
    this.goBackEvent.emit(true);
  }

}
