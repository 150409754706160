import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Allocation, AllocationChanged} from '../../models/vestrata/Allocation';
import {InstrumentInfo} from '../../models/vestrata/Instrument/Instrument';
import {ChangeNotice, ChangeNoticeType} from '../../models/vestrata/PortfolioModel';
import {TranslateService} from '@ngx-translate/core';
import {Permission} from '../../models/vestrata/Permission';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {DocumentService} from '../../api/services/document.service';
import {Subject} from 'rxjs';
import {VestrataDocument} from '../../models/vestrata/VestrataDocument';
import {SharedSnackbarService} from '../shared-snackbar/services/shared-snackbar.service';
import {VersionStatus} from '../../models/vestrata/Version';
import {SnackbarMessage} from '../shared-snackbar/models/snackbar-message';

@Component({
  selector: 'ves-shared-allocation-changes',
  templateUrl: './shared-allocation-changes.component.html',
  styleUrls: ['./shared-allocation-changes.component.scss']
})
export class SharedAllocationChangesComponent implements OnInit, OnChanges, OnDestroy {

  private _onDestroy = new Subject();
  @ViewChild('file') fileUpload: ElementRef;

  @Input() permission: Permission;
  @Input() allocationsDifferences: any[];
  @Input() editable = true;
  @Input() approval = false;
  @Input() borderless = false;
  @Input() versionStatus: VersionStatus;

  _changeNotice: ChangeNotice;
  @Output() changeNoticeChange = new EventEmitter<ChangeNotice>();
  @Input() set changeNotice(v: ChangeNotice) {
   this._changeNotice = v ? v : new ChangeNotice();
  }
  get changeNotice() {
    return this._changeNotice;
  }
  languages: any;

  @Output() instrumentDetailsClicked = new EventEmitter<InstrumentInfo>();

  constructor(private translate: TranslateService,
              private documentService: DocumentService,
              private snackBar: SharedSnackbarService,
              private loader: SharedLoaderService) {
    this.languages = [
      {
        name: this.translate.instant('allocation-changes.en'),
        value: 'EN'
      },
      {
        name: this.translate.instant('allocation-changes.fr'),
        value: 'FR'
      },
    ].sort((l1, l2) =>  l1.name.localeCompare(l2.name));
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If there is no allocations then it is first allocation.
    if (this.checkInitial()) {
      if (this._changeNotice && !this._changeNotice.changeType) {
        this.changeTypeSelected(null, ChangeNoticeType.INITIAL);
      }
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  checkInitial() {
    const status = this.allocationsDifferences.filter (a => a.status !== 'new');
    return !status || status.length === 0;
  }

  getIsinByInstrument(instrument: InstrumentInfo): string {
    return InstrumentInfo.getIdFromInstrumentIds(instrument, 'ISIN');
  }

  getAllocationClass(status: string): string {
    let allocationClass;
    switch (status) {
      case AllocationChanged.UP: allocationClass = 'up'; break;
      case AllocationChanged.DOWN: allocationClass = 'down'; break;
      case AllocationChanged.NEW: allocationClass = 'new'; break;
      case AllocationChanged.REMOVED: allocationClass = 'removed'; break;
      case AllocationChanged.NOCHANGE: allocationClass = 'nochange'; break;
    }
    return allocationClass;
  }

  getImageByStatus(status: string): string {
    let path = '';
    switch (status) {
      case AllocationChanged.UP:
        path = '../../../../../../assets/images/components/components-13/pictogram-statu-up.svg'; break;
      case AllocationChanged.DOWN:
        path = '../../../../../../assets/images/components/components-13/pictogram-statu-down.svg'; break;
      case AllocationChanged.NEW:
        path = '../../../../../../assets/images/components/components-13/pictogram-statu-new.svg'; break;
      case AllocationChanged.REMOVED:
        path = '../../../../../../assets/images/components/components-13/pictogram-statu-removed.svg'; break;
    }

    return path;
  }

  changeTypeSelected(event: any, item: string) {
    this._changeNotice.changeType = null;

    switch (item) {
      case ChangeNoticeType.TACTICAL: this._changeNotice.changeType = ChangeNoticeType.TACTICAL; break;
      case ChangeNoticeType.SWITCH: this._changeNotice.changeType = ChangeNoticeType.SWITCH; break;
      case ChangeNoticeType.STRATEGIC: this._changeNotice.changeType = ChangeNoticeType.STRATEGIC; break;
      case ChangeNoticeType.REBALANCE: this._changeNotice.changeType = ChangeNoticeType.REBALANCE; break;
      case ChangeNoticeType.INITIAL: this._changeNotice.changeType = ChangeNoticeType.INITIAL; break;

    }
    this.changeNoticeChange.emit(this._changeNotice);
  }

  onCommentaryChanged(value: string) {
    this._changeNotice.commentary = value;
    this.changeNoticeChange.emit(this._changeNotice);
  }

  getDetailsImageByStatus(status: string): string {
    let path = '../../../../../../assets/images/components/components-4/';
    switch (status) {
      case AllocationChanged.UP: path += 'single-icon-display-view-up.svg'; break;
      case AllocationChanged.DOWN: path += 'single-icon-display-view-down.svg'; break;
      case AllocationChanged.NEW: path += 'single-icon-display-view.svg'; break;
      case AllocationChanged.REMOVED: path += 'single-icon-display-view-removed.svg'; break;
      case AllocationChanged.NOCHANGE: path += 'disabled/single-icon-display-view.svg'; break;
    }
    return path;
  }

  onInstrumentDetailsClick(value: any): void {
    if (value.status === AllocationChanged.REMOVED) {
      this.instrumentDetailsClicked.emit(value.oldAllocation.instrument);
    } else {
      this.instrumentDetailsClicked.emit(value.newAllocation.instrument);
    }
  }

  openUploader() {
    this.fileUpload.nativeElement.click();
  }

  onUploadDocument(fs: any) {
    const uploadedDocument = fs[0];
    if (uploadedDocument) {
      this.loader.showFullLoader('upload-file.uploading');
      this.documentService.uploadDocument(uploadedDocument).pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader()))
        .subscribe((returnValue) => {
          const document = new VestrataDocument();
          document.name = uploadedDocument.name;
          document.url = returnValue.url;
          document.type = 'ChangeNotice';
          document.title = 'Change Notice';
          document.language = 'all';
          document.summary = '';
          this._changeNotice.document = document;
          this.changeNoticeChange.emit(this._changeNotice);
          this.snackBar.push(new SnackbarMessage(this.translate.instant('allocation-changes.upload-success'), 'info'));
        }, error => {
          this.snackBar.push(new SnackbarMessage(this.translate.instant('allocation-changes.upload-error'), 'error'));
        });
    }
  }

  downloadDocument(changeNotice: ChangeNotice) {
    if (changeNotice.document && changeNotice.document.url) {
      window.location.href = changeNotice.document.url;
    }
  }
}
