import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';

@Pipe({
  name: 'dateCustom'
})
export class DateCustomPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: string | number | Date, format: string = 'shortDate', useAppLocal: boolean = false): any {
    const lang = navigator.language || window.navigator.language;
    let datePipe: DatePipe = new DatePipe(lang);
    if (useAppLocal) {
      datePipe = new DatePipe(this.translateService.currentLang);
    }
    if (value) {
      return datePipe.transform(moment(value), format);
    } else {
      return '';
    }
  }


}
