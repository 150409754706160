import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'ves-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {

  @Input() pages: number;
  @Input() results: number;
  @Input() type = 0;
  @Output() sizeChanged = new EventEmitter<number>();
  @Output() selectedPageChanged = new EventEmitter<number>();

  readonly pageSizeOptions = [25, 50, 100];
  currentPage = 1;
  currentPageSizeOption = 0;
  arrowToggle = true;
  displayPaged: number[];

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.pages) {
      this.displayPaged = [];
      if (changes.pages.currentValue <= 3) {
        for (let i = 1; i < this.pages + 1; i++) {
          this.displayPaged.push(i);
        }
      } else {
        this.displayPaged = [1, 2, 3];
      }
    }
  }

  itemSelected(index: number) {
    this.currentPageSizeOption = index;
    this.currentPage = 1;
    this.pageSelected(this.currentPage);
    this.sizeChanged.emit(this.pageSizeOptions[this.currentPageSizeOption]);
  }

  pageSelected(index: number) {
    this.currentPage = index;
    if (this.pages > 3) {
      if (this.currentPage === 1) {
        this.displayPaged = [1, 2, 3];
      } else if (this.currentPage === this.pages) {
        this.displayPaged = [this.currentPage - 2, this.currentPage - 1, this.currentPage];
      } else {
        this.displayPaged = [this.currentPage - 1, this.currentPage, this.currentPage + 1];
      }
    }
    this.selectedPageChanged.emit(this.currentPage);
  }

}
