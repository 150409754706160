import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ves-page-card',
  templateUrl: './page-card.component.html',
  styleUrls: ['./page-card.component.scss']
})
export class PageCardComponent implements OnInit {

  @Input() title: string;

  @Input() buttonText: string;

  @Input() titleImagePath: string;

  @Output() cardClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
