import {HttpClient, HttpHeaders} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {ConfigService} from '@ngx-config/core';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttpService} from '../api.http.service';
import {Metric} from '../../models/vestrata/Metric';
import {TimePeriod} from '../../models/vestrata/TimePeriod';
import {MetricData, MetricsVersion} from '../../models/vestrata/MetricData';
import {PortfolioModelVersion} from '../../models/vestrata/PortfolioModel';
import moment, {Moment} from 'moment';

@Injectable({
  providedIn: 'root',
})
export class MetricsService {

  public readonly MONTHLY_FREQUENCY = 'M';
  public readonly QUARTERLY_FREQUENCY = 'Q';
  public readonly YEARLY_FREQUENCY = 'Q';

  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getRiskMetrics(): Observable<Metric[]> {
    return this.http.get<Metric[]>(this.serviceUrl + 'metrics',  ApiHttpService.getHeaders());
  }

  getTimePeriod(): Observable<TimePeriod[]> {
    return this.http.get<TimePeriod[]>(this.serviceUrl + 'metrics/timeperiod',  ApiHttpService.getHeaders());
  }

  getModelMetricData(modelId: string): Observable<MetricData> {
    return this.http.get<MetricData>(this.serviceUrl + 'portfoliomodel/metric/' + modelId,  ApiHttpService.getHeaders());
  }

  getModelBenchmarkMetricData(modelId: string): Observable<MetricData> {
    return this.http.get<MetricData>(this.serviceUrl + 'portfoliomodel/metric/' + modelId + '/benchmark',  ApiHttpService.getHeaders());
  }

  saveMetricData(modelId: string, pending: MetricsVersion ): Observable<MetricData> {
    return this.http.put<MetricData>(this.serviceUrl + 'portfoliomodel/metric/' + modelId, pending, ApiHttpService.getHeaders());
  }

  submitMetricData(modelId: string): Observable<MetricData> {
     return this.http.post<MetricData>(this.serviceUrl + 'portfoliomodel/metric/submit/' + modelId, null, ApiHttpService.getHeaders());
    //return this.http.post<MetricData>(this.serviceUrl + 'portfoliomodel/metric/submit/' + modelId, null,
     // { headers: new HttpHeaders( {'Content-Type': 'application/json', 'Access-Control-Expose-Headers': 'error-code' }), observe: 'response' as 'body'});
  }

  unlockMetricData(modelId: string): Observable<MetricData> {
    return this.http.post<MetricData>(this.serviceUrl + 'portfoliomodel/metric/unlock/' + modelId, null, ApiHttpService.getHeaders());
  }

  approveMetricData(modelId: string): Observable<MetricData> {
    return this.http.post<MetricData>(this.serviceUrl + 'portfoliomodel/metric/approve/' + modelId, null, ApiHttpService.getHeaders());
  }
  declineMetricData(modelId: string, note: string): Observable<MetricData> {
    return this.http.post<MetricData>(this.serviceUrl + 'portfoliomodel/metric/decline/' + modelId, note, ApiHttpService.getHeaders());
  }

  private getLastExpectedAsOfDate(currentMonth: Moment, type: 'month' | 'quarter') {
    const value = moment(currentMonth).subtract(1, type).endOf(type);
    // Last expected as of day is sunday or saturday - so get the last business date.
    const weekday = value.isoWeekday();
    if (weekday === 6 || weekday === 7) {
      value.subtract((weekday - 5), 'day'); // Get previous friday.
    }
    return value;
  }

  getMetricsDiffDays(lastMetricDate: string, frequency: string = 'M', day: number = 0) {
    const today = moment();
    let nextPeriod = 0;
    if (day > 0) {
      if (frequency === this.MONTHLY_FREQUENCY) {
        const dueDate = moment().set('date', day);
        if (lastMetricDate) {
          const lastExpectedAsOfDate = this.getLastExpectedAsOfDate(dueDate, 'month');
          const lmd = moment(lastMetricDate);

          // If last metric update is after this month due date, due date become next month due date.
          if (lmd.isAfter(lastExpectedAsOfDate, 'day') || lmd.isSame(lastExpectedAsOfDate, 'day')) {
            dueDate.add(1, 'month');
            nextPeriod = dueDate.diff(today, 'day');
          } else {
            const nextDueDate = moment(lmd).add(1, 'month').set('date', day);
            nextPeriod = nextDueDate.diff(today, 'day');
          }
        }
      } else {
        // const dueDate = moment().startOf('quarter').set('date', day);
        const dueDate = moment().set('date', day);
        if (lastMetricDate) {
          const lastExpectedAsOfDate = this.getLastExpectedAsOfDate(dueDate, 'quarter');
          const lmd = moment(lastMetricDate);

          if (lmd.isAfter(lastExpectedAsOfDate, 'day') || lmd.isSame(lastExpectedAsOfDate, 'day')) {
            dueDate.add(1, 'quarter');
            nextPeriod = dueDate.diff(today, 'day');
          } else {
            const nextDueDate = moment(lmd).add(1, 'quarter').startOf('quarter').set('date', day);
            nextPeriod = nextDueDate.diff(today, 'day');
          }
        }
      }
      return nextPeriod;
    }
  }
}


