import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngx-config/core';
import {VesConfService} from './conf.service';
import {Inject, Injectable} from '@angular/core';
import {BuildInfo} from "../../models/vestrata/BuildInfo";
import {Observable} from "rxjs";
import {ApiHttpService} from "../api.http.service";

@Injectable({
  providedIn: 'root',
})
export class VesBackEndService {

  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private vesConfService: VesConfService) {

    this.serviceUrl = this.vesConfService.getApiEndPoint();
  }

  getFrontEndBuildInfo(): BuildInfo {
    const myBuild = new BuildInfo();
    myBuild.commitId = this.config.getSettings('system.commitId');
    myBuild.pipelineId = this.config.getSettings('system.pipelineId');
    myBuild.buildtime = this.config.getSettings('system.buildDatetime');
    return myBuild;
  }

  getBuildInfo(): Observable<BuildInfo> {
    return this.http.get<BuildInfo>(this.serviceUrl + 'buildinfo', ApiHttpService.getHeaders());
  }
}
