import {Component, ElementRef, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {isEqual} from 'lodash';
import {FourEyeComponent} from '../../4eye-component';
import {VersionStatus} from '../../../models/vestrata/Version';

@Component({
  selector: 'ves-slider-radio',
  templateUrl: './slider-radio.component.html',
  styleUrls: ['./slider-radio.component.scss']
})
export class SliderRadioComponent extends FourEyeComponent<any> implements OnInit, OnChanges {

  private scrollPosition = 0;
  @ViewChild('sliderRadio') parentContainer: ElementRef;
  @ViewChild('buttonsContainer') buttonsContainer: ElementRef;

  @Input() dataSlider: any[] = [];
  @Input() type = 0; // 0 is normal, 1 is risk slider

  constructor(private renderer: Renderer2) {
    super();
  }

  protected equals(a: any, b: any): boolean {
    return isEqual(a, b);
  }


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if ((changes.dataSlider && changes.dataSlider.currentValue) ||
      (this.dataSlider && changes.editingValue )) {
      const selectedItem = this.dataSlider.find((item) => {
        return item.object && isEqual(item.object, this._editingValue);
      });
      if (selectedItem) {
        this.selectItem(selectedItem);
        this.editingValueChange.emit(selectedItem.object);
      }
    }
  }

  onClick(item: any) {
    if (!this.isBlocked()) {
      if (item.status === 'selected') {
        item.status = undefined;
        this._editingValue = undefined;
      } else {
        this.selectItem(item);
      }
      this.editingValueChange.emit(this._editingValue);
    }
  }

  isBlocked(): boolean {
    return this.getState().readonly || this.getState().state === VersionStatus.REQUEST;
  }

  private selectItem(item: any) {
    this.dataSlider.forEach( i => {
      i.status = undefined;
    });
    item.status = 'selected';
    this._editingValue = item.object;
  }

  getClass(item: any, status: string, blocked: boolean): string {
    let classValue = 'radio';
    if (this.type ===  0) {
      if (item.status === 'selected') {
        classValue = 'radio-selected';
        classValue += ' ' + this.getStyleClass(status, blocked);
      }

      // If need to highlight the selected radio button
      if (item?.status === 'active') {
        classValue = 'radio-active';
      }
    } else {
      classValue = 'risk-item';
    }

    return classValue;
  }

  getStyleClass(status: string, blocked: boolean): string {
    let styleClass = '';
    if (this.fourEyesMode) {
      switch (status) {
        case VersionStatus.EDITED :
          styleClass = 'edited';
          break;
        case VersionStatus.SAVED :
          styleClass = 'saved';
          break;
        case VersionStatus.REQUEST :
          styleClass = 'submitted';
          break;
      }
    }

    if (blocked && status !== VersionStatus.REQUEST) {
      styleClass = 'locked';
    }

    return styleClass;
  }

  isOverflowing(): boolean {
    if (this.parentContainer && this.buttonsContainer) {
      const containerWidth = this.buttonsContainer.nativeElement.scrollWidth;
      const parentWidth = this.parentContainer.nativeElement.clientWidth;

      if (containerWidth > parentWidth) {
        this.renderer.setStyle(this.buttonsContainer.nativeElement, 'width', parentWidth - 76 + 'px');
        return true;
      }
    }
    return false;
  }

  scroll(where: 'toEnd' | 'toStart') {
    if (where === 'toEnd') {
      this.scrollPosition = this.buttonsContainer.nativeElement.scrollLeft + 150;
      this.buttonsContainer.nativeElement.scrollTo(this.scrollPosition, 0);
    } else {
      this.scrollPosition = this.buttonsContainer.nativeElement.scrollLeft - 150;
      this.buttonsContainer.nativeElement.scrollTo(this.scrollPosition, 0);
    }
  }

  showFadeout(): boolean {
    if (!this.buttonsContainer) {
      return false;
    }
    return this.buttonsContainer.nativeElement.offsetWidth + this.scrollPosition < this.buttonsContainer.nativeElement.scrollWidth;
  }
}
