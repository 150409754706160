import { Component, OnInit, Input } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ves-shared-language-menu',
  templateUrl: './shared-language-menu.component.html',
  styleUrls: ['./shared-language-menu.component.scss']
})
export class SharedLanguageMenuComponent implements OnInit {

  @Input()
  orientation?: 'horizontal' | 'vertical';

  currentLanguage: any;
  languages: any[];
  showAvailable = false;

  constructor(private translateService: TranslateService) {
    this.languages = [{
      locale: 'en',
      src: '../../../assets/images/languages-menu/en/en.png',
      srcset: '../../../assets/images/languages-menu/en/en.png 1x,' +
        '../../../assets/images/languages-menu/en/en@2x.png 2x,' +
        '../../../assets/images/languages-menu/en/en@3x.png 3x'
    }, {
      locale: 'fr',
      src: '../../../assets/images/languages-menu/fr/fr.png',
      srcset: '../../../assets/images/languages-menu/fr/fr.png 1x,' +
        '../../../assets/images/languages-menu/fr/fr@2x.png 2x,' +
        '../../../assets/images/languages-menu/fr/fr@3x.png 3x'
    }/*, {
      locale: 'de',
      src: '../../../assets/images/newStyles/languages-menu/de/de.png',
      srcset: '../../../assets/images/newStyles/languages-menu/de/de.png 1x,' +
          '../../../assets/images/newStyles/languages-menu/de/de@2x.png 2x,' +
          '../../../assets/images/newStyles/languages-menu/de/de@3x.png 3x'
    }, {
      locale: 'it',
      src: '../../../assets/images/newStyles/languages-menu/it/it.png',
      srcset: '../../../assets/images/newStyles/languages-menu/it/it.png 1x,' +
          '../../../assets/images/newStyles/languages-menu/it/it@2x.png 2x,' +
          '../../../assets/images/newStyles/languages-menu/it/it@3x.png 3x'
    }, {
      locale: 'es',
      src: '../../../assets/images/newStyles/languages-menu/es/es.png',
      srcset: '../../../assets/images/newStyles/languages-menu/es/es.png 1x,' +
          '../../../assets/images/newStyles/languages-menu/es/es@2x.png 2x,' +
          '../../../assets/images/newStyles/languages-menu/es/es@3x.png 3x'
    }*/];
  }

  ngOnInit() {
    this.currentLanguage = this.getCurrentLanguageData(this.translateService.currentLang);
  }

  toggleAvailable() {
    this.showAvailable = !this.showAvailable;
  }

  getCurrentLanguageData(currentLanguage: string): any {
    return this.languages.find(l => l.locale === (currentLanguage || 'en'));
  }

  select(language: string) {
    this.currentLanguage = this.getCurrentLanguageData(language);
    this.toggleAvailable();
    this.translateService.use(language);
    // TODO: Do real translate
  }

}
