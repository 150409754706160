import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  InsightLocal,
  InsightMainCategory,
  InsightVersion,
  Tag,
  TagGroup
} from '../../../models/vestrata/Insight';
import {Version, VersionStatus} from '../../../models/vestrata/Version';
import {Permission} from '../../../models/vestrata/Permission';
import {UtilsService} from '../../../api/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {InsightService} from '../../../api/services/insight.service';
import moment from 'moment';
import {CommonService} from '../../../api/services/common.service';
import {Organisation} from '../../../models/vestrata/Organisation';
import {ArticleAssetAllocation, ArticleModelDto, ArticleStrategyDto} from '../../../models/vestrata/Article';

@Component({
  selector: 'ves-shared-insight-details',
  templateUrl: './shared-insight-details.component.html',
  styleUrls: ['./shared-insight-details.component.scss']
})
export class SharedInsightDetailsComponent implements OnInit, OnChanges {

  @Input() pendingInsightVersion: InsightVersion;
  @Input() editingInsightVersion: InsightVersion;
  @Input() currentInsightVersion: InsightVersion;
  @Input() pendingInsightLocal: InsightLocal;
  @Input() editingInsightLocal: InsightLocal;
  @Input() currentInsightLocal: InsightLocal;
  @Input() pendingInsight: Version<InsightVersion>;
  @Input() permission: Permission;
  @Input() insightOrgId: string;
  @Input() insightOrgName: string;
  @Input() isAdmin = false;

  organisation: Organisation;
  @Output()
  thumbnailUpdated = new EventEmitter<string>();

  @Output()
  bannerUpdated = new EventEmitter<string>();

  $tagOptions: string[] = [];
  tagGroups: TagGroup[] = [];

  mainCategories: InsightMainCategory[] = [];
  regions: string[] = [];
  assetClasses: string[] = [];
  themes: string[] = [];
  strategies: ArticleStrategyDto[] = [];
  models: ArticleModelDto[] = [];
  assetAllocations: ArticleAssetAllocation[] = [];
  shareTypes$: any[];
  toogleReset = false;

  constructor(private utilsService: UtilsService,
              private translate: TranslateService,
              private snackbar: SharedSnackbarService,
              private insightService: InsightService,
              private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.getOrganisation().subscribe(org => {
      if (org) {
        this.organisation = org;
      }
    });

    this.setTags();
    this.setCategories();

    if (this.editingInsightVersion.endDate) {
      this.toogleReset = true;
    }
    // console.log('********* INSIGHT DETAILS ***********');
    // console.log(this.currentInsightVersion);
    // console.log(this.pendingInsightVersion);
    // console.log(this.editingInsightVersion);
    // console.log('*************************************');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.pendingInsight.status !== VersionStatus.REQUEST &&
        !this.editingInsightVersion.startDate || this.editingInsightVersion.startDate === '') {
        this.editingInsightVersion.startDate = moment(new Date()).format('YYYY-MM-DD');
      }
    }
  }

  setCategories() {
    this.regions = [];
    this.themes = [];
    this.shareTypes$ = [];
    this.mainCategories = Object.values(InsightMainCategory);
    this.assetAllocations = Object.values(ArticleAssetAllocation);
    this.insightService.getInsightDetailsCategories().subscribe(c => {
      c.regions.forEach(a => this.regions.push(a.id));
      c.themes.forEach(a => this.themes.push(a.id));
      c.assetClasses.forEach(a => this.assetClasses.push(a.name));
      this.strategies = c.strategies;
      this.models = c.models;
      if (this.models.length === 0 ) {
        this.mainCategories.splice(this.mainCategories.indexOf(InsightMainCategory.PRODUCT), 1);
      }
    });
    this.shareTypes$.push({ value: null , name: ''});
    this.shareTypes$.push({ value: true , name: this.translate.instant('insight.type-public')});
    this.shareTypes$.push({ value: false , name: this.translate.instant('insight.type-private')});
  }

  getModelName(model: ArticleModelDto) {
    return model.strategyName + ' ' + model.investmentObjectiveName + ' ' + model.currency;
  }
  thumbnailUploaded(imageUrl: string) {
    this.thumbnailUpdated.emit(imageUrl);
  }

  bannerUploaded(imageUrl: string) {
    this.bannerUpdated.emit(imageUrl);
  }

  checkChanges() {
  }

  checkMainCategory(insightVersion: InsightVersion, category) {
    return insightVersion && insightVersion.mainCategory === category;
  }

  updateMainCategory(category) {
    this.editingInsightVersion.mainCategory = category;
  }

  updateAACategory(category) {
    this.editingInsightVersion.assetAllocationCategory = category;
  }

  checkAACategory(insightVersion: InsightVersion, category) {
    return insightVersion && insightVersion.assetAllocationCategory === category;
  }

  checkAssetClassCategory(insightVersion: InsightVersion, category) {
    return insightVersion && insightVersion.assetCategory.includes(category);
  }

  updateAssetClassCategory(category) {
    if (this.editingInsightVersion.assetCategory.indexOf(category) > -1 ) {
      this.editingInsightVersion.assetCategory.splice(this.editingInsightVersion.assetCategory.indexOf(category), 1);
    } else {
      this.editingInsightVersion.assetCategory.push(category);
    }
  }
  checkRegionCategory(insightVersion: InsightVersion, category) {
    return insightVersion && insightVersion.regionCategory.includes(category);
  }

  updateRegionCategory(category) {
    if (this.editingInsightVersion.regionCategory.indexOf(category) > -1 ) {
      this.editingInsightVersion.regionCategory.splice(this.editingInsightVersion.regionCategory.indexOf(category), 1);
    } else {
      this.editingInsightVersion.regionCategory.push(category);
    }
  }

  checkStrategyCategory(insightVersion: InsightVersion, id) {
    return insightVersion && insightVersion.strategyCategory.includes(id);
  }

  updateStrategyCategory(id) {
    if (this.editingInsightVersion.strategyCategory.indexOf(id) > -1 ) {
      this.editingInsightVersion.strategyCategory.splice(this.editingInsightVersion.strategyCategory.indexOf(id), 1);
    } else {
      this.editingInsightVersion.strategyCategory.push(id);
    }
  }

  checkModelCategory(insightVersion: InsightVersion, id) {
    return insightVersion && insightVersion.modelCategory.includes(id);
  }

  updateModelCategory(id) {
    if (this.editingInsightVersion.modelCategory.indexOf(id) > -1 ) {
      this.editingInsightVersion.modelCategory.splice(this.editingInsightVersion.modelCategory.indexOf(id), 1);
    } else {
      this.editingInsightVersion.modelCategory.push(id);
    }
  }

  checkThemeCategory(insightVersion: InsightVersion, category) {
    return insightVersion && insightVersion.themeCategory.includes(category);
  }

  updateThemeCategory(category) {
    if (this.editingInsightVersion.themeCategory.indexOf(category) > -1 ) {
      this.editingInsightVersion.themeCategory.splice(this.editingInsightVersion.themeCategory.indexOf(category), 1);
    } else {
      this.editingInsightVersion.themeCategory.push(category);
    }
  }

  checkTag(versionTags: Tag[], tag: Tag) {
    if (versionTags) {
      return versionTags.find(t => t.id === tag.id);
    }
  }

  setTags() {
    this.insightService.getTagGroups().subscribe(tgs => {
      this.tagGroups = tgs;
      this.tagGroups.forEach(tg => {
        this.setTagsFromGroup(tg);
      });
    });
  }

  setTagsFromGroup(group: TagGroup) {
    group.tags.forEach(tag => {
      let tagLocal = tag.data.find(t => t.lang === this.translate.currentLang);
      if ( !tagLocal ) {
        tagLocal = tag.data.find(t => t.lang === 'en');
      }
      const existingTag = this.editingInsightVersion.tags.find(t => t.id === tag.id);
      if (!existingTag || !existingTag.id) {
        this.$tagOptions.push(tagLocal.name);
      }
    });
  }

  addTag(tagString: string) {
    let tag = new Tag();
    this.tagGroups.forEach(tg => {
      tg.tags.forEach(t => {
        const tagLocal = t.data.find(tl => tl.name === tagString);
        if (tagLocal) {
          tag = t;
        }
      });
    });
    if (tag && tag.id) {
      if (this.editingInsightVersion.tags.indexOf(tag) === -1) {
        this.editingInsightVersion.tags.push(tag);
      }
      // console.log(this.editingInsightVersion.tags);
      this.utilsService.removeFromIterable(this.$tagOptions, tagString);
    }
  }

  removeTag(tag) {
    // console.log('***********REMOVE TAG ***************');
    // console.log(tag);
    this.utilsService.removeFromIterable(this.editingInsightVersion.tags, tag);
    let tagLocal = tag.data.find(t => t.lang === this.translate.currentLang);
    if ( !tagLocal ) {
      tagLocal = tag.data.find(t => t.lang === 'en');
    }
    this.$tagOptions.push(tagLocal.name);
  }

  isBankOrg(): boolean {
    return this.organisation && !!this.organisation.bank;
  }
}
