import {Component, Input, OnInit} from '@angular/core';
import {UsedInto, VestrataDocument} from '../../models/vestrata/VestrataDocument';
import {DocumentService} from '../../api/services/document.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ves-document-display',
  templateUrl: './document-display.component.html',
  styleUrls: ['./document-display.component.scss']
})
export class DocumentDisplayComponent implements OnInit {

  @Input() document: VestrataDocument;
  @Input() securedUrl;

  constructor(private documentService: DocumentService,
              private translate: TranslateService) { }

  ngOnInit(): void {
  }

  isImage() {
    return this.documentService.getSimpleDocumentType(this.document.contentType) === 'Image';
  }
  isAudio() {
    return this.documentService.getSimpleDocumentType(this.document.contentType) === 'Audio';
  }
  isVideo() {
    return this.documentService.getSimpleDocumentType(this.document.contentType) === 'Video';
  }

  checkViewerVisibility() {
    if (!this.document) {
      return false;
    }
    return ['pdf', 'word', 'ppt', 'excel']
      .includes(this.documentService.getSimpleDocumentType(this.document.contentType).toLowerCase());

  }

  getViewerType() {
    const simpleType = this.documentService.getSimpleDocumentType(this.document.contentType).toLowerCase();
    if (simpleType === 'pdf') {
      return 'google';
    } else {
      return 'office';
    }
  }

  filterUsedInto(usedIntoList: UsedInto[]): UsedInto[] {
    return usedIntoList.filter(((value, index, array) => array.findIndex((ui) => ui.type === value.type) === index));
  }
}
