import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {PortfolioModel} from '../../models/vestrata/PortfolioModel';
import {Allocation} from '../../models/vestrata/Allocation';

@Component({
  selector: 'ves-shared-model-xray',
  templateUrl: './shared-model-xray.component.html',
  styleUrls: ['./shared-model-xray.component.scss']
})
export class SharedModelXrayComponent implements OnInit, OnChanges {

  @Input() model: PortfolioModel;
  @Input() allocations: Allocation[];
  @Input() morningstarApiReady = false;

  constructor(private loader: SharedLoaderService) { }

  ngOnInit() {
    if (!this.morningstarApiReady) {
      this.loader.showFullLoader();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.morningstarApiReady && changes.morningstarApiReady.currentValue) {
      this.loader.dismissLoader();
    }
  }

}
