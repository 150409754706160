import { Injectable } from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
import {SharedSnackbarService} from "../../components/shared-snackbar/services/shared-snackbar.service";
import {SnackbarMessage} from "../../components/shared-snackbar/models/snackbar-message";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class GenericBackendErrorService {

  constructor(private snackbar: SharedSnackbarService, private translate: TranslateService) { }

  displayErrors(errorCodesLine: string) {
    if (!errorCodesLine) {
      return;
    }
    const errors = this.getErrorsFromHeaders(errorCodesLine);
    if (!errors || errors.length === 0) {
      return;
    }
    errors.forEach(error => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant("gen-errors."+error.translateKey, this.getErrorParams(error.params)), 'error', null, true));
    });
  }

  getErrorsFromHeaders(errorCodesLine: string): GenericError[]{
    const headerLines = errorCodesLine.split(',');
    console.log(headerLines);
    if (!headerLines || headerLines.length === 0) {
      return null;
    }
    const errors = [];
    headerLines.forEach( line => {
      const key = line.split('|')[0].replace(' ', '');
      const params = line.substr(line.indexOf('|')+1).split('|');
      errors.push(new GenericError(key, params));
    });
    return errors;
  }

  private getErrorParams(params: string[]) {
    const interpolateParams = {};
    if (!params) {
      return interpolateParams;
    }
    params.forEach((item, index) => interpolateParams['param'+index] = item);
    console.log('INTERPOLATE***', interpolateParams);
    return interpolateParams;
  }
}

export class GenericError {
  translateKey: string;
  params: string[];

  constructor(key: string, params: string[]) {
    this.translateKey = key;
    this.params = params;
  }
}
