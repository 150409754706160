import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'ves-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss'],
  animations: [
    trigger(
      'openClose',
      [
        transition(
          ':enter',
          [
            style({ width: 0, right: -340 }),
            animate('0.5s ease-out',
              style({ width: 340, right: 0 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ width: 340, right: 0 }),
            animate('0.5s ease-in',
              style({ width: 0 , right: -340}))
          ]
        )
      ]
    )
  ]
})
export class RightPanelComponent implements OnInit {

  @Input() hexagonCount: number;
  @Input() showHexagon: boolean;
  @Input() show = false;
  @Output() showChange = new EventEmitter<boolean>();

  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.show = false;
    this.showChange.emit(this.show);
  }

}
