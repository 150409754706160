import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngx-config/core';
import {VesConfService} from './conf.service';
import {Observable} from 'rxjs';
import {ApiHttpService} from '../api.http.service';
import {
  Insight,
  InsightCategoriesDto,
  TagGroup
} from '../../models/vestrata/Insight';
import {Region} from '../../models/vestrata/Region';
import {Theme} from '../../models/vestrata/Theme';
import {SearchRequest} from '../../models/vestrata/SearchRequest';
import {Page} from '../../models/vestrata/Page';
import {TranslateService} from '@ngx-translate/core';
import {ArticleCategoryDto, ArticleModelDto, ArticleStrategyDto} from '../../models/vestrata/Article';
import {Tutorial} from '../../models/vestrata/Tutorial';

@Injectable({
  providedIn: 'root'
})
export class InsightService {

  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService,
              private translate: TranslateService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getInsightById(insightId: string): Observable<Insight> {
    return this.http.get<Insight>(this.serviceUrl + 'insight/' + insightId , ApiHttpService.getHeaders());
  }

  getInsights(): Observable<Insight[]> {
    return this.http.get<Insight[]>(this.serviceUrl + 'insight' , ApiHttpService.getHeaders());
  }

  createInsight(insight: Insight): Observable<Insight> {
    return this.http.post<Insight>(this.serviceUrl + 'insight/' , insight, ApiHttpService.getHeaders());
  }

  updateInsight(insight: Insight): Observable<Insight> {
    return this.http.put<Insight>(this.serviceUrl + 'insight/' + insight.id , insight.pending.data, ApiHttpService.getHeaders());
  }

  deleteInsight(insight: Insight): Observable<Insight> {
    return this.http.delete<Insight>(this.serviceUrl + 'insight/' + insight.id , ApiHttpService.getHeaders());
  }

  submitInsightForApproval(insightId: string): Observable<Insight> {
    return this.http.post<Insight>(this.serviceUrl + 'insight/submit/' + insightId, ApiHttpService.getHeaders());
  }

  cancelInsightApproval(insightId: string): Observable<Insight> {
    return this.http.post<Insight>(this.serviceUrl + 'insight/unlock/' + insightId, ApiHttpService.getHeaders());
  }

  approveInsightChange(insightId: string): Observable<Insight> {
    return this.http.post<Insight>(this.serviceUrl + 'insight/approve/' + insightId, ApiHttpService.getHeaders());
  }


  declineInsightChange(insightId: string, note: string): Observable<Insight> {
    return this.http.post<Insight>(this.serviceUrl + 'insight/decline/' + insightId, note,  ApiHttpService.getHeaders());
  }

  getTagGroups(): Observable<TagGroup[]> {
    return this.http.get<TagGroup[]>(this.serviceUrl + 'global/tag/',  ApiHttpService.getHeaders());
  }

  removeTagGroup(tagGroupId: string): Observable<void> {
    return this.http.delete<void>(this.serviceUrl + 'tag/'+ tagGroupId,
      ApiHttpService.getHeaders());
  }
  createTagGroup(tagGroup: TagGroup): Observable<TagGroup> {
    return this.http.post<TagGroup>(this.serviceUrl + 'tag' , tagGroup, ApiHttpService.getHeaders());
  }

  updateTagGroup(tagGroup: TagGroup): Observable<TagGroup> {
    return this.http.put<TagGroup>(this.serviceUrl + 'tag' ,tagGroup, ApiHttpService.getHeaders());
  }

  // Curation Endpoints
  curateInsightSetEnable(insightId: string, enabled: boolean): Observable<Insight> {
    return this.http.put<Insight>(this.serviceUrl + 'insight/enable/' + insightId + '/' + enabled, ApiHttpService.getHeaders());
  }

  curateInsightSetPromoted(insightId: string, promote: boolean): Observable<Insight> {
    return this.http.put<Insight>(this.serviceUrl + 'insight/promote/' + insightId + '/' + promote, ApiHttpService.getHeaders());
  }

  // Search
  getInsightsWithSearchCriteria(searchCriteria: SearchRequest): Observable<Page<Insight>> {
    return this.http.post<Page<Insight>>(this.serviceUrl + 'insight/search', searchCriteria, ApiHttpService.getHeaders());
  }

  getLastInsight(): Observable<Insight> {
    return this.http.get<Insight>(this.serviceUrl + 'insight/last', ApiHttpService.getHeaders());
  }

  // categories for details/creation
  // TODO: REMOVE
  getInsightDetailsCategories(): Observable<ArticleCategoryDto> {
    return this.http.get<ArticleCategoryDto>(this.serviceUrl + 'insight/category?=' + this.translate.currentLang,
      ApiHttpService.getHeaders());
  }

  createInsightRegion(region: Region): Observable<Region> {
    return this.http.post<Region>(this.serviceUrl + 'insight/region', region,
      ApiHttpService.getHeaders());
  }
  createInsightTheme(theme: Theme): Observable<Theme> {
    return this.http.post<Theme>(this.serviceUrl + 'insight/theme', theme,
      ApiHttpService.getHeaders());
  }

  deleteInsightRegion(region: string): Observable<void> {
    return this.http.delete<void>(this.serviceUrl + 'insight/region/'+ region,
      ApiHttpService.getHeaders());
  }

  deleteInsightTheme(theme: string): Observable<void> {
    return this.http.delete<void>(this.serviceUrl + 'insight/theme/'+ theme,
      ApiHttpService.getHeaders());
  }

  // categories for display
  getInsightDisplayCategories(): Observable<InsightCategoriesDto[]> {
    return this.http.get<InsightCategoriesDto[]>(this.serviceUrl + 'insight/categories', ApiHttpService.getHeaders());
  }

  getInsightStrategies(): Observable<ArticleStrategyDto[]> {
    return this.http.get<ArticleStrategyDto[]>(this.serviceUrl + 'insight/strategies', ApiHttpService.getHeaders());
  }

  getInsightModels(): Observable<ArticleModelDto[]> {
    return this.http.get<ArticleModelDto[]>(this.serviceUrl + 'insight/models', ApiHttpService.getHeaders());
  }

  // 4eyes Deletion
  submitDeletion(instanceId: string): Observable<Insight> {
    return this.http.post<Insight>(this.serviceUrl + 'insight/delete/submit/' + instanceId,
      ApiHttpService.getHeaders());
  }
  approveDeletion(instanceId: string): Observable<void> {
    return this.http.post<void>(this.serviceUrl + 'insight/delete/approve/' + instanceId,
      ApiHttpService.getHeaders());
  }
  declineDeletion(instanceId: string): Observable<Insight> {
    return this.http.post<Insight>(this.serviceUrl + 'insight/delete/decline/' + instanceId,
      ApiHttpService.getHeaders());
  }

  reset(instanceId: string): Observable<Insight> {
    return this.http.post<Insight>(this.serviceUrl + 'insight/reset/' + instanceId,
      ApiHttpService.getHeaders());
  }
}
