
import { Allocation } from './Allocation';
import { Contact } from "./Contact";
import { InvestmentObjective } from './InvestmentObjective';
import { MifidBenchmark } from './MifidBenchmark';
import { PartnerProfile } from './Partner';
import { Strategy } from './Strategy';
import { Version, VersionStatus } from './Version';
import { VestrataDocument, VestrataDocumentInfo } from './VestrataDocument';
import {BenchmarkPercentage} from "./Benchmark";
import {SearchRequest} from "./SearchRequest";

export class PortfolioModel {
  id: string;
  partnerId: string;
  strategyId: string;
  investmentObjectiveId: string;
  openClose: boolean = true;
  style: string = null;
  currency: string;
  partner?: PartnerProfile;
  partnerName?: string;
  strategy?: Strategy;
  strategyName?: string;
  investmentObjective: InvestmentObjective;
  creationDate: string;
  inceptionDate: string; // should be used as the date of the first version.
  externalId: string;
  current: Version<PortfolioModelVersion>;
  pending: Version<PortfolioModelVersion>;
  versions?: Version<PortfolioModelVersion>[];
  distributions: PortfolioModelDistribution[];
  distribution: PortfolioModelDistribution;
  lastMetricDate: string;
  metricPendingStatus: VersionStatus;
  investmentObjectiveName: string;
  esgSpectrum: string;
  esgConstraints: string;

  static getModelName(model: PortfolioModel, currentLang: string): string {
    let modelName = '';
    if (model.strategy != null && model.strategy.pending != null && model.strategy.pending.data != null && model.strategy.pending.data.strategyLocal != null) {
      model.strategy.pending.data.strategyLocal.forEach(s => {
        if (s.lang === currentLang) {
          modelName = s.name + ' - ';
        }
      });
    }
    if (model.investmentObjective != null && model.investmentObjective.info != null) {
      model.investmentObjective.info.forEach(io => {
        if (io.lang === currentLang) {
          modelName = modelName + io.name;
        }
      });
    }
    return modelName;
  }
}

export class PortfolioModelVersion {
  version: number;
  mifidBenchmark: BenchmarkPercentage[] = [];
  subAdvisorFee: number;
  annualReturnTarget: number;
  annualVolatilityTarget: number;

  contact: Contact;

  allocations: Allocation[];
  validForSubmission: boolean;

  riskDisclosures: VestrataDocumentInfo[];
  fundChangeReports: VestrataDocumentInfo[];
  assetAllocationReports: VestrataDocumentInfo[];
  factSheets: VestrataDocumentInfo[];

  changeNotice?: ChangeNotice;

  metricFrequency: string;
  metricDay: number;
  metrics: ModelMetric[] = [];
}

export class ModelMetric {
  metricId: string;
  trailingTimePeriods: string[] = [];
  calendarFrequencies: string[] = [];
}

export class PortfolioModelDistribution {
  id: string;
  bankId: string;
  bankName: string;
  name: string;
  status: PfmBankStatus;
  portfolioModelId?: string;

  partnerSubmitterId: string;
  partnerSubmitterEmail: string;
  partnerSubmitter: string;
  partnerSubmissionDate: string;

  bankApproverId: string;
  bankApproverEmail: string;
  bankApprover: string;
  bankApprovalDate: string;

  acknowledges: ChangeAcknowledge[] = [];
  acknowledged?: boolean;

  currentBankInfo: Version<BankInfo>;
  pendingBankInfo: Version<BankInfo>;
}

export class ChangeAcknowledge {
  version: number;
  acknowledgeUserId: string;
  acknowledgeUserEmail: string;
  acknowledgeUser: string;
  acknowledgeDate: string;
}

export class BankInfo {
  riskProfileLevel: number;
  investmentMinimum: number;
  bankManagementFees: string;
  bankCustodyFees: string;
}

export class ChangeNotice {
  changeType: string;
  title: string;
  commentary: string;
  document: VestrataDocument;
}

export class PortfolioModelSearchFilter {
  searchRequest: SearchRequest;
  partner: any[];
  strategy: any[];
  risk: any[];
}

export class ChangeNoticeType {
  static readonly INITIAL = 'initial';
  static readonly SWITCH = 'switch';
  static readonly TACTICAL = 'tactical';
  static readonly STRATEGIC = 'strategic';
  static readonly REBALANCE = 'rebalance';

  static values(): string[] {
    return [ChangeNoticeType.INITIAL, ChangeNoticeType.SWITCH, ChangeNoticeType.TACTICAL,
      ChangeNoticeType.STRATEGIC, ChangeNoticeType.REBALANCE];
  }

}

export enum PfmBankStatus {
  PFM_SUBMITTED = 'Submitted',
  PFM_APPROVED = 'Approved',
  PFM_REJECTED = 'Rejected',
  PFM_REVOKED = 'Revoked',
  PFM_PENDING_CHANGE = 'Pending Change',
  PFM_CHANGE_ACKNOWLEDGED = 'Change Acknowledged',
}

export class PartnerPortfolioModelFilter {
  searchRequest: SearchRequest;
  currencies: string[];
  partners: string[];
  risks: any[];
  clientRiskProfile: any[];
  invObj: any[];
  sponsors: any[];
  strategyEsgCriteria: string;
  strategyType: string[];
  currencyOrder = 'desc';
  riskRankOrder: string;
}
