import {KeycloakService} from 'keycloak-angular';

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
  return () => {
    const conf = window['keycloak'];

    let iamUrl = window.location.href;
    console.log('*** APP INIT ***');
    if (iamUrl.includes('vestrata.com')) {
      console.log('*** Prod mode ***');
      const env = iamUrl.split('.')[1];
      iamUrl = 'https://iam.' + env + '.vestrata.com/auth';
    } else {
      iamUrl = 'https://iam.staging-v1.vestrata.com/auth';
    }
    console.log(iamUrl);
    return keycloak.init({
      config: {
        url: iamUrl,
        realm: conf.realm,
        clientId: conf.clientId
      },
      bearerExcludedUrls: ['https://lt.morningstar.com', 'https://storage.googleapis.com'],
      initOptions: {
        checkLoginIframe: true,
        checkLoginIframeInterval: 25
      },
      loadUserProfileAtStartUp: true
    });
  };
}
