import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {CurrencyPipe, DecimalPipe, getCurrencySymbol} from "@angular/common";
import {NumberCustomPipe} from "./number-custom.pipe";

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  private useAppLocal = false;
  constructor(private translateService: TranslateService) {
  }

  transform(input: any, currency: string, args?: any, useAppLocal: boolean = false): any {
    this.useAppLocal = useAppLocal;
    let exp;
    const suffixes = ['k', 'm', 'b', 't', 'p', 'e'];
    const isNagtiveValues = input < 0;
    if (Number.isNaN(input) || (input < 1000 && input >= 0) || !this.isNumeric(input) || (input < 0 && input > -1000)) {
      if (!!args && this.isNumeric(input) && !(input < 0) && input != 0) {
        return this.getAmountText(input.toFixed(args),currency,  '');
      } else {
        return this.getAmountText(input,currency,  '');
      }
    }

    if (!isNagtiveValues) {
      exp = Math.floor(Math.log(input) / Math.log(1000));

      return this.getAmountText((input / Math.pow(1000, exp)) , currency,  suffixes[exp - 1]);
    } else {
      input = input * -1;

      exp = Math.floor(Math.log(input) / Math.log(1000));

      return this.getAmountText((input * -1 / Math.pow(1000, exp)) , currency, suffixes[exp - 1]);
    }

  }
  isNumeric(value): boolean {
    if (value < 0) value = value * -1;
    if (/^-{0,1}\d+$/.test(value)) {
      return true;
    } else if (/^\d+\.\d+$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  private getAmountText(s: any, currency, suffix: string) {
    if (s === null  || s === undefined) {
      return  '';
    }
    let lang = navigator.language || window.navigator.language;
    if (this.useAppLocal) {
      lang = this.translateService.currentLang;
    }
    console.log('*****LANG-LOCAL****', lang);
    const currencyPipe = new CurrencyPipe(lang);
    const numberPipe = new NumberCustomPipe(this.translateService);

    const symbol = getCurrencySymbol(currency, 'narrow'); // currencyPipe.transform(0, currency, 'symbol').split('0.00')[0];
    const numberIndex = currencyPipe.transform(0, currency, 'symbol').indexOf('0');

    const numberValue = numberPipe.transform(s, this.useAppLocal);

    let retValue = numberValue + AmountPipe.suffixSpaceCheck(lang) + suffix;

    if (numberIndex === 0) {
      retValue = retValue + symbol;
    } else if (numberIndex > 0) {
      retValue = symbol + retValue;
    }
    //const value = s +  + suffix;
    console.log('*****VALUE****', retValue);
    return retValue;
  }

  private static suffixSpaceCheck(lang: string) {
    switch (lang) {
      case 'fr':
      case 'fr-FR':
        return ' ';
      default:
        return '';
    }
  }
}
