import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "@ngx-config/core";
import {VesConfService} from "./conf.service";
import {Observable} from "rxjs";
import {ApiHttpService} from "../api.http.service";
import {ApprovalProcess, ApprovalProcessVersion} from "../../models/vestrata/ProductApproval";

@Injectable({
  providedIn: 'root'
})
export class ApprovalProcessService {
  private static readonly ROOT_ENDPOINT = 'approval-process/';
  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getAll(): Observable<ApprovalProcess[]> {
    return this.http.get<ApprovalProcess[]>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT, ApiHttpService.getHeaders());
  }

  getAllApproved(): Observable<ApprovalProcess[]> {
    return this.http.get<ApprovalProcess[]>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + 'approved', ApiHttpService.getHeaders());
  }

  getById(salesMeetingCategoryId: string): Observable<ApprovalProcess> {
    return this.http.get<ApprovalProcess>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + salesMeetingCategoryId,
      ApiHttpService.getHeaders());
  }
/*  getById(salesMeetingCategoryId: string): Observable<SalesMeeting> {
    return this.http.get<SalesMeeting>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + salesMeetingCategoryId,
      ApiHttpService.getHeaders());
  }*/

  createSalesMeetingCategory(category: ApprovalProcess): Observable<ApprovalProcess> {
    return this.http.post<ApprovalProcess>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT , category, ApiHttpService.getHeaders());
  }

  updateSalesMeetingCategory(category: ApprovalProcess): Observable<ApprovalProcess> {
    return this.http.put<ApprovalProcess>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + category.id ,
      category.pending.data, ApiHttpService.getHeaders());
  }

  deleteSalesMeetingCategory(category: ApprovalProcess): Observable<void> {
    return this.http.delete<void>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + category.id,
      ApiHttpService.getHeaders());
  }

  submitSalesMeetingCategoryForApproval(categoryId: string): Observable<ApprovalProcess> {
    return this.http.post<ApprovalProcess>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + 'submit/' + categoryId,
      ApiHttpService.getHeaders());
  }

  resetSalesMeetingCategoryForApproval(categoryId: string): Observable<ApprovalProcess> {
    return this.http.post<ApprovalProcess>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + 'reset/' + categoryId,
      ApiHttpService.getHeaders());
  }

  cancelSalesMeetingCategoryApproval(categoryId: string): Observable<ApprovalProcess> {
    return this.http.post<ApprovalProcess>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + 'unlock/' + categoryId,
      ApiHttpService.getHeaders());
  }

  approveSalesMeetingCategoryChange(categoryId: string): Observable<ApprovalProcess> {
    return this.http.post<ApprovalProcess>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + 'approve/' + categoryId,
      ApiHttpService.getHeaders());
  }

  declineSalesMeetingCategoryChange(categoryId: string, note: string): Observable<ApprovalProcess> {
    return this.http.post<ApprovalProcess>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + 'decline/' + categoryId,
      note,  ApiHttpService.getHeaders());
  }

  getCategoryLocalizedLabel(category: ApprovalProcessVersion, lang: string, fallbackEnglish: boolean = false): string {
    let label: string;
    if (category) {
      const lCategory = category.localized.find(c => c.lang === lang);
      if (fallbackEnglish) {
        label = lCategory ? lCategory.name : category.localized.find(c => c.lang === 'en').name;
      } else {
        label = lCategory ? lCategory.name : null;
      }
    }
    return label;
  }

  // 4eyes Deletion
  submitDeletionCategory(categoryId: string): Observable<ApprovalProcess> {
    return this.http.post<ApprovalProcess>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + 'delete/submit/' + categoryId,
      ApiHttpService.getHeaders());
  }
  approveDeletionCategory(categoryId: string): Observable<void> {
    return this.http.post<void>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + 'delete/approve/' + categoryId,
      ApiHttpService.getHeaders());
  }
  declineDeletionCategory(categoryId: string): Observable<ApprovalProcess> {
    return this.http.post<ApprovalProcess>(this.serviceUrl + ApprovalProcessService.ROOT_ENDPOINT + 'delete/decline/' + categoryId,
      ApiHttpService.getHeaders());
  }
}
