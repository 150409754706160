import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Permission} from '../../models/vestrata/Permission';
import {combineLatest, forkJoin, of, Subject} from 'rxjs';
import {Role, Specialty} from '../../models/vestrata/Role';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {Organisation} from '../../models/vestrata/Organisation';
import {User, UserVersion} from '../../models/vestrata/User';
import {Version, VersionStatus} from '../../models/vestrata/Version';
import {NavigationData} from '../../basic-components/unknown-components/navigation-menu/navigation-menu.component';
import {Portal} from '../../models/vestrata/Portal';
import {OrganisationTeam} from '../../models/vestrata/OrganisationTeam';
import {CommonService} from '../../api/services/common.service';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {UserService} from '../../api/services/user.service';
import {UtilsService} from '../../api/utils.service';
import {NavigationMenuService} from '../../basic-components/unknown-components/navigation-menu/navigation-menu.service';
import {SharedSnackbarService} from '../shared-snackbar/services/shared-snackbar.service';
import {OrganisationTeamService} from '../../api/services/organisation-team.service';
import {AuthenticationService} from '../../api/services/authentication.service';
import {SnackbarMessage} from '../shared-snackbar/models/snackbar-message';
import {Location} from '@angular/common';
import {cloneDeep, isEqual} from 'lodash';
import {AdminService} from '../../api/services/admin.service';

@Component({
  selector: 'ves-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.scss']
})
export class UserMaintenanceComponent implements OnInit, OnDestroy {

  private _onDestroy = new Subject();
  readonly PRODUCT_SPECIALIST_VALUE = Role.PRODUCT_SPECIALIST.value;

  @Input() permission: Permission;

  organisation: Organisation;
  user: User;
  password: string;
  passwordConfirmation: string;
  ownUser = false;
  isAdmin = false;

  // 4EYES //
  editingUser: Version<UserVersion>;
  pendingUser: Version<UserVersion>;
  currentUser: Version<UserVersion>;
  navigationData: NavigationData = new NavigationData();

  // UI
  showPanel = false;
  displayRoles = false;
  displayTeams = false;
  readonly PORTAL;
  portalSelected: Portal;
  rightPanelTitle: string;
  roles: Role[];
  specialties: Specialty[];
  teams: OrganisationTeam[];
  ipsEnabled = false;
  advisorEnabled = false;
  partnerEnabled = false;
  editingEmail = '';
  viewResetPwd = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private common: CommonService,
              private loader: SharedLoaderService,
              private translate: TranslateService,
              private userService: UserService,
              private utilsService: UtilsService,
              private location: Location,
              private menuService: NavigationMenuService,
              private snackbar: SharedSnackbarService,
              public teamService: OrganisationTeamService,
              private adminService: AdminService,
              private authService: AuthenticationService) {
    this.ownUser = this.route.snapshot.data.ownUser;
    this.isAdmin = this.route.snapshot.data.isAdmin;
    console.log('******* IS ADMIN ******');
    console.log(this.isAdmin);
    this.specialties = this.sortSpecialities(Specialty.values());
    this.PORTAL = Portal;
    this.translate.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe(event => {
      if (event.lang) {
        this.specialties = this.sortSpecialities(this.specialties);
      }
    });
  }

  private static getNewUser(organisationId: string): User {
    const user = new User();
    user.email = '';
    user.organisationId = organisationId;
    user.pending = new Version<UserVersion>();
    user.pending.data = new UserVersion();
    return user;
  }

  ngOnInit(): void {
    this.initUser();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.menuService.resetNavigationData();
  }

  isLocked() {
    if (!this.permission?.write && !this.isAdmin && !this.ownUser) {
      return true;
    }
    return this.user?.pending?.status === VersionStatus.REQUEST ||
      this.user?.pending?.status === VersionStatus.DELETE;
  }

  updatedEnable(event: boolean, portal: Portal) {
    if (!portal) {
      return;
    }

    if (event) {
      if (!this.editingUser.data.portalsEnabled) {
        this.editingUser.data.portalsEnabled = [];
      }

      this.editingUser?.data?.portalsEnabled?.push(portal);
    } else {
      this.utilsService.removeFromIterable(this.editingUser?.data?.portalsEnabled, portal);
    }

    this.setPortalEnabledValues();
  }

  onReset() {
    this.loader.showBarLoader();
    this.userService.resetSavedUser(this.user.id).pipe(takeUntil(this._onDestroy), finalize(() => {
      this.loader.dismissLoader();
    })).subscribe(result => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.success-reset')));
      this.setup4Eyes(result);
    }, err => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.' + err.error.message), 'error'));
    });
  }

  getPlatformRoles(platform: Portal): Role[] {
    return this.userService.sortRoles(this.userService.getRolesByPlatform(platform, this.roles));
  }

  isPlatformRole(roleValue: string, platform: Portal) {
    return !!this.getPlatformRoles(platform).find(r => r.value === roleValue);
  }

  assignRole(platform: Portal) {
    if (this.canAssign(platform)) {
      this.showPanel = true;
      this.displayRoles = true;
      this.displayTeams = false;
      this.portalSelected = platform;
      this.rightPanelTitle = 'user.assign-roles';
    }

  }

  assignTeam(platform: Portal) {
    if (this.canAssign(platform)) {
      this.showPanel = true;
      this.displayRoles = false;
      this.displayTeams = true;
      this.portalSelected = platform;
      this.rightPanelTitle = 'user.assign-teams';
    }
  }

  assign(platform: Portal) {
    if (this.canAssign(platform)) {
      this.showPanel = true;
      this.displayRoles = true;
      this.displayTeams = true;
      this.portalSelected = platform;
      this.rightPanelTitle = 'user.assign-roles';
    }
  }

  private canAssign(portal: Portal): boolean {
    let assign = false;
    switch (portal) {
      case Portal.IPS: assign = this.ipsEnabled; break;
      case Portal.ADVISOR: assign = this.advisorEnabled; break;
      case Portal.PARTNER: assign = this.partnerEnabled; break;
    }
    return assign;
  }

  confirmRoles() {
    this.showPanel = false;
  }

  openRole(role: string) {
    // TODO
  }

  checkRights() {
    // TODO
  }

  hasRole(role: Role, version: UserVersion) {
    if (!role || !version) {
      return false;
    }
    return !!version.roles?.find((r) => r === role.value);
  }

  hasSpecialty(specialty: Specialty, version: UserVersion) {
    if (!specialty || !version) {
      return false;
    }
    return version.specialties?.includes(specialty); // Wont work if specialty is converted to a complex object.
  }

  roleChange(role: Role, add: boolean) {
    if (add) {
      if (this.editingUser.data.roles) {
        this.editingUser.data.roles.push(role.value);
      } else {
        this.editingUser.data.roles = [role.value];
      }
    } else {
      this.utilsService.removeFromIterable(this.editingUser.data.roles, role.value);
    }
  }

  specialtyChange(specialty: Specialty, add: boolean) {
    if (add) {
      if (this.editingUser.data.specialties) {
        this.editingUser.data.specialties.push(specialty);
      } else {
        this.editingUser.data.specialties = [specialty];
      }
    } else {
      this.utilsService.removeFromIterable(this.editingUser.data.specialties, specialty);
    }

    if (this.editingUser.data.specialties.length === 0) {
      if (this.hasRole(Role.PRODUCT_SPECIALIST, this.editingUser.data)) {
        this.utilsService.removeFromIterable(this.editingUser.data.roles, Role.PRODUCT_SPECIALIST.value);
      }
    } else {
      if (!this.hasRole(Role.PRODUCT_SPECIALIST, this.editingUser.data)) {
        this.editingUser.data.roles.push(Role.PRODUCT_SPECIALIST.value);      }
    }
  }

  getUserTeamsByPlatform(platform: Portal): OrganisationTeam[] {
    const userTeams = this.editingUser.data.teams;
    const platformTeams = this.getTeamsByPlatform(platform);
    return platformTeams.filter(p => userTeams?.includes(p.id));
  }

  getTeamsByPlatform(platform: Portal): OrganisationTeam[] {
    // Equals ignore case
    return this.teams.filter(t => t.current.data.type.localeCompare(platform.toString(), undefined, {sensitivity: 'base'}) === 0);
  }

  hasTeam(team: OrganisationTeam, version: UserVersion) {
    if (!team || !version) {
      return false;
    }
    return version.teams?.includes(team.id);
  }

  teamChange(team: OrganisationTeam, add: boolean) {
    if (add) {
      if (this.editingUser.data.teams) {
        this.editingUser.data.teams.push(team.id);
      } else {
        this.editingUser.data.teams = [team.id];
      }
    } else {
      this.utilsService.removeFromIterable(this.editingUser.data.teams, team.id);
    }
  }

  private initUser() {
    combineLatest([
      this.common.getOrganisation(),
      this.route.queryParamMap,
      this.isAdmin ? of([]) : this.teamService.getAllApproved(),
      this.userService.getRoles()])
      .pipe(
        tap(() => this.loader.showFullLoader()),
        takeUntil(this._onDestroy),
        map(data => {
          console.log(data);
          this.teams = data[2];
          this.roles = this.userService.sortRoles(data[3]);
          return data;
        }),
        switchMap(data => {
          console.log(data);
          const params = data[1];
          const id = params.get('id');
          const $organisation = params.get('org') ?
            this.adminService.getOrganisationById(params.get('org')) :
            of(data[0]);
          const organisationId =  params.get('org') ? params.get('org') : data[0].id;
          if (this.ownUser) {
            return forkJoin([this.userService.getOwnUser(), $organisation]);
          }

          if (id) {
            return forkJoin([this.userService.getUserById(id), $organisation]);
          } else {
            return forkJoin([of(UserMaintenanceComponent.getNewUser(organisationId)), $organisation]);
          }
        })).subscribe(data => {
      console.log(data);
      this.user = data[0];
      this.organisation = data[1];
      if (this.user?.keycloakId === this.authService.getMyId()) {
        this.ownUser = true;
      }
      if (this.ownUser) {
        this.permission = {read: true, write: true, approve: false, publish: false};
      }

      if (this.isAdmin) {
        this.permission = {read: true, write: true, approve: false, publish: true};
      }

      this.setup4Eyes(this.user);
      this.loader.dismissLoader();
    }, error => {
      console.log(error);
      if (!this.ownUser) {
        this.router.navigate(['..'], {relativeTo: this.route});
        this.snackbar.push(new SnackbarMessage(this.translate.instant('user.unable'), 'error'));
      }
      this.loader.dismissLoader();
    });

  }

  onCancel() {
    this.location.back();
  }

  onSave() {
    if (this.validateUser()) {
      if (!this.user.pending) {
        this.user.pending = new Version<UserVersion>();
      }
      this.user.pending.data = cloneDeep(this.editingUser.data);
      this.loader.showBarLoader();
      if (this.ownUser) {
        this.loader.showBarLoader();
        this.userService.updateOwnUser(this.user)
          .pipe(takeUntil(this._onDestroy), finalize(() => {
            this.loader.dismissLoader();
          })).subscribe(result => {
          this.snackbar.push(new SnackbarMessage(this.translate.instant('user.success-updated')));
          this.setup4Eyes(result);
        }, err => {
          this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.' + err.error.message), 'error'));
        });

      } else {
        if (this.user.id) {
          // Update
          this.userService.updateUser(this.user).pipe(takeUntil(this._onDestroy),
            finalize(() => this.loader.dismissLoader())).subscribe(result => {
            this.snackbar.push(new SnackbarMessage(this.translate.instant('user.success-updated')));
            this.setup4Eyes(result);
          }, err => {
            // this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.' + err.error.message), 'error'));
          });
        } else {
          this.userService.createUser(this.user).pipe(takeUntil(this._onDestroy),
            finalize(() => this.loader.dismissLoader())).subscribe(result => {
            this.snackbar.push(new SnackbarMessage(this.translate.instant('user.success-created')));
            this.setup4Eyes(result);
          }, err => {
            this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.' + err.error.message), 'error'));
          });
        }
      }
    }

  }

  onSubmitForApproval() {
    this.loader.showBarLoader();
    this.userService.submitUserForApproval(this.user.id).pipe(takeUntil(this._onDestroy),
      finalize(() => this.loader.dismissLoader())).subscribe(result => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.success-submitted')));
      this.setup4Eyes(result);
    }, err => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.' + err.error.message), 'error'));
    });
  }

  onUnlock() {
    this.loader.showBarLoader();
    this.userService.cancelUserApproval(this.user.id).pipe(takeUntil(this._onDestroy),
      finalize(() => this.loader.dismissLoader())).subscribe(result => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.success-unlock')));
      this.setup4Eyes(result);
    }, err => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.' + err.error.message), 'error'));
    });
  }

  onApprove() {
    this.loader.showBarLoader();
    this.userService.approveUserChange(this.user.id).pipe(takeUntil(this._onDestroy),
      finalize(() => this.loader.dismissLoader())).subscribe(result => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.success-approve')));
      this.setup4Eyes(result);
    }, err => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.' + err.error.message), 'error'));
    });
  }

  onDecline(note) {
    this.loader.showBarLoader();
    this.userService.declineUserChange(this.user.id, note).pipe(takeUntil(this._onDestroy),
      finalize(() => this.loader.dismissLoader())).subscribe(result => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.success-reject')));
      this.setup4Eyes(result);
    }, err => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.' + err.error.message), 'error'));
    });
  }

  equals() {
    // inv-1279 can only save when user has been given access to a portal.
    const hasPortalAccess = this.ipsEnabled || this.advisorEnabled || this.partnerEnabled;
    this.navigationData.editingValue = hasPortalAccess && isEqual(this.editingUser, this.pendingUser) &&
      this.user.email === this.editingEmail;
    this.setMenuData();
    return this.navigationData.editingValue;
  }

  resetPassword(userId: string) {
    this.loader.showBarLoader();
    this.userService.resetUserPassword(userId).pipe(takeUntil(this._onDestroy),
      finalize(() => this.loader.dismissLoader())).subscribe(result => {
      this.setup4Eyes(result);
      this.viewResetPwd = false;
    }, error => {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('user-reset-password-error', 'error')));
    });
  }

  private setMenuData() {
    this.navigationData.permission = this.permission;
    this.navigationData.currentVersion = this.currentUser;
    this.navigationData.pendingVersion = this.pendingUser;
    this.navigationData.fourEyeView = true;
    this.navigationData.interface = this;
    this.menuService.setNavigationData(this.navigationData);
  }

  private setup4Eyes(user: User) {
    if (user) {
      this.user = user;
      this.editingEmail = this.user.email;
      this.pendingUser = this.user.pending;
      this.currentUser = this.user.current;
      this.password = '';
      this.passwordConfirmation = '';
      this.editingUser = cloneDeep(this.pendingUser ? this.pendingUser : this.currentUser);

      // Setup platform enabled
      this.setPortalEnabledValues();
    }
    this.setMenuData();
  }

  private sortSpecialities(specialties: Specialty[]): Specialty[] {
    return specialties.sort((a, b) =>
      this.translate.instant('specialty.' + a).localeCompare(this.translate.instant('specialty.' + b)));
  }

  private validateUser(): boolean {
    let valid = true;

    // Set organisation id to other organisation if there is some set.
    this.user.organisationId = this.organisation.id;

    // Check firstname
    if (!this.editingUser.data.firstName ||  this.editingUser.data.firstName === '') {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.missing-firstname'), 'error'));
      valid =  false;
    }

    // Check lastname
    if (!this.editingUser.data.lastName ||  this.editingUser.data.lastName === '') {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.missing-lastname'), 'error'));
      valid =  false;
    }

    // Check mail
    if ((!this.user.email ||  this.user.email  === '') && !this.editingEmail) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.missing-email'), 'error'));
      valid =  false;
    } else {
      this.user.email = this.editingEmail;
    }

    if (this.organisation.approvedDomains && this.organisation.approvedDomains.length > 0) {
      let domainOk  = false;
      this.organisation.approvedDomains.forEach(domain => {
        if (this.user.email.includes('@' + domain)) {
          domainOk = true;
        }
      });
      if (!domainOk) {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.invalid-domain'), 'error'));
        valid = false;
      }
    } else {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.no-valid-domains'), 'error'));
      valid = false;
    }

    if (!this.user.id) {
      if (!this.password || this.password.length === 0) {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.password-needed-for-new-user'), 'error'));
        valid =  false;
      }
    }

    if (this.password && this.password.length > 0) {
      if (this.password !== this.passwordConfirmation ) {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.passwords-not-match'), 'error'));
        valid =  false;
      } else {
        this.user.password = this.password;
      }
    }

    if (!this.ipsEnabled && !this.advisorEnabled && !this.partnerEnabled && this.organisation.name !== 'Vestrata') {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('user.error.no-access-given'), 'error'));
      valid =  false;
    }

    return valid;
  }

  private setPortalEnabledValues() {
    this.ipsEnabled = this.editingUser?.data?.portalsEnabled?.includes(Portal.IPS);
    this.advisorEnabled = this.editingUser?.data?.portalsEnabled?.includes(Portal.ADVISOR);
    this.partnerEnabled = this.editingUser?.data?.portalsEnabled?.includes(Portal.PARTNER);
  }


}
