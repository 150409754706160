export class SearchRequest {
    criteria: object;
    sorts: Sorting[] = [];
    paging: Paging;
    text: string;
}
export class Paging {
    page: number;
    size: number;
}
export class Sorting {
    property: string;
    direction: number;
}
