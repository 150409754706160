export class CostCalculation {
    initialInvestment: number;
    initialValue: number;
    grossBenefit: number;
    grossValue: number;
    netValue: number;
    netBenefit: number;
    productFees: number;
    productFeeRatio: number;
    initialFees: number;
    subAdvisorFees: number;
    bankManagementFees: number;
    bankManagementFeeRatio: number;
    bankCustodyFees: number;
    totalFees: number;
    bankCustodyFeeRatio: number;
    reductionInReturnValue: number;
    reductionInReturnPercent: number;
}
