import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FourEyeComponent} from '../../4eye-component';
import {TranslateService} from '@ngx-translate/core';
import {VersionStatus} from '../../../models/vestrata/Version';

@Component({
  selector: 'ves-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent extends FourEyeComponent<boolean> implements OnInit, OnChanges {

  @Input() label: string;
  @Input() oneActive: boolean;
  constructor(private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    // check if the label is a translation key eg insight.name
    if (this.label && this.label.indexOf('.') > -1) {
      this.label = this.translate.instant(this.label);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes ) {
    }
  }


  equals(a: boolean, b: boolean): boolean {
    const x = Boolean(a);
    const y = Boolean(b);
    return x === y;
  }

  onClick(): void {
    if ((!this.getState().readonly && this.fourEyesMode) || (!this.fourEyesMode && !this.locked)) {
      this._editingValue = ! this._editingValue;
      this.editingValueChange.emit(this._editingValue);
    }
  }

}
