import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import {StoreModule} from "@ngrx/store";
import * as fromAlert from 'vestrata-components-lib';
import * as fromNotification from 'vestrata-components-lib';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    CoreModule,
    AppRoutingModule,
    StoreModule.forRoot({
      retrieveAlerts: fromAlert.retrieveAlertsReducer, reloadAlerts: fromAlert.reloadAlertReducer,
      retrieveNotifications: fromNotification.retrieveNotificationsReducer, reloadNotifications: fromNotification.reloadNotificationReducer})
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
