import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Permission} from '../../../models/vestrata/Permission';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ves-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit, OnChanges {

  @Input() options: string[] = [];
  @Input() filterLimit = 3;
  @Input() id: string;
  @Input() public labelName: string;
  @Input() public placeholderMsg: string;
  @Input() locked = false;
  @Input() permission: Permission;

  @Output() selectTag = new EventEmitter<string>();

  filteredOptions: string[] = [];
  textString = '';
  isOpen = false;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    if (!this.permission.write) {
      this.locked = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      // console.log(changes);
      this.sortOptions();
    }
  }

  onClick(item) {
    this.isOpen = false;
    this.selectTag.emit(item);
    this.textString = '';
  }

  sortOptions() {
    this.options.sort();
  }

  filterOptions() {
    // only display options once they have entered at least 3 chars.
    if (this.textString.length > 0) {
      this.isOpen = true;

      this.filteredOptions = [];
      const exact = this.options.find(o => o.toLowerCase() === this.textString.toLowerCase());
      if (exact) { this.filteredOptions.push(exact); }
      const startsWith = this.options.filter(o => o.toLowerCase().startsWith(this.textString.toLowerCase()));
      startsWith.forEach(option => {
        if (option.toLowerCase().includes(this.textString.toLowerCase()) && this.filteredOptions.length < this.filterLimit) {
          if (this.filteredOptions.indexOf(option) === -1) {
            this.filteredOptions.push(option);
          }
        }
      });
      if (this.filteredOptions.length < this.filterLimit) {
        this.options.forEach(option => {
          if (option.toLowerCase().includes(this.textString.toLowerCase()) && this.filteredOptions.length < this.filterLimit) {
            if (this.filteredOptions.indexOf(option) === -1) {
              this.filteredOptions.push(option);
            }
          }
        });
      }
    } else {
      this.isOpen = false;
    }
  }

  getTranslation(text) {
    return this.translate.instant(text);
  }

  notFound() {
    this.isOpen = false;
  }
}
