import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BenchmarkPercentage} from "../../../models/vestrata/Benchmark";

@Component({
  selector: 'ves-benchmark-selection-dialog',
  templateUrl: './shared-benchmark-selection-dialog.component.html',
  styleUrls: ['./shared-benchmark-selection-dialog.component.scss']
})
export class SharedBenchmarkSelectionDialogComponent implements OnInit {
  @Input() viewDialog = false;
  @Input() withRemainingTags = false;
  @Input() withPercentage = false;
  @Input() isMultiSelect = false;
  @Input() selectedElements: BenchmarkPercentage[] =[];
  @Input() selectedBenchmarkIds: string[] =[];
  @Input() monoSelectedId: string;
  @Input() currency: string; // Filter only one currency.
  @Input() defaultCurrency: string; // Use to know if benchmark are aligned with object currency.
  @Input() onlyAssetSchema = false;

  @Output() saveEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  emitSelectedElements() {
    this.saveEvent.emit(this.selectedElements);
  }

  onClose() {
    this.closeEvent.emit();
  }
}
