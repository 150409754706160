import {Injectable} from "@angular/core";
import {Currency} from "../../models/vestrata/Currency";
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "@ngx-config/core";
import {VesConfService} from "./conf.service";
import {Observable} from "rxjs";
import {Organisation, OrganisationIpsDto} from "../../models/vestrata/Organisation";
import {ApiHttpService} from "../api.http.service";
import {Strategy} from "../../models/vestrata/Strategy";
import {AssetClass} from "../../models/vestrata/MajorAssetClass";

@Injectable({
  providedIn: 'root',
})
export class IpsAdvisorPartnerService {

  public serviceUrl: string;
  private globalCurrency: Currency[] = [];
  private globalAssetClasses: AssetClass[] = [];

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }


  getPartnerList(): Observable<OrganisationIpsDto[]> {
    return this.http.get<OrganisationIpsDto[]>(this.serviceUrl + 'partner', ApiHttpService.getHeaders());
  }

  getPartner(partnerId: string): Observable<Organisation> {
    return this.http.get<Organisation>(this.serviceUrl + 'partner/' + partnerId, ApiHttpService.getHeaders());
  }

  getStrategiesByPartner(partnerId: string): Observable<Strategy[]> {
    return this.http.get<Strategy[]>(this.serviceUrl + 'strategy/partner/' + partnerId, ApiHttpService.getHeaders());
  }

}
