import {Component, Input, OnInit} from '@angular/core';
import {TargetMarket} from '../../../models/vestrata/Instrument/Fund';

@Component({
  selector: 'ves-shared-instrument-target-market',
  templateUrl: './shared-instrument-target-market.component.html',
  styleUrls: ['./shared-instrument-target-market.component.scss']
})
export class SharedInstrumentTargetMarketComponent implements OnInit {

  @Input() targetMarket: TargetMarket;
  constructor() { }

  ngOnInit(): void {
  }

  checkRisk(type) {
    switch (type) {
      case 'srri': {
        if (this.targetMarket.riskSRRI && this.targetMarket.riskSRRI > 0) {
          return true;
        }
        break;
      }
      case 'sri': {
        if (this.targetMarket.riskSRI && this.targetMarket.riskSRI > 0) {
          return true;
        }
        break;
      }
      case 'srri': {
        if (this.targetMarket.riskInternalMethodology && this.targetMarket.riskInternalMethodology > 0) {
          return true;
        }
        break;
      }
      default: {
        return false;
      }
    }

  }

  checkHolding(type) {
    if (this.targetMarket?.recommendedHoldingPeriod && type) {
      console.log('********************* HOLDING PERIOD ************');
      console.log(this.targetMarket.recommendedHoldingPeriod);
      console.log(type);
      switch (type) {
        case this.targetMarket.recommendedHoldingPeriod: {
          return true;
        }
        case 'years': {
          if (this.targetMarket.recommendedHoldingPeriod !== 'L' && this.targetMarket.recommendedHoldingPeriod !== 'M' &&
            this.targetMarket.recommendedHoldingPeriod !== 'S' && this.targetMarket.recommendedHoldingPeriod !== 'V') {
            this.targetMarket.holdingPeriodYears = +this.targetMarket.recommendedHoldingPeriod;
            return true;
          } else {
            return false;
          }
        }
        default: {
          return false;
        }
      }
    }
}
}
