import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "@ngx-config/core";
import {VesConfService} from "./conf.service";
import {Observable} from "rxjs";
import {Market} from "../../models/vestrata/Market";
import {ApiHttpService} from "../api.http.service";
import {IndustrySector} from "../../models/vestrata/IndustrySector";

@Injectable({
  providedIn: 'root'
})
export class IndustrySectorService {

  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getAllIndustrySectors(): Observable<IndustrySector[]> {
    return this.http.get<IndustrySector[]>(this.serviceUrl + 'industrysector',  ApiHttpService.getHeaders());
  }

  getIndustryById(industryId: string): Observable<IndustrySector> {
    return this.http.get<IndustrySector>(this.serviceUrl + 'industrysector/' + industryId,  ApiHttpService.getHeaders());
  }
}
