import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngx-config/core';
import {VesConfService} from './conf.service';
import {Observable} from 'rxjs';
import {Page} from '../../models/vestrata/Page';
import {ApiHttpService} from '../api.http.service';
import {Research} from '../../models/vestrata/Research';

@Injectable({
  providedIn: 'root'
})
export class ResearchService {

  private static readonly ROOT_ENDPOINT = 'research/';
  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  search(searchRequest: any): Observable<Page<Research>> {
    return this.http.post<Page<Research>>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + 'search', searchRequest,
      ApiHttpService.getHeaders());
  }

  getById(instanceId: string): Observable<Research> {
    return this.http.get<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + instanceId,
      ApiHttpService.getHeaders());
  }

  getOwn(): Observable<Research[]> {
    return this.http.get<Research[]>(this.serviceUrl + ResearchService.ROOT_ENDPOINT,
      ApiHttpService.getHeaders());
  }

  create(instance: Research): Observable<Research> {
    return this.http.post<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT, instance,
      ApiHttpService.getHeaders());
  }

  update(instance: Research): Observable<Research> {
    return this.http.put<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + instance.id, instance.pending.data,
      ApiHttpService.getHeaders());
  }

  submitForApproval(instanceId: string): Observable<Research> {
    return this.http.post<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + 'submit/' + instanceId,
      ApiHttpService.getHeaders());
  }

  unlock(instanceId: string): Observable<Research> {
    return this.http.post<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + 'unlock/' + instanceId,
      ApiHttpService.getHeaders());
  }

  reset(instanceId: string): Observable<Research> {
    return this.http.post<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + 'reset/' + instanceId,
      ApiHttpService.getHeaders());
  }

  approveChange(instanceId: string): Observable<Research> {
    return this.http.post<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + 'approve/' + instanceId,
      ApiHttpService.getHeaders());
  }

  declineChange(instanceId: string, note: string): Observable<Research> {
    return this.http.post<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + 'decline/' + instanceId,
      note,  ApiHttpService.getHeaders());
  }

  submitDelete(instanceId: string): Observable<Research> {
    return this.http.post<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + 'delete/submit/' + instanceId,
      ApiHttpService.getHeaders());
  }

  approveDelete(instanceId: string): Observable<Research> {
    return this.http.post<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + 'delete/approve/' + instanceId,
      ApiHttpService.getHeaders());
  }

  declineDelete(instanceId: string, note: string): Observable<Research> {
    return this.http.post<Research>(this.serviceUrl + ResearchService.ROOT_ENDPOINT + 'delete/decline/' + instanceId,
      note,  ApiHttpService.getHeaders());
  }
}
