import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { Chart } from 'chart.js';
import {CostCalculator} from '../../../models/vestrata/CostCalculator';
import {CostCalculation} from '../../../models/vestrata/CostCalculation';

@Component({
  selector: 'ves-cost-chart',
  templateUrl: './cost-chart.component.html',
  styleUrls: ['./cost-chart.component.scss']
})
export class CostChartComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('canvas') canvas: ElementRef;
  private chart: Chart;

  @Input()
  costs: CostCalculation;

  @Input()
  type: 'amount' | 'costs' = 'amount';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.costs && changes.costs.currentValue) {
      this.chart = this.initChart(this.costs, this.type);
    }
  }

  ngAfterViewInit(): void {
    if (this.costs) {
      this.chart = this.initChart(this.costs, this.type);
    }

  }

  initChart(costs: CostCalculation, type: 'amount' | 'costs'): Chart {
    let values;
    let chartLabels;
    let colors;
    if ( type === 'amount' ) {
      values = [costs.initialValue, costs.grossValue , costs.netValue, costs.reductionInReturnValue];
      colors = ['#003057', '#00C4B3', '#248ED8', '#EFF1F2'];
      chartLabels = ['Amount after initial costs', 'Amount without charges', 'Amount after charges', 'Return value'];
    } else {
      if (costs.bankManagementFees && costs.bankManagementFees !== 0) {
        let bankFees: number;
        if (costs.bankCustodyFees && costs.bankCustodyFees !== 0) {
          bankFees = costs.bankManagementFees + costs.bankCustodyFees;
        } else {
          bankFees = costs.bankManagementFees;
        }
        values = [costs.initialFees, costs.productFees, costs.subAdvisorFees, bankFees];
        colors = ['#a7c1f5', '#003057', '#6082c6', '#248ED8'];
        chartLabels = ['Initial costs', 'Estimated Product Fees', 'Sub-advisor Fees', 'Bank Fees'];
      } else {
        values = [costs.initialFees, costs.productFees, costs.subAdvisorFees];
        colors = ['#a7c1f5', '#003057', '#6082c6'];
        chartLabels = ['Initial costs', 'Estimated Product Fees', 'Sub-advisor Fees'];
      }
    }

    const set = [];
    for (let i = 0; i < values.length; i++) {
      let stackItem = i;

      if (this.type === 'amount') {
        if (i >= values.length - 2) {
          stackItem = values.length;
        }
      }

      set.push({
        label: chartLabels[i],
        data: [values[i]],
        backgroundColor: colors[i],
        stack: stackItem
      });
    }
    if (this.canvas == null) { return null; }
    return new Chart(this.canvas.nativeElement.getContext('2d'), {
      type: 'bar',
      data: {
        datasets: set
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          displayColors: true,
          callbacks: {
            title() {},
            label(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const index = tooltipItem.index;
              return dataset.label + ': ' + dataset.data[index];
            }
          }
        },
        // responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              fontColor: '#000000',
              fontFamily: 'Source Sans Pro'
            },
          }],
          xAxes: [{
            gridLines: {
              zeroLineColor: 'rgba(0, 0, 0, 0)',
            },
            categoryPercentage: 0.8,
            barPercentage: 0.8,
          }]
        }
      }
    });
  }
}
