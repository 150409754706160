import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SafeResourceUrl} from "@angular/platform-browser";
import {MARKETING_MATERIALS_TYPE} from "../../models/vestrata/TradeIdea";
import {VestrataDocument} from "../../models/vestrata/VestrataDocument";

@Component({
  selector: 'ves-shared-marketing-materials-card',
  templateUrl: './shared-marketing-materials-card.component.html',
  styleUrls: ['./shared-marketing-materials-card.component.scss']
})
export class SharedMarketingMaterialsCardComponent implements OnInit {
  @Input() marketingMaterials = [];
  @Input() hasView: boolean;
  @Input() hasDelete: boolean;
  @Input() hasUpload: boolean;
  @Output() viewFileEvent = new EventEmitter<any>();
  @Output() deleteFileEvent = new EventEmitter<any>();
  @Output() addFileEvent = new EventEmitter<any>();
  viewFileDialog = false;
  docDialogMode = 'view';
  documentIdToView: string;
  tempUploadedDocument: VestrataDocument;

  constructor() { }

  ngOnInit(): void {}

  openFile(doc) {
    this.viewFileEvent.emit([
      this.docDialogMode = 'view',
      this.documentIdToView = doc,
      this.viewFileDialog = true
    ]);
  }

  deleteFile(doc) {
    this.deleteFileEvent.emit(doc);
  }

  addFile() {
    this.addFileEvent.emit(true);
  }

  imgTypeMaterials(type: string, imgSize: string) {
    let bigImg: string;
    let smallImg: string;

    switch (type) {
      case MARKETING_MATERIALS_TYPE.PDF:
        bigImg = '../../../assets/images/components/components-24/marketing/placeholder-thumbnail-pdf.svg';
        smallImg = '../../../assets/images/components/shared/icon-media-document.svg';
        break;
      case MARKETING_MATERIALS_TYPE.EXCEL:
        bigImg = '../../../assets/images/components/components-24/marketing/placeholder-thumbnail-excel.svg';
        smallImg = '../../../assets/images/components/shared/icon-media-document.svg';
        break;
      case MARKETING_MATERIALS_TYPE.WORD:
        bigImg = '../../../assets/images/components/components-24/pictogram-media.svg';
        smallImg = '../../../assets/images/components/components-24/pictogram-media.svg';
        break;
      case MARKETING_MATERIALS_TYPE.POWERPOINT:
        bigImg = '../../../assets/images/components/components-24/marketing/placeholder-thumbnail-powerpoint.svg';
        smallImg = '../../../assets/images/components/shared/icon-media-document.svg';
        break;
      case MARKETING_MATERIALS_TYPE.AUDIO:
        bigImg = '../../../assets/images/components/components-24/marketing/placeholder-thumbnail-audio.svg';
        smallImg = '../../../assets/images/components/shared/icon-media-audio.svg';
        break;
      case MARKETING_MATERIALS_TYPE.VIDEO:
        bigImg = '../../../assets/images/components/components-24/marketing/placeholder-thumbnail-video.svg';
        smallImg = '../../../assets/images/components/shared/icon-media-video.svg';
        break;
      case MARKETING_MATERIALS_TYPE.WEBLINK:
        bigImg = '../../../assets/images/components/components-24/marketing/placeholder-thumbnail-weblink.svg';
        smallImg = '../../../assets/images/components/components-2/icon-weblink.svg';
        break;
      case MARKETING_MATERIALS_TYPE.SLIDESHOW:
        bigImg = '../../../assets/images/components/components-24/marketing/placeholder-thumbnail-slideshow.svg';
        smallImg = '../../../assets/images/components/shared/icon-media-images.svg';
        break;
      default:
        bigImg = '../../../assets/images/components/components-24/pictogram-media.svg'
        smallImg = '../../../assets/images/components/components-24/pictogram-media.svg';
    }
    if (imgSize === 'big') {
      return bigImg;
    } else {
      return smallImg;
    }
  }
}
