import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Instrument, InstrumentInfo} from '../../models/vestrata/Instrument/Instrument';
import {CommonService} from '../../api/services/common.service';
import {AssetClass} from '../../models/vestrata/MajorAssetClass';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Benchmark} from "../../models/vestrata/Benchmark";
import {BenchmarkInfo} from "../../models/vestrata/BenchmarkInfo";

@Component({
  selector: 'ves-asset-class',
  templateUrl: './asset-class.component.html',
  styleUrls: ['./asset-class.component.scss']
})
export class AssetClassComponent implements OnInit, OnChanges {

  @Input() mode: 'sticker-xl' | 'sticker-xs' | 'sticker-s' | 'sticker-m' |'linear' = 'linear';

  @Input() majorAssetClass: string;

  @Input() minorAssetCLass: string;

  // Input instrument will be priorized compare to string asset classes.
  @Input() instrument: Instrument | InstrumentInfo;

  @Input() benchmark: Benchmark | BenchmarkInfo;

  @Input() assetClasses: AssetClass[] = [];

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.instrument) {
      this.majorAssetClass = this.commonService.getMajorAssetClassFromInstrument(this.instrument);
      this.minorAssetCLass = this.commonService.getMinorAssetClassFromInstrument(this.instrument);
    }

    if (changes.benchmark) {
      this.majorAssetClass = this.commonService.getMajorAssetClassFromBenchmark(this.benchmark);
      this.minorAssetCLass = this.commonService.getMinorAssetClassFromBenchmark(this.benchmark);
    }
  }

  getMajorAssetClassColor(major: string, light = false) {
    if (!this.assetClasses) {
      return '';
    }
    const ac = this.assetClasses.find(item => item.name === major);
    return ac ? light ? ac.majorLightColor : ac.majorColor : '';
  }

  getAssetClassImage() {
    let image;
    if (this.assetClasses.length) {
      this.assetClasses.forEach(item => {
        switch (item.name) {
          case item.name = 'Equities': image = '../../../assets/images/components/instruments/instrument-search/Equities-Asset-Class.svg'; break;
          case item.name = 'Fixed Income': image = '../../../assets/images/components/instruments/instrument-search/Fixed-Income-Sub-Asset-Class.svg'; break;
          case item.name = 'Miscellaneous': image = '../../../assets/images/components/instruments/instrument-search/Miscellaneous-Sub-Asset-Class.svg'; break;
          case item.name = 'Commodities': image = '../../../assets/images/components/instruments/instrument-search/Commodities-Sub-Asset-Class.svg'; break;
          case item.name = 'Money Market': image = '../../../assets/images/components/instruments/instrument-search/Money-Market-Sub-Asset-Class.svg'; break;
          case item.name = 'Alternatives': image = '../../../assets/images/components/instruments/instrument-search/Alternatives-Sub-Asset-Class.svg'; break;
          case item.name = 'Cash': image = '../../../assets/images/components/instruments/instrument-search/Cash-Sub-Asset-Class.svg'; break;
        }
      });
      return image;
    }
  }

}
