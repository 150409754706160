import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Paging, SearchRequest} from '../../../models/vestrata/SearchRequest';
import {InsightMongoSearch} from '../../../models/vestrata/Insight';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {SnackbarMessage} from '../../shared-snackbar/models/snackbar-message';
import {TranslateService} from '@ngx-translate/core';
import {SharedLoaderService} from '../../shared-loader/service/shared-loader.service';
import {ActivatedRoute} from '@angular/router';
import {NavigationData} from '../../../basic-components/unknown-components/navigation-menu/navigation-menu.component';
import {NavigationMenuService} from '../../../basic-components/unknown-components/navigation-menu/navigation-menu.service';
import {AuthenticationService} from '../../../api/services/authentication.service';
import {ArticleType} from '../../../models/vestrata/Article';
import {ArticleService} from '../../../api/services/article.service';

@Component({
  selector: 'ves-shared-insights-search',
  templateUrl: './shared-insights-search.component.html',
  styleUrls: ['./shared-insights-search.component.scss']
})
export class SharedInsightsSearchComponent implements OnInit, OnChanges, OnDestroy {

  @Input() searchText = '';
  @Input() organisationId: string;
  type: ArticleType;

  searchCriteria: SearchRequest;

  articles: any[] = []; // Insight / Research / Tutorials
  loadNumber: number;
  remaining: number;
  loadMoreNumber: number;
  currentPage = 0;

  private _onDestroy = new Subject();
  private navigationData = new NavigationData();

  constructor(private authService: AuthenticationService,
              private articleService: ArticleService,
              private snackbar: SharedSnackbarService,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private menuService: NavigationMenuService,
              private loader: SharedLoaderService) {
    this.type = this.route.snapshot.data.type;
  }


  ngOnInit() {
    if (!this.organisationId) {
      this.organisationId = this.authService.getOrganisationId();
    }

    this.route.queryParamMap.pipe(takeUntil(this._onDestroy)).subscribe(params => {
      this.searchText = params.get('search');
      this.newSearch();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.searchText) {
      this.newSearch();
    }
  }

  newSearch() {
    this.articles = [];
    this.searchCriteria = new SearchRequest();
    this.searchCriteria.paging = new Paging();
    this.searchCriteria.paging.page = 0;
    this.searchCriteria.paging.size = 8;
    this.searchCriteria.criteria = {};

    if (this.type === ArticleType.INSIGHT) {
      this.searchCriteria.criteria[InsightMongoSearch.curations] = {
        $elemMatch: {
          bankId: this.organisationId,
          enabled: true
        }
      };
    }
    this.searchCriteria.sorts.push({property: 'curations.promoted', direction: -1});
    this.searchCriteria.sorts.push({property: 'current.data.startDate', direction: -1});
    this.loadMoreNumber = this.searchCriteria.paging.size;
    this.searchCriteria.text = this.searchText;
    this.executeLoad();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.menuService.resetNavigationData();
  }

  loadMore() {
    this.currentPage++;
    this.searchCriteria.paging.page = this.currentPage;
    this.executeLoad();
  }

  executeLoad() {
    this.loader.showBarLoader();
    this.articleService.search(this.searchCriteria, this.type)
      .pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe(results => {
      this.articles.push(...results.content);
      this.loadNumber = this.articles.length;
      this.remaining = results.totalElements - this.loadNumber;
      if (this.remaining < this.searchCriteria.paging.size) {
        this.loadMoreNumber = this.remaining;
      }
      this.navigationData.pageTitle = this.setPageTitle(results.totalElements);
      this.menuService.setNavigationData(this.navigationData);
    }, err => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('insight-search.error.' + err.error.message), 'error'));
    });
  }

  private setPageTitle(elementNumber: number) {
    let title = '';
    if (elementNumber) {
      title += elementNumber + ' | ';
      title += elementNumber > 1 ? this.translate.instant('insight-search.insights')
        : this.translate.instant('insight-search.insight');
    }
    return title;
  }
}
