import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {finalize, flatMap, switchMap, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {IpsAdvisorPartnerService} from '../../api/services/ipsadvisor.partner.service';
import {StrategyService} from '../../api/services/strategy.service';
import {ModelService} from '../../api/services/model.service';
import {Organisation} from '../../models/vestrata/Organisation';
import {Strategy, StrategyFilter} from '../../models/vestrata/Strategy';
import {PortfolioModelListDto} from '../../models/vestrata/PortfolioModelListDto';
import {SharedSnackbarService} from '../shared-snackbar/services/shared-snackbar.service';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {SnackbarMessage} from '../shared-snackbar/models/snackbar-message';
import {
  BlocOption,
  FilterOption
} from '../../basic-components/components-5/two-level-bar-filter/two-level-bar-filter.component';
import {Paging, SearchRequest} from '../../models/vestrata/SearchRequest';
import {PartnerPortfolioModelFilter, PortfolioModelSearchFilter} from '../../models/vestrata/PortfolioModel';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'shared-app-discretionary-model-list',
  templateUrl: './shared-discretionary-model-list.component.html',
  styleUrls: ['./shared-discretionary-model-list.component.scss']
})
export class SharedDiscretionaryModelListComponent implements OnInit, OnDestroy {
  private static readonly INVESTMENT_STYLES = 'investment';
  private static readonly FILTER_INV_OBJ = 'objective';
  private static readonly FILTER_CURRENCY = 'currency';
  private static readonly FILTER_PARTNER = 'partner';
  private static readonly FILTER_ESG = 'esg';
  private static readonly FILTER_CLIENT_RISK = 'client risk';

  @Input() portal;
  @Output() modelListCountChanged = new EventEmitter<number>();

  filteredModels: PortfolioModelListDto[] = [];
  allModels: PortfolioModelListDto[] = [];
  viewPreviewProfile = false;
  viewPreviewStrategy = false;
  organisation: Organisation;
  strategiesList: Strategy[] = [];
  currentStrategy: Strategy;
  currentLang: string;

  private _onDestroy = new Subject();

  // ----FILTERS & SEARCH-------
  filterObject = new PartnerPortfolioModelFilter();
  filterBlocs: BlocOption[] = [];
  searchFilter = new PortfolioModelSearchFilter();

  filterPartner: BlocOption[] = [];
  filterRiskRanking: BlocOption[] = [];
  filterStrategy: BlocOption[] = [];
  selectedFilters: BlocOption[] = [];
  filteringText: string;
  strategyFilter = new StrategyFilter();

  partnerId: string;
  viewFilters: boolean;

  // Paginator
  pages = 0;
  results = 0;
  resultNumber = 25;
  pageNumber = 0;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private ipsAdvisorPartnerService: IpsAdvisorPartnerService,
              private translateService: TranslateService,
              private modelService: ModelService,
              private strategyService: StrategyService,
              private snackbarService: SharedSnackbarService,
              private loader: SharedLoaderService) { }

  ngOnInit() {
    this.currentLang = this.translateService.currentLang;
    this.initEmptySearchFilter();

    this.route.queryParamMap.pipe(takeUntil(this._onDestroy),
      switchMap(params => {
        this.loader.showFullLoader('discretionary-model-list.models-loading');
        this.partnerId = params.get('id');
        if (this.partnerId) {
          return this.modelService.getAllApprovedFromAPartner(this.partnerId);
        } else {
          return this.modelService.getAllApproved();
        }
      })).subscribe(results => {
          this.loader.dismissLoader();
          this.filteredModels = results;
          this.allModels = results;
          this.modelListCountChanged.next(this.filteredModels.length);
          this.loadAndBuildFilters();

    }, error => this.snackbarService.push(new SnackbarMessage(this.translateService.instant('discretionary-model-list.models-loading-error'), 'error')));
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // ------ FIlters -------
  onFilterChange(event: any) {
    this.filteredModels = cloneDeep(this.allModels);

    if (event && Array.isArray(event)) {
      for (const option of event) {
        const index = this.selectedFilters.findIndex(b => b.value === option.value);
        if (index !== -1) {
          this.selectedFilters.splice(index, 1);
        }
        if ((option as BlocOption).selectedCount > 0) {
          this.selectedFilters.push(option);
        }
      }
    } else {
      this.filteringText = event;
    }

    // Search field
    if (this.filteringText) {
      this.filterObject.searchRequest.text = this.filteringText.toLowerCase();
      this.filteredModels = this.filteredModels.filter((val) => val.strategyName.toLowerCase().includes(this.filterObject.searchRequest.text));
      if (event) {
        this.filteringText = event;
      } else if (event.length === 0) {
        this.filteringText = '';
        this.initEmptySearchFilter();
      }
    }

    // Other filters
    for (const blocOption of this.selectedFilters) {
      const filters = blocOption.options.filter(o => o.checked);
      if (blocOption.value === SharedDiscretionaryModelListComponent.FILTER_CURRENCY) {
        this.filterObject.currencies = filters.map(f => f.name);
        this.filteredModels = this.filteredModels.filter(c => {
          const data = this.filterObject.currencies.find(e => e === c.currency);
          return c.currency === data;
        });
      } else if (blocOption.value === SharedDiscretionaryModelListComponent.INVESTMENT_STYLES) {
        this.filterObject.strategyType = filters.map(f => f.name);
        this.filteredModels = this.filteredModels.filter(c => {
          const data = this.filterObject.strategyType.find(e => {if (c.strategyType !== 'undefined') { return e === c.strategyType; }});
          if (c.strategyType) {
            return c.strategyType === data;
          }
        });
      } else if (blocOption.value === SharedDiscretionaryModelListComponent.FILTER_INV_OBJ) {
        this.filterObject.invObj = filters.map(f => f.name);
        this.filteredModels = this.filteredModels.filter(c => {
          const data = this.filterObject.invObj.find(e => e === c.investmentObjectiveName);
          return c.investmentObjectiveName === data;
        });
      } else if (blocOption.value === SharedDiscretionaryModelListComponent.FILTER_PARTNER) {
        this.filterObject.partners = filters.map(f => f.name);
        this.filteredModels = this.filteredModels.filter(c => {
          const data = this.filterObject.partners.find(e => e === c.partnerName);
          return c.partnerName === data;
        });
      } else if (blocOption.value === SharedDiscretionaryModelListComponent.FILTER_ESG) {
        this.filteredModels = this.filteredModels.filter(c => {
          if (c.strategyEsgCriteria === true) {
            return c.strategyEsgCriteria;
          }
        });
      } else if (blocOption.value === SharedDiscretionaryModelListComponent.FILTER_CLIENT_RISK) {
        this.filterObject.clientRiskProfile = filters.map(f => f.name);
        this.filteredModels = this.filteredModels.filter(c => {
          const data = this.filterObject.clientRiskProfile.find(e => {
            const a = +e;
            return a === c.clientRiskProfile;
          });

          return c.clientRiskProfile === +data;
        });
      }

    }
    console.log('this.filterObject', this.filterObject);
  }

  getVersion(data) {
    return data.pending && data.pending.status ? data.pending : data.current;
  }

  private initEmptySearchFilter() {
    this.filterObject.currencies = [];
    this.filterObject.clientRiskProfile = [];
    this.filterObject.invObj = [];
    this.filterObject.strategyType = [];
    this.filterObject.partners = [];
    this.filterObject.strategyEsgCriteria = '';
    this.filterObject.searchRequest = new SearchRequest();
    this.filterObject.searchRequest.paging = new Paging();
    this.filterObject.searchRequest.paging.page = 0;
    this.filterObject.searchRequest.paging.size = 50;
    this.filterObject.searchRequest.criteria = {};
    this.filterObject.searchRequest.text = '';
  }

  updateSort(type: string, order: string) {
    switch (type) {
      case 'currency': {
        this.filterObject.currencyOrder = order;
        this.filterObject.riskRankOrder = null;
        break;
      }
      case 'risk': {
        this.filterObject.riskRankOrder = order;
        this.filterObject.currencyOrder = null;
        break;
      }
      default: {
        this.filterObject.currencyOrder = 'desc';
        break;
      }
    }
    if (order === 'asc' && type === 'currency') {
      this.filteredModels = this.getSortedData(this.filteredModels, 'currency', true);
    } else {
      this.filteredModels = this.getSortedData(this.filteredModels, 'currency', false);
    }

    if (order === 'asc' && type === 'risk') {
      this.filteredModels = this.getSortedData(this.filteredModels, 'risk', true);
    } else {
      this.filteredModels = this.getSortedData(this.filteredModels, 'risk', false);
    }
  }

  getSortedData(data, prop, isAsc) {
    return data.sort((a, b) => {
      return (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1);
    });
  }

  loadAndBuildFilters() {
    const currencies = [...new Set(this.filteredModels.map(el => el.currency).filter(Boolean))];
    const investment = [...new Set(this.filteredModels.map(el =>  el.strategyType).filter(Boolean))];
    const invObj = [...new Set(this.filteredModels.map(el =>  el.investmentObjectiveName).filter(Boolean))];
    const partners = [...new Set(this.filteredModels.map(el =>  el.partnerName).filter(Boolean))];
    const clientRisks = [...new Set(this.filteredModels.map(el =>  el.clientRiskProfile).filter(Boolean))];
    const esg = [...new Set(this.filteredModels.map(el =>  el.strategyEsgCriteria).filter(Boolean))];

    this.filterBlocs = [];
    this.filterRiskRanking = [];

    const currencyBloc = new BlocOption();
    currencyBloc.label = 'am-strategy.currencies';
    currencyBloc.options = [];
    currencyBloc.value = SharedDiscretionaryModelListComponent.FILTER_CURRENCY;
    currencies.forEach(key => {
      currencyBloc.options.push(new FilterOption(key, key[key], key, true, false, null));
    });

    const statusBloc = new BlocOption();
    statusBloc.label = 'am-strategy.inv-styles';
    statusBloc.options = [];
    statusBloc.value = SharedDiscretionaryModelListComponent.INVESTMENT_STYLES;
    investment.forEach(key => {
      statusBloc.options.push(new FilterOption(key, key, key, true, false, null));
    });

    const invObjBloc = new BlocOption();
    invObjBloc.label = 'am-strategy.inv-obj';
    invObjBloc.options = [];
    invObjBloc.value = SharedDiscretionaryModelListComponent.FILTER_INV_OBJ;
    invObj.forEach(key => {
      invObjBloc.options.push(new FilterOption(key, key, key, true, false, null));
    });

    const partnersBloc = new BlocOption();
    partnersBloc.label = 'am-strategy.partner';
    partnersBloc.options = [];
    partnersBloc.value = SharedDiscretionaryModelListComponent.FILTER_PARTNER;
    partners.forEach(key => {
      partnersBloc.options.push(new FilterOption(key, key, key, true, false, null));
    });

    const esgBloc = new BlocOption();
    esgBloc.label = 'am-strategy.esg';
    esgBloc.options = [];
    esgBloc.value = SharedDiscretionaryModelListComponent.FILTER_ESG;
    esg.forEach(key => {
      esgBloc.options.push(new FilterOption('Esg', 'esg', null, true, false, null));
    });

    const clientRiskRatingBloc = new BlocOption();
    clientRiskRatingBloc.label = 'am-strategy.client-risk-profile';
    clientRiskRatingBloc.options = [];
    clientRiskRatingBloc.value = SharedDiscretionaryModelListComponent.FILTER_CLIENT_RISK;
    clientRisks.forEach(key => {
      clientRiskRatingBloc.options.push(new FilterOption(key.toString(), key, null, true, false, null));
    });
    this.filterRiskRanking.push(clientRiskRatingBloc);


    if (currencyBloc.options.length > 0) {
      this.filterBlocs.push(currencyBloc);
    }
    if (statusBloc.options.length > 0) {
      this.filterBlocs.push(statusBloc);
    }
    if (invObjBloc.options.length > 0) {
      this.filterBlocs.push(invObjBloc);
    }
    if (partnersBloc.options.length > 0) {
      this.filterBlocs.push(partnersBloc);
    }
    if (esgBloc.options.length > 0) {
      this.filterBlocs.push(esgBloc);
    }
    if (clientRiskRatingBloc.options.length > 0) {
      this.filterBlocs.push(clientRiskRatingBloc);
    }
    console.log('filterBlocs', this.filterBlocs);
  }

  // ------- END FIlters -------

  riskRankingStyle(riskRanking: number) {
    let color;
    let text;
    if (riskRanking === 1 || riskRanking === 0) {
      color = '#00c4b3';
      text = 'Conservative';
    } else if (riskRanking === 2) {
      color = '#248ed9';
      text = 'Moderate';
    } else if (riskRanking === 3) {
    } else if (riskRanking === 3) {
      color = '#248ed9';
      text = 'Balanced';
    } else if (riskRanking === 4) {
      color = '#003057';
      text = 'Growth';
    } else {
      color = '#000000';
      text = 'Aggressive';
    }
    return text;
  }

  editClick(modelId: string) {
      this.router.navigate(['/solutions/discretionary/models/details'], {queryParams: { id: modelId }});
  }

  goBack() {
    this.viewPreviewProfile = false;
    this.viewPreviewStrategy = false;
    this.viewFilters = false;
  }

  clickOnStrategy(strategyId: string) {
    this.loader.showFullLoader('discretionary-model-list.strategy-loading');
    this.strategyService.getStrategyById(strategyId)
      .pipe(takeUntil(this._onDestroy),
      flatMap(s => {
        this.currentStrategy = s;
        console.log(this.currentStrategy);
        return this.ipsAdvisorPartnerService.getPartner(s.partnerId);
      }), finalize(() => this.loader.dismissLoader())).subscribe( p => {
        this.organisation = p;
        this.viewPreviewStrategy = true;
        this.viewFilters = true;
        this.viewPreviewProfile = false;
    }, err => {
      this.snackbarService.push(new SnackbarMessage(this.translateService.instant('discretionary-model-list.strategy-loading-error'), 'error'));
    });
  }

  clickOnPartner(partnerId: string) {
    this.loader.showFullLoader('discretionary-model-list.partner-loading');
    forkJoin(this.ipsAdvisorPartnerService.getPartner(partnerId), this.ipsAdvisorPartnerService.getStrategiesByPartner(partnerId))
      .pipe(takeUntil(this._onDestroy),
        finalize(() => this.loader.dismissLoader()))
      .subscribe(data => {
        if (data[0].partner.current) {
          this.organisation = data[0];
          this.strategiesList = data[1].filter(s => s.current != null && s.current.data != null);
          this.viewPreviewProfile = true;
          this.viewFilters = true;
          this.viewPreviewStrategy = false;
        } else {
          this.snackbarService.push(new SnackbarMessage(this.translateService.instant('discretionary-model-list.partner-missing-data'), 'error'));
        }
      }, err => {
        this.snackbarService.push(new SnackbarMessage(this.translateService.instant('discretionary-model-list.partner-loading-error'), 'error'));
      });

  }

  consoleOutput() {
    console.log('------------------------------------');
    console.log('models');
    console.log(this.filteredModels);
    console.log('------------------------------------');
  }

}
