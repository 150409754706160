import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ves-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {

  private static readonly ALL = 'all';
  @Input()
  currentFilter: any;

  @Input()
  filterOptions: any[] = [];
  // Example
  // [{
  //  label: this.translate.instant('filter-bar.all'),
  //  value: FilterBarComponent.ALL
  // }]

  @Output()
  selectedFilter = new EventEmitter<any>();

  public isOpen: boolean = false;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    if (!this.currentFilter || this.currentFilter === '' ) { this.currentFilter = { label: this.translate.instant('filter-bar.all'), value: FilterBarComponent.ALL}; }
  }

  toggleFilter() {
    this.isOpen = !this.isOpen;
  }
  selectItem(item) {
    if (item === this.currentFilter ) {
      this.currentFilter = { label: this.translate.instant('filter-bar.all'), value: FilterBarComponent.ALL};
    } else {
      this.currentFilter = item;
    }
    this.selectedFilter.emit(this.currentFilter);
    this.toggleFilter();
  }
}
