import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../api/services/authentication.service';

@Component({
  selector: 'ves-shared-vertical-menu',
  templateUrl: './shared-vertical-menu.component.html',
  styleUrls: ['./shared-vertical-menu.component.scss']
})
export class SharedVerticalMenuComponent implements OnInit, OnDestroy, OnChanges {

  private _onDestroy = new Subject();
  private currentUrl: string;
  user = '';

  @Input()
  menu: any[] = [
    {
      name: 'solutions',
      src: '../../../assets/images/horizontal-menu/solutions/icon-solutions.png',
      srcset: '../../../assets/images/horizontal-menu/solutions/icon-solutions.png 1x, ' +
        '../../../assets/images/horizontal-menu/solutions/icon-solutions@2x.png 2x,' +
        '../../../assets/images/horizontal-menu/solutions/icon-solutions@3x.png 3x',
      urls: ['/hub'],
      mainUrl : '/hub'
    },
    {
      name: 'home',
      src: '../../../assets/images/horizontal-menu/dashboard/icon-dashboard.png',
      srcset: '../../../assets/images/horizontal-menu/dashboard/icon-dashboard.png 1x, ' +
        '../../../assets/images/horizontal-menu/dashboard/icon-dashboard@2x.png 2x,' +
        '../../../assets/images/horizontal-menu/dashboard/icon-dashboard@3x.png 3x',
      urls: ['/hub'],
      mainUrl : '/hub'
    },
    {
      name: 'get invested',
      src: '../../../assets/images/horizontal-menu/get-invested/icon-get-invested.png',
      srcset: '../../../assets/images/horizontal-menu/get-invested/icon-get-invested.png 1x, ' +
        '../../../assets/images/horizontal-menu/get-invested/icon-get-invested@2x.png 2x,' +
        '../../../assets/images/horizontal-menu/get-invested/icon-get-invested@3x.png 3x',
      urls: ['/hub', '/hub'],
      mainUrl : '/hub'
    },
    {
      name: 'experts',
      src: '../../../assets/images/horizontal-menu/strategies/icon-experts.png',
      srcset: '../../../assets/images/horizontal-menu/strategies/icon-experts.png 1x, ' +
        '../../../assets/images/horizontal-menu/strategies/icon-experts@2x.png 2x,' +
        '../../../assets/images/horizontal-menu/strategies/icon-experts@3x.png 3x',
      urls: ['/hub', '/hub'],
      mainUrl : '/hub'
    },
    {
      name: 'strategies',
      src: '../../../assets/images/horizontal-menu/strategies/icon-strategies.png',
      srcset: '../../../assets/images/horizontal-menu/strategies/icon-strategies.png 1x, ' +
        '../../../assets/images/horizontal-menu/strategies/icon-strategies@2x.png 2x,' +
        '../../../assets/images/horizontal-menu/strategies/icon-strategies@3x.png 3x',
      urls: ['/hub', '/hub'],
      mainUrl : '/hub'
    },
    {
      name: 'news',
      src: '../../../assets/images/horizontal-menu/news/icon-news.png',
      srcset: '../../../assets/images/horizontal-menu/news/icon-news.png 1x, ' +
        '../../../assets/images/horizontal-menu/news/icon-news@2x.png 2x,' +
        '../../../assets/images/horizontal-menu/news/icon-news@3x.png 3x',
      urls: ['/hub'],
      mainUrl : '/hub'
    }
  ];

  constructor(private router: Router,
              public authService: AuthenticationService) { }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.router.events.pipe(filter(e => e instanceof NavigationEnd),
      takeUntil(this._onDestroy)).subscribe((e: NavigationEnd) => {
      this.currentUrl = e.urlAfterRedirects;
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu.currentValue) {
      this.menu = this.menu.filter(item => {
        if (item.hasPermissions) {
          return true;
        } else {
          return item.hasPermissions !== false;
        }
      });
    }
  }

  isSelected(urls: string[]) {
    return urls.find(url => this.currentUrl.startsWith('/' + url));
  }

  goTo(url: string) {
    if (url && url.startsWith('http')) {
      window.open(url, '_blank');
    } else {
      this.router.navigate(['/' + url]);
    }
  }

  logout() {
    this.authService.disconnect();
  }

}
