import { Component, OnInit, Input, Renderer2, Inject, OnChanges, SimpleChange, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MSComponentService } from '../../api/services/mscomponent.service';


@Component({
  selector: 'ves-shared-ms-fund-report',
  templateUrl: './shared-MS-fund-report.component.html',
  styleUrls: ['./shared-MS-fund-report.component.scss']
})

export class SharedMsFundReportComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isin: string;
  @Input() currency: string;

  public morningstarToken: string = "";

  private isLoading = true;
  private script: any = {};

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    MSComponentService.lazyRemove('ec-report-container');
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.isin && this.translate) {
      this.getMSReportNew(this.isin, this.currency, this.translate);
    }
  }

  getMSReportNew(isin: string, currency: string, translate: TranslateService) {
    MSComponentService.lazyLoad(document.getElementById('ecSecurityReportLoader-container'), 'ec-report-container', 'ec-security-report-loader', 'ecSecurityReportLoader')
    .then(function() {
        const morningstar = (<any>window).morningstar;
        morningstar.asterix.instanceRegistry.get('ecSecurityReportLoader').setParameter('id', isin);
        if (translate !== null) {
          morningstar.asterix.instanceRegistry.get('ecSecurityReportLoader').setParameter('languageId', translate.currentLang);
        } else {
          console.log('Warning translate language is null');
        }
        morningstar.asterix.instanceRegistry.get('ecSecurityReportLoader').setParameter('currencyId', currency);
    })
    .catch((error) => {
      console.error('Error', error);
    });

  }
}
