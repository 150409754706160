import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Permission} from '../../../models/vestrata/Permission';
import {TranslateService} from '@ngx-translate/core';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {Version} from '../../../models/vestrata/Version';
import {SnackbarMessage} from '../../shared-snackbar/models/snackbar-message';
import {InsightService} from '../../../api/services/insight.service';
import {Insight, InsightCuration, InsightVersion} from '../../../models/vestrata/Insight';
import {cloneDeep, isEqual} from 'lodash';
import {Organisation} from '../../../models/vestrata/Organisation';
import {SharedComponentsService} from '../../../api/services/shared-components.service';
import {Paging, SearchRequest} from '../../../models/vestrata/SearchRequest';
import {Router} from '@angular/router';
import {finalize, flatMap, takeUntil} from 'rxjs/operators';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {Page} from '../../../models/vestrata/Page';
import {SharedLoaderService} from '../../shared-loader/service/shared-loader.service';
import {AdminService} from "../../../api/services/admin.service";
import {NavigationData} from '../../../basic-components/unknown-components/navigation-menu/navigation-menu.component';
import {NavigationMenuService} from '../../../basic-components/unknown-components/navigation-menu/navigation-menu.service';

@Component({
  selector: 'ves-shared-insights-list',
  templateUrl: './shared-insights-list.component.html',
  styleUrls: ['./shared-insights-list.component.scss']
})
export class SharedInsightsListComponent implements OnInit, OnDestroy {

  private _onDestroy = new Subject();

  @Input() isIps = true;
  @Input() isAdmin = false;
  @Input() permission: Permission = new Permission();

  @Output() selectInsightClick = new EventEmitter<Insight>();
  @Output() closeInsightList = new EventEmitter<boolean>();

  organisation: Organisation;
  insightList: Insight[] = [];
  partnerList: Insight[] = [];
  filteredList: Insight[] = [];

  viewOwn = true;
  viewPartner = false;

  viewDeleteCheck = false;
  insightToDelete: Insight;
  headers = ['insight-title', '', '', '', 'languages', 'state', '', '', ''] ;

  hasChanged = false;

  partnerSearch: SearchRequest = new SearchRequest();
  viewPreview = false;
  viewSinglePreview = false;
  insightToPreview: Version<InsightVersion>;
  insightPreviewOrg: string;
  insightPreviewCurations: InsightCuration[] = [];
  viewAdd: boolean;
  $partners: any[] =[];
  adminSelectedPartner: any;

  isApproveDeletion = false;
  navigationData = new NavigationData();

  constructor(private insightService: InsightService,
              private translate: TranslateService,
              private snackbar: SharedSnackbarService,
              private commonService: SharedComponentsService,
              private loader: SharedLoaderService,
              private router: Router,
              private menuService: NavigationMenuService,
              private adminService: AdminService) { }

  ngOnInit() {
    const orgObservable: Observable<Page<Insight>> = this.commonService.getOrganisation().pipe(flatMap(org => {
        this.organisation = org;
        let ret: Observable<Page<Insight>> = of(new Page<Insight>());
        if (this.isIps || this.isAdmin) {
          this.partnerSearch.criteria = { organisationId: { $ne: this.organisation.id } };
          ret = this.insightService.getInsightsWithSearchCriteria(this.partnerSearch);
        }
        return ret;
      })
    );

    this.loader.showFullLoader();
    forkJoin(this.insightService.getInsights(), orgObservable).pipe(takeUntil(this._onDestroy),
      finalize(() => this.loader.dismissLoader())).subscribe(data => {
        this.insightList = data[0];
        this.filteredList = cloneDeep(this.insightList);
        this.partnerList = data[1].content;
        this.setMenuData();
    }, error => {
      this.snackbar.push(new SnackbarMessage(error, 'error'));
    });

    this.loadPartnersForAdmin();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.menuService.resetNavigationData();
  }

  selectInsight(insight) {
    this.selectInsightClick.emit(insight);
  }

  onAddNew() {
    if (this.isAdmin){
      this.viewAdd = true;
      return;
    }
    const insight = new Insight();
    insight.pending = new Version<InsightVersion>();
    insight.pending.data = new InsightVersion();
    this.selectInsightClick.emit(insight);
  }

  deleteInsightCheck(insight) {
    this.insightToDelete = insight;
    this.viewDeleteCheck = true;
  }

  cancelDelete() {
    this.insightToDelete = null;
    this.viewDeleteCheck = false;
  }

  deleteInsight() {
    this.insightService.deleteInsight(this.insightToDelete).subscribe(result => {
      this.insightList.splice(this.insightList.indexOf(this.insightToDelete), 1);
      this.filteredList.splice(this.filteredList.indexOf(this.insightToDelete), 1);
      this.snackbar.push(new SnackbarMessage(this.translate.instant('insight.deleted'), 'info'));
    }, err => {
        this.snackbar.push(new SnackbarMessage(err.error.message, 'error'));
    });
    this.viewDeleteCheck = false;
  }

  filterTable(searchString) {
    this.filteredList = cloneDeep(this.insightList);
    this.filteredList = this.filteredList.filter((val) => {
      const version = this.getInsightVersion(val);
      const localInsight = version.data.locales.find(a => a.lang === this.translate.currentLang);
      return localInsight.title.toLowerCase().includes(searchString.toLowerCase());
    });
  }

  getInsightName(insight: Insight) {
    console.log(insight);
    if (insight.pending) {
      const localInsight = insight.pending.data.locales.find(a => a.lang === this.translate.currentLang);
      if (localInsight) {
        return localInsight.title;
      } else {
        return insight.pending.data.locales.find(a => a.lang === 'en');
      }
    } else {

    }
  }

  openSinglePreview(insight: Insight) {
    if (insight.pending && insight.pending.data) {
      this.insightToPreview = insight.pending;
    } else {
      this.insightToPreview = insight.current;
    }
    this.insightPreviewOrg = insight.organisationName;
    this.insightPreviewCurations = insight.curations;
    this.viewSinglePreview = true;
  }

  closePreview() {
    this.insightToPreview = null;
    this.viewSinglePreview = false;
    this.viewPreview = false;
  }

  getInsightVersion(insight: Insight) {
    console.log(insight);
    if (insight.pending && insight.pending.status) {
      return insight.pending;
    } else if (insight.current) {
      return insight.current;
    }
  }

/*  onPreview() {
    this.viewPreview = !this.viewPreview;
    this.viewSinglePreview = false;
  }*/

  viewOwnInsight() {
    this.viewOwn = true;
    this.viewPartner = false;
    this.filteredList = cloneDeep(this.insightList);
  }

  viewPartnerInsight() {
    this.viewOwn = false;
    this.viewPartner = true;
    this.filteredList = cloneDeep(this.partnerList);
  }

  updateCurationEnable(insight: Insight, enabled) {
    if (insight.current && insight.current.data ) {
      this.insightService.curateInsightSetEnable(insight.id, enabled).pipe(takeUntil(this._onDestroy)).subscribe( i => {
        if (insight.organisationId === this.organisation.id) {
          // own insight
          this.insightList.splice(this.insightList.indexOf(insight), 1, i);
        } else {
          // partner insight
          this.partnerList.splice(this.partnerList.indexOf(insight), 1, i);
        }
        this.partnerList.splice(this.filteredList.indexOf(insight), 1, i);
        this.snackbar.push(new SnackbarMessage(this.translate.instant('insight.curation-enable'), 'info'));
      }, error => {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('insight.error.curation-enable'), 'error'));
      });
    } else {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('insight.error.no-approved-version'), 'error'));
    }
  }

  updateCurationPromoted(insight: Insight, enabled) {
    if (insight.current && insight.current.data ) {
      this.insightService.curateInsightSetPromoted(insight.id, enabled).pipe(takeUntil(this._onDestroy)).subscribe(i => {
        if (insight.organisationId === this.organisation.id) {
          // own insight
          this.insightList.splice(this.insightList.indexOf(insight), 1, i);
        } else {
          // partner insight
          this.partnerList.splice(this.partnerList.indexOf(insight), 1, i);
        }
        this.partnerList.splice(this.filteredList.indexOf(insight), 1, i);
        this.snackbar.push(new SnackbarMessage(this.translate.instant('insight.curation-promote'), 'info'));
      }, error => {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('insight.error.curation-promote'), 'error'));
      });
    } else {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('insight.error.no-approved-version'), 'error'));
    }
  }

  cancelDialog() {
    this.viewAdd = false;
  }

  goAdminCreateInsight(isVestrata) {
    if (!isVestrata && !this.adminSelectedPartner){
      this.snackbar.push(new SnackbarMessage(this.translate.instant('insight.error.no-partner'), 'error'));
      return;
    }
    const insight = new Insight();
    insight.pending = new Version<InsightVersion>();
    insight.pending.data = new InsightVersion();
    insight.organisationId = !isVestrata ? this.adminSelectedPartner.value : this.organisation.id;
    insight.organisationName = !isVestrata ? this.adminSelectedPartner.name : this.organisation.name;
    insight.byVestrata = !isVestrata;
    this.selectInsightClick.emit(insight);
  }

  private loadPartnersForAdmin() {
    if (!this.isAdmin) {
      return;
    }
    this.adminService.getOrganisations().pipe(takeUntil(this._onDestroy)).subscribe(orgs => {
      orgs.filter(p => p.id !== this.organisation.id && p.partner)
        .forEach(partner => {
          this.$partners.push({ name: partner.name, value: partner.id });
        });
    });

  }

  fourEyesDelete(insight: Insight, isApproving: boolean) {
    this.insightToDelete = insight;
    this.viewDeleteCheck =true;
    this.isApproveDeletion = isApproving;
  }

  submitOrApproveDeletion(instance: Insight) {
    if (this.isApproveDeletion) {
      this.approveDeleteInstance(instance);
      return;
    }
    this.submitDeleteInstance(instance);
  }

  submitDeleteInstance(instance: Insight) {
    this.loader.showBarLoader();
    this.insightService.submitDeletion(instance.id).pipe(takeUntil(this._onDestroy),
      finalize(() => {
        this.cancelDelete();
        this.loader.dismissLoader();
      }))
      .subscribe(data => {
        this.insightList[this.insightList.indexOf(this.insightList.find(item=> item.id === data.id))] = data;
        this.filteredList[this.filteredList.indexOf(this.filteredList.find(item=> item.id === data.id))] = data;
        this.snackbar.push(new SnackbarMessage(this.translate.instant('four-eyes-deletion.submit-delete'), 'info'));
        this.setMenuData();
      });
  }

  approveDeleteInstance(instance: Insight) {
    this.loader.showBarLoader();
    this.insightService.approveDeletion(instance.id).pipe(takeUntil(this._onDestroy),
      finalize(() => {
        this.cancelDelete();
        this.loader.dismissLoader();
      }))
      .subscribe(_ => {
        this.insightList.splice(this.insightList.indexOf(this.insightToDelete), 1);
        this.filteredList.splice(this.filteredList.indexOf(this.insightToDelete), 1);
        this.snackbar.push(new SnackbarMessage(this.translate.instant('four-eyes-deletion.approved-deletion'), 'info'));
        this.setMenuData();
      });
  }

  rejectDeleteInstance(instance: Insight) {
    this.loader.showBarLoader();
    this.insightService.declineDeletion(instance.id).pipe(takeUntil(this._onDestroy),
      finalize(() => {
        this.cancelDelete();
        this.loader.dismissLoader();
      }))
      .subscribe(data => {
        this.insightList[this.insightList.indexOf(this.insightList.find(item=> item.id === data.id))] = data;
        this.filteredList[this.filteredList.indexOf(this.filteredList.find(item=> item.id === data.id))] = data;
        this.snackbar.push(new SnackbarMessage(this.translate.instant('four-eyes-deletion.reject-deletion'), 'info'));
        this.setMenuData();
      });
  }

  private setMenuData() {
    this.navigationData.permission = this.permission;
    this.navigationData.interface = this;
    this.navigationData.fourEyeView = false;
    this.navigationData.pageTitle = this.buildPageTitle(this.filteredList);
    this.menuService.setNavigationData(this.navigationData);
  }

  private buildPageTitle(insights: Insight[]): string {
      let title = '';
      if (insights) {
        title += insights.length + ' ';
        title += insights.length > 1 ? this.translate.instant('insight.insights-title')
          : this.translate.instant('insight.insight-title');
      }
      return title;
  }
}
