import {HttpClient} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {ConfigService} from '@ngx-config/core';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiHttpService} from '../api.http.service';
import {User, UserVersion} from '../../models/vestrata/User';
import {SearchRequest} from '../../models/vestrata/SearchRequest';
import {Page} from '../../models/vestrata/Page';
import {SalesMeeting} from '../../models/vestrata/SalesMeeting';
import {Portal} from "../../models/vestrata/Portal";
import {Version} from "../../models/vestrata/Version";
import {Role} from '../../models/vestrata/Role';
import {UtilsService} from '../utils.service';
import {TranslateService} from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class UserService  {

  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private translate: TranslateService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getUserRoles(userID: string) {
    return this.http.get<Version<UserVersion>>(this.serviceUrl + 'user/roles/' + userID , ApiHttpService.getHeaders());
  }

  getUsersById(organisationId: string): Observable<User[]> {
    return this.http.get<User[]>(this.serviceUrl + 'user/organisation/' + organisationId , ApiHttpService.getHeaders());
  }

  getUserById(userId: string): Observable<User> {
    return this.http.get<User>(this.serviceUrl + 'user/' + userId , ApiHttpService.getHeaders());
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.serviceUrl + 'user' , ApiHttpService.getHeaders());
  }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(this.serviceUrl + 'user' , user, ApiHttpService.getHeaders());
  }

  updateUser(user: User): Observable<User> {
    return this.http.put<User>(this.serviceUrl + 'user' , user, ApiHttpService.getHeaders());
  }

  deleteUser(user: User): Observable<User> {
    return this.http.delete<User>(this.serviceUrl + 'user/' + user.id , ApiHttpService.getHeaders());
  }

  // TO REMOVE
  passwordReset(user: User): Observable<User> {
    return this.http.put<User>(this.serviceUrl + 'user/password' , user, ApiHttpService.getHeaders());
  }

  resetUserPassword(userId: string): Observable<User> {
    return this.http.put<User>(this.serviceUrl + 'user/password/' + userId , ApiHttpService.getHeaders());
  }

  resetSavedUser(userId: string): Observable<User> {
    return this.http.post<User>(this.serviceUrl + 'user/reset/' + userId , ApiHttpService.getHeaders());
  }

  enableUser(userId: string): Observable<User> {
    return this.http.put<User>(this.serviceUrl + 'user/reset/' + userId , ApiHttpService.getHeaders());
  }

  disableUser(userId: string): Observable<User> {
    return this.http.post<User>(this.serviceUrl + 'user/disable/' + userId , ApiHttpService.getHeaders());
  }

  submitUserForApproval(userId: string): Observable<User> {
    return this.http.post<User>(this.serviceUrl + 'user/submit/' + userId, ApiHttpService.getHeaders());
  }

  cancelUserApproval(userId: string): Observable<User> {
    return this.http.post<User>(this.serviceUrl + 'user/unlock/' + userId, ApiHttpService.getHeaders());
  }

  approveUserChange(userId: string): Observable<User> {
    return this.http.post<User>(this.serviceUrl + 'user/approve/' + userId, ApiHttpService.getHeaders());
  }


  declineUserChange(userId: string, note: string): Observable<User> {
    return this.http.post<User>(this.serviceUrl + 'user/decline/' + userId, note,  ApiHttpService.getHeaders());
  }

  getOwnUser(): Observable<User> {
    return this.http.get<User>(this.serviceUrl + 'me' , ApiHttpService.getHeaders());
  }

  updateOwnUser(user: User): Observable<User> {
    return this.http.put<User>(this.serviceUrl + 'me' , user, ApiHttpService.getHeaders());
  }

  // 4eyes Deletion
  submitDeletion(instanceId: string): Observable<User> {
    return this.http.post<User>(this.serviceUrl + 'user/delete/submit/' + instanceId,
      ApiHttpService.getHeaders());
  }
  approveDeletion(instanceId: string): Observable<void> {
    return this.http.post<void>(this.serviceUrl + 'user/delete/approve/' + instanceId,
      ApiHttpService.getHeaders());
  }
  declineDeletion(instanceId: string): Observable<User> {
    return this.http.post<User>(this.serviceUrl + 'user/delete/decline/' + instanceId,
      ApiHttpService.getHeaders());
  }
  getUsersSearchCriteria(searchCriteria: SearchRequest): Observable<Page<User>> {
    return this.http.post<Page<User>>(this.serviceUrl + 'user/search', searchCriteria, ApiHttpService.getHeaders());
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.serviceUrl + 'user/roles', ApiHttpService.getHeaders());
  }

  getRolesByPlatform(platform: Portal, roles: Role[]): Role[] {
    return roles ? roles.filter(r => {
        let permission = false;
        switch (platform) {
          case Portal.IPS: permission = !!r.ipsPermissions; break;
          case Portal.ADVISOR: permission = !!r.advisorPermissions; break;
          case Portal.PARTNER: permission = !!r.partnerPermissions; break;
          case Portal.ADMIN: break; // TODO.
        }
        return permission;
      }) :
    [];
  }

  sortRoles(roles: Role[]): Role[] {
    // Sort roles by alphabetical order
    roles.sort((a, b) =>
      this.translate.instant('roles.' + a.value).localeCompare(this.translate.instant('roles.' + b.value)));

    // Put product specialist at the end of the array if needed.
    const productSpecialistIndex  = roles.findIndex(r => r.value === Role.PRODUCT_SPECIALIST.value);
    if (productSpecialistIndex !== -1) {
      roles.push(roles.splice(productSpecialistIndex, 1)[0]);
    }

    return roles;
  }

}
