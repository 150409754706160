import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngx-config/core';
import {VesConfService} from './conf.service';
import {OrganisationTeam, OrganisationTeamVersion} from '../../models/vestrata/OrganisationTeam';
import {Observable} from 'rxjs';
import {ApiHttpService} from '../api.http.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class OrganisationTeamService {

  private static readonly ROOT_ENDPOINT = 'organisation/team/';
  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private translate: TranslateService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getAll(): Observable<OrganisationTeam[]> {
    return this.http.get<OrganisationTeam[]>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT, ApiHttpService.getHeaders());
  }

  getAllApproved(): Observable<OrganisationTeam[]> {
    return this.http.get<OrganisationTeam[]>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + 'approved', ApiHttpService.getHeaders());
  }

  getById(OrganisationTeamId: string): Observable<OrganisationTeam> {
    return this.http.get<OrganisationTeam>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + OrganisationTeamId,
      ApiHttpService.getHeaders());
  }

  createOrganisationTeam(team: OrganisationTeam): Observable<OrganisationTeam> {
    return this.http.post<OrganisationTeam>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT , team, ApiHttpService.getHeaders());
  }

  updateOrganisationTeam(team: OrganisationTeam): Observable<OrganisationTeam> {
    return this.http.put<OrganisationTeam>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + team.id ,
      team.pending.data, ApiHttpService.getHeaders());
  }

  submitOrganisationTeamForApproval(teamId: string): Observable<OrganisationTeam> {
    return this.http.post<OrganisationTeam>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + 'submit/' + teamId,
      ApiHttpService.getHeaders());
  }

  resetOrganisationTeamForApproval(teamId: string): Observable<OrganisationTeam> {
    return this.http.post<OrganisationTeam>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + 'reset/' + teamId,
      ApiHttpService.getHeaders());
  }

  cancelOrganisationTeamApproval(teamId: string): Observable<OrganisationTeam> {
    return this.http.post<OrganisationTeam>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + 'unlock/' + teamId,
      ApiHttpService.getHeaders());
  }

  approveOrganisationTeamChange(teamId: string): Observable<OrganisationTeam> {
    return this.http.post<OrganisationTeam>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + 'approve/' + teamId,
      ApiHttpService.getHeaders());
  }

  declineOrganisationTeamChange(teamId: string, note: string): Observable<OrganisationTeam> {
    return this.http.post<OrganisationTeam>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + 'decline/' + teamId,
      note,  ApiHttpService.getHeaders());
  }

  getTeamLocalizedLabel(team: OrganisationTeamVersion, lang: string, fallbackEnglish: boolean = false): string {
    let label: string;
    if (team) {
      const lTeam = team.localizedTeams.find(c => c.lang === lang);
      if (fallbackEnglish) {
        label = lTeam ? lTeam.name : team.localizedTeams.find(c => c.lang === 'en').name;
      } else {
        label = lTeam ? lTeam.name : null;
      }
    }
    return label;
  }

  // 4eyes Deletion
  submitDeletionTeam(teamId: string): Observable<OrganisationTeam> {
    return this.http.post<OrganisationTeam>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + 'delete/submit/' + teamId,
      ApiHttpService.getHeaders());
  }
  approveDeletionTeam(teamId: string): Observable<void> {
    return this.http.post<void>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + 'delete/approve/' + teamId,
      ApiHttpService.getHeaders());
  }
  declineDeletionTeam(teamId: string): Observable<OrganisationTeam> {
    return this.http.post<OrganisationTeam>(this.serviceUrl + OrganisationTeamService.ROOT_ENDPOINT + 'delete/decline/' + teamId,
      ApiHttpService.getHeaders());
  }

  getLocalizedTeamName(team: OrganisationTeam): string {
    const localizedTeam = team?.current?.data?.localizedTeams?.find(l => l.lang === this.translate.currentLang);
    return localizedTeam ? localizedTeam.name :
      team?.current?.data?.localizedTeams?.find(l => l.lang === 'en').name;
  }
}
