import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {NavigationEnd, NavigationExtras, Router} from '@angular/router';
import {Version} from '../../../models/vestrata/Version';
import {Permission} from '../../../models/vestrata/Permission';
import {NavigationMenuService} from './navigation-menu.service';
import {Location} from '@angular/common';
import filterDeep from 'deepdash-es/filterDeep';

@Component({
  selector: 'ves-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss']
})
export class NavigationMenuComponent implements OnInit, OnChanges, OnDestroy {

  private _onDestroy = new Subject();
  currentUrl: string;
  @Input() menu: NavigationItem[] = [];
  @Input() hasHeader = true;
  navigationData: NavigationData;

  constructor(private router: Router,
              private location: Location,
              private menuService: NavigationMenuService) {
    this.currentUrl = this.router.url.split('?')[0];
    this.router.events.pipe(filter(e => e instanceof NavigationEnd),
      takeUntil(this._onDestroy)).subscribe((e: NavigationEnd) => {
      this.currentUrl = e.urlAfterRedirects.split('?')[0];
    });
    this.menuService.navigationDataChanged.pipe(takeUntil(this._onDestroy)).subscribe(data => {
      this.navigationData = data;
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.menu?.currentValue) {
      const rightFilteredMenu: NavigationItem[] = filterDeep(this.menu,
        (v: NavigationItem) => {
          if (v.hasPermissions) {
            return true;
          } else {
            return v.hasPermissions !== false;
          }
        }, { childrenPath: ['subMenu'] }
      );

      // Remove top component with no child element only of they initially had some.
      this.menu = this.menu.filter(item => {
        const filteredItem = rightFilteredMenu.find(i => i.url === item.url);
        return !(item.subMenu && item.subMenu.length > 0 &&
          (!filteredItem.subMenu || filteredItem.subMenu.length === 0));

      });
    }
  }

  isSelected(urls: string[]) {
    return urls && urls.find(url => this.currentUrl.startsWith('/' + url));
  }

  goTo(url: string, extra?: NavigationExtras) {
    if (url && url.startsWith('http')) {
      window.open(url, '_blank');
    } else {
      !extra ? this.router.navigate(['/' + url]) : this.router.navigate(['/' + url], extra);
    }
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onMenuHover(item: NavigationItem, active: boolean) {
    item.isActive = active;
  }

  getPageTitle(url: string, menu: NavigationItem[]) {
    if (menu) {
      for (const item of menu) {
        if (url === ('/' + item.url)) {
          return item.title;
        } else {
          const pageTitle = this.getPageTitle(url, item.subMenu);
          if (pageTitle) {
            return pageTitle;
          }
        }
      }
    }
    return null;
  }

  getActiveMenu(url: string, menu: NavigationItem[]): NavigationItem {
    if (!menu || !url) {
      return;
    }
    return menu.find(item => url.startsWith('/' + item.url));
  }

}

export class NavigationItem {
    title: string;
    activatedUrls: string[];
    url: string;
    subMenu?: NavigationItem[] = [];
    isActive ?= false;
    hasPermissions ?= true;
    extras ?: NavigationExtras;
}

export class NavigationData {
  pageTitle: string;
  hasHeader = true;
  // 4Eyes process data
  interface: any;
  currentVersion: Version<any>;
  pendingVersion: Version<any>;
  permission: Permission;
  editingValue: boolean;
  fourEyeView: boolean;
}
