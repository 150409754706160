import {Alert} from "../../models/vestrata/Alert";
import {Action, createReducer, on} from "@ngrx/store";
import * as AlertActions from '../actions/alert.actions';

export  class AlertState {
  allAlerts: Alert[];
}
export const initialState =  {
   allAlerts: []
};

export const reloadState =  {};

const alertReducer = createReducer(
  initialState,
  // on(AlertActions.retrieveAlerts, (state: AlertState, { payload }) => (allAlerts: payload ))
  on(AlertActions.retrieveAlerts, (state: AlertState, { payload }) => {
    return { ...state, allAlerts: payload };
  })
);

const reloadReducer = createReducer(
  reloadState,
  on(AlertActions.askReload, (state: AlertState) => {
    return { ...state};
  })
);

export function retrieveAlertsReducer(state: AlertState | undefined, action: Action) {
  return alertReducer(state, action);
}

export function reloadAlertReducer(state: AlertState | undefined, action: Action) {
  return reloadReducer(state, action);
}
