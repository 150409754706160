import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ves-shared-tradingview-market-data',
  templateUrl: './shared-tradingview-market-data.component.html',
  styleUrls: ['./shared-tradingview-market-data.component.scss']
})
export class SharedTradingviewMarketDataComponent implements  OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() groups: SymbolGroup [] = [];
  @Input() chartUrl: string;
  @Input() type = 0;

  containerId: string;

  widgetMarketDataConfig: WidgetMarketDataConfig = new WidgetMarketDataConfig();

  private _onDestroy = new Subject();

  constructor(private translateService: TranslateService) {
    this.containerId = 'tradingview-market-data';
  }

  ngOnInit() {
    if (!this.chartUrl) {
      this.chartUrl = location.origin + '/market-views/market-overview/full-chart';
    }
    this.widgetMarketDataConfig.symbolsGroups = this.getSymbolGroups(this.type);
    this.widgetMarketDataConfig.locale = this.translateService.currentLang;
    this.widgetMarketDataConfig.largeChartUrl = this.chartUrl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.type && !changes.type.firstChange) {
      console.log('**************** MARKET DATA **********************');
      console.log(changes);
      this.widgetMarketDataConfig.symbolsGroups = this.getSymbolGroups(this.type);
      this.ngAfterViewInit();
    }
  }


  ngAfterViewInit() {
    const divForWidget = document.getElementsByClassName(this.containerId);
    if (divForWidget != null) {
      const myScript = document.createElement('script');
      myScript.innerHTML = JSON.stringify(this.widgetMarketDataConfig);
      myScript.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js';
      divForWidget[0].appendChild(myScript);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    const myDiv = document.getElementById(this.containerId);
    if (myDiv && myDiv.hasChildNodes()) {
      myDiv.removeChild(myDiv.childNodes[0]);
      myDiv.remove();
    }
  }

  getSymbolGroups(type): SymbolGroup[] {

    switch (type) {
      case 0: {
        // FOREX
        const group = new SymbolGroup();
        group.name = this.translateService.instant('market-overview.forex');
        group.originalName = this.translateService.instant('market-overview.forex');
        group.symbols = [];
        group.symbols.push( { name: 'FX:GBPUSD', displayName: 'BRITISH POUND / U.S. DOLLAR' });
        group.symbols.push( { name: 'FX:EURUSD', displayName: 'EURO / U.S. DOLLAR' });
        group.symbols.push( { name: 'FX:AUDUSD', displayName: 'AUSTRALIAN DOLLAR / U.S. DOLLAR' });
        group.symbols.push( { name: 'FX:USDJPY', displayName: 'U.S. DOLLAR / JAPANESE YEN' });
        group.symbols.push( { name: 'FX:USDCAD', displayName: 'U.S. DOLLAR / CANADIAN DOLLAR' });
        group.symbols.push( { name: 'FX:USDCHF', displayName: 'U.S. DOLLAR / SWISS FRANC' });
        group.symbols.push( { name: 'FX:NZDUSD', displayName: 'NEW ZEALAND DOLLAR / U.S. DOLLAR' });
        this.groups.push(group);
        break;
      }
      case 1: {
        // ALTERNATIVES METALS ENERGY AGRUCULTURAL
        const group = new SymbolGroup();
        group.name = this.translateService.instant('market-overview.metals');
        group.originalName = this.translateService.instant('market-overview.metals');
        group.symbols = [];
        group.symbols.push( { name: 'FX_IDC:XAUUSD', displayName: 'GOLD' });
        group.symbols.push( { name: 'TVC:GOLDSILVER', displayName: 'GOLDSILVER' });
        group.symbols.push( { name: 'FX_IDC:XPDUSD', displayName: 'PALLADIUM' });
        group.symbols.push( { name: 'FX_IDC:XPTUSD', displayName: 'PLATINUM' });
        group.symbols.push( { name: 'FOREXCOM:XAGUSD', displayName: 'SILVER' });
        group.symbols.push( { name: 'COMEX:HG1!', displayName: 'COPPER' });
        this.groups.push(group);
        const group2 = new SymbolGroup();
        group2.name = this.translateService.instant('market-overview.energy');
        group2.originalName = this.translateService.instant('market-overview.energy');
        group2.symbols = [];
        group2.symbols.push( { name: 'CURRENCYCOM:NATURALGAS', displayName: 'NATGASUSD' });
        group2.symbols.push( { name: 'TVC:UKOIL', displayName: 'UKOIL' });
        group2.symbols.push( { name: 'TVC:USOIL', displayName: 'USOIL' });
        this.groups.push(group2);

        const group3 = new SymbolGroup();
        group3.name = this.translateService.instant('market-overview.agricultural');
        group3.originalName = this.translateService.instant('market-overview.agricultural');
        group3.symbols = [];
        group3.symbols.push( { name: 'FOREXCOM:SOYUSD', displayName: 'SOYBNUSD' });
        group3.symbols.push( { name: 'OANDA:SUGARUSD', displayName: 'SUGARUSD' });
        group3.symbols.push( { name: 'CBOT:ZC1!', displayName: 'CORN FUTURES' });
        group3.symbols.push( { name: 'CBOT:ZW1!', displayName: 'WHEAT FUTURES' });
        this.groups.push(group3);
        break;
      }
      default: {
        // FOREX
        const group = new SymbolGroup();
        group.name = this.translateService.instant('market-overview.forex');
        group.originalName = this.translateService.instant('market-overview.forex');
        group.symbols = [];
        group.symbols.push( { name: 'FX:GBPUSD', displayName: 'BRITISH POUND / U.S. DOLLAR' });
        group.symbols.push( { name: 'FX:EURUSD', displayName: 'EURO / U.S. DOLLAR' });
        group.symbols.push( { name: 'FX:AUDUSD', displayName: 'AUSTRALIAN DOLLAR / U.S. DOLLAR' });
        group.symbols.push( { name: 'FX:USDJPY', displayName: 'U.S. DOLLAR / JAPANESE YEN' });
        group.symbols.push( { name: 'FX:USDCAD', displayName: 'U.S. DOLLAR / CANADIAN DOLLAR' });
        group.symbols.push( { name: 'FX:USDCHF', displayName: 'U.S. DOLLAR / SWISS FRANC' });
        group.symbols.push( { name: 'FX:NZDUSD', displayName: 'NEW ZEALAND DOLLAR / U.S. DOLLAR' });
        this.groups.push(group);
        break;
      }
    }
    return this.groups;
  }

}

export class WidgetMarketDataConfig {
  width: any = '100%';
  height: any = '100%';
  symbolsGroups: SymbolGroup[];
  isTransparent = false;
  colorTheme = 'light';
  locale: string;
  autosize: true;
  largeChartUrl: string;
}

export class SymbolGroup {
  name: string;
  originalName: string;
  symbols: any[] = [];
}
