import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {AlertService} from "../../api/services/alert.service";
import {Alert} from "../../models/vestrata/Alert";
import {takeUntil} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {retrieveAlerts} from "../../ngrx/actions/alert.actions";

@Component({
  selector: 'ves-shared-alert-dispatcher',
  template: ''
})
/*
 * This component will play the role of all A&N
 * retrieving, filtering and dispatching via
 * NGRX actions
 */
export class SharedAlertDispatcherComponent implements OnInit, OnDestroy, AfterViewInit{

  _onDestroy = new Subject();

  alertList: Alert[] = [];

  constructor(private alertService: AlertService, private store: Store<any>) { }

  ngOnInit() {
    this.alertService.startPeriodicAlerts(120)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        data => {
          console.log(data);
          this.store.dispatch(retrieveAlerts({payload:data}))
          // Filtering all alerts by category & subCategories
          // then dispatch for every action

        },
      )
  }

  ngAfterViewInit() {
    this.listenToReloadRequest();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private listenToReloadRequest() {
    this.store.select('reloadAlerts').pipe(takeUntil(this._onDestroy)).subscribe(
      state => {
        console.log('***Ask-Reload Alerts***',state);
        this.reloadAlerts();
      }
    );
  }

  private reloadAlerts() {
    this.alertService.getAlerts()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        data => {
          this.store.dispatch(retrieveAlerts({payload:data}));
        }
      )
  }
}
