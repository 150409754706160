import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

import {isEqual} from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ImplementationVehicle, ImplementationVehicleLocal} from '../../models/vestrata/TradeIdea';
import {InstrumentInfo} from '../../models/vestrata/Instrument/Instrument';
import {DocumentUploadTypes, VestrataDocument, VestrataDocumentInfo} from '../../models/vestrata/VestrataDocument';
import {AssetClass} from '../../models/vestrata/MajorAssetClass';
import {SharedSnackbarService} from '../shared-snackbar/services/shared-snackbar.service';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {DocumentService} from '../../api/services/document.service';
import {CommonService} from '../../api/services/common.service';
import {TradeIdeaService} from '../../api/services/trade-idea.service';
import {SnackbarMessage} from '../shared-snackbar/models/snackbar-message';
import {Permission} from '../../models/vestrata/Permission';
import {VersionStatus} from '../../models/vestrata/Version';
import {IpsService} from '../../api/services/ips.service';
import {Organisation} from '../../models/vestrata/Organisation';
import {AuthenticationService} from '../../api/services/authentication.service';

@Component({
  selector: 'ves-shared-implementation-vehicle',
  templateUrl: './shared-implementation-vehicle.component.html',
  styleUrls: ['./shared-implementation-vehicle.component.scss']
})
export class SharedImplementationVehicleComponent implements OnInit, OnDestroy, OnChanges {

  @Input() pendingStatus: VersionStatus;
  @Input() pendingIV: ImplementationVehicle;
  @Input() tradeIdeaId: string;
  @Input() currentIV: ImplementationVehicle;
  @Input() editingIV: ImplementationVehicle;
  @Output() editingIVChange = new EventEmitter<ImplementationVehicle>();
  @Input() readonly = false;
  @Input() display = false;
  @Input() currentLanguage;
  @Input() permission: Permission;

  presale: HTMLElement;
  menu = [];
  @Input() currentTab = 0;
  sides: any[];
  currentSide: any;
  viewAddInstrument = false;
  instruToExclude: InstrumentInfo[] = [];
  tempSelectedInstrument: InstrumentInfo[] = [];
  selectedInstrumentIds: string[] = [];
  switchTarget: string;


  _onDestroy = new Subject();
  temporaryDocument: VestrataDocument = new VestrataDocument();
  assetClasses: AssetClass[] = [];
  @Output() postSaleEvent = new EventEmitter();
  instrumentMaxSelection = -1;
  showInstrumentDetails = false;
  detailInstruId: string;
  pdfOnly = DocumentUploadTypes.pdf();

  organisation: Organisation;
  defCurrency: string;

  constructor(private translate: TranslateService, private snackbar: SharedSnackbarService,
              private loader: SharedLoaderService, private documentService: DocumentService,
              private ipsService: IpsService,
              private common: CommonService, private tiService: TradeIdeaService, private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.loadAssetClasses();
    this.initSides();

    // get organization currency
    this.ipsService.getOrganisation().pipe(takeUntil(this._onDestroy)).subscribe(result => {
      this.organisation = result;
      console.log('organisation', this.organisation);

      // get organisation currency
      if (this.organisation.defaultCurrency) {
        this.defCurrency = this.organisation.defaultCurrency;
      } else if (this.organisation.currencies && !this.organisation.defaultCurrency) {
        this.defCurrency = this.organisation.currencies[0];
      } else {
        this.defCurrency = 'EUR';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initMenu();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  subMenuSelected(tab) {
    if (this.pendingIV) {
      if (!this.currentIV && tab === 3) {
        this.menu[3].isActive = false;
      } else {
        this.currentTab = tab;
      }
    } else {
      this.menu[0].isActive = true;
      this.menu[1].isActive = false;
      this.menu[2].isActive = false;
      this.menu[3].isActive = false;
      this.menu[4].isActive = false;
    }
  }

  private initSides() {
    this.sides = [
      {name: this.translate.instant('trade-idea.side-sell'), object: 'sell'},
      {name: this.translate.instant('trade-idea.side-buy'), object: 'buy'},
      {name: this.translate.instant('trade-idea.side-switch'), object: 'switch'}
    ];
    this.currentSide = this.editingIV.side;
  }

  sideSelected(obj) {
    console.log(obj);
    if (this.editingIV.side !== obj) {
      if (obj === 'sell' && this.editingIV.sellSideProducts && this.editingIV.buySideProducts) {
        this.editingIV.sellSideProducts = this.editingIV.sellSideProducts.concat(Object.assign([], this.editingIV.buySideProducts));
        this.editingIV.side = obj;
      }
      if (obj === 'buy' && this.editingIV.buySideProducts && this.editingIV.sellSideProducts) {
        this.editingIV.buySideProducts = this.editingIV.buySideProducts.concat(Object.assign([], this.editingIV.sellSideProducts));
        this.editingIV.side = obj;
      }
      if (obj === 'switch' &&
        ((this.editingIV.buySideProducts && this.editingIV.buySideProducts.length > 1) ||
          (this.editingIV.sellSideProducts && this.editingIV.sellSideProducts.length > 1)
        )) {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('trade-idea.switch-instrument-clean'), 'warning'));
      } else {
        this.editingIV.side = obj;
      }
    }

    this.editingIVChange.emit(this.editingIV);
  }

  getLocalData(iv: ImplementationVehicle): ImplementationVehicleLocal {
    if (!iv || !iv.locales || iv.locales.length === 0) {
      return null;
    }
    let local = null;
    iv.locales.forEach(p => {
      if (p.lang === this.currentLanguage) {
        local = p;
      }
      if (!local) {
        local = iv.locales.find(i => i.lang === 'en');
      }
    });
    return local;
  }

  addSelectedInstrumentToIv() {

    if (this.editingIV.side === 'sell') {
      if (!this.editingIV.sellSideProducts) {
        this.editingIV.sellSideProducts = [];
      }
      this.editingIV.sellSideProducts = this.editingIV.sellSideProducts.concat(this.tempSelectedInstrument);
    }
    if (this.editingIV.side === 'buy') {
      if (!this.editingIV.buySideProducts) {
        this.editingIV.buySideProducts = [];
      }
      this.editingIV.buySideProducts = this.editingIV.buySideProducts.concat(this.tempSelectedInstrument);
    }
    if (this.editingIV.side === 'switch') {
      if (this.switchTarget === 'buy') {
        this.editingIV.buySideProducts = Object.assign([], this.tempSelectedInstrument);
      } else {
        this.editingIV.sellSideProducts = Object.assign([], this.tempSelectedInstrument);
      }
    }
    this.tempSelectedInstrument = [];
    this.viewAddInstrument = false;
  }

  addInstrument(param) {
    this.switchTarget = param;
    if (this.pendingIV.sellSideProducts && this.pendingIV.sellSideProducts.length > 0) {
      this.instruToExclude = this.instruToExclude.concat(this.pendingIV.sellSideProducts);
    }
    if (this.pendingIV.buySideProducts && this.pendingIV.buySideProducts.length > 0) {
      this.instruToExclude = this.instruToExclude.concat(this.pendingIV.buySideProducts);
    }
    this.viewAddInstrument = true;
  }

  getIsin(instru: InstrumentInfo) {
    return InstrumentInfo.getIdFromInstrumentIds(instru, 'ISIN');
  }

  removeFromInstruments(sideList: InstrumentInfo[], i: number) {
    sideList.splice(i, 1);
  }

  removeFromFiles(documents: VestrataDocumentInfo[], i: number) {
    documents.splice(i, 1);
  }

  addPresaleDocument(doc: VestrataDocumentInfo) {
    if (!this.editingIV.preSalesDocuments) {
      this.editingIV.preSalesDocuments = [];
    }

    if (this.editingIV.preSalesDocuments.findIndex(item => item.id === doc.id) > -1) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.document-already-selected'), 'error'));
      return;
    }

    this.editingIV.preSalesDocuments.push(Object.assign({}, doc));
  }
  addPostsaleDocument(doc: VestrataDocumentInfo) {
    if (!this.editingIV.postSalesDocuments) {
      this.editingIV.postSalesDocuments = [];
    }

    if (this.editingIV.postSalesDocuments.findIndex(item => item.id === doc.id) > -1) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.document-already-selected'), 'error'));
      return;
    }

    this.editingIV.postSalesDocuments.push(Object.assign({}, doc));
    this.saveAfterPostSale();
  }
  /*uploadFile(fs: any, type: string) {
    // console.log(fs[0]);
    if (fs && fs[0] && !['application/pdf'].includes(fs[0].type)) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('upload-file.upload-file-invalid-error'), 'error'));
      return;
    }
    this.loader.showFullLoader('upload-file.uploading');
    return this.documentService.uploadDocument(fs[0]).pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader()))
      .subscribe(returnUrl => {
        this.temporaryDocument.name = fs[0].name;
        this.temporaryDocument.url = returnUrl.url;
        this.temporaryDocument.modificationDate = new Date().toISOString();

        // console.log(this.temporaryDocument);
        if (type === 'presale') {
          if (!this.editingIV.preSalesDocuments) {
            this.editingIV.preSalesDocuments = [];
          }
          this.editingIV.preSalesDocuments.push(Object.assign({},this.temporaryDocument));
        }
        if (type === 'postsale') {
          if (!this.editingIV.postSalesDocuments) {
            this.editingIV.postSalesDocuments = [];
          }
          this.editingIV.postSalesDocuments.push(Object.assign({},this.temporaryDocument));
          this.saveAfterPostSale();
        }
      }, error => {
        console.log('error:' + error);
      });
  }*/

  private loadAssetClasses() {
    this.common.getMajorAssetClasses().pipe(takeUntil(this._onDestroy))
      .subscribe(data => this.assetClasses = data);
  }

  private saveAfterPostSale() {
    this.postSaleEvent.emit(this.currentTab);
  }

  showInstrumentDetail(instru) {
    this.detailInstruId = instru.id;
    this.showInstrumentDetails = true;
  }

  private initMenu() {
    this.menu = [
      {name: 'Construction', isActive: this.currentTab === 0},
      {name: this.display ? 'Products' : 'Product Selection', isActive: this.currentTab === 1},
      {name: 'Pre sales', isActive: this.currentTab === 2}
    ];
    if (this.currentIV) {
      this.menu.push({name: 'Post sales', isActive: this.currentTab === 3}, {name: 'Performance', isActive: this.currentTab === 4});
    }
  }

  isAdvisor() {
    return !this.auth.getPermissions().ips;
  }
}
