import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Alert, AlertLocal} from "../../../models/vestrata/Alert";
import {Store} from "@ngrx/store";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {askReloadNotifications, retrieveNotifications} from "../../../ngrx/actions/notification.actions";
import {SharedSnackbarService} from "../../shared-snackbar/services/shared-snackbar.service";
import {SnackbarMessage} from "../../shared-snackbar/models/snackbar-message";
import {
  BlocOption, FilterChild,
  FilterOption
} from "../../../basic-components/components-5/two-level-bar-filter/two-level-bar-filter.component";
import {cloneDeep} from 'lodash';
import {NotificationService} from "../../../api/services/notification.service";

@Component({
  selector: 'ves-shared-notif-list',
  templateUrl: './shared-notif-list.component.html',
  styleUrls: ['./shared-notif-list.component.scss']
})
export class SharedNotifListComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() notificationNumberChange = new EventEmitter<number>();
  @Output() advancedViewChange = new EventEmitter<boolean>();
  @Input() portal:  string;
  notifications: any[];
  filteredNotifications: Alert[];
  blocFilterOptions: BlocOption[] = [];
  sortField: string;
  sortOrder = false; //false for asc true for desc
  showMuted = false;
  _onDestroy = new Subject();
  isAdvanced = false;

  categoryTypes: any[] = [];
  categoryCount: number;
  currentLang: String;

  constructor(private store: Store<any>, private translate: TranslateService, private notificationService: NotificationService,
              private snackbar: SharedSnackbarService) { }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;

    this.translate.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe(event => {
      if (event.lang) {
        this.currentLang = this.translate.currentLang;
      }
    });

    this.subscribeToAlertStore();
  }

  ngAfterViewInit() {
    this.askForLoadAlerts();
  }
  private askForLoadAlerts() {
    this.store.dispatch(askReloadNotifications());
  }
  private subscribeToAlertStore() {
    this.store.select('retrieveNotifications').pipe(takeUntil(this._onDestroy)).subscribe(
      state => {
        // Extract category types and making a new array of them
        const filteredArr = state.allNotifications.map(t =>  {
          return {label: t.category, active: false};
        });
        this.categoryTypes = filteredArr.reduce((thing, current) => {
          const x = thing.find(item => item.label === current.label);
          if (!x) {
            return thing.concat([current]);
          } else {
            return thing;
          }
        }, []);

        console.log('***STATE Notifications***',state);
        this.notifications = cloneDeep(state.allNotifications);
        this.configureFlatNotificationList();
        this.notificationNumberChange.emit(this.notifications.length);
      }
    );
  }
  //-----ACTIONS------------
  showLessMore() {
    this.isAdvanced = !this.isAdvanced;
    this.advancedViewChange.emit(this.isAdvanced);
  }
  sortByField(field: string) {
    this.sortOrder = this.sortField === field ? !this.sortOrder : false;
    this.sortField = field;
    this.filteredNotifications.sort((a,b) => this.compare(a,b, field) )
  }
  compare( a, b, field ) {
    if ( a[field] < b[field] ){
      return this.sortOrder ? -1 : 1;
    }
    if ( a[field] > b[field] ){
      return this.sortOrder ? 1 : -1;
    }
    return 0;
  }
  muteAlert(notification: Alert){
    if (notification.state === 'MUTE'){
      return;
    }
    this.notificationService.muteNotification(notification).pipe(takeUntil(this._onDestroy))
      .subscribe(
        data => {
          notification.isMuted = true;
        },
        error => {
          this.snackbar.push(new SnackbarMessage(this.translate.instant('notifications.cannot-mute-notif'), 'error'));
        }
      )
  }
  navigateForAction(notification: Alert) {
    //this.performNotificationAction.emit(notification);
    this.notificationService.takeNotificationAction(notification, this.portal);
  }
  //-------------------------


  configureFlatNotificationList(){
    this.resetFilterCounts();
    this.notifications.forEach( notif => {
      notif.title = this.getNotificationFromLocal(notif).title;
      notif.text = this.getNotificationFromLocal(notif).text;
      notif.categoryColorClass = this.getCategoryColorClass(notif);
      this.updateFilterOptions(notif);
    });
    /*for (let i = 0; i< this.alerts.length; i++) {
      this.alerts[i].extens
      this.alerts[i] = Object.assign(
        this.alerts[i],
        {title: this.getAlertFromLocal(this.alerts[i]).title,
                text:  this.getAlertFromLocal(this.alerts[i]).text,
                categoryColorClass: this.getCategoryColorClass(this.alerts[i])
                }
        );*/
    /*this.alerts[i].title = this.getAlertFromLocal(this.alerts[i]).title;
    this.alerts[i].text = this.getAlertFromLocal(this.alerts[i]).text;
    this.alerts[i].categoryColorClass = this.getCategoryColorClass(this.alerts[i]);
    this.updateFilterOptions(this.alerts[i]);
  }*/
    this.filterNotifications();

  }
  //TODO This function must be simplified
  private filterNotifications() {
    // first filter is to show muted or not
    this.filteredNotifications = this.notifications.filter(alrt => this.showMuted || !alrt.isMuted);

    let categories = [];
    this.blocFilterOptions.forEach(bloc =>{
      if (bloc.options && bloc.options.length>0){
        bloc.options.forEach(option => {
          if (option.checked){
            categories.push({category:option.value, subcats: []})
          }
          option.childs.forEach(child => {
            if (child.checked){
              let prnt = categories.find(cat => cat.category === option.value);
              if (!prnt){
                prnt = {category:option.value, subcats: []};
                categories.push(prnt);
              }
              prnt.subcats.push(child.value);
            }
          });

        });
      }
    });
    if (categories.length>0){
      let tempList = [];
      categories.forEach(cat => {
        tempList = tempList.concat(this.filteredNotifications.filter(notification =>
          notification.category === cat.category && (cat.subcats.length ===  0 || cat.subcats.indexOf(notification.subCategory) >= 0 )
        ));
      });
      this.filteredNotifications = tempList;
    }


  }
  getCategoryColorClass(notification: Alert) {
    if (notification.isMuted){
      return  'cat-muted';
    }

    return 'cat-' + notification.category.toLowerCase();

  }
  getNotificationFromLocal(notification): AlertLocal {
    const local = this.translate.currentLang;
    let ret = notification.localized.find(s => s.lang === local );
    if (!ret) {
      ret = notification.localized.find(s => s.lang === 'en' );
    }
    return ret;
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private updateFilterOptions(notification: Alert) {
    // test if no bloc Existent
    if (this.blocFilterOptions.length === 0){
      const blocOption = new BlocOption();
      blocOption.label = 'notifications.categories'; blocOption.options = []
      this.blocFilterOptions.push(blocOption)
    }

    // check for parent if existing
    let parentCategory = this.blocFilterOptions[0].options.find(option => notification.category === option.value);
    if (!parentCategory) {
      parentCategory = new FilterOption(notification.category, notification.category, 0, true, true, []);
      this.blocFilterOptions[0].options.push(parentCategory);
    }
    parentCategory.totalCount++;
    // check if subCategegory
    let filterChild = parentCategory.childs.find(child => child.value === notification.subCategory);
    if (!filterChild){
      filterChild =  new FilterChild(notification.subCategory, notification.subCategory, true, 0);
      parentCategory.childs.push(filterChild);
    }
    filterChild.totalCount++;
  }

  private resetFilterCounts() {
    if (!this.blocFilterOptions || this.blocFilterOptions.length === 0){
      return
    }
    this.blocFilterOptions.forEach(bloc => {
      bloc.options.forEach(option => {
        option.totalCount = 0;
        option.childs.forEach(child => child.totalCount = 0);
      })
    });
  }

  onFiltersChange(event) {
    console.log(event);
    this.blocFilterOptions = event;
    this.categoryTypes.forEach((items) => items.active = false);
    this.filterNotifications();
  }

  showMutedChange(event) {
    this.showMuted = event;
    this.filterNotifications();
  }

  canPerformAction(notification: Alert) {
    return this.notificationService.getNotificationAction(notification, this.portal);
  }

  onChangeCategoryTypeSelected(active: any, type: any) {
    let tempList = [];

    if (this.categoryTypes.find(item => !item.active && item.label === type.label)) {
      this.categoryTypes.forEach((items) => {
        items.active = false;
      });
      this.categoryTypes.find(item => item.label === type.label).active = true;
      this.notifications.forEach(() => {
        tempList = this.notifications.filter(notification => notification.category === type.label);
      });
    }

    if(tempList.length > 0) {
      this.filteredNotifications = tempList;
    }
    else {
      this.filteredNotifications = this.notifications;
    }
  }

  categoryCounting(type: any) {
    let counter = [];
    this.notifications.forEach(() => {
      counter = this.notifications.filter(notification => notification.category === type);
    });
    return this.categoryCount = counter.length;
  }
}
