import { InvestmentObjective } from "./InvestmentObjective";
import { PortfolioModelDistribution, PortfolioModelVersion } from "./PortfolioModel";
import { Version, VersionStatus } from './Version';
import {SearchRequest} from "./SearchRequest";

export class PortfolioModelListDto {
  id: string;
  strategyName: string;
  investmentObjectiveName: string;
  investmentObjectiveRank: number;
  opened: boolean;
  pendingStatus: string; // deplitcated?
  style: string;
  currency: string;
  lastUpdate: string;
  lastUpdateBy: string;
  bankApprovalCount: number;
  assetAllocations: AssetAllocation[];
  distributions: PortfolioModelDistribution[];
  hasCurrent: boolean;
  lastMetricDate: string;
  firstApproval: string;
  metricsUpdateFrequency: string;
  metricsUpdateDay: number;
  metricPendingStatus: VersionStatus;
  acknowledged?: boolean;
  strategyEsgCriteria: boolean;
  strategyType: string;
  clientRiskProfile?: number;

// FOR IPS only
  partnerId: string;
  strategyPicture: string;
  partnerName: string;
  partnerPicture: string;
  strategyId: string;

  distributionStatus: string;
}

export class AssetAllocation {
  assetClass: string;
  percentage: number;
}

export class StrategyPortfolioModelListDto {
  id: string;
  strategyName: string;

  opened: boolean;
  pendingStatus: string;
  current: Version<PortfolioModelVersion>;
  pending: Version<PortfolioModelVersion>;
  investmentObjective: InvestmentObjective;
  style: string;
  currency: string;
  lastUpdate: string;
  lastUpdateBy: string;
  bankApprovalCount: number;
  assetAllocations: AssetAllocation[];
}

export class PortfolioModelFilter {
  searchRequest: SearchRequest;
  currencies: string[];
  strategy: string[];
  partner: string[];
  risks: number[];
  partnerOrder: string;
  currencyOrder: string;
  riskRankOrder: string;
}
