import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ves-shared-completion-remaining',
  templateUrl: './shared-completion-remaining.component.html',
  styleUrls: ['./shared-completion-remaining.component.scss']
})
export class SharedCompletionRemainingComponent implements OnInit {

  @Input() style: 'filled' | 'outlined' = 'outlined';
  @Input() type: 'success' | 'alert' | 'neutral'  | 'info' | 'note' = 'alert';
  @Input() rightTarget: any;
  @Input() leftValue: any;
  @Input() valueSymbol: any;
  @Input() label: string;
  constructor() { }

  ngOnInit(): void {
  }

  getLeftIcon() {
    const path = '/assets/images/components/components-22/';
    const prefix = this.style === 'filled' ? '-white' : '';
    switch (this.type) {
      case "alert":
        return path + 'alert-icon' + prefix + '.svg';
      case "note":
        return path + 'note-icon' + prefix + '.svg';
      default:
        return path + 'check-icon' + prefix + '.svg';
    }
  }

  getComparisonIcon() {
    const path = '/assets/images/components/components-22/';
    const prefix = this.style === 'filled' ? '-white' : '';
    const symbolType = this.leftValue === this.rightTarget ? 'equal' : 'nequal';
    return path + 'comparison-icon-' + symbolType + prefix + '.svg';
  }
}
