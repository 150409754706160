import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService {
  constructor() {

  }
  static getHeadersPlainText(): {headers: HttpHeaders} {
    return { headers: new HttpHeaders( {'Content-Type': 'application/json', 'responseType': 'text' })};
  }
  static getHeaders() {
    return { headers: new HttpHeaders( {'Content-Type': 'application/json' })};
  }
  static getHeadersForUpload(): { headers: HttpHeaders } {
    return { headers: new HttpHeaders()};
  }
  static getHeadersForFileType(type: string): { headers: HttpHeaders } {
    return { headers: new HttpHeaders({'Content-Type': type })};
  }
  static getHeadersForFile(): HttpHeaders {
    return new HttpHeaders({'Content-Type': 'text/csv; charset=utf-8'});
  }
  static getOptionsWithParams(httpParams: HttpParams) {
    return {headers: new HttpHeaders( {'Content-Type': 'application/json' } ), params: httpParams};
  }
}
