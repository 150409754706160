import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {VersionStatus} from "../../../models/vestrata/Version";
import {Permission} from "../../../models/vestrata/Permission";

@Component({
  selector: 'ves-move',
  templateUrl: './move.component.html',
  styleUrls: ['./move.component.scss']
})
export class MoveComponent implements OnInit, OnChanges {

  @Input()
  itemIndex: number;

  @Input()
  arrayLength: number;

  @Input()
  locked: boolean = false;

  // Set the flag if you want to enable the color management of the 4eyes
  @Input() fourEyesMode: boolean = true;
  // set the instance like an id to have different behaviour on the same page
  @Input() instance: string;
  //// set the pending version to manage the approved state
  @Input() pendingVersionStatus: VersionStatus;
  // set the permission
  @Input() permission: Permission;


  @Output() move = new EventEmitter<any>();




  up: boolean = true;
  down: boolean = true;

  constructor() { }

  ngOnInit() {
    this.checkActions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.itemIndex && (changes.itemIndex.currentValue !== changes.itemIndex.previousValue )) {
        this.checkActions();
      }
      if (changes.arrayLength && (changes.arrayLength.currentValue !== changes.arrayLength.previousValue)) {
        this.arrayLength --;
        this.checkActions();
      }
    }
  }

  checkActions() {
    this.up = this.itemIndex !== 0;
    this.down = this.itemIndex !== this.arrayLength;
  }

  moveClick(direction) {
    if (this.pendingVersionStatus !== VersionStatus.REQUEST && this.permission.write) {
      this.move.emit(direction);
    } else {
      this.locked = true;
    }
  }

  getStyleClass(status: VersionStatus, blocked: boolean): string {
    let styleClass = '';
    if (this.fourEyesMode) {
      switch (status) {
        case VersionStatus.EDITED :
          styleClass = 'edited';
          break;
        case VersionStatus.SAVED :
          styleClass = 'saved';
          break;
        case VersionStatus.REQUEST :
          styleClass = 'submitted';
          break;
      }
    }
    if (blocked && status !== VersionStatus.REQUEST) {
      styleClass = 'locked';
    }

    return styleClass;
  }


}
