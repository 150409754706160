import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ves-shared-document-chooser-dialog',
  templateUrl: './shared-document-chooser-dialog.component.html',
  styleUrls: ['./shared-document-chooser-dialog.component.scss']
})
export class SharedDocumentChooserDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
