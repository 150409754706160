import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {
  AssetClass,
  InstrumentData,
  InstrumentId,
  InstrumentType,
  InstrumentWithPriceDto,
  MifidBenchmark,
  PortfolioModel
} from '../../../../public-api';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {LastPriceDto} from "../../../models/vestrata/Instrument/Instrument";
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'ves-shared-instrument-categorisation',
  templateUrl: './shared-instrument-categorisation.component.html',
  styleUrls: ['./shared-instrument-categorisation.component.scss']
})
export class SharedInstrumentCategorisationComponent implements OnInit, OnDestroy {

  private readonly PAST_PERFORMANCES_COLORS = ['#003057', '#248ED9'];
  mainColors = this.PAST_PERFORMANCES_COLORS;

  @Input() performance: any[];
  labels: string[];

  private _onDestroy = new Subject();
  @Input() instrumentWithPrice: InstrumentWithPriceDto;
  @Input() analytics: any;
  @Input() assetClasses: AssetClass[];

  constructor(private translate: TranslateService,
              private cp: CurrencyPipe) {
  }
  instrument: any;

  ngOnInit(): void {
    this.instrument = this.instrumentWithPrice.instrument;
    this.labels = [];
    this.labels.push(this.translate.instant('instrument-details.growth.instrument') + ' > ' + this.instrument.name);
    console.log('*********** INSTRUMENT CATEGORISATION **********');
    console.log(this.performance);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getDropdownData(info: any, type) {
    const data: InstrumentData[] = [];
    if ( type === 'InstrumentId') {
      data.push(...this.getInstrumentIdsList(this.instrument));
    } else if ( type === 'Sustainability') {
      const e = new InstrumentData();
      const s = new InstrumentData();
      const g = new InstrumentData();
      e.label = this.translate.instant('instrument-details.analytics.environmental');
      e.value = info && info.environmental ? info.environmental : ' -';
      s.label = this.translate.instant('instrument-details.analytics.social');
      s.value = info && info.social ? info.social : ' -';
      g.label = this.translate.instant('instrument-details.analytics.governance');
      g.value = info && info.governance ? info.governance : ' -';
      data.push(e);
      data.push(s);
      data.push(g);
    } else if ( type === 'Price') {
      const price = new InstrumentData();
      price.label = this.instrumentWithPrice?.lastPrice?.lastPriceDate ? this.instrumentWithPrice.lastPrice.lastPriceDate : '-';
      price.value = this.instrumentWithPrice?.lastPrice?.lastPrice ? this.instrumentWithPrice.lastPrice.lastPrice.toString() : '0';
      data.push(price);
    }
    return data;
  }

  checkPriips() {
    switch (this.instrument.type) {
      case 'Equity': {
        return 'N';
      }
      default: {
        return 'Y';
      }
    }
  }

  getPriceLabel(): string {
    if (this.instrument.type === 'Fund') {
      return 'instrument-details.analytics.last-nav';
    } else {
      return 'instrument-details.analytics.last-price';
    }
  }
  getPriceCurrency(): string {
    if (this.instrument.priceCurrency) {
      return this.instrument.priceCurrency;
    } else {
      return this.instrument.currency;
    }
  }

  getSector() {
    let ret = '';
    if (this.instrument.sector && this.instrument.sector.length > 0 ) {
      ret = this.instrument.sector[0];
      if (this.instrument.sector[1]) {
        ret += ' › ' + this.instrument.sector[1];
      }
    }
    return ret;
  }

  getBenchmark(mb: MifidBenchmark[]) {
    let ret = '';
    if (mb && mb.length > 0) {
      mb.forEach(b => {
        ret += b.name;
        if (mb.length > 1) {
          ret += '(' + b.weighting + ') ';
        }
      });
    }
    return ret;
  }

  getProductType(types: string[]) {
    return types[types.length - 1];
  }

  getFee(type) {
    switch (type) {
      case 'initial': {
        if (this.instrument.initialFeesEstimated) {
          return this.instrument.initialFeesEstimated.toString();
        } else {
          return '0';
        }
      }
      case 'onGoing': {
        if (this.instrument.ongoingChargeEstimated) {
          return this.instrument.ongoingChargeEstimated.toString();
        } else {
          return '0';
        }
      }
      case 'performance': {
        if (this.instrument.performanceFeeEstimated) {
          return this.instrument.performanceFeeEstimated.toString();
        } else {
          return '0';
        }
      }
      case 'transaction': {
        if (this.instrument.transactionFeeEstimated) {
          return this.instrument.transactionFeeEstimated.toString();
        } else {
          return '0';
        }
      } default: {
          return '0';
      }
    }
  }

  getHighLow(priceInfo: LastPriceDto) {
    if (priceInfo && priceInfo.yearHigh && priceInfo.yearLow) {
      const curr = this.cp.transform(priceInfo?.yearLow, this.instrumentWithPrice?.instrument?.currency, 'symbol', '1.0', this.translate.currentLang);
      const currency = curr.charAt(0);
      return currency + ' ' + priceInfo.yearLow + ' - ' + priceInfo.yearHigh;
    }
  }

  getDayRange(priceInfo: LastPriceDto) {
    if (priceInfo && priceInfo.high && priceInfo.low) {
      const curr = this.cp.transform(priceInfo?.low, this.instrumentWithPrice?.instrument?.currency, 'symbol', '1.0', this.translate.currentLang);
      const currency = curr.charAt(0);
      return currency + ' ' + priceInfo.low + ' - ' + priceInfo.high;
    }
  }

  isYieldEnhancement(): boolean {
    return this.instrument.type === 'StructuredProduct' && this.instrument.structuredProductType === 'Yield Enhancement';
  }

  getBarrierLevel() {
    console.log('Calculate Barrier Level');
    console.log('value', this.instrument.initialUnderlyingLevel);
    console.log(this.instrument.barrier);
    console.log((this.instrument.initialUnderlyingLevel / 100) * this.instrument.barrier);
    return (this.instrument.initialUnderlyingLevel / 100) * this.instrument.barrier;
  }

  private getInstrumentIdsList(instrument: any): InstrumentData[] {
    const data = [];
    if (instrument.type === InstrumentType.EQUITY_TYPE) {

      // Ticker First
      const ticker = instrument?.exchangeData?.find(d => d.primary === true);
      if (ticker) {
        const row = new InstrumentData();
        row.label = 'TICKER';
        row.value = ticker.ticker;
        data.push(row);
      }

      // ISIN
      const isin = instrument?.instrumentIds?.find(d => d.idType === 'ISIN');
      if (isin) {
        const row = new InstrumentData();
        row.label = isin.idType;
        row.value = isin.id;
        data.push(row);
      }

      // OTHERS
      instrument?.instrumentIds?.forEach(d => {
        if (d.idType !== 'ISIN') {
          const row = new InstrumentData();
          row.label = d.idType;
          row.value = d.id;
          data.push(row);
        }
      });

    } else if (instrument.type === InstrumentType.DEBT_TYPE) {

      // ISIN
      const isin = instrument?.instrumentIds?.find(d => d.idType === 'ISIN');
      if (isin) {
        const row = new InstrumentData();
        row.label = isin.idType;
        row.value = isin.id;
        data.push(row);
      }

      // Bloomberg
      const bbg = instrument?.instrumentIds?.find(d => d.idType === 'BBG');
      if (bbg) {
        const row = new InstrumentData();
        row.label = bbg.idType;
        row.value = bbg.id;
        data.push(row);
      }

      // TICKER
      const ticker = instrument?.exchangeData?.find(d => d.primary === true);
      if (ticker) {
        const row = new InstrumentData();
        row.label = 'TICKER';
        row.value = ticker.ticker;
        data.push(row);
      }

      // OTHERS
      instrument?.instrumentIds?.forEach(d => {
        if (d.idType !== 'ISIN' && d.idType !== 'BBG') {
          const row = new InstrumentData();
          row.label = d.idType;
          row.value = d.id;
          data.push(row);
        }
      });


    } else {

      // ISIN
      const isin = instrument?.instrumentIds?.find(d => d.idType === 'ISIN');
      if (isin) {
        const row = new InstrumentData();
        row.label = isin.idType;
        row.value = isin.id;
        data.push(row);
      }

      // TICKER
      const ticker = instrument?.exchangeData?.find(d => d.primary === true);
      if (ticker) {
        const row = new InstrumentData();
        row.label = 'TICKER';
        row.value = ticker.ticker;
        data.push(row);
      }

      // Bloomberg
      const bbg = instrument?.instrumentIds?.find(d => d.idType === 'BBG');
      if (bbg) {
        const row = new InstrumentData();
        row.label = bbg.idType;
        row.value = bbg.id;
        data.push(row);
      }

      // OTHERS
      instrument?.instrumentIds?.forEach(d => {
        if (d.idType !== 'ISIN' && d.idType !== 'BBG') {
          const row = new InstrumentData();
          row.label = d.idType;
          row.value = d.id;
          data.push(row);
        }
      });
    }

    return data;
  }

  getPrimaryExchange(instrument: any): string {
    const exchange = instrument?.exchangeData?.find(e => e.primary === true);
    return exchange ? exchange.name : '';
  }

  getIssuedAmount(instrument: any): string {
    let ia = '';
    if (instrument?.issuedAmount) {
      const splitAmount = (instrument?.issuedAmount as string)?.split(' ');
      const amount = splitAmount[0];
      const currency = splitAmount[1];
      ia = this.cp.transform(amount, currency, 'symbol', '1.0', this.translate.currentLang);
    }
    return ia;
  }
}
