import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'ves-card-btn',
  templateUrl: './card-btn.component.html',
  styleUrls: ['./card-btn.component.scss']
})
export class CardBtnComponent implements OnInit, OnChanges {

  @Output()
  eachClick = new EventEmitter<any>();

  @Input()
  id: string;

  @Input()
  isLocked: boolean;

  imgUrl: string = '../../../../assets/images/components/components-4/card-btn-add.svg';
  imgUrlHover: string = '../../../../assets/images/components/components-4/hover/card-btn-add.svg';
  imgUrlLocked: string = '../../../../assets/images/components/components-4/disabled/card-btn-add.svg';

  currentImg: string;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isLocked) {
      this.currentImg = this.imgUrlLocked;
    } else if (changes && changes.cardBtnAdd) {
      this.currentImg = this.imgUrl;
    }
  }

  onHover() {
    this.currentImg = this.imgUrlHover;
    if (this.isLocked) {
      this.currentImg = this.imgUrlLocked;
    }
  }

  onHoverOut() {
    this.currentImg = this.imgUrl;
    if (this.isLocked) {
      this.currentImg = this.imgUrlLocked;
    }
  }

  click() {
      this.eachClick.emit();
  }

  ngOnInit() {
    if (this.isLocked) {
      this.currentImg = this.imgUrlLocked;
    } else {
      this.currentImg = this.imgUrl;
    }
  }



}
