import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TooltipData} from '../tooltip/tooltip.component';

@Component({
  selector: 'ves-thumb-btn',
  templateUrl: './thumb-btn.component.html',
  styleUrls: ['./thumb-btn.component.scss']
})
export class ThumbBtnComponent implements OnInit, OnChanges {

  @Output()
  eachClick = new EventEmitter<any>();

  currentIcon: string;

  @Input()
  tooltipData: TooltipData;
  showTooltip: boolean = false;

  @Input()
  tooltipPosition: 'center' | 'left' = 'center';

  @Input()
  id: string;

  @Input()
  imgUrl: string;

  @Input()
  imgUrlHover: string;

  @Input()
  imgUrlLocked: string;

  @Input()
  isLocked: boolean;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('btn-change');
    // console.log(changes);
    if (this.isLocked) {
      this.currentIcon = this.imgUrlLocked;
    } else {
      this.currentIcon = this.imgUrl;
    }
  }

  onHover() {
    this.currentIcon = this.imgUrlHover;
    if (this.isLocked) {
      this.currentIcon = this.imgUrlLocked;
    }
    this.showTooltip = true;
  }

  onHoverOut() {
    this.currentIcon = this.imgUrl;
    this.showTooltip = false;
    if (this.isLocked) {
      this.currentIcon = this.imgUrlLocked;
    }
  }

  click() {
    console.log('click');
    if (!this.isLocked) {
      this.eachClick.emit();
    }
  }

  ngOnInit() {
    if (this.isLocked) {
      this.currentIcon = this.imgUrlLocked;
    } else {
      this.currentIcon = this.imgUrl;
    }
  }

  getArrowPosition(tooltipPosition: string): string {
    let value = 'center';
    switch (tooltipPosition) {
      case 'center': value = 'center'; break;
      case 'left': value = 'right'; break;
    }
    return value;
  }

  getTooltipPosition(position: string) {
    let value = 'center';
    switch (position) {
      case 'center': value = 'tool-center'; break;
      case 'left': value = 'tool-left'; break;
    }
    return value;
  }

}

export class IconUrl {
  readonly id: string;
  imgUrl: string;
  imgUrlHover: string;
  imgUrlLocked: string;
  isLocked: boolean;

  constructor(id: string, isLocked: boolean) {
    this.id = id;
    this.isLocked = isLocked;
  }
}
