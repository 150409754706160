import {Injectable} from '@angular/core';
import {Article, ArticleCategoryDto, ArticleType} from '../../models/vestrata/Article';
import {Observable, of} from 'rxjs';
import {InsightService} from './insight.service';
import {TutorialService} from './tutorial.service';
import {Page} from '../../models/vestrata/Page';
import {ResearchService} from './research.service';
import {Insight, TagGroup} from '../../models/vestrata/Insight';
import {ApiHttpService} from '../api.http.service';
import {HttpClient} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {Research} from '../../models/vestrata/Research';
import {Tutorial} from '../../models/vestrata/Tutorial';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  serviceUrl: string;

  constructor(private http: HttpClient,
              private insightService: InsightService,
              private researchService: ResearchService,
              private confService: VesConfService,
              private tutorialService: TutorialService,
              private translate: TranslateService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  search(searchRequest: any, type: ArticleType): Observable<Page<Article<any>>> {
    switch (type) {
      case ArticleType.INSIGHT: return this.insightService.getInsightsWithSearchCriteria(searchRequest);
      case ArticleType.RESEARCH: return this.researchService.search(searchRequest);
      case ArticleType.TUTORIAL: return this.tutorialService.search(searchRequest);
      default: of(null);
    }
  }

  getById(instanceId: string, type: ArticleType): Observable<Article<any>> {
    switch (type) {
      case ArticleType.INSIGHT: return this.insightService.getInsightById(instanceId);
      case ArticleType.RESEARCH: return this.researchService.getById(instanceId);
      case ArticleType.TUTORIAL: return this.tutorialService.getById(instanceId);
      default: of(null);
    }
  }

  // TODO: Change
  getArticleDetailsCategories(): Observable<ArticleCategoryDto> {
    return this.http.get<ArticleCategoryDto>(this.serviceUrl + 'insight/category?=' + this.translate.currentLang,
      ApiHttpService.getHeaders());
  }

  getTagGroups(): Observable<TagGroup[]> {
    return this.http.get<TagGroup[]>(this.serviceUrl + 'global/tag/',  ApiHttpService.getHeaders());
  }

  createArticle(type: ArticleType, article: Article<any>): Observable<Article<any>> {
    switch (type) {
      case ArticleType.INSIGHT: return this.insightService.createInsight(article as Insight);
      case ArticleType.RESEARCH:  return this.researchService.create(article as Research);
      case ArticleType.TUTORIAL:  return this.tutorialService.create(article as Tutorial);
      default: of(null);
    }
  }

  updateArticle(type: ArticleType, article: Article<any>): Observable<Article<any>> {
    switch (type) {
      case ArticleType.INSIGHT: return this.insightService.updateInsight(article as Insight);
      case ArticleType.RESEARCH:  return this.researchService.update(article as Research);
      case ArticleType.TUTORIAL:  return this.tutorialService.update(article as Tutorial);
      default: of(null);
    }
  }

/*  deleteArticle(type: ArticleType, article: Article<any>): Observable<Article<any>> {
    switch (type) {
      case ArticleType.INSIGHT: return this.insightService.deleteInsight(article as Insight);
      case ArticleType.RESEARCH:  return this.insightService.deleteInsight(article as Research);
      case ArticleType.TUTORIAL:  return this.insightService.deleteInsight(article as Tutorial);
      default: of(null);
    }
  }*/

  submitArticleForApproval(type: ArticleType, article: Article<any>): Observable<Article<any>> {
    switch (type) {
      case ArticleType.INSIGHT: return this.insightService.submitInsightForApproval(article.id);
      case ArticleType.RESEARCH:  return this.researchService.submitForApproval(article.id);
      case ArticleType.TUTORIAL:  return  this.tutorialService.submitForApproval(article.id);
      default: of(null);
    }
  }

  cancelArticleApproval(type: ArticleType, article: Article<any>): Observable<Article<any>> {
    switch (type) {
      case ArticleType.INSIGHT: return this.insightService.cancelInsightApproval(article.id);
      case ArticleType.RESEARCH:  return this.researchService.unlock(article.id);
      case ArticleType.TUTORIAL:  return this.tutorialService.unlock(article.id);
      default: of(null);
    }
  }

  approveArticleChange(type: ArticleType, article: Article<any>): Observable<Article<any>> {
    switch (type) {
      case ArticleType.INSIGHT: return this.insightService.approveInsightChange(article.id);
      case ArticleType.RESEARCH:  return this.researchService.approveChange(article.id);
      case ArticleType.TUTORIAL:  return this.tutorialService.approveChange(article.id);
      default: of(null);
    }
  }

  declineArticleChange(type: ArticleType, article: Article<any>, note: string): Observable<Article<any>> {
    switch (type) {
      case ArticleType.INSIGHT: return this.insightService.declineInsightChange(article.id, note);
      case ArticleType.RESEARCH:  return this.researchService.declineChange(article.id, note);
      case ArticleType.TUTORIAL:  return this.tutorialService.declineChange(article.id, note);
      default: of(null);
    }
  }

  reset(type: ArticleType, article: Article<any>): Observable<Article<any>> {
    switch (type) {
      case ArticleType.INSIGHT: return this.insightService.reset(article.id);
      case ArticleType.RESEARCH:  return this.researchService.reset(article.id);
      case ArticleType.TUTORIAL:  return this.tutorialService.reset(article.id);
      default: of(null);
    }
  }


}
