import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Permission } from '../../models/vestrata/Permission';

@Component({
  selector: 'ves-shared-dialog',
  templateUrl: './shared-dialog.component.html',
  styleUrls: ['./shared-dialog.component.scss']
})
export class SharedDialogComponent implements OnInit {

  @Input()
  btnText = '';

  @Input()
  secondBtnText: string;

  @Input()
  permission: Permission;

  @Output()
  goBack = new EventEmitter<any>();

  @Output()
  btnClick = new EventEmitter<any>();

  @Output()
  secondBtnClick = new EventEmitter<any>();

  @Input()
  headTextKey: string;

  @Input()
  size = 'small';

  @Input()
  withFooter = true;

  @Input()
  btnType = 'readwrite';

  dialogHeadText: string;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    if (this.headTextKey) {
      this.dialogHeadText = this.translate.instant(this.headTextKey);
    } else {
      this.dialogHeadText = this.translate.instant('dialog.missing-header');
    }
  }

  closeDialog() {
    this.goBack.emit();
  }

  clickBtn() {
    this.btnClick.emit();
  }
  clickSecondBtn() {
    this.secondBtnClick.emit();
  }
}
