import {Article, ArticleCategoriesDto, ArticleLocal, ArticleMongoSearch, ArticleSearchCriteria, ArticleVersion} from './Article';
import {Version} from './Version';
import {VestrataDocumentInfo} from './VestrataDocument';

export class Tutorial extends Article<TutorialVersion> {
  enable: boolean;
  history: Version<TutorialVersion>[];
}

export class TutorialVersion extends ArticleVersion<ArticleLocal> {
  sectors?: string[];
  cpd: boolean;
  expectedReadingTime: string;
  version: number;
  completed: {id: string, name: string, email: string, date: string}[];
  documents: VestrataDocumentInfo[];
}

export enum TutorialMainCategory {
  EDUCATIONAL = 'EDUCATIONAL',
  COMPLIANCE = 'COMPLIANCE',
  RISK = 'RISK',
  GENERAL = 'GENERAL',
}

export class TutorialCategoriesDto extends ArticleCategoriesDto {
  mainCategory: TutorialMainCategory;
}

export class TutorialSearchCriteria extends ArticleSearchCriteria {
  completed: boolean;
}

export class TutorialMongoSearch extends ArticleMongoSearch {
  static readonly completed = 'completed';
  static readonly enable = 'enable';
}

export enum TutorialCPD {
  MINUTES_30 = 'MINUTES_30',
  MINUTES_60 = 'MINUTES_60',
  MINUTES_90 = 'MINUTES_90',
  MINUTES_120 = 'MINUTES_120',
}
