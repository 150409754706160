import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {forkJoin, of, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as moment_ from 'moment';
const moment = moment_;

import {PortfolioModel} from "../../models/vestrata/PortfolioModel";
import {PerformanceService} from "../../api/services/performance.service";
import {MetricsService} from "../../api/services/metrics.service";
import {MetricData} from "../../models/vestrata/MetricData";

@Component({
  selector: 'app-shared-model-performances',
  templateUrl: './shared-model-performances.component.html',
  styleUrls: ['./shared-model-performances.component.scss']
})
export class SharedModelPerformancesComponent implements OnInit, OnChanges, OnDestroy {

  private _onDestroy = new Subject();
  private readonly DATE_FORMAT = 'YYYY-MM-DD';
  private readonly AMOUNT = 10000;
  private readonly BASE_INDEX = 100;
  private readonly PROJECTED_YEARS = '10';
  public readonly QUARTERS = ['Q1', 'Q2', 'Q3', 'Q4'];
  private readonly PAST_PERFORMANCES_COLORS = ['#00C4B3', '#248ed8'];
  private readonly PROJECTED_PERFORMANCES_COLORS = ['#C66919', '#00C4B3', '#248ED8'];

  @Input() model: PortfolioModel;

  projectedPerformancesLabels: string[];
  pastPerformanceLabel: string[];

  viewDisclaimer = false;
  mainColors = this.PAST_PERFORMANCES_COLORS;
  minorColors = ['#F2FCFB', '#D2E8F7'];
  chartTimeUnit;


  performances: any[];
  labels: string[];

  metrics: MetricData;
  benchmarkMetrics: MetricData;
  currentReturnValues: any[];
  currentBenchmarkReturnValues: any[];

  startDate = moment();
  endDate = moment();
  now = moment();
  moment = moment;

  minStartDate: string;
  maxStartDate: string;
  minEndDate: string;
  maxEndDate: string;

  currentReturnArray: 'trailing' | 'yearly' | 'quarterly' = 'yearly';
  currentCategory: 'past' | 'projected' = 'past';

  constructor(private performanceService: PerformanceService,
              private metricsService: MetricsService,
              private translate: TranslateService) {
    moment.locale(translate.currentLang);
    this.getLabels(translate.currentLang);
    this.translate.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe((event) => {
      this.getLabels(event.lang);
      moment.locale(event.lang);
    });
  }

  private static filterTimePeriod(t1: any, t2: any) {
    return t1.localeCompare(t2, undefined, {numeric: true});
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && changes.model.currentValue) {
      if (this.model.id) {
        this.startDate = moment(this.model.inceptionDate);
        this.setMinAndMaxDate();

        this.getLabels(this.translate.currentLang);
        this.labels = this.currentCategory === 'past' ? this.pastPerformanceLabel : this.projectedPerformancesLabels;
        this.getPortfolioModelPerformance(this.model, this.AMOUNT, this.startDate, this.endDate);

        this.metricsService.getModelMetricData(this.model.id).pipe(takeUntil(this._onDestroy)).subscribe(md => {
          if (md && md.current) {
            this.metrics = md;
            this.currentReturnValues = this.getReturnValueByFilter(this.currentReturnArray, this.metrics);
          }
        });

        this.metricsService.getModelBenchmarkMetricData(this.model.id).pipe(takeUntil(this._onDestroy)).subscribe(md => {
          console.log('******Model Benchmark****', md);
          this.benchmarkMetrics = md;
          this.currentBenchmarkReturnValues = this.getReturnValueByFilter(this.currentReturnArray, this.benchmarkMetrics)
        });
      }
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  onSlidingBarDateChanged(startDate: string) {
    this.startDate = moment(startDate, 'L');
    this.setMinAndMaxDate();
    this.getPortfolioModelPerformance(this.model, this.AMOUNT, this.startDate, this.endDate);
  }

  onRangeDateChanged(event: any) {
    if (this.currentCategory === 'past') {
      if (this.startDate === this.now) {
        this.startDate = moment(this.model.inceptionDate);
      } else {
        this.startDate = moment(this.startDate);
      }
      this.endDate = moment(this.endDate);
      this.setMinAndMaxDate();
      this.getPortfolioModelPerformance(this.model, this.AMOUNT, this.startDate, this.endDate);
      console.log('changing to past');
    } else {
      this.startDate = this.now;
      this.endDate = moment().add(this.PROJECTED_YEARS, 'year');
      this.setMinAndMaxDate();
      console.log('changing to projected');
      this.getProjectedPerformance(this.model, this.AMOUNT, this.endDate);
    }
  }

  onCurrentArrayChanged(value: 'trailing' | 'yearly' | 'quarterly') {
    this.currentReturnArray = value;
    this.currentReturnValues = this.getReturnValueByFilter(value, this.metrics);
    this.currentBenchmarkReturnValues = this.getReturnValueByFilter(value, this.benchmarkMetrics);
  }

  getReturnValueByFilter(filter: 'trailing' | 'yearly' | 'quarterly', metrics: MetricData): any[] {
    let values = [];

    if (filter === 'trailing') {
      const trailingData = metrics.current.data.trailingData.find(item => item.metricId === 'TR').data;
      for (const data of trailingData) {
        values.push({header: data.timePeriodId, value: data.value + this.BASE_INDEX});
      }
      values = values.sort((t1, t2) => SharedModelPerformancesComponent.filterTimePeriod(t1.header, t2.header));
      // always put Since inception at the END
      const si = values.find(a => a.header === 'SI');
      if (values.includes(si)) {
        values.splice(values.indexOf(si), 1);
        values.push(si);
      }
    } else if (filter === 'yearly') {
      const calendarData = metrics.current.data.calendarData;
      const yearStart = moment(this.model.inceptionDate).year();
      const thisYear = moment(new Date()).year();

      const years = [];
      for (let i = yearStart; i <= thisYear; i++) {
        years.push(i);
      }

      const yearsData = calendarData.filter(data => data.timePeriodId[0] === 'Y');
      for (const year of years) {
        const d = yearsData.find(data => moment(data.valueDate).year() === year);
        if (d) {
          values.push({header: year, value: d.value + this.BASE_INDEX});
        } else {
          values.push({header: year, value: ''});
        }
      }
    } else {
      const calendarData = metrics.current.data.calendarData;
      const quarters = calendarData.filter(data => data.timePeriodId[0] === 'Q');
      const fQuarters = [];
      for (const quarter of quarters) {
        const y = moment(quarter.valueDate).year();
        const existingYearData = fQuarters.length > 0 && fQuarters.find(array => array[0].value === y);
        if (existingYearData) {
          existingYearData.push({header: quarter.timePeriodId, value: quarter.value + this.BASE_INDEX, valueDate: quarter.valueDate});
        } else {
          fQuarters.push([
            {header: '0', value: y, valueDate: y + '-01-01'},
            {header: quarter.timePeriodId, value: quarter.value + this.BASE_INDEX, valueDate: quarter.valueDate}
          ]);
        }
      }

      // Complete potententially non field values.
      for (const q of fQuarters) {
        if (q.length < 5) { // 4 quarter + current year.
          for (let i = 0; i < this.QUARTERS.length; i++) {
            const monthNumber = i + i * 2 + 2; // Q1 = 2, Q2 = 5, Q3 = 8, Q4 = 11.
            const endDate = this.getEndOfMonthDate(q[0].value, monthNumber);
            const existingQuarter = q.find(item => item.valueDate === endDate);
            if (!existingQuarter) {
              q.splice(i, 0, {header: 'Q', value: '', valueDate: endDate});
            }
          }
        }
      }

      // Sort from Q1 to Q4.
      for (const fQuarter of fQuarters) {
        fQuarter.sort((i1, i2) => {
          return moment(i1.valueDate).valueOf() - moment(i2.valueDate).valueOf();
        });
      }
      values = fQuarters;
    }
    return values;
  }

  // Return if the current time period has been marked as mandatory or not
  // Time period can be either 'M', 'Y', 'Q', 'T' for trailing.
  hasCalendarTimePeriod(tp: string): boolean {
    let hasTimePeriod = false;
    if (this.model.current && this.model.current.data.metrics) {
      const currentTotalReturnMetric =  this.model.current.data.metrics.find(m => m.metricId === 'TR');
      if (tp === 'T') {
        const trailingTR = this.metrics ? this.metrics.current.data.trailingData.find(item => item.metricId === 'TR') : null;
        let hasTR = false;
        if (trailingTR && trailingTR.data) { hasTR = true; }
        hasTimePeriod = currentTotalReturnMetric ?
          currentTotalReturnMetric.trailingTimePeriods.length > 0 && hasTR :
          false;
      } else {
        hasTimePeriod = currentTotalReturnMetric ?
          currentTotalReturnMetric.calendarFrequencies.includes(tp) :
          false;
      }
    }
    return hasTimePeriod;
  }

  onCategoryClick(event: any, category: string) {
    if (category === 'past') {
      this.currentCategory = event ? 'past' : 'projected';
    } else {
      this.currentCategory = event ? 'projected' : 'past';
    }

    if (this.currentCategory === 'past') {
      this.chartTimeUnit = null;
      this.labels = this.pastPerformanceLabel;
      this.mainColors = this.PAST_PERFORMANCES_COLORS;
    }
    this.onRangeDateChanged(null);
  }

  private getEndOfMonthDate(year: number, month: number): any {
    const startDate = moment([year, month]);
    const endDate = moment(startDate).endOf('month').format(this.DATE_FORMAT);
    return endDate;
  }

  private setMinAndMaxDate() {
    if (this.currentCategory === 'past') {
      this.minStartDate = this.model.inceptionDate;
      this.maxStartDate = this.endDate.format(this.DATE_FORMAT);
      this.minEndDate = this.startDate.format(this.DATE_FORMAT);
      this.endDate = moment();
      this.maxEndDate = this.now.format(this.DATE_FORMAT);
    } else {
      this.minStartDate = this.now.format(this.DATE_FORMAT);
      this.maxStartDate = this.now.format(this.DATE_FORMAT);
      this.minEndDate = this.now.format(this.DATE_FORMAT);
      this.maxEndDate = null;
    }
  }

  private getProjectedPerformance(model: PortfolioModel, amount: number, endDate: any) {
    this.performanceService.getProjectedPerformance(model.id, endDate.diff(this.now, 'year').toString(), amount).pipe(takeUntil(this._onDestroy)).subscribe(values => {
      const firstItem = {date: moment().format(this.DATE_FORMAT), value: amount};
      const worstProjection = [firstItem];
      const normalProjection = [firstItem];
      const bestProjection = [firstItem];
      for (const v of values) {
        const d = moment().add(v[0], 'year').format(this.DATE_FORMAT);
        worstProjection.push({date: d, value: v[1]});
        normalProjection.push({date: d, value: v[2]});
        bestProjection.push({date: d, value: v[3]});
      }
      this.chartTimeUnit = 'year';
      this.performances = [worstProjection, normalProjection, bestProjection];
      this.labels = this.projectedPerformancesLabels;
      this.mainColors = this.PROJECTED_PERFORMANCES_COLORS;
    });
  }

  private getPortfolioModelPerformance(model: PortfolioModel, amount: number, startDate: any, endDate: any) {
    //TODO must get from backend blended benchmark values
    forkJoin([
      this.performanceService.getPortfolioModelPerformance(model.id, amount, startDate.format(this.DATE_FORMAT), endDate.format(this.DATE_FORMAT)),
      (this.model?.current?.data?.mifidBenchmark && this.model?.current?.data?.mifidBenchmark.length > 0) ?
        this.performanceService.getPerformance(PerformanceService.PERFORMANCE_TYPE_BENCHMARK, this.model.current.data.mifidBenchmark[0].benchmark?.id,amount, startDate.format(this.DATE_FORMAT), endDate.format(this.DATE_FORMAT)) :
        of({benchmarkValues: []})
    ]).pipe(takeUntil(this._onDestroy))
      .subscribe(data => {
        if (data[0] && data[0].portfolioModelValues) {
          this.performances = [data[0].portfolioModelValues, data[1].benchmarkValues];
        } else {
          this.performances = [];
        }
    });
  }

  private getLabels(lang: string) {
    if (this.model) {
      this.pastPerformanceLabel = [this.translate.instant('model-performance.performance') + ' > ' + PortfolioModel.getModelName(this.model, lang)];
      if (this.model?.current?.data?.mifidBenchmark && this.model?.current?.data?.mifidBenchmark.length > 0) {
        this.pastPerformanceLabel.push(this.translate.instant('benchmarks.benchmark') + ' > ' + this.model?.current?.data?.mifidBenchmark[0].benchmark?.name)
      }
    }
    this.projectedPerformancesLabels = [this.translate.instant('model-performance.adverse'),
      this.translate.instant('model-performance.most-probable'),
      this.translate.instant('model-performance.optimal')
    ];
  }
}
