import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {VersionStatus} from '../../models/vestrata/Version';
import {SharedSnackbarService} from '../shared-snackbar/services/shared-snackbar.service';
import {SnackbarMessage} from "../shared-snackbar/models/snackbar-message";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ves-shared-org-domain',
  templateUrl: './shared-org-domain.component.html',
  styleUrls: ['./shared-org-domain.component.scss']
})
export class SharedOrgDomainComponent implements OnInit, OnChanges {

  viewAddDomain: boolean = false;
  viewConfirmRemove: boolean = false;
  domainToAdd: string = '';
  domainToRemove: string = '';

  @Input()
  blocked: boolean = false;

  @Input()
  currentValue: string[];

  @Input()
  pendingValue: string[];

  previousDomains: string[];
  _domains: string[] = [];
  @Output() domainChange = new EventEmitter<string[]>();
  @Input() set domains(v: string[]) {
    this.previousDomains = this.previousDomains ? this._domains : v;
    this._domains = v;
  }
  get domains() {
    return this._domains;
  }



  status: string;

  constructor(private snackbar: SharedSnackbarService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.status = this.getStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes) {
      this.status = this.getStatus();
    }
  }

  toggleDialog(dialog) {
    switch (dialog) {
      case 'add' :
        this.viewAddDomain = !this.viewAddDomain;
        break;
      case 'remove' :
        this.viewConfirmRemove = !this.viewConfirmRemove;
        break;
      default : console.log('unknown dialog');
    }
  }

  cancelDialog() {
    this.viewConfirmRemove = false;
    this.viewAddDomain = false;
    this.domainToAdd = '';
    this.domainToRemove = '';
  }

  checkRemove(domain) {
    this.domainToRemove = domain;
    this.toggleDialog('remove');
  }

  addDomain() {
    if (!this._domains) { this._domains = []; }
    if (this.domainToAdd && this.domainToAdd.length > 0 && this.domainToAdd !== ' ') {
      if (this.domainToAdd.includes('@')) {
        this.domainToAdd = this.domainToAdd.replace('@', '');
      }
      if (this._domains.indexOf(this.domainToAdd) < 0 ) {
        this._domains.push(this.domainToAdd);
        this.domainToAdd = '';
        this.domainChange.emit(this._domains);
        this.status = this.getStatus();
      } else {
        console.log('error already added domain');
        this.snackbar.push(new SnackbarMessage(this.translate.instant('organisation.warning.domain-already-added'), 'error'));
      }
      this.status = this.getStatus();
    }
    this.toggleDialog('add');
  }

  removeDomain(domain) {
    this._domains.splice(this._domains.indexOf(domain), 1);
    this.domainChange.emit(this._domains);
    this.status = this.getStatus();
    this.toggleDialog('remove');
  }

  getStatus(): VersionStatus {
    let status = VersionStatus.APPROVED;
    if (!this.pendingValue) {
      console.log(JSON.stringify(this.currentValue));
      console.log(JSON.stringify(this._domains));
      if (this.currentValue && (JSON.stringify(this.currentValue) !== JSON.stringify(this._domains))) {
        status = VersionStatus.EDITED;
      } else {
        status = VersionStatus.APPROVED;
      }
    } else {
      if (JSON.stringify(this.currentValue) !== JSON.stringify(this._domains)) {

        if (JSON.stringify(this.pendingValue) !== JSON.stringify(this._domains)) {
          status = VersionStatus.EDITED;
        } else {
          if (this.blocked) {
            status = VersionStatus.REQUEST;
          } else {
            status = VersionStatus.SAVED;
          }
        }
      } else {
        if (JSON.stringify(this.pendingValue) !== JSON.stringify(this._domains)) {
          status = VersionStatus.EDITED;
        } else {
          status = VersionStatus.APPROVED;
        }
      }
    }
    return status;
  }
}
