import {createAction, props} from "@ngrx/store";
import {Alert} from "../../models/vestrata/Alert";

export const retrieveNotifications = createAction(
  '[All Notifications] retrieving',
  props<{ payload: Alert[] }>()
);

export const askReloadNotifications = createAction(
  '[All Notifications] reload'
);

export const validationNotifications = createAction(
  '[Validation Notifications] validation',
  props<{ payload: Alert[] }>()
);

export const insightNotifications = createAction(
  '[Insight Notifications] count',
  props<{ payload: number }>()
);
