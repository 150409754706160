import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {ApiHttpService} from '../api.http.service';
import {BankDto} from '../../models/vestrata/BankDto';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  private readonly serviceUrl: string;
  private readonly ENDPOINT = 'banks';

  constructor(private http: HttpClient,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  // Agreed Banks will be base on authenticated user organisation id.
  getAgreedBanks(): Observable<BankDto[]> {
    return this.http.get<BankDto[]>(this.serviceUrl + this.ENDPOINT, ApiHttpService.getHeaders());
  }

  getAgreedBanksByPartnerId(partnerId: string): Observable<BankDto[]> {
    return this.http.get<BankDto[]>(this.serviceUrl + this.ENDPOINT + '/' + partnerId, ApiHttpService.getHeaders());
  }
}
