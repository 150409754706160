import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit, Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {filter, finalize, takeUntil} from 'rxjs/operators';
import {UsedInto, VestrataDocument, VestrataDocumentInfo} from '../../../models/vestrata/VestrataDocument';
import {Tag, TagGroup} from '../../../models/vestrata/Insight';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {DocumentService} from '../../../api/services/document.service';
import {SharedLoaderService} from '../../shared-loader/service/shared-loader.service';
import {UtilsService} from '../../../api/utils.service';
import {SnackbarMessage} from '../../shared-snackbar/models/snackbar-message';
import {AuthenticationService} from '../../../api/services/authentication.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Portal } from '../../../models/vestrata/Portal';

@Component({
  selector: 'ves-shared-document-maintenance-dialog',
  templateUrl: './shared-document-maintenance-dialog.component.html',
  styleUrls: ['./shared-document-maintenance-dialog.component.scss']
})
export class SharedDocumentMaintenanceDialogComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject();
  @Input() documentId: any; // if exist load from server
  @Input() vestrataDocument = new VestrataDocument();
  @Input() mode: 'creation' | 'selection' | 'view' = 'view';
  @Input() isFromLibrary = false;
  @Input() allowedTypes;
  @Input() portal;

  @Output() closingEvent = new EventEmitter();
  @Output() selectEvent = new EventEmitter<VestrataDocumentInfo>();
  @Output() saveUploadEvent = new EventEmitter<VestrataDocumentInfo>();

  typesDropdown: any[] = [];
  $tagOptions: string[] = [];
  tagGroups: TagGroup[] = [];

  permission = { read: true, write: true, approve: false, publish: false };
  orgaId: string;

  viewerType: string;

  @ViewChild('fileUploader') fileUploader: ElementRef;

  currentSlideUrl: string;
  sliderIndex = 0;

  currentUrl: string;

  isIPSInsight: boolean = false;
  isIPSMarketing: boolean = false;
  isIPSCompilance : boolean = false;
  isIPSResearch: boolean = false;
  isIPSDocLib: boolean = false

  isLocked = false;

  constructor(private snackbar: SharedSnackbarService, private translate: TranslateService,
              private documentService: DocumentService, private loader: SharedLoaderService,
              private utilsService: UtilsService, private auth: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.orgaId = this.auth.getOrganisationId();

    this.currentUrl = this.router.url;

    this.router.events.pipe(filter(e => e instanceof NavigationEnd),
      takeUntil(this._onDestroy)).subscribe((e: NavigationEnd) => {
      this.currentUrl = e.urlAfterRedirects;
    });

    this.activatedRoute.queryParams.subscribe(params => {
      const id = params['id'];
      console.log(id);
    });

    this.checkUrls();

    if (this.documentId?.url) {
      if (this.documentId?.contentType === 'Slideshow') {
        this.currentSlideUrl = this.documentId?.url[0];
        console.log('currentSlideUrl', this.currentSlideUrl)
        this.slideNext();
      }
      this.vestrataDocument = this.documentId;
      console.log('vestrataDocument ->', this.vestrataDocument)
    } else {
      if (this.documentId) {
        this.loader.showFullLoader('documents.loading-document');
        this.documentService.getById(this.documentId)
          .pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader()))
          .subscribe(data => {
            this.vestrataDocument = data;
          });
      } else {
        this.checkDocumentMode();
      }

    }
  }

  private checkUrls(): void {
    this.isIPSInsight = this.currentUrl.startsWith('/market-views/library/insights');
    this.isIPSMarketing = this.currentUrl.startsWith('/market-views/house-view/trade-ideas/details') || this.currentUrl.startsWith('/market-views/library/marketing-materials');
    this.isIPSCompilance = this.currentUrl.startsWith('/market-views/library/regulatory-materials') || this.currentUrl.startsWith('/governance/product-catalog/approved-products/details');
    this.isIPSResearch = this.currentUrl.endsWith('/market-views/library/research');
    this.isIPSDocLib = this.currentUrl.endsWith('/governance/library/document-library');

    if (this.isIPSCompilance || this.isIPSMarketing) {
      this.portal = Portal.IPS;
    }
  }

  slidePrevious() {
    if (!this.documentId?.url || this.documentId?.url.length === 0) {
      return;
    }
    this.sliderIndex--;
    if (this.sliderIndex < 0) {
      this.sliderIndex = this.documentId?.url.length - 1;
    }
    this.currentSlideUrl = this.documentId?.url[this.sliderIndex];
  }

  slideNext() {
    this.sliderIndex++;
    if (this.sliderIndex > this.documentId?.url.length - 1) {
      this.sliderIndex = 0;
    }
    this.currentSlideUrl = this.documentId?.url[this.sliderIndex];
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initForCreation() {
    console.log('isIPSInsight', this.isIPSInsight);
    console.log('isIPSMarketing', this.isIPSMarketing);
    console.log('isIPSCompilance', this.isIPSCompilance);
    console.log('isIPSResearch', this.isIPSResearch);
    console.log('isIPSDocLib', this.isIPSDocLib);

    console.log('portal', this.portal);

    this.mode = 'creation';
    // VestrataDocument.docTypes().forEach(item => {
    //   if (this.isIPSInsight && item === 'insight') return this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
    //   if (this.isIPSPostMArketing && item === 'post_marketing') return this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
    //   if (this.isIPSMarketing && item === 'pre_marketing') return this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
    //   if (this.isIPSCompilance && item === 'compliance') return this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
    //   if (this.isIPSResearch && item === 'research') return this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });

    //   this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
    // });

    for (let item of VestrataDocument.docTypes()) {
      if (this.portal === Portal.IPS) {
        this.isLocked = true;
        if (this.isIPSInsight && item === 'insight') {
          this.vestrataDocument.type = 'insight';
          this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
          break;
        } else if (this.isIPSMarketing && item === 'marketing') {
          this.vestrataDocument.type = 'marketing';
          this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
          break;
        } else if (this.isIPSCompilance && item === 'compliance') {
          this.vestrataDocument.type = 'compliance';
          this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
          break;
        } else if (this.isIPSResearch && item === 'research') {
          this.vestrataDocument.type = 'research';
          this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
          break;
        } else if (this.isIPSDocLib) {
          this.isLocked = true;
          this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
        }
      }
      else {
        this.typesDropdown.push({ name: this.translate.instant('documents.' + item), value: item });
      }
    }

    console.log('typesDropdown', this.typesDropdown);

    this.setTags();
  }

  initForSelection() {
    this.mode = 'selection';
  }

  onFileChoosed(files: any) {
    console.log('***Files*** ', files);
  }
  isValidFileType(file: File): boolean {
    return this.allowedTypes.includes(file.type);
  }

  checkDocumentMode() {
    if (this.vestrataDocument && this.vestrataDocument.id && this.mode === 'creation') {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.already-existing-doc'), 'warning'));
      this.initForSelection();
      return;
    }

    if (this.mode === 'creation') {
      this.initForCreation();
    }

    // uploaded from library
    if (this.mode === 'view' && this.vestrataDocument && !this.vestrataDocument.id) {
      this.initForCreation();
    }
  }

  closeDialog() {
    this.deleteUncompleteUpload();
  }

  deleteUncompleteUpload() {

    if (this.vestrataDocument && !this.vestrataDocument.id && this.mode === 'creation') {
      this.loader.showFullLoader('document.aborting-upload');
      this.documentService.deleteSecuredDocument(this.vestrataDocument.blobName).pipe(takeUntil(this._onDestroy),
        finalize(() => this.loader.dismissLoader()))
        .subscribe(
          data => {
            console.log('non complete upload document deleted');
            this.closingEvent.emit();
          }, error => {this.closingEvent.emit(); }
          );
    } else {
      this.closingEvent.emit();
    }
  }


  saveDocument() {
    if (!this.validDocument()) {
      return;
    }
    this.loader.showBarLoader();
    this.documentService.saveDocument(this.vestrataDocument).pipe(takeUntil(this._onDestroy),
      finalize(() => this.loader.dismissLoader()))
      .subscribe(data => {
        this.saveUploadEvent.emit(new VestrataDocumentInfo(data));
        }
      , error => {});
  }

  validDocument() {
    let ret = true;
    if (!this.vestrataDocument.title) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.no-title'), 'error'));
      ret = false;
    }
    if (!this.vestrataDocument.summary) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.no-summary'), 'error'));
      ret = false;
    }
    if (!this.vestrataDocument.url) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.no-url'), 'error'));
      ret = false;
    }
    if (!this.vestrataDocument.type) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.no-type'), 'error'));
      ret = false;
    }
    if (!this.vestrataDocument.effectiveDate) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.no-effectiveDate'), 'error'));
      ret = false;
    }
    return ret;
  }

  selectDocument() {
    this.selectEvent.emit(new VestrataDocumentInfo(this.vestrataDocument));
  }

  checkViewerVisibility() {
    if (!this.vestrataDocument) {
      return false;
    }
    return ['pdf', 'word', 'ppt', 'excel']
      .includes(this.documentService.getSimpleDocumentType(this.vestrataDocument.contentType).toLowerCase());

  }

  getViewerType() {
    const simpleType = this.documentService.getSimpleDocumentType(this.vestrataDocument.contentType).toLowerCase();
    if (simpleType === 'pdf') {
      return 'google';
    } else {
      return 'office';
    }
  }

  getUsedIntoTitle(item: UsedInto) {
    let title = item.localTitles[this.translate.currentLang];
    if (!title) {
      title = item.localTitles.en;
    }
    return title;
  }

  onUploadFile(files: any) {

    if (!this.isValidFileType(files[0])) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.file-type-not-allowed'), 'error'));
      return;
    }
    this.loader.showFullLoader('upload-file.uploading');
    this.documentService.uploadSecuredDocument(files[0]).pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader()))
      .subscribe(doc => {
        this.fileUploader.nativeElement.value = '';
        this.vestrataDocument = doc;
        this.mode = 'creation';
        this.checkDocumentMode();
      }, error => {this.fileUploader.nativeElement.value = ''; });
  }

  isImage() {
    return this.documentService.getSimpleDocumentType(this.vestrataDocument.contentType) === 'Image';
  }
  isAudio() {
    return this.documentService.getSimpleDocumentType(this.vestrataDocument.contentType) === 'Audio';
  }
  isVideo() {
    return this.documentService.getSimpleDocumentType(this.vestrataDocument.contentType) === 'Video';
  }

  // -----TAGs Functions
  setTags() {
    this.documentService.getTagGroups().subscribe(tgs => {
      this.tagGroups = tgs;
      this.tagGroups.forEach(tg => {
        this.setTagsFromGroup(tg);
      });
    });
  }

  setTagsFromGroup(group: TagGroup) {
    group.tags.forEach(tag => {
      let tagLocal = tag.data.find(t => t.lang === this.translate.currentLang);
      if ( !tagLocal ) {
        tagLocal = tag.data.find(t => t.lang === 'en');
      }
      const existingTag = this.vestrataDocument.tags ?
        this.vestrataDocument.tags.find(t => t.id === tag.id) : null;
      if (!existingTag || !existingTag.id) {
        this.$tagOptions.push(tagLocal.name);
      }
    });
  }
  addTag(tagString: string) {
    if (!this.vestrataDocument.tags) {
      this.vestrataDocument.tags = [];
    }
    let tag = new Tag();
    this.tagGroups.forEach(tg => {
      tg.tags.forEach(t => {
        const tagLocal = t.data.find(tl => tl.name === tagString);
        if (tagLocal) {
          tag = t;
        }
      });
    });
    if (tag && tag.id) {
      if (this.vestrataDocument.tags.indexOf(tag) === -1) {
        this.vestrataDocument.tags.push(tag);
      }
      // console.log(this.editingInsightVersion.tags);
      this.utilsService.removeFromIterable(this.$tagOptions, tagString);
    }
  }

  removeTag(tag) {
    // console.log('***********REMOVE TAG ***************');
    // console.log(tag);
    this.utilsService.removeFromIterable(this.vestrataDocument.tags, tag);
    let tagLocal = tag.data.find(t => t.lang === this.translate.currentLang);
    if ( !tagLocal ) {
      tagLocal = tag.data.find(t => t.lang === 'en');
    }
    this.$tagOptions.push(tagLocal.name);
  }

  download() {
    window.open(this.vestrataDocument.url, '_blank');
  }
}
