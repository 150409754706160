import {createAction, props} from "@ngrx/store";
import {Alert} from "../../models/vestrata/Alert";

export const retrieveAlerts = createAction(
  '[All Alerts] retrieving',
  props<{ payload: Alert[] }>()
);

export const askReload = createAction(
  '[All Alerts] reload'
);

export const validationAlerts = createAction(
  '[Validation Alerts] validation',
  props<{ payload: Alert[] }>()
);

export const insightAlerts = createAction(
  '[Insight Alerts] count',
  props<{ payload: number }>()
);
