import {PartnerPermission, IpsPermission, AdvisorPermission} from './Permission';
import { TwoFactorAuthenticationMethod } from './TwoFactorAuthenticationMethod';
import {Version} from './Version';
import {Organisation} from "./Organisation";
import {Role, Specialty} from './Role';
import {Portal} from './Portal';

export class User {
    id: string;
    keycloakId: string;
    email: string;
    // Deprecated
    password: string;
    organisation: Organisation;
    organisationId: string;
    current: Version<UserVersion>;
    pending: Version<UserVersion>;

    // Deprecated all below
    expirationDate: string;
    accountNonLocked = true;
    accountNonExpired = true;
    badPasswordCount: number;
    changePasswordDate: string;
    credentialsNonExpired = true;
    forceChangePassword: boolean;
    lastConnectionDate: string;
    passwordHistory: string;

    initiationStatus: string;
    twoFAData: TwoFactorAuthenticationMethod[];
}

export class UserVersion {
  firstName: string;
  lastName: string;
  // Deprecated
  organisations: OrganisationPermissions [] = [];
  // permissions: OrganisationPermissions;
  roles: string[] = [];
  specialties: Specialty[] = [];
  teams: string[] = [];
  portalsEnabled: Portal[] = [];
  email?: string;
}

export class OrganisationPermissions {
  // Deprecated
  organisationId: string;
  partner?: PartnerPermission;
  ips?: IpsPermission;
  advisor?: AdvisorPermission;
  admin?: boolean; // this is in relation for vestrata admin portal access and only for vestrata organisation.
}

// Deprecated
export class FAMethods {
  static readonly FACE = 'Face';
  static readonly VOICE = 'Voice';
}
