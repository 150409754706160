import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";
import {finalize, takeUntil} from "rxjs/operators";
import {DocumentUploadTypes, VestrataDocument, VestrataDocumentInfo} from "../../../models/vestrata/VestrataDocument";
import {SharedSnackbarService} from "../../shared-snackbar/services/shared-snackbar.service";
import {DocumentService} from "../../../api/services/document.service";
import {SharedLoaderService} from "../../shared-loader/service/shared-loader.service";
import {SnackbarMessage} from "../../shared-snackbar/models/snackbar-message";
import {Permission} from "../../../models/vestrata/Permission";
import {VersionStatus} from "../../../models/vestrata/Version";
import { Portal } from '../../../models/vestrata/Portal';

@Component({
  selector: 'ves-shared-upload-process-button',
  templateUrl: './shared-upload-process-button.component.html',
  styleUrls: ['./shared-upload-process-button.component.scss']
})
export class SharedUploadProcessButtonComponent implements OnInit, OnDestroy {
  @Input() id: string = 'upload-btn';
  @Input() btnLabel: string = 'documents.upload';
  @Input() allowedTypes: string[] = DocumentUploadTypes.all();
  @Input() hideButton: boolean = false;
  @Input()  permission: Permission;
  @Input()  fourEyesStatus: VersionStatus;
  @Input()  portal: Portal;

  @Output() outputDocument = new EventEmitter<VestrataDocumentInfo>();

  @ViewChild('fileUploader') fileUploader : ElementRef;

  private _onDestroy = new Subject();
  viewChooserDialog = false;
  viewFileDialog = false;
  viewLibraryDialog = false;

  tempUploadedDocument: VestrataDocument;


  constructor(private snackbar: SharedSnackbarService, private translate: TranslateService,
              private documentService: DocumentService, private loader: SharedLoaderService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public showChooserDialog() {
    this.viewChooserDialog = true;
  }
  cancelChooserDialog() {
    this.viewChooserDialog = false;
  }

  selectExistingClick() {
    this.viewLibraryDialog = true;
    this.viewChooserDialog = false;
  }

  showFileDialog() {
    this.viewFileDialog = true;
  }

  onFileChoosed(files: any) {

    if (!this.isValidFileType(files[0])) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.file-type-not-allowed'), 'error'));
      return;
    }
    this.loader.showFullLoader('upload-file.uploading');
    this.documentService.uploadSecuredDocument(files[0]).pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader()))
      .subscribe(doc => {
        this.fileUploader.nativeElement.value = '';
        this.tempUploadedDocument = doc;
        this.showFileDialog()
      }, error => {this.fileUploader.nativeElement.value = '';});
  }
  isValidFileType(file: File): boolean {
    return this.allowedTypes.includes(file.type);
  }

  onSaveSelectDocument(doc: VestrataDocument) {
    this.viewChooserDialog = false;
    this.viewFileDialog = false;
    this.viewLibraryDialog = false;
    console.log('DOCUMENT ----', doc);
    this.outputDocument.emit(new VestrataDocumentInfo(doc));
  }

  getChooserDialogHeader() {
    let ret = ' (';
    const simpleTypes = [];
    this.allowedTypes.forEach(contentType => {
      const st = this.documentService.getSimpleDocumentType(contentType);
      if (!simpleTypes.includes(st)){
        simpleTypes.push(st);
      }
    });
    console.log('****SIMPLE-TYPES****', simpleTypes);
    simpleTypes.forEach((item, i) => {
      ret = (ret + item) + (i === simpleTypes.length-1 ? '' : ', ');
    });
    ret += ')';
    return ret;
  }
}
