import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Article, ArticleLocal, ArticleType} from '../../models/vestrata/Article';
import {TranslateService} from '@ngx-translate/core';
import {TutorialVersion} from '../../models/vestrata/Tutorial';
import {AuthenticationService} from '../../api/services/authentication.service';
import moment from 'moment';
import {Permission} from '../../models/vestrata/Permission';
import {TutorialService} from '../../api/services/tutorial.service';
import {finalize, takeUntil} from 'rxjs/operators';
import {forkJoin, Observable, Subject} from 'rxjs';
import {SharedSnackbarService} from '../shared-snackbar/services/shared-snackbar.service';
import {SnackbarMessage} from '../shared-snackbar/models/snackbar-message';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {DocumentService} from '../../api/services/document.service';
import {VestrataDocument} from '../../models/vestrata/VestrataDocument';

@Component({
  selector: 'ves-article-display-view',
  templateUrl: './article-display-view.component.html',
  styleUrls: ['./article-display-view.component.scss']
})
export class ArticleDisplayViewComponent implements OnInit, OnChanges, OnDestroy {

  // tslint:disable-next-line:variable-name
  private _onDestroy = new Subject();
  private readonly officeDocExtensions = ['pptx', 'docx', 'xlsx', 'ppt', 'doc', 'xls'];
  private readonly googleDocExtensions = ['pdf', 'jpg', 'jpeg'];

  @Input() url: string;
  @Input() article: Article<any>;
  @Input() permission: Permission;
  @Input() editable = false;
  @Output() closeArticle = new EventEmitter<boolean>();
  @Output() articleUpdated = new EventEmitter<Article<any>>();

  sanitizedUrl: SafeResourceUrl;
  isWebOpenable = false;
  lang: string;
  currentLocale: ArticleLocal;
  markedAsRead = false;
  documents: VestrataDocument[] = [];

  constructor(private sanitizer: DomSanitizer,
              private loader: SharedLoaderService,
              private authService: AuthenticationService,
              private documentService: DocumentService,
              private tutorialService: TutorialService,
              private snackbar: SharedSnackbarService,
              private translate: TranslateService) {
    this.lang = this.translate.currentLang;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes?.url?.currentValue) {
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      window.scroll(0, 0);
      this.isWebOpenable = true;
    }

    if (changes?.article?.currentValue) {
      this.currentLocale = this.getCurrentArticleLocale(this.article, this.lang);
      if (this.article.current.data.documents) {
        this.loader.showFullLoader();
        const observables: Observable<VestrataDocument>[] = [];
        for (const doc of this.article.current.data.documents) {
          observables.push(this.documentService.getById(doc.id));
        }
        forkJoin(observables).pipe(takeUntil(this._onDestroy),
          finalize(() => this.loader.dismissLoader())).subscribe(docs => {
            this.documents = docs;
        });
      }
    }
  }

  closeClick() {
    this.closeArticle.emit(true);
  }

  openOnWeb() {
    window.open(this.url, '_blank');
    this.closeArticle.emit(true);
  }

  getViewerType(url: string) {

    const extension = url.split('.').pop().substring(0, 5).toLowerCase();
    let ret = 'object';
    if (this.googleDocExtensions.includes(extension)) {
      ret = 'google';
    } else if (this.officeDocExtensions.includes(extension)) {
      ret = 'office';
    }
    console.log('url:' + this.url);
    console.log('type:' + ret);
    return ret;
  }

  getCurrentArticleLocale(article: Article<any>, locale: string): ArticleLocal {
    const articleLocal = article?.current?.data?.locales.find(l => l.lang === locale);
    return articleLocal ? articleLocal : article?.current?.data?.locales.find(l => l.lang === 'en');
  }

  getFlagLabel(article: Article<any>) {
    const value = this.translate.instant('article-display-view.' + article?.current?.data?.expectedReadingTime);
    return '<div class="flex-column flex-align-items-center flex-justify-content-center"><p class="title">' + value + '</p><p>mins</p></div>';
  }

  isCompleted(article: Article<any>) {
    return (article?.current?.data as TutorialVersion)?.completed?.find(c => c.id === this.authService.getMyId());
  }

  isNew(article: Article<any>) {
    return moment().diff(article?.current?.reviewedDate) < 7;
  }

  confirmComplete() {
    this.loader.showBarLoader();
    this.tutorialService.completeTutorial(this.article.id).pipe(takeUntil(this._onDestroy),
      finalize(() => this.loader.dismissLoader()))
      .subscribe(a => {
        this.article = a;
        this.currentLocale = this.getCurrentArticleLocale(this.article, this.lang);
        this.articleUpdated.emit(this.article);
        this.snackbar.push(new SnackbarMessage(this.translate.instant('article-display-view.complete-success')));
      }, error => {
        console.log(error);
        this.snackbar.push(new SnackbarMessage(this.translate.instant('article-display-view.complete-error'), 'error'));
      });
  }

  getCompletionDate(article: Article<any>) {
    return (article?.current?.data as TutorialVersion)?.completed?.find(c => c.id === this.authService.getMyId())
      ?.date;
  }

}
