import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Permission} from "../../../models/vestrata/Permission";
import {VersionStatus} from "../../../models/vestrata/Version";

@Component({
  selector: 'ves-translation-tabs',
  templateUrl: './translation-tabs.component.html',
  styleUrls: ['./translation-tabs.component.scss']
})
export class TranslationTabsComponent implements OnInit, OnChanges {


  @Input() permission: Permission;
  // set the pending version to manage the approved state
  @Input() pendingStatus: VersionStatus;

  @Input()
  tabs: any[];

  @Input()
  mode: 'preview' | 'config' = 'config';

  @Input()
  selectedLabel: string;

  @Output()
  menuClick = new EventEmitter<string>();

  @Output()
  addLanguage = new EventEmitter<string>();

  @Output()
  removeLanguage = new EventEmitter<string>();

  // TODO: Remove when language adding will be managed.
  isEnabled = true;
  viewAddLanguage = false;
  chosenLanguage = '';
  languages$: any = [{name: this.translateService.instant('languages.fr'), value: 'fr'} ,
    {name: this.translateService.instant('languages.en'), value: 'en'}];

  constructor(public translateService: TranslateService) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('this.pendingStatus', this.pendingStatus);
    if (changes) {
      if (!this.permission || !this.permission.write || this.pendingStatus === VersionStatus.REQUEST) {
        this.isEnabled = false;
      } else {
        this.isEnabled = true;
      }
      if ( changes.tabs && changes.tabs.currentValue.length > 0) {
      }
    }
    console.log('isEnabled', this.isEnabled);
  }

  onClick(label: string) {
    if (label === 'add') {
      this.viewAddLanguage = true;
    } else {
      this.menuClick.emit(label);
      this.selectedLabel = label;
    }
  }

  getRealLanguageName(lang: string) {
    if (lang === 'fr') {
      return 'translation-tabs-component.french';
    } else if (lang === 'en') {
      return 'translation-tabs-component.english';
    } else if (lang === 'it') {
      return 'translation-tabs-component.italian';
    }
  }

  currentLanguageChange(event) {
    this.chosenLanguage = event;
  }
  confirmLanguage() {

    /*this.metric.data.forEach(local => {
      const lang = this.languages$.find(l => l.value === local.lang);
      this.languages$.splice(this.languages$.indexOf(lang), 1);
    });
    this.viewAddLanguage = true;*/

    if (this.chosenLanguage !== '') {
      this.viewAddLanguage = false;
      this.addLanguage.emit(this.chosenLanguage);
    }
  }
  cancelLanguage() {
    this.viewAddLanguage = false;
  }

  remove(lang) {
    this.removeLanguage.emit(lang);



  }

}
