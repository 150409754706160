import {Version} from './Version';

export class MetricData {
  portfolioModelId: string;
  current: Version<MetricsVersion>;
  pending: Version<MetricsVersion>;
}

export class MetricsVersion {
  calendarData: CalendarMetricData[] = [];
  trailingData: TrailingMetricData[] = [];
  asOfDate: string;
}

export class CalendarMetricData {
  timePeriodId: string;
  valueDate: string;
  metricId: string;
  value: number;
  source: string; // MS / CALC / PARTNER
}

export class TrailingMetricData {
  metricId: string;
  data: MetricValue[] = [];
  source: string; // MS / CALC / PARTNER
}

export class MetricValue {
  timePeriodId: string;
  value: number;
}
