import { EventEmitter, Input, Output, Directive } from '@angular/core';
import {Permission} from '../models/vestrata/Permission';
import {VersionStatus} from '../models/vestrata/Version';

@Directive()
export abstract class FourEyeComponent<T> {

    @Input() locked: boolean = false;
    @Input() permission: Permission;
    // set the pending version to manage the approved state
    @Input() pendingStatus: VersionStatus;
    @Input() fourEyesMode: boolean = true;


    @Output() stateChange = new EventEmitter<FourEyeComponentState>();

    @Input() currentValue: T;
    @Input() pendingValue: T;
    _editingValue: T;
    @Input() set editingValue(v: T) {
        this._editingValue = v;
    }
    get editingValue() {
        return this._editingValue;
    }
    @Output() editingValueChange = new EventEmitter<any>();

    getState(): FourEyeComponentState {
        let state: FourEyeComponentState = new FourEyeComponentState();
        if (this.permission != null) {
            if (this.permission.write
                && this.pendingStatus !== VersionStatus.REQUEST
                && this.pendingStatus !== VersionStatus.DELETE
                && !this.locked) {
                state.readonly = false;
            } else {
                state.readonly = true;
            }
        } else if (this.locked) {
          state.readonly = true;
        }



        if (this.fourEyesMode) {
          if (this.pendingStatus === VersionStatus.REQUEST) {
            if (!this.equals(this.pendingValue, this.currentValue)) {
              state.state = VersionStatus.REQUEST;
            } else {
              state.state = VersionStatus.LOCKED;
              state.readonly = true;
            }
          } else {
            state.state = this.pendingStatus;
            if (!this.pendingValue) {
              if (this.currentValue) {
                if (!this.equals(this.currentValue, this._editingValue)) {
                  state.state = VersionStatus.EDITED;
                } else {
                  state.state = VersionStatus.APPROVED;
                }
              } else {
                if (this._editingValue) {
                  state.state = VersionStatus.EDITED;
                } else {
                  state.state = VersionStatus.APPROVED;
                }
              }
            } else {
              if (!this.equals(this.pendingValue, this.currentValue)) {
                if (!this.equals(this._editingValue, this.pendingValue)) {
                  state.state = VersionStatus.EDITED; // TODO: May be changed to approved ?
                } else {
                  if (this.pendingStatus === VersionStatus.DELETE || this.pendingStatus === VersionStatus.REJECT_DELETE) {
                    state.state = this.pendingStatus === VersionStatus.DELETE ? VersionStatus.DELETE : VersionStatus.REJECT_DELETE;
                  }else {
                    state.state = VersionStatus.SAVED;
                  }
                  //}
                }
              } else {
                if (!this.equals(this._editingValue, this.pendingValue)) {
                  state.state = VersionStatus.EDITED;
                } else if (this.pendingStatus === VersionStatus.DELETE || this.pendingStatus === VersionStatus.REJECT_DELETE) {
                  state.state = this.pendingStatus === VersionStatus.DELETE ? VersionStatus.DELETE : VersionStatus.REJECT_DELETE;
                } else {
                  state.state = VersionStatus.APPROVED;
                }
              }
            }
          }
          this.stateChange.emit(state);
        }
        return state;
    }

    protected abstract equals(a: T, b: T): boolean;

}

export class FourEyeComponentState {

    public readonly: boolean;
    public state: string;

}
