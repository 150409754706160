import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppAuthGuard} from 'vestrata-components-lib';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'strategies',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/strategies/strategies.module').then(mod => mod.StrategiesModule)
  },
  {
    path: 'portfolio-models',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/portfolio-models/portfolio-models.module').then(mod => mod.PortfolioModelsModule)
  },
  {
    path: 'approvals',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/bank-approvals/bank-approvals.module').then(mod => mod.BankApprovalsModule)
  },
  {
    path: 'insights',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/insights/insights.module').then(mod => mod.InsightsModule)
  },
  {
    path: 'account',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/account/account.module').then(mod => mod.AccountModule)
  },
  {
    path: 'documents',
    canActivate: [AppAuthGuard],
    loadChildren: () => import('./modules/documents/documents.module').then(mod => mod.DocumentsModule)
  },
  {path: '**', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
