import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange} from '@angular/core';

import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SharedLoaderService} from "../shared-loader/service/shared-loader.service";
import {SharedSnackbarService} from "../shared-snackbar/services/shared-snackbar.service";
import {VestrataDocument} from "../../models/vestrata/VestrataDocument";
import {PortfolioModelVersion} from "../../models/vestrata/PortfolioModel";
import {UtilsService} from "../../api/utils.service";
import {AuthenticationService} from '../../api/services/authentication.service';

@Component({
  selector: 'app-shared-model-marketing-readonly',
  templateUrl: './shared-model-marketing-readonly.component.html',
  styleUrls: ['./shared-model-marketing-readonly.component.scss']
})
export class SharedModelMarketingReadonlyComponent implements OnInit, OnChanges, OnDestroy {

  @Input() editingData: PortfolioModelVersion;

  private _onDestroy = new Subject();
  elementUpload: HTMLElement;

  blockedValue: any = {
    removedoc: false,
    docEffectiveDate: false,
    addDocument: false
  };

  dataLanguage: any[] = [
    {
      name: 'English',
      value: 'EN'
    },
    {
      name: 'French',
      value: 'FR'
    },
  ];

  constructor(private loader: SharedLoaderService,
              public translateService: TranslateService,
              private utilsService: UtilsService,
              private authService: AuthenticationService,
              private snackBar: SharedSnackbarService
              ) { }

  ngOnInit() {
    this.elementUpload = document.getElementById('upload-for-document');
    this.checkModelVersion();
  }

  public ngOnChanges(changes: { [propKey: string]: SimpleChange  }) {
    this.checkModelVersion();
  }

  checkModelVersion() {
    if (!this.editingData.fundChangeReports) {
      this.editingData.fundChangeReports = [];
    }
    if (!this.editingData.assetAllocationReports) {
      this.editingData.assetAllocationReports = [];
    }
    if (!this.editingData.factSheets) {
      this.editingData.factSheets = [];
    }
    if (!this.editingData.riskDisclosures) {
      this.editingData.riskDisclosures = [];
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // --- Temporary until we have document repository stuff-----
  openedFactDocAccordions = [];
  openedRiskDocAccordions = [];
  openedFundDocAccordions = [];
  openedAllocationDocAccordions = [];
  isDocAccordionOpen(docList, i) {
    return docList.indexOf(i) > -1;
  }
  manageAccordion(docList, i: number) {
    if (this.isDocAccordionOpen(docList, i)) {
      docList.splice(docList.indexOf(i), 1);
    } else {
      docList.push(i);
    }
  }
  // ----------------------------------------------------------

  formatDate(date: string) {
    let ret = '';
    if (date != null) {
      ret = UtilsService.formatDate(date);
    }
    return ret;
  }
  getDocLanguage(doc: VestrataDocument) {
    return UtilsService.getFullLanguageName(doc.language);
  }


  consoleOut() {
    console.log('------------------------');
    console.log('model');
    console.log(this.editingData);
    console.log('------------------------');
  }

  isIpsOrAdvisor() {
    return !this.authService.getPermissions().partner;
  }
}
