import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../models/vestrata/User';
import {UserService} from '../../api/services/user.service';
import {Permission} from '../../models/vestrata/Permission';
import {SharedSnackbarService} from '../shared-snackbar/services/shared-snackbar.service';
import {SnackbarMessage} from '../shared-snackbar/models/snackbar-message';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, finalize, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {Paging, SearchRequest} from '../../models/vestrata/SearchRequest';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationMenuService} from '../../basic-components/unknown-components/navigation-menu/navigation-menu.service';
import {NavigationData} from '../../basic-components/unknown-components/navigation-menu/navigation-menu.component';
import {Role} from '../../models/vestrata/Role';
import {AuthenticationService} from '../../api/services/authentication.service';

@Component({
  selector: 'ves-shared-user-list',
  templateUrl: './shared-user-list.component.html',
  styleUrls: ['./shared-user-list.component.scss']
})
export class SharedUserListComponent implements OnInit, OnDestroy {

  private static readonly ORGANISATION_ID_SEARCH_KEY = 'organisationId';
  private _onDestroy = new Subject();

  @Input()
  organisationId: string;

  @Input()
  adminNewOrga = false;

  @Input()
  permission: Permission = new Permission();

  isAdmin = false;

  @Input()
  embedded = false;

  @Output()
  selectUserClick = new EventEmitter<User>();

  @Output()
  deleteUserClick = new EventEmitter<User>();

  @Output()
  closeUserList = new EventEmitter<boolean>();

  userList: User[] = [];

  viewDeleteCheck = false;
  viewUnlock = false;
  userToDelete: User;
  userToUnlock: User;

  hasChanged = false;
  isApproveDeletion = false;

  // Paginator //
  searchCriteria: SearchRequest;
  pages = 0;
  results = 0;
  resultNumber = 25;
  pageNumber = 0;

  $search = new Subject<string>();

  // NEw //
  private navigationData = new NavigationData();
  roles: Role[];
  connectedUserId: string;

  constructor(private userService: UserService,
              private translate: TranslateService,
              private loader: SharedLoaderService,
              private router: Router,
              private authService: AuthenticationService,
              private activatedRoute: ActivatedRoute,
              private menuService: NavigationMenuService,
              private snackbar: SharedSnackbarService) {
    this.isAdmin = this.activatedRoute.snapshot.data.isAdmin;
    this.connectedUserId = this.authService.getMyId();
  }

  ngOnInit() {
    console.log('************* USER MANAGEMENT *****************');
    console.log('isAdmin:' + this.isAdmin);
    console.log(this.permission);
    this.userService.getRoles().pipe(takeUntil(this._onDestroy)).subscribe(r => this.roles = r);
    this.initEmptySearchFilter();
    this.executeLoad();
    this.setMenuData();
  }

  onSizeChanged(size: number) {
    this.resultNumber = size;
    this.searchCriteria.paging.page = this.pageNumber;
    this.searchCriteria.paging.size = this.resultNumber;
    this.executeLoad();
  }

  onPageChanged(page: number) {
    this.pageNumber = page - 1;
    this.searchCriteria.paging.page = this.pageNumber;
    this.searchCriteria.paging.size = this.resultNumber;
    this.executeLoad();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.menuService.resetNavigationData();
  }

  selectUser(user) {
    if (this.isAdmin && this.adminNewOrga) {
      this.router.navigate(['user'],
        {relativeTo: this.activatedRoute,
          queryParams: {org: this.organisationId, id: user.id}});
    } else {
      this.router.navigate(['details'],
        {relativeTo: this.activatedRoute,
          queryParams: {id: user.id}});
    }
  }

  onAddNew() {
    if (this.isAdmin && this.adminNewOrga) {
      this.router.navigate(['user'],
        {relativeTo: this.activatedRoute, queryParams: {org: this.organisationId}});
    } else {
      this.router.navigate(['details'],
        {relativeTo: this.activatedRoute});
    }
  }

  deleteUserCheck(user) {
    this.userToDelete = user;
    this.viewDeleteCheck = true;
  }

  cancelDelete() {
    this.userToDelete = null;
    this.viewDeleteCheck = false;
  }

  onEnableChanged(disable: boolean, user: User) {
    if (!disable) {
      this.userService.disableUser(user.id).pipe(takeUntil(this._onDestroy)).subscribe(result => {
        this.userList.splice(this.userList.indexOf(user), 1, result);
        this.snackbar.push(new SnackbarMessage(this.translate.instant( 'user.success-disable'), 'info'));
      }, err => {
        this.snackbar.push(new SnackbarMessage(err.message, 'error'));
      });
    } else {
      this.onUserReset(user, true);
    }
  }

  showUnlockDialog(user: User) {
    this.viewUnlock = true;
    this.userToUnlock = user;
  }

  cancelUnlock() {
    this.viewUnlock = false;
    this.userToUnlock = null;
  }

  onUserReset(user, enable = false) {
    this.userService.enableUser(user.id).pipe(takeUntil(this._onDestroy), finalize(() => {
      this.viewUnlock = false;
    })).subscribe(result => {
      this.userList.splice(this.userList.indexOf(user), 1, result);
      this.snackbar.push(new SnackbarMessage(this.translate.instant(enable ? 'user.success-enable' : 'user.success-reset'), 'info'));
    }, err => {
      this.snackbar.push(new SnackbarMessage(err.message, 'error'));
    });
  }

  deleteUser() {
    this.userService.deleteUser(this.userToDelete).pipe(takeUntil(this._onDestroy)).subscribe(result => {
      this.userList.splice(this.userList.indexOf(this.userToDelete), 1);
    });
    this.viewDeleteCheck = false;
  }

  onFilterChange(search: string, organisationId?: string) {
    this.initEmptySearchFilter();
    if (organisationId) {
      this.searchCriteria.criteria[SharedUserListComponent.ORGANISATION_ID_SEARCH_KEY] = organisationId;
    }
    this.searchCriteria.text = search;
    this.executeLoad();
  }

  private initEmptySearchFilter() {
    this.resultNumber = 25;
    this.pageNumber = 0;
    this.searchCriteria = new SearchRequest();
    this.searchCriteria.paging = new Paging();
    this.searchCriteria.paging.page = this.pageNumber;
    this.searchCriteria.paging.size = this.resultNumber;
    this.searchCriteria.criteria = {};
    this.searchCriteria.text = '';
  }

  executeLoad() {
    this.loader.showBarLoader();
    this.userService.getUsersSearchCriteria(this.searchCriteria).pipe(takeUntil(this._onDestroy),
      finalize(() => this.loader.dismissLoader())).subscribe(results => {
      this.userList = results.content;
      this.pages = results.totalPages;
      this.results = results.totalElements;
    }, error => {
      this.snackbar.push(new SnackbarMessage(this.translate.instant(error), 'error'));
    });
  }

  onCancelClick() {
    this.closeUserList.emit(true);
  }

  fourEyesDelete(instance: User, isApproving: boolean) {
    this.userToDelete = instance;
    this.viewDeleteCheck = true;
    this.isApproveDeletion = isApproving;
  }

  submitOrApproveDeletion(instance: User) {
    if (this.isApproveDeletion) {
      this.approveDeleteInstance(instance);
      return;
    }
    this.submitDeleteInstance(instance);
  }

  submitDeleteInstance(instance: User) {
    this.loader.showBarLoader();
    this.userService.submitDeletion(instance.id).pipe(takeUntil(this._onDestroy),
      finalize(() => {
        this.cancelDelete();
        this.loader.dismissLoader();
      }))
      .subscribe(data => {
        this.userList[this.userList.indexOf(this.userList.find(item => item.id === data.id))] = data;
        this.snackbar.push(new SnackbarMessage(this.translate.instant('four-eyes-deletion.submit-delete'), 'info'));
      });
  }

  approveDeleteInstance(instance: User) {
    this.loader.showBarLoader();
    this.userService.approveDeletion(instance.id).pipe(takeUntil(this._onDestroy),
      finalize(() => {
        this.cancelDelete();
        this.loader.dismissLoader();
      }))
      .subscribe(_ => {
        this.userList.splice(this.userList.indexOf(this.userToDelete), 1);
        this.snackbar.push(new SnackbarMessage(this.translate.instant('four-eyes-deletion.approved-deletion'), 'info'));
      });
  }
  rejectDeleteInstance(instance: User) {
    this.loader.showBarLoader();
    this.userService.declineDeletion(instance.id).pipe(takeUntil(this._onDestroy),
      finalize(() => {
        this.cancelDelete();
        this.loader.dismissLoader();
      }))
      .subscribe(data => {
        this.userList[this.userList.indexOf(this.userList.find(item => item.id === data.id))] = data;
        this.snackbar.push(new SnackbarMessage(this.translate.instant('four-eyes-deletion.reject-deletion'), 'info'));
      });
  }

  private setMenuData() {
    this.navigationData.interface = this;
    this.navigationData.permission = this.permission;
    this.navigationData.fourEyeView = false;
    this.menuService.setNavigationData(this.navigationData);
  }
}
