import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Permission} from '../../../models/vestrata/Permission';
import {NavigationData} from '../../../basic-components/unknown-components/navigation-menu/navigation-menu.component';
import {combineLatest, forkJoin, Observable, of, Subject} from 'rxjs';
import {NavigationMenuService} from '../../../basic-components/unknown-components/navigation-menu/navigation-menu.service';
import {MorningStarService} from '../../../api/services/morningstar.service';
import {InstrumentService} from '../../../api/services/instrument.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedLoaderService} from '../../shared-loader/service/shared-loader.service';
import {AuthenticationService} from '../../../api/services/authentication.service';
import {CommonService} from '../../../api/services/common.service';
import {TranslateService} from '@ngx-translate/core';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {finalize, switchMap, takeUntil} from 'rxjs/operators';
import {MSComponentService} from '../../../api/services/mscomponent.service';
import {InstrumentWithPriceDto} from '../../../models/vestrata/Instrument/Instrument';
import {AssetClass} from "../../../models/vestrata/MajorAssetClass";
import {PerformanceService} from "../../../api/services/performance.service";
import * as moment from "moment";

@Component({
  selector: 'ves-shared-instrument-details',
  templateUrl: './shared-instrument-details.component.html',
  styleUrls: ['./shared-instrument-details.component.scss']
})
export class SharedInstrumentDetailsComponent implements OnInit, OnDestroy {

  private _onDestroy = new Subject();

  private navigationData = new NavigationData();
  subMenu = [];
  permission: Permission;

  startDate = moment();
  endDate = moment();
  initialAmount = 10000;

  @Input() instrumentId: string;
  instrumentWithPrice: InstrumentWithPriceDto;
  params: any[];
  loaded = false;
  viewCategorisation = true;
  viewTargetMarket = false;
  viewPerformance = false;

  performance: any[];
  viewAnalytics = false;

  isLoading = true;
  isMorningstarApiReady = false;
  $assetClasses: Observable<AssetClass[]>;

  constructor(private authService: AuthenticationService,
              private loader: SharedLoaderService,
              private router: Router,
              private instrumentService: InstrumentService,
              private commonService: CommonService,
              private translate: TranslateService,
              private snackbar: SharedSnackbarService,
              private route: ActivatedRoute,
              private menuService: NavigationMenuService,
              private morningStarService: MorningStarService,
              private performanceService: PerformanceService) {
    this.startDate = moment().add(-1, 'years');
    this.endDate = moment();
  }

  ngOnInit(): void {
    this.$assetClasses = this.commonService.getMajorAssetClasses();
    this.route.queryParamMap.pipe(takeUntil(this._onDestroy), switchMap(params => {
      this.loader.showFullLoader();
      this.loaded = false;
      this.instrumentWithPrice = undefined;
      let id: string;
      if (this.instrumentId) {
        id = this.instrumentId;
      } else if (params.get('id')) {
        id = params.get('id');
      }
      if (id && id !== '') {
        return forkJoin([this.instrumentService.getInstrumentById(id),
          this.performanceService.getPerformance(PerformanceService.PERFORMANCE_TYPE_INSTRUMENT, id, this.initialAmount, this.startDate.format('YYYY-MM-DD'),
            this.endDate.format('YYYY-MM-DD'), 'D')]);
      } else {
        return of(null);
      }
    })).subscribe((data: any) => {
      // console.log('************* INSTRUMENT DETAILS ********************');
      // console.log(data);
      this.loader.dismissLoader();
      this.loaded = true;
      this.instrumentWithPrice = data[0];
      if (data[1] && data[1].instrumentValues) {
        this.performance = [data[1].instrumentValues];
      } else {
        this.performance = [];
      }
      this.setSubMenu();
      if (this.instrumentWithPrice.instrument.type === 'Fund') {
        this.subMenuSelected(1);
      } else {
        this.subMenuSelected(0);
      }

    }, err => {
      this.loader.dismissLoader();
      this.loaded = true;
    });

    const self = this;
    this.morningStarService.getMorningStarToken().subscribe(token => {
      MSComponentService.initComponent(token.access_token, this.translate.currentLang, () => {
        self.isLoading = false;
        this.isMorningstarApiReady = true;
      });
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.menuService.resetNavigationData();
  }

  setSubMenu() {
    this.subMenu = [];
    this.subMenu.push({
      name: 'instrument-details.tabs.categorisation', isActive: true
    });
    if (this.instrumentWithPrice.instrument.type === 'Fund') {
      this.subMenu[0].isActive = false;
      this.subMenu.push({name: 'instrument-details.tabs.target-market', isActive: true });
    }
    if (this.instrumentWithPrice.instrument.type !== 'StructuredProduct') {
      this.subMenu.push({name: 'instrument-details.tabs.performance'});
    }
    if (this.instrumentWithPrice.instrument.type === 'Fund' || this.instrumentWithPrice.instrument.type === 'Equity') {
      this.subMenu.push({name: 'instrument-details.tabs.analytics' });
    }
  }

  subMenuSelected(tab: any) {
    this.viewCategorisation = false;
    this.viewTargetMarket =  false;
    this.viewPerformance = false;
    this.viewAnalytics = false;

    switch (tab) {
      case 0: this.viewCategorisation = true; break;
      case 1: {
        if (this.instrumentWithPrice.instrument.type === 'Fund') {
          this.viewTargetMarket = true;
        } else {
          this.viewPerformance = true;
        }
        break;
      }
      case 2: {
        if (this.instrumentWithPrice.instrument.type === 'Fund') {
          this.viewPerformance = true;
        } else if (this.instrumentWithPrice.instrument.type === 'Equity') {
          this.viewAnalytics = true;
        }
        break;
      }
      case 3: this.viewAnalytics = true; break;
      default: this.viewCategorisation = true;
    }
  }

  getInstrumentId(type) {
    return this.instrumentWithPrice.instrument.instrumentIds.find(a => a.idType === type).id;
  }

  private buildPageTitle(instrument: any) {
    let title = this.translate.instant('instrument-details.type.' + instrument.type);
    if (instrument && instrument.id) {
      const meetingTitle = instrument.name;
      title += ' | ' + this.translate.instant('instrument-details.identification.isin') + ' › ' + this.getInstrumentId('ISIN');
    }
    return title;
  }

  private setMenuData() {
    if (this.instrumentWithPrice.instrument) {
      this.navigationData.permission = this.permission;
      this.navigationData.interface = this;
      this.navigationData.fourEyeView = false;
      this.navigationData.pageTitle = this.buildPageTitle(this.instrumentWithPrice.instrument);
      this.menuService.setNavigationData(this.navigationData);
    } else {
      this.menuService.resetNavigationData();
    }
  }
}
