import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {ApiHttpService} from '../api.http.service';
import {PerformanceDto} from '../../models/vestrata/PerformanceDto';
import {PriceInfo} from '../../components/shared-performance-filter/shared-performance-filter.component';
import {PerformanceMetricDto} from '../../models/vestrata/PerformanceMetric';


@Injectable({
  providedIn: 'root'
})
export class PerformanceService {

  public static readonly  PERFORMANCE_TYPE_INSTRUMENT = 'instrument';
  public static readonly  PERFORMANCE_TYPE_BENCHMARK = 'benchmark';
  public static readonly  PERFORMANCE_TYPE_PORTFOLIO_MODEL = 'portfoliomodel';
  private readonly serviceUrl: string;

  constructor(private http: HttpClient,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getPortfolioModelPerformance(pfmId: string, initialAmount: number, startDate: string, endDate: string): Observable<any> {
    return this.http.get<any>(this.serviceUrl + 'portfoliomodel/performance/' + pfmId + '/' + initialAmount + '/' + startDate + '/' + endDate, ApiHttpService.getHeaders());
  }

  getProjectedPerformance(pfmId: string, yearsNumber: string, amount: number): Observable<any[]> {
    return this.http.get<any[]>(this.serviceUrl + 'portfoliomodel/returns/' + pfmId + '/' + yearsNumber + '/' + amount,
      ApiHttpService.getHeaders());
  }

  // standard method for instruments, benchmarks
  getPerformance(instance: string, id: string, initialAmount: number, startDate: string, endDate: string, type?: string): Observable<PerformanceDto> {
    if (!type) {
      type = 'M'; // default to monthly
    }
    return this.http.get<PerformanceDto>(this.serviceUrl + 'performance/' + instance + '/' + id  + '/' + initialAmount + '/' + startDate + '/' + endDate + '/' + type,
      ApiHttpService.getHeaders());
  }

  getPriceHistory(priceId: string, startDate: string, endDate: string): Observable<PerformanceDto> {
    return this.http.get<PerformanceDto>(this.serviceUrl + 'performance/price-history/' + priceId  + '/' + startDate + '/' + endDate ,
      ApiHttpService.getHeaders());
  }

  getPriceMinMax(priceId: string, startDate: string, endDate: string): Observable<PriceInfo> {
    return this.http.get<PriceInfo>(this.serviceUrl + 'performance/price/' + priceId  + '/' + startDate + '/' + endDate ,
      ApiHttpService.getHeaders());
  }

  getPerformanceMetrics(type: string, parentId: string): Observable<PerformanceMetricDto> {
    return this.http.get<PerformanceMetricDto>(this.serviceUrl + 'performance/metric/' + type + '/' + parentId , ApiHttpService.getHeaders());
  }
}
