import {BenchmarkInfo} from "./BenchmarkInfo";
import {SearchRequest} from "./SearchRequest";

export class Benchmark {
  id: string;
  externalIds: BenchmarkIndexId[];

  name: string;
  type: string;
  provider: string;

  currency: string;

  hedged: boolean;
  hedgedCurrency: string;

  domicile: string;
  benchmarkInfo: string;
  assetClasses: string[];

  lastPrice?: number;
  lastPriceDate?: string;
  creationDate?: string;

  inceptionDate?: string;
  ticker?: string;

  static getIdFromExternalIds(benchmark: Benchmark, refType: string): string {
    let identifier = '';
    if (benchmark.externalIds != null) {
      benchmark.externalIds.forEach(item => {
        if (item.idType === refType) {
          identifier = item.id;
        }
      });
    }
    return identifier;
  }
}

export class BenchmarkIndexId {
  id: string;
  idType: string;
}

export class BenchmarkPercentage {
  benchmark: Benchmark;
  percentage: number;
  offsetPercentage: number;
}

export class BenchmarkSearchFilter {
  searchRequest: SearchRequest;
  currencies: string[];
  types: string[];
  onlyAssetSchema: boolean;
}
