import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentService} from "../../../api/services/document.service";

@Component({
  selector: 'ves-shared-doc-type-tag',
  templateUrl: './shared-doc-type-tag.component.html',
  styleUrls: ['./shared-doc-type-tag.component.scss']
})
export class SharedDocTypeTagComponent implements OnInit, OnChanges {

  @Input() docType: string;
  icon: string;
  type: string;

  constructor(private docService: DocumentService) {

  }

  ngOnInit(): void {
    this.type = this.docService.getSimpleDocumentType(this.docType);
    this.icon = this.getIcon();
  }
  ngOnChanges(changes: SimpleChanges): void {

      this.type = this.docService.getSimpleDocumentType(this.docType);
      this.icon = this.getIcon();

  }


  getIcon() {
    let icon = null;
    const pathPrefix = '/assets/images/components/shared/';
    if (!this.docType) {
      return icon;
    }
    switch (this.type) {
      case 'PDF':
      case 'Word':
      case 'PPT':
      case 'Excel':
        icon = pathPrefix + 'icon-media-document.svg';
        break;
      case 'Audio':
        icon = pathPrefix + 'icon-media-audio.svg';
        break;
      case 'Video':
        icon = pathPrefix + 'icon-media-video.svg';
        break;
      case 'Image':
        icon = pathPrefix + 'icon-media-images.svg';
        break;
      case 'Slideshow':
        icon = pathPrefix + 'icon-media-images.svg';
        break;
      case 'YouTube':
        icon = pathPrefix + 'icon-media-video.svg';
        break;
    }
    return icon
  }
}
