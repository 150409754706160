import {Version} from './Version';
import {VestrataDocument} from './VestrataDocument';
import {Contact} from "./Contact";
import {Currency} from "./Currency";
import {BenchmarkInfo} from "./BenchmarkInfo";
import {SearchRequest} from "./SearchRequest";
import {ProductRiskCode, ProductRiskLevel} from "./ProductRiskCode";
import {ChangeNotice} from "./PortfolioModel";
import {BenchmarkPercentage} from "./Benchmark";

export class AdvisoryModel {
  id: string;
  organisationId: string;
  creationDate: Date;
  createdBy: string;
  current: Version<AdvisoryModelVersion>;
  pending: Version<AdvisoryModelVersion>;
  versions?: Version<AdvisoryModelVersion>[];
}

export class AdvisoryModelVersion {
  version: number;
  strategy: string;
  productRiskRank: number;
  currency: string;
  benchmarkList: BenchmarkPercentage[] = [];

  manager: Contact;
  changeNotice?: ChangeNotice;

  allocations: AdvisoryModelAllocation[];
}

export class AdvisoryModelAllocation {
  type: string;
  code: string;
  saa: number;
  taa: number;
  overUnderWeight: number;
  benchmark: BenchmarkInfo;
  allocations: AdvisoryModelAllocation[];
}

export class AdvisoryAllocationDiff {
  status: string;
  path: string[];
  typePath: string[];
  newAlloc: AdvisoryModelAllocation;
  oldAlloc: AdvisoryModelAllocation;
}

export class AdvisoryAllocationChanged {
  static readonly ADDED = 'added';
  static readonly REMOVED = 'removed';
  static readonly REPLACED = 'replaced';
  static readonly REVISED = 'revised';

  static values(): string[] {
    return [AdvisoryAllocationChanged.ADDED, AdvisoryAllocationChanged.REMOVED, AdvisoryAllocationChanged.REPLACED, AdvisoryAllocationChanged.REVISED];
  }
}

export class ModelBenchmark {
  benchmark: BenchmarkInfo;
  percentage: number;

}
export enum AllocationTypes {
  ASSET_CLASS1 = 'ASSET_CLASS1',
  ASSET_CLASS2 = 'ASSET_CLASS2',
  ASSET_CLASS3 = 'ASSET_CLASS3',
  REGION = 'REGION',
  SECTOR = 'SECTOR',
  CURRENCY = 'CURRENCY',
}
export class AdvisoryModelFilter {
  searchRequest: SearchRequest;
  currencies: string[];
  risks: number[];
  currencyOrder = 'desc';
  riskRankOrder: string;
}
