import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'ves-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit, OnDestroy {

  private _onDestroy = new Subject();

  @Input()
  menuHorizontal: any = [];

  @Input()
  style: string = '';

  @Input()
  multiSelectionAvailable = false;

  @Output()
  selected = new EventEmitter<any>();

  currentDropdown: number;

  constructor() {
  }

  ngOnInit() {
    console.log(this.menuHorizontal);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  goTo(index: number, option?: string) {
    if (!this.multiSelectionAvailable) {
      this.menuHorizontal.forEach( p => {
        p.isActive = false;
      });
    }
    this.menuHorizontal[index].isActive = true;
    if (option) {
      const selection = {
        item: index,
        selection: option
      };
      this.selected.emit(selection);
      this.closeDropdown();
    } else {
      this.selected.emit(index);
    }
  }

  openDropdown(index) {
    this.currentDropdown = index;
  }

  closeDropdown() {
    this.currentDropdown = null;
  }
}
