import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Location} from '@angular/common';

@Component({
  selector: 'ves-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit, OnDestroy {

  private _onDestroy = new Subject();
  private currentUrl: string;

  @Output()
  back = new EventEmitter<any>();

  _searchText: string;
  @Input() set searchText(v: string) {
    this._searchText = v;
  }
  get searchText() {
    return this._searchText;
  }
  @Output() searchTextChange = new EventEmitter<any>();


  @Input()
  menuHorizontal: any = [
  ];

  @Input() viewSearchBar = false;

  constructor(private router: Router, private location: Location ) {
  }

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.router.events.pipe(filter(e => e instanceof NavigationEnd),
      takeUntil(this._onDestroy)).subscribe((e: NavigationEnd) => {
      this.currentUrl = e.urlAfterRedirects;
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  isSelected(urls: string[]) {
      return urls && urls.find(url => this.currentUrl.startsWith('/' + url));
  }

  goTo(url: string) {
    this.router.navigate(['/' + url]);
  }

  searchClick(text) {
    this.searchText = text;
    this.searchTextChange.emit(text);
  }

  backClicked() {
    this.location.back();
    this.back.emit();
  }

}
