import {AdvisorPermission, IpsPermission, PartnerPermission} from './Permission';

export class Role {
  static readonly CIO = {value: 'cio'};
  static readonly HDPM = {value: 'hdpm'};
  static readonly DPM = {value: 'dpm'};
  static readonly HIPS = {value: 'hips'};
  static readonly BA = {value: 'ba'};
  static readonly PRODUCT_SPECIALIST = {value: 'product-specialist'};
  static readonly SAL = {value: 'sal'};
  static readonly PRODUCT_DEV = {value: 'product-dev'};
  static readonly PRODUCT_APP_CO = {value: 'product-app-co'};
  static readonly IPS_ADMIN = {value: 'ips-admin'};
  static readonly ADVISOR_ADMIN = {value: 'advisor-admin'};
  static readonly PARTNER_ADMIN = {value: 'partner-admin'};
  static readonly USER_ADMIN = {value: 'user-admin'};

  // TODO; Remove when all roles are defined.
  static readonly ADVISOR_READER = {value: 'reader'};
  static readonly ADVISOR_WRITER = {value: 'writer'};
  static readonly ADVISOR_APPROVER = {value: 'approver'};
  static readonly PARTNER_READER = {value: 'reader'};
  static readonly PARTNER_WRITER = {value: 'writer'};
  static readonly PARTNER_APPROVER = {value: 'approver'};

  partnerPermissions?: PartnerPermission;
  ipsPermissions?: IpsPermission;
  advisorPermissions?: AdvisorPermission;
  value: string;

  static values(): Role[] {
    return [Role.CIO, Role.HDPM, Role.DPM, Role.HIPS, Role.BA,
      Role.PRODUCT_SPECIALIST, Role.SAL, Role.PRODUCT_DEV,
      Role.PRODUCT_APP_CO, Role.IPS_ADMIN, Role.ADVISOR_ADMIN, Role.PARTNER_ADMIN,
      Role.USER_ADMIN, Role.ADVISOR_READER, Role.ADVISOR_WRITER,
      Role.ADVISOR_APPROVER, Role.PARTNER_READER, Role.PARTNER_WRITER, Role.PARTNER_APPROVER];
  }
}

export class Specialty {
  static readonly ETFS = 'etfs';
  static readonly EQUITIES = 'equities';
  static readonly FX = 'fx';
  static readonly FIXED_INCOME = 'fixed-income';
  static readonly FUNDS = 'funds';
  static readonly STRUCTURED_PRODUCTS = 'structured-products';

  static values(): Specialty[] {
    return [Specialty.ETFS, Specialty.EQUITIES, Specialty.FX,
    Specialty.FIXED_INCOME, Specialty.FUNDS, Specialty.STRUCTURED_PRODUCTS];
  }
}
