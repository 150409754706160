import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {ApiHttpService} from '../api.http.service';
import {forkJoin, Observable, pipe} from 'rxjs';
import {TagGroup} from "../../models/vestrata/Insight";
import {
  DocumentSearchFilter,
  DocumentUploadTypes,
  VestrataDocument,
  VestrataDocumentInfo
} from "../../models/vestrata/VestrataDocument";
import {
  ProductRecommendationListDto,
  ProductRecommendationSearchFilter
} from "../../models/vestrata/ProductRecommendation";
import {Page} from "../../models/vestrata/Page";
import {flatMap, map} from 'rxjs/internal/operators';


@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  public serviceUrl: string;
  public blobName: string;

  constructor(private http: HttpClient,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  uploadDocument(file: File): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('filename', file.name);
    params = params.set('contentType', file.type);
    return this.http.get<any>(this.serviceUrl + 'document', ApiHttpService.getOptionsWithParams(params))
      .pipe(flatMap(dto => {
        const url = dto.url;
        this.blobName = dto.blobName;
        console.log('upload new file:', file);
        console.log('url', url);
        return this.http.put<any>(url, file, ApiHttpService.getHeadersForUpload());
      }), flatMap(result => {
        console.log(result);
        let confirmParams: HttpParams = new HttpParams();
        confirmParams = confirmParams.set('blobName', this.blobName);
        return this.http.get<any>(this.serviceUrl + 'document/confirmed', ApiHttpService.getOptionsWithParams(confirmParams));
      }));
  }

  uploadSecuredDocument(file: File): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('filename', file.name);
    params = params.set('contentType', file.type);
    return this.http.get<any>(this.serviceUrl + 'document/secured', ApiHttpService.getOptionsWithParams(params))
      .pipe(flatMap(dto => {
        const url = dto.url;
        this.blobName = dto.blobName;
        return this.http.put<any>(url, file, ApiHttpService.getHeadersForUpload());
      }), flatMap(result => {
        let confirmParams: HttpParams = new HttpParams();
        confirmParams = confirmParams.set('blobName', this.blobName);
        return this.http.get<any>(this.serviceUrl + 'document/secured/confirmed', ApiHttpService.getOptionsWithParams(confirmParams));
      }));
  }

  uploadDocuments(files: any[]): Observable<any[]> {
    const obs = [...files].map(file => {
      return this.uploadDocument(file);
    });
    return forkJoin(obs);
  }

  deleteDocument(url: string): Observable<void>  {
    let param: HttpParams = new HttpParams();
    param = param.set('url', encodeURIComponent(url));
    return this.http.delete<void>(this.serviceUrl + 'document', ApiHttpService.getOptionsWithParams(param));
  }
  deleteSecuredDocument(url: string): Observable<void>  {
    return this.http.post<void>(this.serviceUrl + 'document/secured',url, ApiHttpService.getHeaders());
  }

  getTagGroups(): Observable<TagGroup[]> {
    return this.http.get<TagGroup[]>(this.serviceUrl + 'global/tag/',  ApiHttpService.getHeaders());
  }
  saveDocument(vestrataDocument: VestrataDocument): Observable<VestrataDocument> {
    return this.http.post<VestrataDocument>(this.serviceUrl + 'document/create',vestrataDocument, ApiHttpService.getHeaders());
  }
  loadListFilters(): Observable<any> {
    return this.http.get<any>(this.serviceUrl  + 'document/load-filters', ApiHttpService.getHeaders());
  }
  loadFilters(isArchive: boolean): Observable<any> {
    return this.http.get<any>(this.serviceUrl  + 'document/load-filters/' + isArchive, ApiHttpService.getHeaders());
  }
  searchDocumentWithFilters(filters: DocumentSearchFilter): Observable<Page<VestrataDocumentInfo>> {
    return this.http.post<Page<VestrataDocumentInfo>>(this.serviceUrl + 'document/search', filters, ApiHttpService.getHeaders());
  }
  getById(docId: string): Observable<VestrataDocument> {
    return this.http.get<VestrataDocument>(this.serviceUrl  + 'document/secured/' + docId,
      ApiHttpService.getHeaders());
  }

  getSixDocBlob(document: VestrataDocumentInfo): Observable<string> {
    return this.http.get<Blob>(this.serviceUrl + 'instrument/regdoc/six/' + document.id, ApiHttpService.getHeaders())
      .pipe(map(blob => {
        console.log(blob);
        console.log('OKK');
        return this.downloadFile(blob, 'pdf', document.title);
      }));
  }

  downloadFile(result: Blob, type: string, filename: string) {
    const url = window.URL.createObjectURL(result); // <-- work with blob directly
    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
    return url;
  }
  /*
      generateOfgemReport(month: string, year: string) {
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          // have to explicitly give as 'blob' or 'json'
          responseType: 'blob' as 'blob'
        };
        return this.http.get(this.backEndUrl + 'rest/ofgem/' +  year + '/' + month,  options);
      }*/
  getSimpleDocumentType(contentType) {
    let type = 'Unknown';
    if (!contentType) {
      return type;
    }
    if (DocumentUploadTypes.audios().includes(contentType)) {
      type = 'Audio';
    }
    if (DocumentUploadTypes.videos().includes(contentType)) {
      type = 'Video';
    }
    if (DocumentUploadTypes.pdf().includes(contentType)) {
      type = 'PDF';
    }
    if (DocumentUploadTypes.images().includes(contentType)) {
      type = 'Image';
    }
    if (DocumentUploadTypes.words().includes(contentType)) {
      type = 'Word';
    }
    if (DocumentUploadTypes.presentations().includes(contentType)) {
      type = 'PPT';
    }
    if (DocumentUploadTypes.sheets().includes(contentType)) {
      type = 'Excel';
    }
    if (DocumentUploadTypes.slideshow().includes(contentType)) {
      type = 'Slideshow';
    }
    if (DocumentUploadTypes.youtube().includes(contentType)) {
      type = 'YouTube';
    }
    return type;

  }

  getContentTypesFromSimpleType(simpleType: string){
    switch (simpleType) {
      case 'Audio':
        return DocumentUploadTypes.audios();
      case 'Video':
        return DocumentUploadTypes.videos();
      case 'PDF':
        return DocumentUploadTypes.pdf();
      case 'Image':
        return DocumentUploadTypes.images();
      case 'Word':
        return DocumentUploadTypes.words();
      case 'PPT':
        return DocumentUploadTypes.presentations();
      case 'Excel':
        return DocumentUploadTypes.sheets();
    }
  }
}
