import { SearchFilter } from './../../../models/vestrata/AccountExceptionDto';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subject} from "rxjs";
import {filter, finalize, takeUntil} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {
  DocumentSearchFilter,
  DocumentUploadTypes,
  VestrataDocTypes,
  VestrataDocument,
  VestrataDocumentInfo
} from "../../../models/vestrata/VestrataDocument";
import {Permission} from "../../../models/vestrata/Permission";
import {
  BlocOption,
  FilterOption
} from "../../../basic-components/components-5/two-level-bar-filter/two-level-bar-filter.component";
import {DocumentService} from "../../../api/services/document.service";
import {SharedSnackbarService} from "../../shared-snackbar/services/shared-snackbar.service";
import {SharedLoaderService} from "../../shared-loader/service/shared-loader.service";
import {SnackbarMessage} from "../../shared-snackbar/models/snackbar-message";
import {Paging, SearchRequest} from "../../../models/vestrata/SearchRequest";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import { Portal } from '../../../models/vestrata/Portal';

@Component({
  selector: 'ves-shared-document-library',
  templateUrl: './shared-document-library.component.html',
  styleUrls: ['./shared-document-library.component.scss']
})
export class SharedDocumentLibraryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() allowedTypes: string[] = DocumentUploadTypes.all();
  @Input() allowUpload = true;
  @Input() isArchive = false;
  @Input() mode: 'view' | 'selection' = 'view';
  @Input() portal: Portal;
  @Output() selectDocumentEvent = new EventEmitter<VestrataDocumentInfo>();
  @ViewChild('fileUploader') fileUploader: ElementRef;
  tempUploadedDocument: VestrataDocument;
  viewFileDialog = false;
  docDialogMode = 'view';

  private static readonly FILTER_TYPE = 'type';
  private static readonly FILTER_CONTENT_TYPE = 'contentType';
  private static readonly SORT_UP = 'up';
  private static readonly SORT_DOWN = 'down';
  private _onDestroy = new Subject();
  permission: Permission;

  // ----FILTERS & SEARCH-------
  searchFilter = new DocumentSearchFilter();
  filterTypes: BlocOption[] = [];
  selectedFilters: BlocOption[] = [];
  filteringText: string;
  headerSort = {
    type: SharedDocumentLibraryComponent.SORT_DOWN,
    title: SharedDocumentLibraryComponent.SORT_DOWN,
    summary: SharedDocumentLibraryComponent.SORT_DOWN,
    effectiveDate: SharedDocumentLibraryComponent.SORT_DOWN,
    expiryDate: SharedDocumentLibraryComponent.SORT_DOWN,
    contentType: SharedDocumentLibraryComponent.SORT_DOWN
  };
  headers = Object.keys(this.headerSort);
  // ---------------------------
  documents: VestrataDocumentInfo[];
  documentIdToView: string;
  @ViewChild('fileUploader') myDiv: ElementRef;

  currentUrl: string;

  isIPSInsight: boolean = false;
  isIPSMarketing: boolean = false;
  isIPSCompilance : boolean = false;
  isIPSResearch: boolean = false;
  isIPSDocLib: boolean = false;

  // Specific data
  readonly readonly;

  // Paginator //
  pages = 0;
  results = 0;
  resultNumber = 25;
  pageNumber = 0;

  constructor(private documentService: DocumentService, private snackbar: SharedSnackbarService,
              private translate: TranslateService, private loader: SharedLoaderService,
              private route: ActivatedRoute,
              private router: Router,
  ) {
    this.readonly = !!this.route.snapshot.data.readonly;
    this.allowUpload = this.allowUpload && !this.readonly;
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url;

    this.router.events.pipe(filter(e => e instanceof NavigationEnd),
      takeUntil(this._onDestroy)).subscribe((e: NavigationEnd) => {
      this.currentUrl = e.urlAfterRedirects;
    });

    this.checkUrls();

    this.initEmptySearchFilter();
    this.loadAndBuildFilters();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.allowUpload = this.allowUpload && !this.readonly;
  }

  private checkUrls(): void {
    this.isIPSInsight = this.currentUrl.startsWith('/market-views/library/insights');
    this.isIPSMarketing = this.currentUrl.startsWith('/market-views/house-view/trade-ideas/details') || this.currentUrl.startsWith('/market-views/library/marketing-materials');
    this.isIPSCompilance = this.currentUrl.startsWith('/market-views/library/regulatory-materials') || this.currentUrl.startsWith('/governance/product-catalog/approved-products/details');
    this.isIPSResearch = this.currentUrl.endsWith('/market-views/library/research');
    this.isIPSDocLib = this.currentUrl.endsWith('/governance/library/document-library');

    if (this.isIPSCompilance || this.isIPSMarketing) {
      this.portal = Portal.IPS;
    }
  }

  clickUploadCheck() {
    const uploadNew = this.route.snapshot.queryParamMap.get('upload');
    if (uploadNew && this.allowUpload) {
      const checkExist = setInterval(function () {
        this.fileUploader.click();
        clearInterval(checkExist);
      }, 500);
    }
  }

  private executeLoad() {
    console.log('searchFilter', this.searchFilter);
    console.log('portal', this.portal);

    if (this.isIPSCompilance || this.isIPSMarketing) {
      this.portal = Portal.IPS
    }

    if (this.portal === Portal.IPS) {
      if (this.isIPSMarketing) {
        this.searchFilter.types = [VestrataDocTypes.MARKETING];
      } else if (this.isIPSCompilance) {
        this.searchFilter.types = [VestrataDocTypes.COMPLIANCE];
      } else if (this.isIPSInsight) {
        this.searchFilter.types = [VestrataDocTypes.INSIGHT];
      } else if (this.isIPSResearch) {
        this.searchFilter.types = [VestrataDocTypes.RESEARCH];
      }
    }

    this.loader.showBarLoader();
    return this.documentService.searchDocumentWithFilters(this.searchFilter)
      .pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe(results => {
        this.documents = results.content;
        this.pages = results.totalPages;
        this.results = results.totalElements;
        this.clickUploadCheck();
      }, err => {
        this.snackbar.push(new SnackbarMessage(this.translate.instant(err.error.message), 'error'));
      });
  }

  onFilterChange(event: any) {
    this.initEmptySearchFilter();

    console.log('event', event);

    if (event && Array.isArray(event)) {
      for (const option of event) {
        const index = this.selectedFilters.findIndex(b => b.value === option.value);
        if (index !== -1) {
          this.selectedFilters.splice(index, 1);
        }
        if ((option as BlocOption).selectedCount > 0) {
          this.selectedFilters.push(option);
        }
      }
    } else {
      this.filteringText = event;
    }

    this.searchFilter.searchRequest.text = this.filteringText;
    for (const option of this.selectedFilters) {
      const filters = option.options.filter(o => o.checked);
      if (option.value === SharedDocumentLibraryComponent.FILTER_TYPE) {
        this.searchFilter.types = filters.map(f => f.value);
      } else if (option.value === SharedDocumentLibraryComponent.FILTER_CONTENT_TYPE) {
        filters.map(f => f.value).forEach(item => {
          this.searchFilter.contentTypes = this.searchFilter.contentTypes.concat(this.documentService.getContentTypesFromSimpleType(item))
        });

      }
    }

    this.executeLoad();
  }

  private initEmptySearchFilter() {
    this.searchFilter.contentTypes = [];
    this.searchFilter.types = [];
    this.searchFilter.archive = this.isArchive;
    // this.searchFilter.minEffectiveDate = null;
    // this.searchFilter.maxEffeciveDate = null;
    this.searchFilter.searchRequest = new SearchRequest();
    this.searchFilter.searchRequest.paging = new Paging();
    this.searchFilter.searchRequest.paging.page = 0;
    this.searchFilter.searchRequest.paging.size = 25;
    this.searchFilter.searchRequest.criteria = {};
    this.searchFilter.searchRequest.text = '';
  }

  onDateFilterChange() {
    this.executeLoad();
  }

  loadAndBuildFilters() {
    console.log('other portal', this.portal);
    console.log('isIPSCompilance', this.isIPSCompilance);
    console.log('isIPSMarketing', this.isIPSMarketing);


    if (this.isIPSCompilance || this.isIPSMarketing) {
      this.portal = Portal.IPS;
    }

    console.log('isArchive', this.isArchive);
    this.documentService.loadFilters(this.isArchive).pipe(takeUntil(this._onDestroy))
      .subscribe(
        data => {
          this.filterTypes = [];
          console.log('******DATA-FILTER****', data);
          const typeBloc = new BlocOption();
          typeBloc.label = 'documents.type';
          typeBloc.options = [];
          typeBloc.value = SharedDocumentLibraryComponent.FILTER_TYPE;

          if (this.portal === Portal.IPS) {
            if (this.isIPSMarketing) {
              typeBloc.options.push(new FilterOption('documents.' + VestrataDocTypes.MARKETING, VestrataDocTypes.MARKETING, data.types[VestrataDocTypes.MARKETING], true, false, null, true));
            } else if (this.isIPSCompilance) {
              typeBloc.options.push(new FilterOption('documents.' + VestrataDocTypes.COMPLIANCE, VestrataDocTypes.MARKETING, data.types[VestrataDocTypes.MARKETING], true, false, null, true));
            } else if (this.isIPSInsight) {
              typeBloc.options.push(new FilterOption('documents.' + VestrataDocTypes.INSIGHT, VestrataDocTypes.MARKETING, data.types[VestrataDocTypes.MARKETING], true, false, null, true));
            } else if (this.isIPSResearch) {
              typeBloc.options.push(new FilterOption('documents.' + VestrataDocTypes.RESEARCH, VestrataDocTypes.MARKETING, data.types[VestrataDocTypes.MARKETING], true, false, null, true));
            }
          } else {
            Object.keys(data.types).forEach(key => {
              typeBloc.options.push(new FilterOption('documents.' + key, key, data.types[key], true, false, null));
            });
          }

            // Object.keys(data.types).forEach(key => {
            //   if (this.portal === Portal.IPS && (this.isIPSMarketing || this.isIPSCompilance || this.isIPSInsight || this.isIPSResearch)) {
            //     typeBloc.options.push(new FilterOption('documents.'+key, key, data.types[key], false, false, null, true));
            //   }
            //   else {
            //     typeBloc.options.push(new FilterOption('documents.'+key, key, data.types[key], true, false, null));
            //   }
            // });

            this.filterTypes.push(typeBloc);

          if (this.mode !== 'selection') {
            const contentTypesBloc = new BlocOption();
            contentTypesBloc.label = 'documents.doc-type';
            contentTypesBloc.options = [];
            contentTypesBloc.value = SharedDocumentLibraryComponent.FILTER_CONTENT_TYPE;
            Object.keys(data.contentTypes).forEach(key => {
              contentTypesBloc.options.push(new FilterOption(key, key, data.contentTypes[key], true, false, null));
            });
            this.filterTypes.push(contentTypesBloc);
          } else {
            this.searchFilter.contentTypes = this.allowedTypes;
          }

          console.log("******DATA-FILTER****", this.filterTypes);

          this.executeLoad();
        }
      );
  }

  onFileChoosed(files: any) {
    console.log('Document:', files);
    if (!this.isValidFileType(files[0])) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.file-type-not-allowed'), 'error'));
      return;
    }
    this.loader.showFullLoader('upload-file.uploading');
    this.documentService.uploadSecuredDocument(files[0]).pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader()))
      .subscribe(doc => {
        this.fileUploader.nativeElement.value = '';
        this.tempUploadedDocument = doc;
        this.docDialogMode = doc.id ? 'view' : 'creation';
        this.viewFileDialog = true;
        if (doc.id) {
          this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.already-existing-doc'), 'warning'));
        }
      }, error => {this.fileUploader.nativeElement.value = '';});
  }
  isValidFileType(file: File): boolean {
    return this.allowedTypes.includes(file.type);
  }

  onSelectFile(doc) {

  }

  onSaveUploadedFile(event) {
    this.viewFileDialog =false;
    this.documents.unshift(event);
  }

  sort(key: string) {
    if (!key) { return; }
    const newValue = this.headerSort[key] === SharedDocumentLibraryComponent.SORT_DOWN ?
      SharedDocumentLibraryComponent.SORT_UP :
      SharedDocumentLibraryComponent.SORT_DOWN;
    Object.keys(this.headerSort).forEach(k => this.headerSort[k] = SharedDocumentLibraryComponent.SORT_DOWN);
    this.headerSort[key] = newValue;

    this.searchFilter.searchRequest.sorts = [];
    this.searchFilter.searchRequest.sorts.push({property: key, direction: newValue === SharedDocumentLibraryComponent.SORT_DOWN ? -1 : 1});
    this.executeLoad();
  }

  openDetails(doc) {
    this.docDialogMode = 'view';
    // this.tempUploadedDocument = doc;
    this.documentIdToView = doc.id;
    this.viewFileDialog = true;
  }

  selectDoc(doc: VestrataDocumentInfo) {
    if (!this.allowedTypes.includes(doc.contentType)) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('documents.select-file-type-not-allowed'), 'error'));
      return;
    }
    this.selectDocumentEvent.emit(doc);
  }

  onCloseDocViewer() {
    this.viewFileDialog = false;
    this.documentIdToView = null;
    this.tempUploadedDocument = null;
  }

  onSizeChanged(size: number) {
    this.resultNumber = size;
    this.searchFilter.searchRequest.paging.page = this.pageNumber;
    this.searchFilter.searchRequest.paging.size = this.resultNumber;
    this.executeLoad();
  }

  onPageChanged(page: number) {
    this.pageNumber = page - 1;
    this.searchFilter.searchRequest.paging.page = this.pageNumber;
    this.searchFilter.searchRequest.paging.size = this.resultNumber;
    this.executeLoad();
  }
}
