import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ves-sliding-bar',
  templateUrl: './sliding-bar.component.html',
  styleUrls: ['./sliding-bar.component.scss']
})
export class SlidingBarComponent implements OnInit, OnChanges, OnDestroy {

  private style = document.createElement('style');
  private _onDestroy = new Subject();

  @ViewChild('labels') labels: ElementRef;
  @ViewChild('range') range: ElementRef;

  @Output() dateChanged = new EventEmitter<string>();
  @Input() inceptionDate: any;
  items = [];

  constructor(private renderer: Renderer2,
              private translate: TranslateService) {
    moment.locale(translate.currentLang);
    this.translate.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe(event => {
      moment.locale(event.lang);
    });
  }

  ngOnInit() {
    document.body.appendChild(this.style);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inceptionDate && changes.inceptionDate.currentValue) {
      const monthDifference = moment().diff(moment(this.inceptionDate), 'month');
      if (monthDifference >= 1) {
        this.items.push(DateRange.ONE_MONTH);
      }
      if (monthDifference >= 3) {
        this.items.push(DateRange.THREE_MONTHS);
      }

      if (monthDifference >= 6) {
        this.items.push(DateRange.SIX_MONTHS);
      }

      if (monthDifference >= 12) {
        this.items.push(DateRange.ONE_YEAR);
      }

      if (monthDifference >= 12 * 3) {
        this.items.push(DateRange.THREE_YEAR);
      }

      if (monthDifference >= 12 * 5) {
        this.items.push(DateRange.FIVE_YEAR);
      }

      if (monthDifference >= 12 * 10) {
        this.items.push(DateRange.TEN_YEAR);
      }
      this.items.push(DateRange.INCEPTION);
    }
    this.applyNewStyle(this.items.length - 1);
  }

  onValueChanged(v: any) {
    this.applyNewStyle(v.value);

    // Emit value changed
    this.dateChanged.emit(this.calculateDateFrom((this.items[v.value])));
  }

  onLabelItemClick(v: number) {
    this.onValueChanged({value: v});
    this.range.nativeElement.value = v;
  }

  private calculateDateFrom(range: string): string {
    const now = moment();
    let date = moment(this.inceptionDate);
    switch (range) {
      case DateRange.ONE_MONTH:
        date = now.subtract(1, 'month');
        break;
      case DateRange.THREE_MONTHS:
        date = now.subtract(3, 'month');
        break;
      case DateRange.SIX_MONTHS:
        date = now.subtract(6, 'month');
        break;
      case DateRange.ONE_YEAR:
        date = now.subtract(1, 'year');
        break;
      case DateRange.THREE_YEAR:
        date = now.subtract(3, 'year');
        break;
      case DateRange.FIVE_YEAR:
        date = now.subtract(5, 'year');
        break;
      case DateRange.TEN_YEAR:
        date = now.subtract(10, 'year');
        break;
    }
    // Get the end of the previous month.
    date = date.subtract(1, 'month').endOf('month');
    return date.format('L');
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private applyNewStyle(v: number) {
    // Apply range style
    const val = (v / (this.items.length - 1)) * 100;
    const prefs = ['webkit-slider-runnable-track', 'moz-range-track', 'ms-track'];
    let style = '';
    for (const pref of prefs) {
      style += '.range {background: linear-gradient(to right, #003057 0%, #248ED8 ' + val + '%, #fff ' + val + '%, #fff 100%)}';
      style += '.range input::-' + pref + '{background: linear-gradient(to right, #003057 0%, #248ED8 ' + val + '%, #D7DADB ' + val + '%, #D7DADB 100%)}';
    }
    this.style.textContent = style;

    // Apply labels style
    if (this.labels) {
      for (const label of this.labels.nativeElement.children) {
        this.renderer.removeClass(label, 'selected');
      }
      this.renderer.addClass(this.labels.nativeElement.children[v], 'selected');
    }
  }
}

enum DateRange {
  ONE_MONTH = '1M',
  THREE_MONTHS = '3M',
  SIX_MONTHS = '6M',
  ONE_YEAR = '1Y',
  THREE_YEAR = '3Y',
  FIVE_YEAR = '5Y',
  TEN_YEAR = '10Y',
  INCEPTION = 'INCEPTION'
}
