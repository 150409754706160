import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ves-product-type',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.scss']
})
export class ProductTypeComponent implements OnInit {

  @Input() type;
  @Input() productType;
  @Input() onlyProdTypeImg: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
