import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "@ngx-config/core";
import {VesConfService} from "./conf.service";
import {interval, Observable} from "rxjs";
import {ApiHttpService} from "../api.http.service";
import {Alert} from "../../models/vestrata/Alert";
import {flatMap} from "rxjs/operators";
import {Router} from "@angular/router";
import {SharedSnackbarService} from "../../components/shared-snackbar/services/shared-snackbar.service";
import {SnackbarMessage} from "../../components/shared-snackbar/models/snackbar-message";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService,
              private router: Router,
              private snackbar: SharedSnackbarService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getNotifications(): Observable<Alert[]> {
    return this.http.get<Alert[]>(this.serviceUrl + 'alert/notifications' , ApiHttpService.getHeaders());
  }

  muteNotification(notification: Alert): Observable<void> {
    return this.http.post<void>(this.serviceUrl + 'alert/notifications/' + notification.id + '/mute', null,ApiHttpService.getHeaders());
  }

  startPeriodicNotifications(seconds: number): Observable<Alert[]> {
    return  interval(seconds*1000)
      .pipe(
        flatMap(x => this.getNotifications())
      )
  }

  getNotificationAction(notification: Alert, portal: string){
    const portalMapping = this.getGlobalMapping(notification)[portal];
    const category = portalMapping.find(cat => cat.category === notification.category);
    const action = category ?  category.subCategories.find(subCat => subCat.subCategory === notification.subCategory): null;
    return action;
  }
  takeNotificationAction(notification: Alert, portal: string) {
    const action = this.getNotificationAction(notification, portal);
    if (!action) {
      return;
    }
    this.router.navigate(action.command, action.extras);
  }

  getGlobalMapping(notification: Alert){
    return {
      ips : [
        {category: 'CURATION', subCategories: [
            {subCategory: 'INSIGHTS', command: ['market-views/library/insights/library']}
          ]
        },
        {category: 'VALIDATION', subCategories: [
            {subCategory: 'FEES',  command: ['settings/fee-schedule']},
            {subCategory: 'SALES_MEETING_CATEGORY',  command: ['settings/platform-settings/meeting-categories']},
            {subCategory: 'TRADE_IDEA_CATEGORY',  command: ['settings/platform-settings/trade-idea-categories']},
            {subCategory: 'APPROVAL_PROCESS',  command: ['settings/platform-settings/approval-process']},
            {subCategory: 'TEAMS',  command: ['settings/platform-settings/teams']},
            {subCategory: 'INSIGHTS',  command: ['market-views/library/insights/details'] , extras: {queryParams: {id: notification.relatedId}}},
            {subCategory: 'COMPANY_PROFILE',  command: ['account/user-account']},
            {subCategory: 'USERS',  command: ['account/users/details'], extras: {queryParams: { id: notification.relatedId }}},
            {subCategory: 'TRADE_IDEA',  command: ['/market-views/house-view/trade-ideas/details'], extras: {queryParams: {id: notification.relatedId}}},
            {subCategory: 'RECOMMENDATION',  command: ['/market-views/products/recommendations/details'], extras: {queryParams: {id: notification.relatedId}}},
            {subCategory: 'SAA_TAA',  command: ['/market-views/house-view/advisory/details'], extras: {queryParams: {id: notification.relatedId}}},
            {subCategory: 'SAA_TAA_DELETION',  command: ['/market-views/house-view/advisory']}
          ]
        },
        {category: 'PRODUCT', subCategories: [
            {subCategory: 'INSIGHTS', command: ['market-views/library/insights/library']},
            {subCategory: 'MODEL_PORTFOLIO',  command: ['solutions/discretionary/models/details'], extras: {queryParams: {id: notification.relatedId}}}
          ]
        },
      ],
      advisor: [
        {category: 'PRODUCT', subCategories: [
            {subCategory: 'INSIGHTS',  command: ['insights']},
            {subCategory: 'MODEL_PORTFOLIO',  command: ['solutions/discretionary/models']},
          ]
        }
      ],
      admin: [
        {category: 'CURATION', subCategories: [
            {subCategory: 'INSIGHTS', command: ['insights/library']}
          ]
        },
        {category: 'VALIDATION', subCategories: [
            {subCategory: 'INSIGHTS',  command: ['insights/library']}
          ]
        },
        {category: 'PRODUCT', subCategories: [
            {subCategory: 'MODEL_PORTFOLIO',  command: ['solutions/discretionary/models/details'], extras: {queryParams: {id: notification.relatedId}}},
          ]
        }
      ],
      partner: [
        {category: 'VALIDATION', subCategories: [
            {subCategory: 'INSIGHTS',  command: ['insights/library/details'], extras: {queryParams: {id: notification.relatedId}}},
            {subCategory: 'METRICS',  command: ['portfolio-models/metrics-main', { id: notification.relatedId } ]},
            {subCategory: 'MODEL_PORTFOLIO',  command: ['portfolio-models/model-details', { id: notification.relatedId } ]},
            {subCategory: 'STRATEGY',  command: ['strategies/strategy', { id: notification.relatedId }]},
            {subCategory: 'COMPANY_PROFILE',  command: ['account/profile']},
            {subCategory: 'USERS',  command: ['account/users/details'], extras: {queryParams: { id: notification.relatedId }}}
          ]
        },
        {category: 'PRODUCTS', subCategories: [
            {subCategory: 'METRICS',  command: ['portfolio-models/model-details', { id: notification.relatedId } ]}
          ]
        },
      ]
    };
  }

}
