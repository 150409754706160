import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DocumentService} from "../../../api/services/document.service";
import {Subject} from "rxjs";
import {finalize, takeUntil} from 'rxjs/operators';
import {SnackbarMessage} from '../../../components/shared-snackbar/models/snackbar-message';
import {SharedSnackbarService} from '../../../components/shared-snackbar/services/shared-snackbar.service';
import {UtilsService} from '../../../api/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {SharedLoaderService} from '../../../components/shared-loader/service/shared-loader.service';
import {FourEyeComponent} from "../../4eye-component";

@Component({
  selector: 'ves-gallery-several',
  templateUrl: './gallery-several.component.html',
  styleUrls: ['./gallery-several.component.scss']
})
export class GallerySeveralComponent extends FourEyeComponent<string[]> implements OnInit, OnDestroy, OnChanges {

  private readonly MAX_PICTURE_SIZE = 10000000;

  @Output() uploaded = new EventEmitter<string>();
  @Output() deleted = new EventEmitter<string>();
  @Input() hasUpload = true;
  @Input() hasDelete = true;
  @Input() hasBottomSlider = false;

  seeFullGallery: boolean = false;
  hover: boolean = false;
  acHoverLeft: boolean = false;
  acHoverRight: boolean = false;

  // Full gallery url
  currentUrl: string;
  currentIndex = 0;
  acceptedFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  private _onDestroy = new Subject();

  constructor(
    private translateService: TranslateService,
    private loader: SharedLoaderService,
    private utilsService: UtilsService,
    private snackbar: SharedSnackbarService,
    private documentService: DocumentService) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editingValue.currentValue) {
      // Current image has been removed so go to next or close gallery.
      if (!changes.editingValue.currentValue.find(u => u === this.currentUrl)) {
        this.currentIndex = this.currentIndex <= changes.editingValue.currentValue.length - 1 ?
          this.currentIndex :
          changes.editingValue.currentValue.length - 1;

        if (this.currentIndex >= 0) {
          this.currentUrl = changes.editingValue.currentValue[this.currentIndex];
        } else {
          this.seeFullGallery = false;
          this.currentIndex = 0;
        }
      }
    }
  }

  equals(a: string[], b: string[]): boolean {
    return a === b;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  uploadFile(fs: any) {
    if (!this.getState().readonly) {
      const total = [...fs].length + this._editingValue.length;
      if (total > 5) {
        this.snackbar.push(new SnackbarMessage(this.translateService.instant('upload-file.max_reached'), 'error'));
      } else {
        const photosTooBig = [...fs].filter((file: File) => file.size > this.MAX_PICTURE_SIZE);
        if (photosTooBig.length > 0) {
          let message = this.translateService.instant(photosTooBig.length === 1 ? 'upload-file.file-too-large' : 'upload-file.files-too-large');
          for (const file of photosTooBig) {
            message += file.name + ', ';
          }
          this.snackbar.push(new SnackbarMessage(message, 'error'));
        } else if (!this.isValidFileType(fs)) {
          this.snackbar.push(new SnackbarMessage(this.translateService.instant('upload-file.upload-file-invalid-error'), 'error'));
        } else {
          this.loader.showFullLoader('upload-file.uploading');
          return this.documentService.uploadDocuments(fs).pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe(returnUrl => {
            if (returnUrl) {
              const urls = returnUrl.map(value => value.url);
              this._editingValue.push(...urls);
              this.editingValueChange.emit(this._editingValue);
            }
            if (this._editingValue && this._editingValue.length > 0) {
              this.currentUrl = this._editingValue[0];
            }
            this.snackbar.push(new SnackbarMessage(this.translateService.instant('upload-file.uploaded'), 'info'));
          }, error => {
            this.snackbar.push(new SnackbarMessage(error, 'error'));
          });
        }
      }
    }
  }

  private isValidFileType(fs: FileList): boolean {
    for (const f of Array.from(fs)) {
      if (!this.acceptedFileTypes.includes((f as File).type)) {
        return false;
      }
    }
    return true;
  }

  removeFile(logoUrl: string) {
    // If the component has no state : remove image from backend.
    // Otherwise let component parent handle the removal.
    if (!this.currentValue && !this.pendingValue) {
      this.documentService.deleteDocument(logoUrl).pipe(takeUntil(this._onDestroy)).subscribe(returnUrl => {
        this.utilsService.removeFromIterable(this._editingValue, logoUrl);
        this.editingValueChange.emit(this._editingValue);
        if (this._editingValue && this._editingValue.length > 0) {
          this.currentUrl = this._editingValue[0];
        }
        this.deleted.emit(logoUrl);
      }, error => {
        this.snackbar.push(new SnackbarMessage(error, 'error'));
      });
    } else {
      this.deleted.emit(logoUrl);
    }
  }

  onHover() {
    if (!this.getState().readonly) {
      this.hover = true;
    }
  }

  onHoverOut() {
    if (!this.getState().readonly) {
      this.hover = false;
    }
  }

  preview() {
    this.seeFullGallery = true;
  }

  closePreview() {
    this.seeFullGallery = false;
  }

  slidePrevious() {
    this.currentIndex--;
    if (this.currentIndex < 0) {
      this.currentIndex = this._editingValue.length - 1;
    }
    this.currentUrl = this._editingValue[this.currentIndex];
  }

  slideNext() {
    this.currentIndex++;
    if (this.currentIndex > this._editingValue.length - 1) {
      this.currentIndex = 0;
    }
    this.currentUrl = this._editingValue[this.currentIndex];
  }

  delete(url: string) {
    this.removeFile(url);
  }

  getSliderActionImg(value: 'left' | 'right'): string {
    let src;
    if (value === 'left') {
      src = this.acHoverLeft ? '../../../assets/images/components/components-8/arrow-backward-hover.svg' :
        '../../../assets/images/components/components-8/arrow-backward-large.svg';
    } else {
      src = this.acHoverRight ? '../../../assets/images/components/components-8/arrow-forward-hover.svg' :
        '../../../assets/images/components/components-8/arrow-forward-large.svg';
    }
    return src;
  }

  // TODO: Remove that fast ugly code.
  actionHover(value: 'left' | 'right') {
    if ( value === 'left' ) {
      this.acHoverLeft = true;
    } else {
      this.acHoverRight = true;
    }
  }

  actionHoverOut() {
    this.acHoverLeft = false;
    this.acHoverRight = false;
  }

  getFileNameFromUrl(url: string) {
    return url.substring(url.lastIndexOf('/')+1);
  }
}
