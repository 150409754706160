import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";

@Pipe({
  name: 'numberCustom'
})
export class NumberCustomPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: any,useAppLocal: boolean = false): any {
    const lang = navigator.language || window.navigator.language;
    let numberPipe: DecimalPipe = new DecimalPipe(lang);
    if (useAppLocal) {
      numberPipe = new DecimalPipe(this.translateService.currentLang);
    }

    return numberPipe.transform(value);
  }

}
