import {Injectable} from '@angular/core';
import {
  AuthenticationService,
  GenericBackendErrorService,
  SharedSnackbarService,
  SnackbarMessage,
} from 'vestrata-components-lib';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService,
              private translate: TranslateService,
              private snackbar: SharedSnackbarService,
              private genericErrorHandling: GenericBackendErrorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        this.genericErrorHandling.displayErrors(error.headers.get('error-code'));
        if (error instanceof HttpErrorResponse) {
          if (error.status === 0) {
            this.authService.disconnect();
            this.snackbar.push(new SnackbarMessage(this.translate.instant('error.server-error'), 'warning'));
            return throwError(error);
          } else {
            return throwError(error);
          }
        } else {
          return throwError(error);
        }
      }));
  }
}
