import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FourEyeComponent} from '../../4eye-component';

@Component({
  selector: 'ves-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent extends FourEyeComponent<boolean> implements OnInit, OnChanges {

  @Input()
  label: string;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  makeChange() {
    if (!this.locked) {
      this._editingValue = !this._editingValue;
      this.editingValueChange.emit(this._editingValue);
    }
  }

  equals(a: boolean, b: boolean): boolean {
    return a === b;
  }
}
