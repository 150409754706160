import {HttpClient} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {BuildInfo} from '../../models/vestrata/BuildInfo';
import {ConfigService} from '@ngx-config/core';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiHttpService} from '../api.http.service';
import {TimePeriod} from '../../models/vestrata/TimePeriod';
import {Metric} from '../../models/vestrata/Metric';
import {Organisation} from '../../models/vestrata/Organisation';
import {Agreement} from "../../models/vestrata/Agreement";
import { Currency } from "../../models/vestrata/Currency";

@Injectable({
  providedIn: 'root',
})
export class AdminService  {

  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getOrganisation(): Observable<Organisation> {
    return this.http.get<Organisation>(this.serviceUrl + 'organisation/mine', ApiHttpService.getHeaders());
  }

  getFrontEndBuildInfo(): BuildInfo {
    const myBuild = new BuildInfo();
    myBuild.commitId = this.config.getSettings('system.commitId');
    myBuild.pipelineId = this.config.getSettings('system.pipelineId');
    myBuild.buildtime = this.config.getSettings('system.buildDatetime');
    return myBuild;
  }
  getOrganisations(): Observable<Organisation[]> {
    return this.http.get<Organisation[]>(this.serviceUrl + 'organisation/' , ApiHttpService.getHeaders());
  }

  getOrganisationById(id): Observable<Organisation> {
    return this.http.get<Organisation>(this.serviceUrl + 'organisation/' + id , ApiHttpService.getHeaders());
  }
  createOrganisation(organisation: Organisation): Observable<Organisation> {
    return this.http.post<Organisation>(this.serviceUrl + 'organisation/' , organisation, ApiHttpService.getHeaders());
  }

  updateOrganisation(organisation: Organisation): Observable<Organisation> {
    return this.http.put<Organisation>(this.serviceUrl + 'organisation/' , organisation, ApiHttpService.getHeaders());
  }

  deleteOrganisation(organisation: Organisation): Observable<Organisation> {
    return this.http.delete<Organisation>(this.serviceUrl + 'organisation/' + organisation.id , ApiHttpService.getHeaders());
  }


  getTimePeriods(): Observable<TimePeriod[]> {
    return this.http.get<TimePeriod[]>(this.serviceUrl + 'metrics/timeperiod', ApiHttpService.getHeaders());
  }

  createTimePeriod(period: TimePeriod): Observable<TimePeriod> {
    return this.http.post<TimePeriod>(this.serviceUrl + 'metrics/timeperiod', period, ApiHttpService.getHeaders());
  }

  updateTimePeriod(period: TimePeriod): Observable<TimePeriod> {
    return this.http.put<TimePeriod>(this.serviceUrl + 'metrics/timeperiod', period, ApiHttpService.getHeaders());
  }

  deleteTimePeriod(period: TimePeriod): Observable<any> {
    return this.http.delete(this.serviceUrl + 'metrics/timeperiod/' + period.id, ApiHttpService.getHeaders());
  }

  getCurrencies(): Observable<Currency[]> {
    return this.http.get<Currency[]>(this.serviceUrl + 'parameter/currency', ApiHttpService.getHeaders());
  }

  getCurrency(id: String): Observable<Currency[]> {
    return this.http.get<Currency[]>(this.serviceUrl + 'parameter/currency' + id, ApiHttpService.getHeaders());
  }

  createCurrency(currency: Currency): Observable<Currency> {
    return this.http.post<Currency>(this.serviceUrl + 'parameter/currency', currency, ApiHttpService.getHeaders());
  }

  updateCurrency(currency: Currency): Observable<Currency> {
    return this.http.put<Currency>(this.serviceUrl + 'parameter/currency', currency, ApiHttpService.getHeaders());
  }

  deleteCurrency(currency: Currency): Observable<any> {
    return this.http.delete(this.serviceUrl + 'parameter/currency/' + currency.id, ApiHttpService.getHeaders());
  }


  getMetrics(): Observable<Metric[]> {
    return this.http.get<Metric[]>(this.serviceUrl + 'metrics', ApiHttpService.getHeaders());
  }

  createMetric(metric: Metric): Observable<Metric> {
    return this.http.post<Metric>(this.serviceUrl + 'metrics', metric, ApiHttpService.getHeaders());
  }

  updateMetric(metric: Metric): Observable<Metric> {
    return this.http.put<Metric>(this.serviceUrl + 'metrics', metric, ApiHttpService.getHeaders());
  }

  deleteMetric(metric: Metric): Observable<any> {
    return this.http.delete(this.serviceUrl + 'metrics/' + metric.id, ApiHttpService.getHeaders());
  }

  // Agreements

  getAgreements(): Observable<Agreement[]> {
    return this.http.get<Agreement[]>(this.serviceUrl + 'agreement', ApiHttpService.getHeaders());
  }

  createAgreement(agreement: Agreement): Observable<Agreement> {
    return this.http.post<Agreement>(this.serviceUrl + 'agreement', agreement, ApiHttpService.getHeaders());
  }

  deleteAgreement(agreement: Agreement): Observable<any> {
    return this.http.delete(this.serviceUrl + 'agreement/' + agreement.id, ApiHttpService.getHeaders());
  }
}
