import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VesConfService} from './conf.service';
import {Observable} from 'rxjs';
import {PortfolioModel} from '../../models/vestrata/PortfolioModel';
import {ApiHttpService} from '../api.http.service';

@Injectable({
  providedIn: 'root'
})
export class DistributionService {

  private readonly serviceUrl: string;
  private readonly ENDPOINT = 'portfoliomodel/distribution';

  constructor(private http: HttpClient,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  submitAcknowledgedModel(pfmId: string): Observable<PortfolioModel> {
    return this.http.put<PortfolioModel>(this.serviceUrl + this.ENDPOINT + '/' + pfmId, ApiHttpService.getHeaders());
  }

  submitPermission(pfmId: string, bankId: string): Observable<PortfolioModel> {
    return this.http.post<PortfolioModel>(this.serviceUrl + this.ENDPOINT + '/' + pfmId + '/' + bankId, ApiHttpService.getHeaders());
  }
  reSubmitPermission(pfmId: string, bankId: string): Observable<PortfolioModel> {
    return this.http.post<PortfolioModel>(this.serviceUrl + this.ENDPOINT + '/resubmit/' + pfmId + '/' + bankId, ApiHttpService.getHeaders());
  }

  cancelSubmission(pfmId: string, bankId: string): Observable<PortfolioModel> {
    return this.http.delete<PortfolioModel>(this.serviceUrl + this.ENDPOINT + '/' + pfmId + '/' + bankId, ApiHttpService.getHeaders());
  }

  revokeSubmission(pfmId: string, bankId: string): Observable<PortfolioModel> {
    return this.http.delete<PortfolioModel>(this.serviceUrl + this.ENDPOINT + '/revoke/' + pfmId + '/' + bankId, ApiHttpService.getHeaders());
  }
}
