import { Injectable } from '@angular/core';
import { ConfigService } from '@ngx-config/core';

@Injectable({
  providedIn: 'root',
})
export class VesConfService {

  constructor(private config: ConfigService) { }

  getApiEndPoint(): string {
    try {
      const url = this.config.getSettings('system.apiEndPoint');
      if (url == null || url.trim() === '') {
        return window.location.origin + "/api/";
      } else {
        return url;
      }
    } catch (exception) {
      return window.location.origin + "/api/";
    }
  }
}
