import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PerformanceMetric, PerformanceMetricDto} from '../../../models/vestrata/PerformanceMetric';
import {PerformanceService} from '../../../api/services/performance.service';
import {Subject} from "rxjs";

@Component({
  selector: 'ves-performance-table',
  templateUrl: './performance-table.component.html',
  styleUrls: ['./performance-table.component.scss']
})
export class PerformanceTableComponent implements OnInit, OnDestroy {
  private _onDestroy = new Subject();
  @Input() performanceMetricDto: PerformanceMetricDto;
  @Input() type: string;
  @Input() instrumentName: string;
  @Input() benchmarkName: string;

  viewCalendar = true;
  viewTrailing = false;
  performanceHeaders: string[] = [];
  calendarHeaders: string[] = [];
  trailingHeaders: string[] = [];

  constructor() { }

  ngOnInit(): void {
    // console.log('************** PERFORMANCE TABLE ******************');
    // console.log('type', this.type);
    // console.log('metrics', this.performanceMetricDto);
    this.setPerformanceHeaders();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  tab(tabNum) {
    switch (tabNum) {
      case 0: {
        // annual
        if (!this.viewCalendar) {
          this.viewCalendar = true;
          this.viewTrailing = false;
          this.performanceHeaders = this.calendarHeaders;
        }
        break;
      }
      case 1: {
        // annual
        if (!this.viewTrailing) {
          this.viewCalendar = false;
          this.viewTrailing = true;
          this.performanceHeaders = this.trailingHeaders;
        }
      }
    }
  }
  setPerformanceHeaders() {
    switch (this.type) {
      case PerformanceService.PERFORMANCE_TYPE_INSTRUMENT: {
        this.performanceMetricDto.instrument.trailingData.forEach(t => {
          if (t.metricId === 'TR') {
            t.data.sort( (a, b) => {
              if (a.timePeriodId !== 'SI') {
                return a.timePeriodId > b.timePeriodId && b.timePeriodId !== 'SI' ? 1 : -1;
              } else {
                return a.timePeriodId > b.timePeriodId ? 1 : -1;
              }
            });
            t.data.forEach(td => {
              this.trailingHeaders.push(td.timePeriodId);
            });
          }
        });
        this.performanceMetricDto.instrument.calendarData.forEach(c => {
          c.data.sort( (a, b) => a.valueDate > b.valueDate ? 1 : -1);
          c.data.forEach(cd => {
            if (cd.metricId === 'TR' && cd.value) {
              this.calendarHeaders.push(cd.valueDate.substring(0, 4));
            }
          });
        });
        this.performanceHeaders = this.calendarHeaders;
        break;
      }
      case PerformanceService.PERFORMANCE_TYPE_BENCHMARK: {
        this.performanceMetricDto.benchmark.trailingData.forEach(t => {
          if (t.metricId === 'TR') {
            t.data.forEach(td => {
              t.data.sort( (a, b) => {
                if (a.timePeriodId !== 'SI') {
                  return a.timePeriodId > b.timePeriodId && b.timePeriodId !== 'SI' ? 1 : -1;
                } else {
                  return a.timePeriodId > b.timePeriodId ? 1 : -1;
                }
              });
            });
          }
        });
        this.performanceMetricDto.benchmark.calendarData.forEach(c => {
          c.data.forEach(cd => {
            if (cd.metricId === 'TR') {
              this.calendarHeaders.push(cd.valueDate.substring(0, 4));
            }
          });
        });
        this.performanceHeaders = this.calendarHeaders;
        break;
      }
    }
  }

  getFieldData(performanceMetric: PerformanceMetric, timePeriod: string): number {
    let ret: number;
    if (this.viewCalendar && performanceMetric.calendarData) {
      ret = performanceMetric.calendarData.find(pm => pm.timePeriodId === 'Y').data.find(d => d.metricId === 'TR' && d.valueDate.substring(0, 4) === timePeriod).value;
    } else if (this.viewTrailing && performanceMetric.trailingData) {
      ret = performanceMetric.trailingData.find(td => td.metricId === 'TR').data.find(d => d.timePeriodId === timePeriod).value;
    }
    return ret;
  }
}
