import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';

import {cloneDeep} from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {
  ChangeAcknowledge,
  ChangeNoticeType,
  PortfolioModel,
  PortfolioModelVersion
} from "../../models/vestrata/PortfolioModel";
import {Version} from "../../models/vestrata/Version";
import {Permission} from "../../models/vestrata/Permission";
import {SharedComponentsService} from "../../api/services/shared-components.service";
import {AllocationChanged} from "../../models/vestrata/Allocation";
import {InstrumentInfo} from "../../models/vestrata/Instrument/Instrument";
import {CommonService} from "../../api/services/common.service";
import {DistributionService} from "../../api/services/distribution.service";
import {SharedSnackbarService} from "../shared-snackbar/services/shared-snackbar.service";
import {Organisation} from "../../models/vestrata/Organisation";
import {Subject} from "rxjs";
import moment from 'moment';
import {takeUntil} from "rxjs/operators";
import {SnackbarMessage} from "../shared-snackbar/models/snackbar-message";
import {AuthenticationService} from "../../api/services/authentication.service";

@Component({
  selector: 'app-shared-model-changes',
  templateUrl: './shared-model-changes.component.html',
  styleUrls: ['./shared-model-changes.component.scss']
})

export class SharedModelChangesComponent implements OnInit, OnChanges, OnDestroy {

  private static readonly ALL_CHANGES_TYPE = 'all';
  private static readonly ALL_STATUS = 'all-status';

  @Input() organisation: Organisation;
  @Input() portal: string;

  @Input() model: PortfolioModel;
  @Output() modelChange = new EventEmitter<PortfolioModel>();

  versions: Version<PortfolioModelVersion>[];
  allocationDifferences: any[];
  allocationDifferencesStatus: any[][];

  filteredVersions: Version<PortfolioModelVersion>[];
  accordionsOpened = [];

  acknowledge = new ChangeAcknowledge();
  viewAcknowledge: boolean;

  // Filter options.
  changeNoticeTypes: any[];
  status: any[];
  currentStatusFilter: any;
  currentTextFilter: string;

  // Instrument details dialog.
  viewInstrumentDetail = false;
  instrumentId: string;
  viewAknInfo: any[] = [];
  viewPartnerAcknMore: boolean[] = [];
  distrModelId: string;

  currentAcknowledge: number;
  private _onDestroy = new Subject();

  // setting permission for some components that need to work even for read only user. Nothing that can be changed, just for display
  permission: Permission;

  constructor(private translate: TranslateService,
              private sharedComponentsService: SharedComponentsService,
              private common: CommonService,
              private distributionService: DistributionService,
              private snackBar: SharedSnackbarService,
              private authService: AuthenticationService) {
    // Init change notice types.
    this.changeNoticeTypes = ChangeNoticeType.values().map(t =>  {
      return {type: t, label: this.translate.instant('model-changes.' + t), active: true};
    });
    this.changeNoticeTypes.unshift({type: SharedModelChangesComponent.ALL_CHANGES_TYPE,
      label: this.translate.instant('model-changes.' + SharedModelChangesComponent.ALL_CHANGES_TYPE), active: true});

    // Init filter status.
    this.status = AllocationChanged.values().map(s => {
      return {label: this.translate.instant('model-changes.' + s), value: s};
    });
    this.currentStatusFilter = {label: this.translate.instant('model-changes.' + SharedModelChangesComponent.ALL_STATUS),
      value: SharedModelChangesComponent.ALL_STATUS};
    this.status.unshift(this.currentStatusFilter);
  }

  ngOnInit() {
    // Checking if PFM is already acknowledged
    if (this.authService.getPermissions() && this.authService.getPermissions().ips) {
      this.permission = this.authService.getPermissions().ips.discretionary;
    } else if (this.authService.getPermissions() && this.authService.getPermissions().partner) {
      this.permission = this.authService.getPermissions().partner.model;
    } else {
      this.permission = new Permission();
    }
    this.checkAcknowledgeModels();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model &&  changes.model.currentValue && changes.model.currentValue.versions) {
      this.versions = cloneDeep(this.model.versions).reverse();
      this.versions = this.versions.filter(v => v.data.changeNotice);
      this.allocationDifferences = [];
      this.allocationDifferencesStatus = [];
      this.versions.forEach((version, index) => {
        const previousAllocations = this.versions[index + 1] ? this.versions[index + 1].data.allocations : null;
        const differences = this.sharedComponentsService.getAllocationsDifferences(previousAllocations, version.data.allocations);
        this.allocationDifferences.push(differences);
        this.allocationDifferencesStatus.push(this.getAllocationsStatus(differences));
      });
      this.applyFilter();
      this.accordionsOpened = this.filteredVersions.map(v => false);
    }
  }


  checkAcknowledgeModels() {
    this.filteredVersions.forEach(el => el.active = false);

    // For partner portal
    this.filteredVersions.forEach(el => {(
        el.active = false,
          el.BankName = this.model?.distributions ? this.model?.distributions[0].bankName : ''
      )}
    );

    if (this.model?.distribution?.acknowledges) {
      const ackIndex = this.model?.distribution?.acknowledges.findIndex(elem => elem.version === this.model.current.data.version);
      this.distrModelId = this.model?.distribution.id;
      console.log('this.distrModelId', this.distrModelId);

      if (this.model?.distribution?.acknowledges[ackIndex]?.acknowledgeDate && this.model?.distribution?.status === 'Approved') {
        this.filteredVersions[0].active = true;
        this.acknowledge.acknowledgeDate = this.model.distribution?.acknowledges[ackIndex]?.acknowledgeDate;
        this.acknowledge.acknowledgeUser = this.model.distribution?.acknowledges[ackIndex]?.acknowledgeUser;
      } else {
        this.filteredVersions[0].active = false;
      }

    }
  }

  checkLastVersionAck(version) {
    return this.model.distribution.status === 'Approved' && version.data.version === this.model?.distribution?.acknowledges?.reverse()[0]?.version;
  }

  toggleParAckn(v) {
    this.currentAcknowledge = v?.data?.version;
    console.log('************VERSION**************', this.currentAcknowledge, ':', v?.data?.version);
  }

  checkViewAcknowledge(version) {
    return !!this.model.distribution?.acknowledges.find(ack => ack.version === version)?.acknowledgeUserId;
  }

  relevantAckVersion(dist, version) {
    return !!dist.acknowledges?.find(d => d.version === version);
  }

  acknowledgedVersion(dist, version) {
    return !!dist.acknowledges?.find(d => d.version === version).acknowledgeUser;
  }
  getAcknowledgeUser(v, dist?) {
    if (this.portal === 'ips') {
      return this.model.distribution?.acknowledges.find(elem => v.data.version === elem.version).acknowledgeUser;
    }

    if (this.portal === 'partner') {
      return dist?.acknowledges.find(elem => v.data.version === elem.version).acknowledgeUser;
    }
  }

  getAcknowledgeDate(v, dist?) {

    if (this.portal === 'ips') {
      return this.model.distribution?.acknowledges.find(elem => v.data.version === elem.version).acknowledgeDate;
    }

    if (this.portal === 'partner') {
      return dist?.acknowledges.find(elem => v.data.version === elem.version).acknowledgeDate;
    }

  }

  getAcknowledgeEmail(v, dist?) {
    if (this.portal === 'ips') {
      return this.model.distribution?.acknowledges.find(elem => v.data.version === elem.version).acknowledgeUserEmail;
    }

    if (this.portal === 'partner') {
      return dist?.acknowledges.find(elem => v.data.version === elem.version).acknowledgeUserEmail;
    }
  }

  checkParAckVersion(v) {
    console.log('*************** PARTNER MODEL *****************');
    let ret = false;
    this.model.distributions.forEach(dist => {
      dist.acknowledges.forEach(elem => {
        if (v.data.version === elem.version) {
          ret = true;
        }
      });
    });
    return ret;
  }

  acknowledgeMore() {
    this.distributionService.submitAcknowledgedModel(this.model.id).pipe(takeUntil(this._onDestroy)).subscribe( pm => {
      console.log('Acknowledged model', pm);
      this.model = pm;
      this.modelChange.emit(this.model);
      this.checkAcknowledgeModels();
    }, err => {
      console.log(err);
      this.snackBar.push(new SnackbarMessage(this.translate.instant(err?.error?.message), 'error'));
    });
  }

  onAccordionButtonClick(index: number) {
    this.accordionsOpened[index] = !this.accordionsOpened[index];
  }

  onChangeNoticeTypeSelected(active: any, type: any) {
    type.active = active;

    // Select / Unselect all.
    if (type.type === SharedModelChangesComponent.ALL_CHANGES_TYPE) {
      this.changeNoticeTypes.forEach(item => item.active = active);
    }

    // If at least one item is unselected then all category = unselected.
    if (this.changeNoticeTypes.find(item => !item.active && item.type !== SharedModelChangesComponent.ALL_CHANGES_TYPE)) {
      this.changeNoticeTypes.find(item => item.type === SharedModelChangesComponent.ALL_CHANGES_TYPE).active = false;
    } else {
      this.changeNoticeTypes.find(item => item.type === SharedModelChangesComponent.ALL_CHANGES_TYPE).active = true;
    }

    this.applyFilter();
  }

  onStatusFilterChanged(event: any) {
    this.currentStatusFilter = event;
    this.applyFilter();
  }

  onFilterByText(event: string) {
    this.currentTextFilter = event.toLowerCase();
    this.applyFilter();
  }

  private applyFilter() {
    // Change Notice type filter
    const enableChangeType = this.changeNoticeTypes.filter((item) => {
      return item.type !== SharedModelChangesComponent.ALL_CHANGES_TYPE && item.active;
    }).map((item2) => item2.type);

    // If there is no change notice doesnt show the changes.
    if (this.versions) {
      this.filteredVersions = this.versions.filter(version => {
        console.log(version.data.changeNotice && enableChangeType.includes(version.data.changeNotice.changeType));
        return version.data.changeNotice && enableChangeType.includes(version.data.changeNotice.changeType);
      });
    }

    // Change status filter
    if (this.currentStatusFilter.value !== SharedModelChangesComponent.ALL_STATUS) {
      if (this.versions) {
        this.filteredVersions = this.filteredVersions.filter(version => {
          const index = this.versions.indexOf(version);
          return this.allocationDifferencesStatus[index].includes(this.currentStatusFilter.value);
        });
      }
    }

    // Apply Text Filter
    if (this.currentTextFilter) {
      console.log('filteredVersions', this.filteredVersions);

      this.filteredVersions = this.filteredVersions.filter(version => {
        // Check text in change type.
        if (version.data.changeNotice.changeType && version.data.changeNotice.changeType.toLowerCase().includes(this.currentTextFilter)) {
          return true;
        }

        // Check text in change notice commentary.
        if (version.data.changeNotice.commentary && version.data.changeNotice.commentary.toLowerCase().includes(this.currentTextFilter)) {
          return true;
        }

        // Check text in allocations data.
        const differences = this.allocationDifferences[this.versions.indexOf(version)];
        if (differences) {
          for (const diff of differences) {
            if (diff.oldAllocation && this.isInstrumentIncludesText(diff.oldAllocation.instrument, this.currentTextFilter)) {
              return true;
            }

            if (diff.newAllocation && this.isInstrumentIncludesText(diff.newAllocation.instrument, this.currentTextFilter)) {
              return true;
            }
          }
        }
        return false;
      });
    }

    console.log('filtered', this.filteredVersions);
  }

  getAllocationsStatus(values: any[]) {
    const status = [];
    AllocationChanged.values().forEach(c => {
      if (values.find(value => value.status === c)) {
        status.push(c);
      }
    });
    return status;
  }

  onInstrumentDetailsClick(instrument: InstrumentInfo): void {
    this.instrumentId = instrument.id;
    this.viewInstrumentDetail = true;
  }

  closeInstrument(): void {
    this.viewInstrumentDetail = false;
    this.instrumentId = null;
  }

  private isInstrumentIncludesText(instrument: InstrumentInfo, text: string): boolean {
    return (instrument.name.toLowerCase().includes(text) ||
      this.common.getMajorAssetClassFromInstrument(instrument).toLowerCase().includes(text) ||
      this.common.getMinorAssetClassFromInstrument(instrument).toLowerCase().includes(text) ||
      instrument.type.toLowerCase().includes(text) ||
      InstrumentInfo.getIdFromInstrumentIds(instrument, 'ISIN').toLowerCase().includes(text));
  }

}
