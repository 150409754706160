import { Injectable } from '@angular/core';
import {AdvisorPermission, IpsPermission, PartnerPermission, PortalPermissions} from '../../models/vestrata/Permission';
import {OrganisationPermissions, User, UserVersion} from '../../models/vestrata/User';
import {Version} from '../../models/vestrata/Version';
import {KeycloakService} from 'keycloak-angular';
import {KeycloakRoles} from 'keycloak-js';
import {Router} from '@angular/router';
import {Role, Specialty} from '../../models/vestrata/Role';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private static readonly KEY_ORGANISATION_ID = 'organisationId';
  private static readonly KEY_GIVEN_NAME = 'given_name';
  private static readonly KEY_FAMILY_NAME = 'family_name';
  private static readonly KEY_ROLES = 'roles';
  private static readonly KEY_EMAIL = 'email';
  private static readonly KEY_SPECIALTIES = 'specialties';
  public static readonly KEY_IPS_PERMISSIONS = 'vestrata-ips';
  public static readonly KEY_ADVISOR_PERMISSIONS = 'vestrata-advisor';
  public static readonly KEY_PARTNER_PERMISSIONS = 'vestrata-partner';
  public static readonly KEY_ADMIN_PERMISSIONS = 'vestrata-admin';

  constructor(private keycloakService: KeycloakService) {}

  private static getPortalPermissions<T extends PortalPermissions>(tokenPermissions: KeycloakRoles, permissions: T): T {
    if (tokenPermissions) {
      const tokenIPSPermissions = tokenPermissions.roles;
      for (const role of tokenIPSPermissions) {
        permissions.setPermission(role);
      }
    } else {
      permissions = null;
    }
    return permissions;
  }

  getOrganisationId(): string {
    return this.keycloakService.getKeycloakInstance().tokenParsed ?
      this.keycloakService.getKeycloakInstance().tokenParsed[AuthenticationService.KEY_ORGANISATION_ID] : '';
  }

  getFirstName(): string {
    return this.keycloakService.getKeycloakInstance().tokenParsed ?
      this.keycloakService.getKeycloakInstance().tokenParsed[AuthenticationService.KEY_GIVEN_NAME] : '';
  }

  getLastName(): string {
    return this.keycloakService.getKeycloakInstance().tokenParsed ?
      this.keycloakService.getKeycloakInstance().tokenParsed[AuthenticationService.KEY_FAMILY_NAME] : '';
  }

  getConnectedUserRoles(): Role[] {
    const roles: string[] = this.keycloakService.getKeycloakInstance().tokenParsed ?
      this.keycloakService.getKeycloakInstance().tokenParsed[AuthenticationService.KEY_ROLES] : [];

    return roles ? Role.values().filter(r => roles.includes(r.value)) : [];
  }

  getEmail(): string {
    return this.keycloakService.getKeycloakInstance().tokenParsed ?
      this.keycloakService.getKeycloakInstance().tokenParsed[AuthenticationService.KEY_EMAIL] : '';
  }

  getMyUser() {
    const user = new User();
    user.id = this.getMyId();
    user.email = this.getEmail();
    user.current = new Version<UserVersion>();
    user.current.data = new UserVersion();
    user.current.data.firstName = this.getFirstName();
    user.current.data.lastName = this.getLastName();
    user.current.data.organisations = [];
    user.current.data.organisations.push( this.getPermissions());
    user.current.data.specialties = this.getSpecialties();
    // user.current.data.permissions = this.getPermissions();
    user.current.data.roles = this.getConnectedUserRoles().map(r => r.value);
    return user;
  }

  getMyId() {
    return this.keycloakService.getKeycloakInstance().idTokenParsed.sub;
  }

  hasPlaformPermissions(key: string): boolean {
    const tokenPermissions = this.keycloakService.getKeycloakInstance().tokenParsed ?
      this.keycloakService.getKeycloakInstance().tokenParsed.resource_access : null;

    return !!(tokenPermissions && tokenPermissions[key]);
  }

  getPermissions(): OrganisationPermissions {
    const orgPermissions = new OrganisationPermissions();
    orgPermissions.organisationId = this.getOrganisationId();
    const tokenPermissions = this.keycloakService.getKeycloakInstance().tokenParsed ?
      this.keycloakService.getKeycloakInstance().tokenParsed.resource_access : null;

    if (tokenPermissions) {
      orgPermissions.ips = AuthenticationService.getPortalPermissions(tokenPermissions[AuthenticationService.KEY_IPS_PERMISSIONS], new IpsPermission());
      orgPermissions.partner = AuthenticationService.getPortalPermissions(tokenPermissions[AuthenticationService.KEY_PARTNER_PERMISSIONS], new PartnerPermission());
      orgPermissions.advisor = AuthenticationService.getPortalPermissions(tokenPermissions[AuthenticationService.KEY_ADVISOR_PERMISSIONS], new AdvisorPermission());
      orgPermissions.admin = !!tokenPermissions[AuthenticationService.KEY_ADMIN_PERMISSIONS];
    }
    return orgPermissions;
  }

  getSpecialties(): Specialty[] {
    const specialties = this.keycloakService.getKeycloakInstance().tokenParsed ?
      this.keycloakService.getKeycloakInstance().tokenParsed[AuthenticationService.KEY_SPECIALTIES] :
      [];
    return specialties ? specialties : [];
  }

  // Redirect on first supported platform or login page.
  redirect(): boolean {
    const url = window.location.href;
    const port = window.location.port;
    const permissions = this.getPermissions();
    let redirect = false;
    if (port) { // Dev mode.
      if (permissions.advisor) {
        window.location.href = url.replace(port, '4203');
        redirect = true;
      }
      if (permissions.ips) {
        window.location.href = url.replace(port, '4201');
        redirect = true;
      }
      if (permissions.partner) {
        window.location.href = url.replace(port, '4200');
        redirect = true;
      }
    } else {
      const env = url.split('.')[1];
      if (permissions.advisor) {
        window.location.href = ('https://advisor.' + env + '.vestrata.com');
        redirect = true;
      }
      if (permissions.ips) {
        window.location.href = ('https://ips.' + env + '.vestrata.com');
        redirect = true;
      }
      if (permissions.partner) {
        window.location.href = ('https://partner.' + env + '.vestrata.com');
        redirect = true;
      }
    }
    return redirect;
  }

  getIamUrl() {
    let iamUrl = window.location.href;
    if (iamUrl.includes('vestrata.com')) {
      const env = iamUrl.split('.')[1];
      iamUrl = 'https://iam.' + env + '.vestrata.com/auth';
    } else {
      iamUrl = 'https://iam.staging-v1.vestrata.com/auth';
    }
    return iamUrl;
  }

  disconnect() {
    this.keycloakService.logout().then(() => console.log('Keycloak logout'))
      .catch((e) => console.log('Keycloak logout error', e));
  }
}
