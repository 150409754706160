import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { Chart } from 'chart.js';
import {AdvisoryModel, AdvisoryModelAllocation} from '../../../../models/vestrata/AdvisoryModel';
import {AssetAllocation} from '../../../../models/vestrata/PortfolioModelListDto';
import {AssetClass} from '../../../../models/vestrata/MajorAssetClass';
import {UtilsService} from '../../../../api/utils.service';


@Component({
  selector: 'ves-shared-advisory-model-charts',
  templateUrl: './shared-advisory-model-charts.component.html',
  styleUrls: ['./shared-advisory-model-charts.component.scss']
})
export class SharedAdvisoryModelChartsComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('saaMinorCanvas') saaMinorCanvas: ElementRef;
  @ViewChild('taaMinorCanvas') taaMinorCanvas: ElementRef;

  private saaMinorChart: Chart;
  private taaMinorChart: Chart;

  @Input() allocations: AdvisoryModelAllocation[];
  @Input() assetClasses: AssetClass[];
  @Input() advisoryModel: AdvisoryModel;

  saaAssetAllocations: AssetAllocation[] = [];
  taaAssetAllocations: AssetAllocation[] = [];
  currentSelectedAssetClass: AdvisoryModelAllocation;
  currentSelectedSubAssetClass: AdvisoryModelAllocation;
  referenceSelectedAssetClass: AdvisoryModelAllocation;


  constructor(private utils: UtilsService) { }

  ngOnInit(): void {
    this.cloneAssetAllocations();
    if (this.allocations) {}
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.allocations) {
      if (this.allocations && this.allocations.length > 0) {
        this.currentSelectedAssetClass = this.allocations[0];
        this.currentSelectedSubAssetClass = undefined;
        this.referenceSelectedAssetClass = this.currentSelectedAssetClass;
      }
    }
    if (this.saaMinorChart) {
      this.initMinorCharts();
    }

  }
  ngAfterViewInit(): void {
    this.initMinorCharts();
  }

  cloneAssetAllocations() {
    if (!this.allocations) {
      return null;
    }
    this.allocations.forEach(item => {
      const  saaAlloc = new AssetAllocation();
      const  taaAlloc = new AssetAllocation();
      saaAlloc.assetClass = item.code;
      taaAlloc.assetClass = item.code;
      saaAlloc.percentage = item.saa;
      taaAlloc.percentage = item.taa;
      this.saaAssetAllocations.push(saaAlloc);
      this.taaAssetAllocations.push(taaAlloc);
    });
  }

  getMajorAssetClasses() {
    if (!this.allocations) {
      return;
    }
    return this.allocations.map(item => item.code);
  }

  getOverUderWeightColor(alloc: AdvisoryModelAllocation) {
    if (alloc.overUnderWeight > 0) {
      return 'over-weight';
    }
    if (alloc.overUnderWeight < 0) {
      return 'under-weight';
    }

    return '';
  }

  getMajorClassColor(code: string) {
    return this.assetClasses.find(item => item.name === code)?.majorColor;
  }

  getSaaTotal() {
    if (!this.allocations) {
      return;
    }
    let saa = 0;
    this.allocations.forEach(item => {
      saa += item.saa;
    });
    return saa;
  }

  getTaaTotal() {
    if (!this.allocations) {
      return;
    }
    let taa = 0;
    this.allocations.forEach(item => {
      taa += item.taa;
    });
    return taa;
  }

  onSelectAssetClass(majorClass: string, isSub: boolean = false) {
    if (isSub) {
      this.currentSelectedSubAssetClass = this.findAllocation(this.allocations, majorClass);
      this.referenceSelectedAssetClass = this.currentSelectedSubAssetClass;
    } else {
      this.currentSelectedAssetClass = this.findAllocation(this.allocations, majorClass);
      this.currentSelectedSubAssetClass = undefined;
      this.referenceSelectedAssetClass = this.currentSelectedAssetClass;

    }
    this.initMinorCharts();
  }

  findAllocation(allocations: AdvisoryModelAllocation[], code: string): AdvisoryModelAllocation {
    let ret: AdvisoryModelAllocation;
    if (allocations != null && code != null) {
      for (const alloc of allocations) {
        if (alloc.code === code) {
          ret = alloc;
          break;
        }
        if (alloc.allocations?.length > 0) {
          ret = this.findAllocation(alloc.allocations, code);
        }
      }
    }
    return ret;
  }

  getMajorColorShade(code: string, index: number) {
    const color = this.assetClasses.find(item => item.name === code).majorColor;
    return this.utils.shadeColor(color, (index + 1) * 5);
  }

  getOWUWEvolution(allocation: AdvisoryModelAllocation): number {
    let ret = 0;
    if (this.advisoryModel && allocation) {
      if (this.advisoryModel.versions?.length > 0) {
        const lastVersionAllocations = this.advisoryModel.versions[this.advisoryModel.versions.length - 1].data.allocations;
        const lastVersionAllocation = lastVersionAllocations.find(a => a.code === allocation.code && a.type === allocation.type);
        if (lastVersionAllocation) {
          ret = allocation.overUnderWeight - (lastVersionAllocation.taa - lastVersionAllocation.saa);
        } else {
          ret = allocation.overUnderWeight;
        }
      } else {
        ret = allocation.overUnderWeight;
      }
    }
    return ret;
  }

  initSaaMinorChart(values: any[], chartLabels: string[], colors: string[]): Chart {
    const borderColors = values.map(v => '#ffffff');
    return new Chart(this.saaMinorCanvas.nativeElement.getContext('2d'), {
      type: 'doughnut',
      data: {
        datasets: [{
          labels: chartLabels,
          data: values,
          backgroundColor: colors,
          borderColor: borderColors,
          hoverBorderColor: borderColors,
          borderWidth: 4
        }]
      },
      options: {
        cutoutPercentage: 80,
        tooltips: {
          displayColors: true,
          callbacks: {
            label(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const index = tooltipItem.index;
              return dataset.labels[index] + ': ' + dataset.data[index] + ' %';
            },
          },
        }
      }
    });
  }

  initTaaMinorChart(values: any[], chartLabels: string[], colors: string[]): Chart {
    const borderColors = values.map(v => '#ffffff');
    return new Chart(this.taaMinorCanvas.nativeElement.getContext('2d'), {
      type: 'doughnut',
      data: {
        datasets: [{
          labels: chartLabels,
          data: values,
          backgroundColor: colors,
          borderColor: borderColors,
          hoverBorderColor: borderColors,
          borderWidth: 4
        }]
      },
      options: {
        cutoutPercentage: 80,
        tooltips: {
          displayColors: true,
          callbacks: {
            label(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const index = tooltipItem.index;
              return dataset.labels[index] + ': ' + dataset.data[index] + ' %';
            },
          },
        }
      }
    });
  }

  private initMinorCharts() {
    if (this.referenceSelectedAssetClass && this.assetClasses) {
      let minorColors = [];
      let minorNames = [];
      let minorSaaAllocations = [];
      let minorTaaAllocations = [];
      const majorColor = this.assetClasses.find(item => item.name === this.currentSelectedAssetClass.code)?.majorColor;

      if (this.referenceSelectedAssetClass.allocations && this.referenceSelectedAssetClass.allocations.length > 0) {
        minorColors = this.referenceSelectedAssetClass.allocations.map((subAlloc, index) => {
          return this.utils.shadeColor(majorColor, (index + 1) * 5);
        });
        minorNames = this.referenceSelectedAssetClass.allocations.map(subAlloc => subAlloc.code);
        minorSaaAllocations = this.referenceSelectedAssetClass.allocations.map(subAlloc => subAlloc.saa);
        minorTaaAllocations = this.referenceSelectedAssetClass.allocations.map(subAlloc => subAlloc.taa);
      } else {
        minorColors.push(majorColor);
        minorNames.push(this.referenceSelectedAssetClass.code);
        minorSaaAllocations.push(this.referenceSelectedAssetClass.saa);
        minorTaaAllocations.push(this.referenceSelectedAssetClass.taa);
      }
      this.saaMinorChart = this.initSaaMinorChart(minorSaaAllocations, minorNames, minorColors);
      this.taaMinorChart = this.initTaaMinorChart(minorTaaAllocations, minorNames, minorColors);
    }
  }
}
