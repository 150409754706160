import {Injectable} from '@angular/core';
import {ICostCalculatorInterface} from '../interface.services/ICostCalculatorInterface';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngx-config/core';
import {VesConfService} from './conf.service';
import {Observable, of} from 'rxjs';
import {Organisation, OrganisationIpsDto} from '../../models/vestrata/Organisation';
import {ApiHttpService} from '../api.http.service';
import {IpsCountDto} from '../../models/vestrata/IpsCountDto';
import {PortfolioModel, PortfolioModelVersion} from '../../models/vestrata/PortfolioModel';
import {Strategy} from '../../models/vestrata/Strategy';
import {BuildInfo} from '../../models/vestrata/BuildInfo';
import {Currency} from "../../models/vestrata/Currency";
import {AssetClass} from "../../models/vestrata/MajorAssetClass";


@Injectable({
  providedIn: 'root',
})
export class AdvisorService implements ICostCalculatorInterface {

  public serviceUrl: string;
  private globalCurrency: Currency[] = [];
  private globalAssetClasses: AssetClass[] = [];

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }


  getFrontEndBuildInfo(): BuildInfo {
    const myBuild = new BuildInfo();
    myBuild.commitId = this.config.getSettings('system.commitId');
    myBuild.pipelineId = this.config.getSettings('system.pipelineId');
    myBuild.buildtime = this.config.getSettings('system.buildDatetime');
    return myBuild;
  }

  getOrganisation(): Observable<Organisation> {
    return this.http.get<Organisation>(this.serviceUrl + 'organisation/mine', ApiHttpService.getHeaders());
  }

  getCurrencies(): Observable<Currency[]> {
    if (this.globalCurrency.length === 0) {
      return this.http.get<Currency[]>(this.serviceUrl + 'global/currency', ApiHttpService.getHeaders());
    } else {
      return of(this.globalCurrency);
    }
  }

  getMajorAssetClasses(): Observable<AssetClass[]> {
    if (this.globalAssetClasses.length === 0) {
      return this.http.get<AssetClass[]>(this.serviceUrl + 'global/assetclass-tree', ApiHttpService.getHeaders());
    } else {
      return of(this.globalAssetClasses);
    }
  }

  getPartnerList(): Observable<OrganisationIpsDto[]> {
    return this.http.get<OrganisationIpsDto[]>(this.serviceUrl + 'partner', ApiHttpService.getHeaders());
  }

  getPartner(partnerId: string): Observable<Organisation> {
    return this.http.get<Organisation>(this.serviceUrl + 'partner/' + partnerId, ApiHttpService.getHeaders());
  }

  getDashboardCount(): Observable<IpsCountDto> {
    return this.http.get<IpsCountDto>(this.serviceUrl + 'dashboard/count', ApiHttpService.getHeaders());
  }

  getAllOfAStrategy(strategyId: string): Observable<PortfolioModel> {
    return this.http.get<PortfolioModel>(this.serviceUrl + 'portfoliomodel/strategy/' + strategyId, ApiHttpService.getHeaders());
  }

  getStrategies(): Observable<Strategy[]> {
    return this.http.get<Strategy[]>(this.serviceUrl + 'strategy', ApiHttpService.getHeaders());
  }

  getStrategyById(strategyId: string): Observable<Strategy> {
    return this.http.get<Strategy>(this.serviceUrl + 'strategy/' + strategyId, ApiHttpService.getHeaders());
  }

  getStrategiesByPartner(partnerId: string): Observable<Strategy[]> {
    return this.http.get<Strategy[]>(this.serviceUrl + 'strategy/partner/' + partnerId, ApiHttpService.getHeaders());
  }

  getCostCalculator(portfolioModelVersion: PortfolioModelVersion, amount: number, months: number, portfolioModelId: string): Observable<any> {
    return this.http.post<any>(this.serviceUrl + 'portfoliomodel/costs/'
      + amount + '/' + months + '/' + portfolioModelId, portfolioModelVersion, ApiHttpService.getHeaders());
  }

  getOnePortfolioModel(modelId: string): Observable<PortfolioModel> {
    return this.http.get<PortfolioModel>(this.serviceUrl + 'portfoliomodel/' + modelId, ApiHttpService.getHeaders());
  }
}
