export class SnackbarMessage {
  message: string;
  callToActionMessage: string;
  type: 'info' | 'warning' |'error';
  allowDuplicate: boolean;

  constructor(message: string, type?: 'info' | 'warning' |'error',
              callToActionMessage?: string, allowDuplicate?: boolean) {
    this.message = message;
    this.callToActionMessage = callToActionMessage;
    this.type = type || 'info';
    this.allowDuplicate = allowDuplicate;
  }


}
