import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FourEyeComponent} from '../../4eye-component';
import {Tag} from '../../../models/vestrata/Insight';
import {isEqual} from 'lodash';
import {AuthenticationService} from '../../../api/services/authentication.service';

@Component({
  selector: 'ves-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent extends FourEyeComponent<Tag> implements OnInit, OnChanges {

  @Input()
  organisation: string;

  @Output()
  removeClick = new EventEmitter<boolean>();

  @Input() type: 'withLocal' | 'simple' = 'withLocal';

  currentOrganisation: string;

  constructor(private authService: AuthenticationService,
              private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.currentOrganisation = this.authService.getOrganisationId();
    /* console.log(this.pendingStatus);
    console.log(this.pendingValue);
    console.log(this._editingValue); */
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  equals(a: Tag, b: Tag): boolean {
    return isEqual(a, b);
  }

  removeTag() {
    this.removeClick.emit(true);
  }

  getTagString() {
    let ret = this._editingValue.data.find(t => t.lang === this.translate.currentLang);
    if (!ret) {
      ret = this._editingValue.data.find(t => t.lang === 'en');
    }
    return ret.name;
  }
}
