import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {finalize, takeUntil} from 'rxjs/operators';
import {Benchmark, BenchmarkPercentage, BenchmarkSearchFilter} from '../../../models/vestrata/Benchmark';
import {Permission} from '../../../models/vestrata/Permission';
import {
  BlocOption,
  FilterOption
} from '../../../basic-components/components-5/two-level-bar-filter/two-level-bar-filter.component';
import {SharedLoaderService} from '../../shared-loader/service/shared-loader.service';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {CommonService} from '../../../api/services/common.service';
import {BenchmarkService} from '../../../api/services/benchmark.service';
import {SnackbarMessage} from '../../shared-snackbar/models/snackbar-message';
import {Paging, SearchRequest} from '../../../models/vestrata/SearchRequest';
import {AssetClass} from '../../../models/vestrata/MajorAssetClass';
import {PortfolioModel} from '../../../models/vestrata/PortfolioModel';
import {TooltipData} from "../../../basic-components/components-4/tooltip/tooltip.component";

@Component({
  selector: 'ves-shared-benchmark-library',
  templateUrl: './shared-benchmark-library.component.html',
  styleUrls: ['./shared-benchmark-library.component.scss']
})
export class SharedBenchmarkLibraryComponent implements OnInit, OnChanges {
  private static readonly FILTER_TYPE = 'type';
  private static readonly FILTER_CURRENCY = 'contentType';
  private static readonly SORT_UP = 'up';
  private static readonly SORT_DOWN = 'down';

  @Input() hideDeleteBtn: boolean;
  @Input() hideViewBtn: boolean;
  @Input() currentModel: PortfolioModel;
  @Input() defaultCurrency: string; // Use to know if benchmark are aligned with object currency.
  @Input() withRemainingTags = false;
  @Input() withPercentage = false;
  @Input() isMultiSelect = false;
  @Input() selectedElements: BenchmarkPercentage[] = [];
  @Input() pendingSelectedElements: BenchmarkPercentage[];
  @Input() currentSelectedElements: BenchmarkPercentage[];
  @Output() selectedElementsChange = new EventEmitter();
  @Input() monoSelectedId: string;
  @Input() selectedBenchmarksId: string[];
  @Input() viewType: 'selection' | 'display' = 'selection';
  @Input() readonly currency: string; // Display only benchmark with this currency.
  @Input() onlyAssetSchema = false; // Display only benchmark from the asset schema

  elements: BenchmarkPercentage[];
  private _onDestroy = new Subject();
  permission: Permission;

  headerSortDisplay = {
    name: SharedBenchmarkLibraryComponent.SORT_DOWN,
    ticker: SharedBenchmarkLibraryComponent.SORT_DOWN,
    currency: SharedBenchmarkLibraryComponent.SORT_DOWN,
    percentage: SharedBenchmarkLibraryComponent.SORT_DOWN,
  };
  headersDisplay = Object.keys(this.headerSortDisplay);

  // ----FILTERS & SEARCH-------
  searchFilter = new BenchmarkSearchFilter();
  filterTypes: BlocOption[] = [];
  filterCurrency: BlocOption[] = [];
  selectedFilters: BlocOption[] = [];
  filteringText: string;
  headerSort = {
    type: SharedBenchmarkLibraryComponent.SORT_DOWN,
    name: SharedBenchmarkLibraryComponent.SORT_DOWN,
    ticker: SharedBenchmarkLibraryComponent.SORT_DOWN,
    provider: SharedBenchmarkLibraryComponent.SORT_DOWN,
    closing_price: SharedBenchmarkLibraryComponent.SORT_DOWN,
    currency: SharedBenchmarkLibraryComponent.SORT_DOWN,
    percentage: SharedBenchmarkLibraryComponent.SORT_DOWN
  };
  headers = Object.keys(this.headerSort);
  // Paginator //
  pages = 0;
  results = 0;
  resultNumber = 25;
  pageNumber = 0;
  // ---------------------------
  completionLabel = 'benchmarks.incomplete-label';
  viewBenchmarkDetails = false;
  detailsBenchmark: Benchmark;
  @Input() displayReadOnly = false;
  $assetClasses: Observable<AssetClass[]>;

  equalCurrTooltip = new TooltipData();
  noEqualCurrTooltip = new TooltipData();

  constructor(private loader: SharedLoaderService,
              private snackbar: SharedSnackbarService,
              private commonService: CommonService,
              private benchSrv: BenchmarkService,
              private translate: TranslateService) {
    this.equalCurrTooltip.title = this.translate.instant('tooltip.equal-BC');
    this.noEqualCurrTooltip.title = this.translate.instant('tooltip.not-equal-BC');
  }

  ngOnInit(): void {
    this.equalCurrTooltip.title = this.translate.instant('tooltip.equal-BC');
    this.noEqualCurrTooltip.title = this.translate.instant('tooltip.not-equal-BC');

    if (this.viewType === 'selection') {
      this.$assetClasses = this.commonService.getMajorAssetClasses();
      this.initEmptySearchFilter();
      this.loadAndBuildFilters();
      this.executeLoad();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedElements?.currentValue == null) {
      this.selectedElements = [];
    }

    if (changes?.onlyAssetSchema?.currentValue != null) {
      this.searchFilter.onlyAssetSchema = this.onlyAssetSchema;
    }
  }

  // ToolTip
  getTooltipPosition(position: string) {
    let value = 'center';
    switch (position) {
      case 'center': value = 'tool-center'; break;
      case 'left': value = 'tool-left'; break;
    }
    return value;
  }
  getArrowPosition(tooltipPosition: string): string {
    let value = 'center';
    switch (tooltipPosition) {
      case 'center': value = 'center'; break;
      case 'left': value = 'right'; break;
    }
    return value;
  }

  getTooltipText(data) {
    if (this.currentModel?.currency === data.benchmark?.currency) {
      return this.equalCurrTooltip;
    } else {
      return this.noEqualCurrTooltip;
    }
  }

/* TOFIX: It causes 4Eyes bug do it
  onHover(data) {
    data.showTooltip = true;
  }

  onHoverOut(data) {
    data.showTooltip = false;
  }*/

  getColorForCurrency(currency: string) {
    if (currency === this.currentModel?.currency) {
      return 'color-jade';
    } else {
      return 'color-candy';
    }
  }

  private executeLoad() {
    this.loader.showBarLoader();
    return this.benchSrv.searchBenchmark(this.searchFilter)
      .pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe(results => {
        this.buildElements(results.content);
        this.pages = results.totalPages;
        this.results = results.totalElements;
      }, err => {
        this.snackbar.push(new SnackbarMessage(this.translate.instant(err?.error?.message), 'error'));
      });
  }
  onFilterChange(event: any) {
    this.initEmptySearchFilter();

    if (event && Array.isArray(event)) {
      for (const option of event) {
        const index = this.selectedFilters.findIndex(b => b.value === option.value);
        if (index !== -1) {
          this.selectedFilters.splice(index, 1);
        }
        if ((option as BlocOption).selectedCount > 0) {
          this.selectedFilters.push(option);
        }
      }
    } else {
        this.filteringText = event;
    }

    this.searchFilter.searchRequest.text = this.filteringText;
    for (const option of this.selectedFilters) {
      const filters = option.options.filter(o => o.checked);
      if (option.value === SharedBenchmarkLibraryComponent.FILTER_TYPE) {
        this.searchFilter.types = filters.map(f => f.value);
      } else if (option.value === SharedBenchmarkLibraryComponent.FILTER_CURRENCY) {
        this.searchFilter.currencies = filters.map(f => f.value);
      }
    }

    this.executeLoad();
  }
  private initEmptySearchFilter() {
    this.resultNumber = 25;
    this.pageNumber = 0;
    this.searchFilter.types = [];
    this.searchFilter.onlyAssetSchema = this.onlyAssetSchema;
    this.searchFilter.currencies = [];
    if (this.currency) {
      this.searchFilter.currencies.push(this.currency);
    }
    this.searchFilter.searchRequest = new SearchRequest();
    this.searchFilter.searchRequest.paging = new Paging();
    this.searchFilter.searchRequest.paging.page = this.pageNumber;
    this.searchFilter.searchRequest.paging.size = this.resultNumber;
    this.searchFilter.searchRequest.criteria = {};
    this.searchFilter.searchRequest.text = '';
  }

  sort(key: string) {
    if (!key || key === 'percentage') { return; }
    const newValue = this.headerSort[key] === SharedBenchmarkLibraryComponent.SORT_DOWN ? SharedBenchmarkLibraryComponent.SORT_UP : SharedBenchmarkLibraryComponent.SORT_DOWN;
    Object.keys(this.headerSort).forEach(k => this.headerSort[k] = SharedBenchmarkLibraryComponent.SORT_DOWN);
    this.headerSort[key] = newValue;

    let prop = key;
    if (key === 'identifier') {
      prop = 'externalIds._id';
    }
    if (key === 'assetClass') {
      prop = 'assetClasses.0';
    }

    this.searchFilter.searchRequest.sorts = [];
    this.searchFilter.searchRequest.sorts.push({property: prop, direction: newValue === SharedBenchmarkLibraryComponent.SORT_DOWN ? -1 : 1});
    console.log('searchFilter', this.searchFilter);
    this.executeLoad();
  }

  loadAndBuildFilters() {
    this.benchSrv.loadListFilters().pipe(takeUntil(this._onDestroy))
      .subscribe(
        data => {
          this.filterCurrency = [];
          this.filterTypes = [];
          console.log('******DATA-FILTER****', data);
          const currencies = new BlocOption();
          currencies.options = [];
          currencies.value = SharedBenchmarkLibraryComponent.FILTER_CURRENCY;
          Object.keys(data.currencies).forEach(key => {
            currencies.options.push(new FilterOption(key, key, data.currencies[key], true, false, null));
          });
          this.filterCurrency.push(currencies);

          const typeBloc = new BlocOption();
          typeBloc.options = [];
          typeBloc.value = SharedBenchmarkLibraryComponent.FILTER_TYPE;
          Object.keys(data.types).forEach(key => {
            typeBloc.options.push(new FilterOption('benchmarks.' + key, key, data.types[key], true, false, null));
          });
          this.filterTypes.push(typeBloc);

        }
      );
  }

  checkCompletion() {
    const percentageSum = this.selectedElements.reduce((sum, item) => sum + item.percentage, 0);
    const completed =  percentageSum === 100;
    this.completionLabel = !completed ? 'benchmarks.incomplete-label' : 'benchmarks.complete-label';
    return !completed ? 'alert' : 'success';
  }

  getPercentageSum() {
    if (!this.selectedElements) {
      return 0;
    }
    return 100 - this.selectedElements.reduce((sum, item) => sum + item.percentage, 0);
  }

  private buildElements(benchmarks: Benchmark[]) {
    this.elements = [];
    benchmarks.forEach(item => {
      const bp = new BenchmarkPercentage();
      bp.benchmark = item;
      this.elements.push(bp);
      if (!this.isMultiSelect && item.id === this.monoSelectedId) {
        console.log('******mono id***', bp);
        this.selectedElements[0] = bp;
      } else if (this.isMultiSelect && this.selectedBenchmarksId?.find(id => id === item.id) != null) {
        this.selectedElements.push(bp);
      }
    });
  }
  onSizeChanged(size: number) {
    this.resultNumber = size;
    this.searchFilter.searchRequest.paging.page = this.pageNumber;
    this.searchFilter.searchRequest.paging.size = this.resultNumber;
    this.executeLoad();
  }

  onPageChanged(page: number) {
    this.pageNumber = page - 1;
    this.searchFilter.searchRequest.paging.page = this.pageNumber;
    this.searchFilter.searchRequest.paging.size = this.resultNumber;
    this.executeLoad();
  }
  getBenchmarkIdentifier(benchmark: Benchmark) {
    return Benchmark.getIdFromExternalIds(benchmark, 'MS');
  }

  openDetails(benchmark: Benchmark) {
    this.detailsBenchmark = benchmark;
    this.viewBenchmarkDetails = true;
  }

  selectBenchmark(benchmark: BenchmarkPercentage) {
    benchmark.percentage = 0;
    this.selectedElements.push(benchmark);
    console.log(this.selectedElements);

  }

  unSelectBenchmark(benchmark: BenchmarkPercentage) {
    benchmark.percentage = null;
    this.selectedElements.splice(this.selectedElements.indexOf(benchmark), 1);
    console.log(this.selectedElements);
  }

  selected(benchmark: BenchmarkPercentage) {
    return this.selectedElements.find(item => item.benchmark.id === benchmark.benchmark.id);
  }

  getBenchmarkPopUpTitle() {
    return this.translate.instant('benchmarks.view-benchmark') + '>' +
      Benchmark.getIdFromExternalIds(this.detailsBenchmark, 'MS') + ' | ' + this.detailsBenchmark?.name;
  }

  fourEyesSelected(benchmark: BenchmarkPercentage, selectedElements: BenchmarkPercentage[]) {
    if (!selectedElements) {
      return null;
    }
    return selectedElements.find(item => item.benchmark.id === benchmark.benchmark.id);
  }

  deleteSelection(index: number) {
    this.selectedElements.splice(index, 1);
  }
}
