import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Version} from "../../../models/vestrata/Version";
import {Permission} from "../../../models/vestrata/Permission";
import {AuthenticationService} from '../../../api/services/authentication.service';

@Component({
  selector: 'ves-four-eyes-delete-btn',
  templateUrl: './four-eyes-delete-btn.component.html',
  styleUrls: ['./four-eyes-delete-btn.component.scss']
})
export class FourEyesDeleteBtnComponent implements OnInit {

  @Input() instance: any;
  @Input() permission: Permission = new Permission();
  @Output() deleteClick = new EventEmitter<boolean>();

  // only for model portfolio list on partner portal
  @Input() pfmPendingStatus: any;

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
  }

  getInstanceVersion(instance: any) : Version<any> {
    return instance.pending && instance.pending.status ? instance.pending : instance.current;
  }

  canDelete(instance: any): boolean {
    return this.permission && ((!instance.current && this.permission.write) || (instance.current && this.permission.approve));
  }

  canApproveDelete(instance: any) {
    return this.permission && this.permission.approve && this.getInstanceVersion(instance).submittedId !== this.authService.getMyId();
  }

  click(isApproving: boolean) {
    this.deleteClick.emit(isApproving);
  }
}
