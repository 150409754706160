import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngx-config/core';
import {VesConfService} from './conf.service';
import {Observable} from 'rxjs';
import {Page} from '../../models/vestrata/Page';
import {Tutorial, TutorialCategoriesDto} from '../../models/vestrata/Tutorial';
import {ApiHttpService} from '../api.http.service';
import {ArticleCategoryDto} from '../../models/vestrata/Article';
import {TranslateService} from '@ngx-translate/core';
import {InsightCategoriesDto} from '../../models/vestrata/Insight';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  private static readonly ROOT_ENDPOINT = 'tutorial/';
  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService,
              private translate: TranslateService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  search(searchRequest: any): Observable<Page<Tutorial>> {
    return this.http.post<Page<Tutorial>>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'search', searchRequest,
      ApiHttpService.getHeaders());
  }

  getById(instanceId: string): Observable<Tutorial> {
    return this.http.get<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + instanceId,
      ApiHttpService.getHeaders());
  }

  getOwn(): Observable<Tutorial[]> {
    return this.http.get<Tutorial[]>(this.serviceUrl + TutorialService.ROOT_ENDPOINT,
      ApiHttpService.getHeaders());
  }

  create(instance: Tutorial): Observable<Tutorial> {
    return this.http.post<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT, instance,
      ApiHttpService.getHeaders());
  }

  update(instance: Tutorial): Observable<Tutorial> {
    return this.http.put<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + instance.id, instance.pending.data,
      ApiHttpService.getHeaders());
  }

  submitForApproval(instanceId: string): Observable<Tutorial> {
    return this.http.post<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'submit/' + instanceId,
      ApiHttpService.getHeaders());
  }

  unlock(instanceId: string): Observable<Tutorial> {
    return this.http.post<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'unlock/' + instanceId,
      ApiHttpService.getHeaders());
  }

  reset(instanceId: string): Observable<Tutorial> {
    return this.http.post<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'reset/' + instanceId,
      ApiHttpService.getHeaders());
  }

  approveChange(instanceId: string): Observable<Tutorial> {
    return this.http.post<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'approve/' + instanceId,
      ApiHttpService.getHeaders());
  }

  declineChange(instanceId: string, note: string): Observable<Tutorial> {
    return this.http.post<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'decline/' + instanceId,
      note,  ApiHttpService.getHeaders());
  }

  submitDelete(instanceId: string): Observable<Tutorial> {
    return this.http.post<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'delete/submit/' + instanceId,
      ApiHttpService.getHeaders());
  }

  approveDelete(instanceId: string): Observable<Tutorial> {
    return this.http.post<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'delete/approve/' + instanceId,
      ApiHttpService.getHeaders());
  }

  declineDelete(instanceId: string, note: string): Observable<Tutorial> {
    return this.http.post<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'delete/decline/' + instanceId,
      note,  ApiHttpService.getHeaders());
  }

  // categories for display
  getDisplayCategories(): Observable<TutorialCategoriesDto[]> {
    return this.http.get<TutorialCategoriesDto[]>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'categories', ApiHttpService.getHeaders());
  }

  completeTutorial(tutorialId: string) {
    return this.http.post<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'complete/' + tutorialId, ApiHttpService.getHeaders());
  }

  enable(tutorialId: string, enabled: boolean): Observable<Tutorial> {
    return this.http.put<Tutorial>(this.serviceUrl + TutorialService.ROOT_ENDPOINT + 'enable/' + tutorialId + '/' + enabled, ApiHttpService.getHeaders());
  }
}
