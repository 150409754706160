import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  Article,
  ArticleAssetAllocation,
  ArticleCategoryDto,
  ArticleLocal,
  ArticleModelDto,
  ArticleStrategyDto,
  ArticleType,
  ArticleVersion
} from '../../models/vestrata/Article';
import {finalize, switchMap, takeUntil, tap} from 'rxjs/operators';
import {combineLatest, forkJoin, Observable, of, Subject} from 'rxjs';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {SharedSnackbarService} from '../shared-snackbar/services/shared-snackbar.service';
import {SnackbarMessage} from '../shared-snackbar/models/snackbar-message';
import {TranslateService} from '@ngx-translate/core';
import {ArticleService} from '../../api/services/article.service';
import {
  Research,
  ResearchLocal,
  ResearchMainCategory,
  ResearchType,
  ResearchVersion
} from '../../models/vestrata/Research';
import {Insight, InsightLocal, InsightMainCategory, InsightVersion, Tag, TagGroup} from '../../models/vestrata/Insight';
import {Tutorial, TutorialCPD, TutorialMainCategory, TutorialVersion} from '../../models/vestrata/Tutorial';
import {Version} from '../../models/vestrata/Version';
import {cloneDeep, isEqual} from 'lodash';
import {UtilsService} from '../../api/utils.service';
import {Location} from '@angular/common';
import {NavigationData} from '../../basic-components/unknown-components/navigation-menu/navigation-menu.component';
import {NavigationMenuService} from '../../basic-components/unknown-components/navigation-menu/navigation-menu.service';
import {TabLanguage} from '../../models/vestrata/TopMenuItem';
import {TradeIdea} from '../../models/vestrata/TradeIdea';
import moment from 'moment';
import {VestrataDocumentInfo} from '../../models/vestrata/VestrataDocument';
import {IndustrySectorService} from '../../api/services/industry-sector.service';
import {IndustrySector} from '../../models/vestrata/IndustrySector';
import {DropdownItem} from '../../basic-components/unknown-components/multivalue-dropdown/multivalue-dropdown.component';
import {AuthenticationService} from '../../api/services/authentication.service';

@Component({
  selector: 'ves-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss']
})
export class ArticleDetailsComponent implements OnInit, OnDestroy {

  private static readonly SORT_UP = 'up';
  private static readonly SORT_DOWN = 'down';
  private _onDestroy = new Subject();
  private navigationData = new NavigationData();
  completeHeaderSort = {
    name: ArticleDetailsComponent.SORT_DOWN,
    email: ArticleDetailsComponent.SORT_DOWN,
    date: ArticleDetailsComponent.SORT_UP,
    version: ArticleDetailsComponent.SORT_DOWN,
  };
  completeHeaders = Object.keys(this.completeHeaderSort);
  completedUsers = [];
  materialHeaders = ['priority', 'docType', 'titleOrLink', 'summary', 'effectiveDate', 'expiryDate'];

  @Input() permission;
  type: ArticleType;
  isAdmin = false;
  organisationId: string;

  article: any; // Article
  editingArticle: Version<any>; // Version<ArticleVersion>
  pendingArticle: Version<any>;
  currentArticle: Version<any>;
  latestArticleVersion: Version<any>;
  tempEditingArticle: Version<any>;
  tempPendingArticle: Version<any>;
  editingLocal: any; // ArticleLocal
  pendingLocal: any;
  currentLocal: any;

  // Categories
  mainCategories: string[] = [];
  assetAllocations: ArticleAssetAllocation[] = [];
  regions: string[] = [];
  assetClasses: string[] = [];
  themes: string[] = [];
  strategies: ArticleStrategyDto[] = [];
  models: ArticleModelDto[] = [];
  tagOptions: string[] = [];
  tagGroups: TagGroup[] = [];
  researchTypes: ResearchType[] = [];
  tradeIdeas: TradeIdea[];
  allSectors: IndustrySector[] = [];
  sectorDropDownItem: DropdownItem[] = [];

  // Language tabs
  tabs: TabLanguage [] = [];
  currentLanguage: string;

  subMenu = [
    {name: 'article.specifications', isActive: true},
    {name: 'article.material'}
  ];
  selectedTab = 0;
  expectedReadingTimes = [];

  private versionNumber;
  viewDocViewer = false;
  docToViewId = null;

  constructor(private route: ActivatedRoute,
              private translate: TranslateService,
              private snackbar: SharedSnackbarService,
              private loader: SharedLoaderService,
              private articleService: ArticleService,
              private utilsService: UtilsService,
              private router: Router,
              private sectorSrv: IndustrySectorService,
              private menuService: NavigationMenuService,
              private location: Location,
              private authService: AuthenticationService) {
    this.type = this.route.snapshot.data.type;
    this.isAdmin = !!this.route.snapshot.data.isAdmin;
    this.currentLanguage = this.translate.currentLang;
    if (this.isTutorial()) {
      Object.values(TutorialCPD).forEach(item => {
        this.expectedReadingTimes.push({name: this.translate.instant('article.' + item), value: item});
      });
    }
  }

  ngOnInit(): void {
    // --WORK-AROUND FOR PARTNER---
    if (!this.type) {
      this.type = ArticleType.INSIGHT;
    }
    // ----------------------------
    const observables: Observable<any>[] = [this.route.queryParamMap,
      this.articleService.getArticleDetailsCategories(),
      this.articleService.getTagGroups()];

    if (!this.isInsight()) {
      observables.push(this.sectorSrv.getAllIndustrySectors());
    }

    combineLatest(observables).pipe(
      tap(() => this.loader.showFullLoader()),
      takeUntil(this._onDestroy),
      switchMap(data => {
        const params = data[0];
        this.versionNumber = params.get('version') ? +params.get('version') : undefined;
        if (data[3]) {
          this.allSectors = data[3];
          console.log(this.allSectors);
          this.sectorDropDownItem = this.getSectorsDropdownData(this.allSectors);
          console.log(this.sectorDropDownItem);
        }

        if (this.isAdmin && params.get('organisation')) {
          this.organisationId = params.get('organisation');
        } else {
          this.organisationId = this.authService.getOrganisationId();
        }
        console.log('DATA', data);
        console.log('ID', params.get('id'));

        if (params.get('id')) {
          return forkJoin([this.articleService.getById(params.get('id'), this.type), of(data[1]), of(data[2])]);
        } else {
          return forkJoin([this.initNewArticle(), of(data[1]), of(data[2])]);
        }
      })
    ).subscribe(data => {
      console.log('DATA2', data);
      this.setup4Eyes(data[0]);
      this.setCategories(data[1]);
      this.setTags(data[2]);
      if (this.isTutorial()) {
        this.completedUsers = this.getCompletedUsers(data[0] as Tutorial);
        this.sortComplete(UtilsService.getPropertyName(this.completeHeaderSort, h => h.date));
      }
      this.loader.dismissLoader();
    }, error => {
      // this.snackbar.push(new SnackbarMessage(this.translate.instant(error), 'error'));
      this.loader.dismissLoader();
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.menuService.resetNavigationData();
  }

  equals() {
    if (this.latestArticleVersion) {
      return true;
    }
    this.navigationData.editingValue = isEqual(this.editingArticle, this.pendingArticle);
    this.menuService.setNavigationData(this.navigationData);
    return this.navigationData.editingValue;
  }

  hasExternalUrl() {
    return this.type === ArticleType.INSIGHT || this.type === ArticleType.RESEARCH;
  }

  isInsight() {
    return this.type === ArticleType.INSIGHT;
  }

  isResearch() {
    return this.type === ArticleType.RESEARCH;
  }

  isTutorial() {
    return this.type === ArticleType.TUTORIAL;
  }

  thumbnailUploaded(imageUrl: string) {
    this.editingArticle.data.thumbnailImage = imageUrl;
  }

  bannerUploaded(imageUrl: string) {
    this.editingArticle.data.bannerImage = imageUrl;
  }

  checkResearchType(version: ResearchVersion, type) {
    return version && version.type === type;
  }

  updateResearchType(type) {
    (this.editingArticle as Version<ResearchVersion>).data.type = type;
  }

  checkMainCategory(version: ArticleVersion<any>, category) {
    return version && version.mainCategory === category;
  }

  updateMainCategory(category) {
    this.editingArticle.data.mainCategory = category;
  }

  updateAACategory(category) {
    this.editingArticle.data.assetAllocationCategory = category;
  }

  checkAACategory(version: ArticleVersion<any>, category) {
    return version && version.assetAllocationCategory === category;
  }

  checkAssetClassCategory(version: ArticleVersion<any>, category) {
    return version && version.assetCategory.includes(category);
  }

  updateAssetClassCategory(category) {
    if (this.editingArticle.data.assetCategory.indexOf(category) > -1 ) {
      this.editingArticle.data.assetCategory.splice(this.editingArticle.data.assetCategory.indexOf(category), 1);
    } else {
      this.editingArticle.data.assetCategory.push(category);
    }
  }

  checkThemeCategory(version: ArticleVersion<any>, category) {
    return version && version.themeCategory.includes(category);
  }

  updateThemeCategory(category) {
    if (this.editingArticle.data.themeCategory.indexOf(category) > -1 ) {
      this.editingArticle.data.themeCategory.splice(this.editingArticle.data.themeCategory.indexOf(category), 1);
    } else {
      this.editingArticle.data.themeCategory.push(category);
    }
  }

  checkRegionCategory(version: ArticleVersion<any>, category) {
    return version && version.regionCategory.includes(category);
  }

  updateRegionCategory(category) {
    if (this.editingArticle.data.regionCategory.indexOf(category) > -1 ) {
      this.editingArticle.data.regionCategory.splice(this.editingArticle.data.regionCategory.indexOf(category), 1);
    } else {
      this.editingArticle.data.regionCategory.push(category);
    }
  }

  checkStrategyCategory(version: InsightVersion, id) {
    return version && version.strategyCategory.includes(id);
  }

  updateStrategyCategory(id) {
    if (this.editingArticle.data.strategyCategory.indexOf(id) > -1 ) {
      this.editingArticle.data.strategyCategory.splice(this.editingArticle.data.strategyCategory.indexOf(id), 1);
    } else {
      this.editingArticle.data.strategyCategory.push(id);
    }
  }

  checkModelCategory(insightVersion: InsightVersion, id) {
    return insightVersion && insightVersion.modelCategory.includes(id);
  }

  updateModelCategory(id) {
    if (this.editingArticle.data.modelCategory.indexOf(id) > -1 ) {
      this.editingArticle.data.modelCategory.splice(this.editingArticle.data.modelCategory.indexOf(id), 1);
    } else {
      this.editingArticle.data.modelCategory.push(id);
    }
  }

  getModelName(model: ArticleModelDto) {
    return model.strategyName + ' ' + model.investmentObjectiveName + ' ' + model.currency;
  }

  addTag(tagString: string) {
    let tag = new Tag();
    this.tagGroups.forEach(tg => {
      tg.tags.forEach(t => {
        const tagLocal = t.data.find(tl => tl.name === tagString);
        if (tagLocal) {
          tag = t;
        }
      });
    });
    if (tag && tag.id) {
      if (this.editingArticle.data.tags.indexOf(tag) === -1) {
        this.editingArticle.data.tags.push(tag);
      }
      this.utilsService.removeFromIterable(this.tagOptions, tagString);
    }
  }

  removeTag(tag) {
    this.utilsService.removeFromIterable(this.editingArticle.data.tags, tag);
    let tagLocal = tag.data.find(t => t.lang === this.translate.currentLang);
    if ( !tagLocal ) {
      tagLocal = tag.data.find(t => t.lang === 'en');
    }
    this.tagOptions.push(tagLocal.name);
  }

  checkTag(versionTags: Tag[], tag: Tag) {
    if (versionTags) {
      return versionTags.find(t => t.id === tag.id);
    }
  }

/*  checkInstrument(tradeIdeas: TradeIdea[], tradeIdea: TradeIdea) {
    if (tradeIdeas) {
      return tradeIdeas.find(t => t.id === tradeIdea.id);
    }
  }

  removeInstrument(tradeIdea: TradeIdea) {
    this.utilsService.removeFromIterable(this.editingArticle.data.tradeIdeas, tradeIdea);
  }

  addInstrument(tradeIdea: TradeIdea) {
    if (!((this.editingArticle.data as ResearchVersion).tradeIdeas?.length > 0)) {
      (this.editingArticle.data as ResearchVersion).tradeIdeas = [];
    }
    (this.editingArticle.data as ResearchVersion).tradeIdeas.push(tradeIdea);
  }

  checkTradeIdea(tradeIdeas: TradeIdea[], tradeIdea: TradeIdea) {
    if (tradeIdeas) {
      return tradeIdeas.find(t => t.id === tradeIdea.id);
    }
  }

  removeTradeIdea(tradeIdea: TradeIdea) {
    this.utilsService.removeFromIterable(this.editingArticle.data.tradeIdeas, tradeIdea);
  }

  addTradeIdea(tradeIdea: TradeIdea) {
    if (!((this.editingArticle.data as ResearchVersion).tradeIdeas?.length > 0)) {
      (this.editingArticle.data as ResearchVersion).tradeIdeas = [];
    }
    (this.editingArticle.data as ResearchVersion).tradeIdeas.push(tradeIdea);
  }*/

  // Four Eyes //
  onSave() {
    if (this.isAdmin && !this.adminSavingValidation()) {
      return;
    }

    if (this.validateArticleForSave()) {
      this.loader.showBarLoader();
      this.article.pending.data = this.editingArticle.data;
      if (!this.article.id) {
        this.articleService.createArticle(this.type, this.article)
          .pipe(takeUntil(this._onDestroy),
            finalize(() => this.loader.dismissLoader()))
          .subscribe(a => {
            this.setup4Eyes(a);
            this.snackbar.push(new SnackbarMessage(this.translate.instant('article.created'), 'info'));
          }, err => {
            this.snackbar.push(new SnackbarMessage(err.error.message, 'error'));
          });
      } else {
        this.articleService.updateArticle(this.type, this.article)
          .pipe(takeUntil(this._onDestroy),
            finalize(() => this.loader.dismissLoader()))
          .subscribe(a => {
            this.setup4Eyes(a);
            this.snackbar.push(new SnackbarMessage(this.translate.instant('article.updated'), 'info'));
          }, err => {
            this.snackbar.push(new SnackbarMessage(err.error.message, 'error'));
          });
      }
    }
  }

  onSubmitForApproval() {
    if (this.validateArticleForSubmission()) {
      this.loader.showBarLoader();
      this.articleService.submitArticleForApproval(this.type, this.article)
        .pipe(takeUntil(this._onDestroy),
          finalize(() => this.loader.dismissLoader()))
        .subscribe(a => {
          this.setup4Eyes(a);
          this.snackbar.push(new SnackbarMessage(this.translate.instant('article.submitted'), 'info'));
        }, err => {
          this.snackbar.push(new SnackbarMessage(err.error.message, 'error'));
        });
    }
  }

  onUnlock() {
    this.loader.showBarLoader();
    this.articleService.cancelArticleApproval(this.type, this.article)
      .pipe(takeUntil(this._onDestroy),
        finalize(() => this.loader.dismissLoader()))
      .subscribe(a => {
        this.setup4Eyes(a);
        this.snackbar.push(new SnackbarMessage(this.translate.instant('article.unlocked'), 'info'));
      }, err => {
        this.snackbar.push(new SnackbarMessage(err.error.message, 'error'));
      });
  }

  onApprove() {
    this.loader.showBarLoader();
    this.articleService.approveArticleChange(this.type, this.article)
      .pipe(takeUntil(this._onDestroy),
        finalize(() => this.loader.dismissLoader()))
      .subscribe(a => {
        this.setup4Eyes(a);
        this.snackbar.push(new SnackbarMessage(this.translate.instant('article.approved'), 'info'));
      }, err => {
        this.snackbar.push(new SnackbarMessage(err.error.message, 'error'));
      });
  }

  onDecline(note) {
    this.loader.showBarLoader();
    this.articleService.declineArticleChange(this.type, this.article, note)
      .pipe(takeUntil(this._onDestroy),
        finalize(() => this.loader.dismissLoader()))
      .subscribe(a => {
        this.setup4Eyes(a);
        this.snackbar.push(new SnackbarMessage(this.translate.instant('article.declined'), 'info'));
      }, err => {
        this.snackbar.push(new SnackbarMessage(err.error.message, 'error'));
      });
  }

  onCancel() {
    this.location.back();
  }

  onCurrent() {
    // TODO: Fix this.
    this.tempEditingArticle = cloneDeep(this.editingArticle);
    this.tempPendingArticle = cloneDeep(this.pendingArticle);
    if (this.currentArticle) {
      this.editingArticle = cloneDeep(this.currentArticle);
      this.pendingArticle = cloneDeep(this.currentArticle);
    }
  }

  onPending() {
    // TODO: Fix this.
    this.editingArticle.data = cloneDeep(this.tempEditingArticle);
    this.pendingArticle.data = cloneDeep(this.tempPendingArticle);
  }

  onReset() {
    this.loader.showBarLoader();
    this.articleService.reset(this.type, this.article).pipe(takeUntil(this._onDestroy)
        , finalize(() => this.loader.dismissLoader()))
        .subscribe(a => {
          this.setup4Eyes(a);
          this.snackbar.push(new SnackbarMessage(this.translate.instant('article.reset'), 'info'));
        }, error => {
          this.snackbar.push(new SnackbarMessage(this.translate.instant('article.reset-error'), 'error'));

        });
  }

  clickOnTranslation(lang) {
    this.currentLanguage = lang;
    this.setupArticleLocales();
  }

  removeLanguage(lang) {
    this.utilsService.removeFromIterable(this.tabs, lang);

    this.tabs.forEach((item, index) => {
      if (item.name === lang) {
        this.tabs.splice(index, 1);
      }
    });

    this.editingArticle.data.locales.forEach((sl, index) => {
      if (sl.lang === lang) {
        this.editingArticle.data.locales.splice(index, 1);
      }
    });
  }

  addLanguage(language: string) {
    if (!this.latestArticleVersion) { // Do not add language in history mode.
      if (this.editingArticle.data.locales.filter(s => s.lang === language).length === 0) {
        const local = new ArticleLocal();
        local.lang = language;
        local.title = '';
        local.description = '';

        if (this.type === ArticleType.INSIGHT) {
          (local as InsightLocal).externalUrl = '';
        }

        if (this.type === ArticleType.RESEARCH) {
          (local as ResearchLocal).externalUrl = '';
          (local as ResearchLocal).document = '';
        }

        this.editingArticle.data.locales.push(local);
        this.tabs.push(new TabLanguage(language));
      }
    }
  }

  private setCategories(categoryDto: ArticleCategoryDto) {
    this.assetAllocations = Object.values(ArticleAssetAllocation);
    this.assetClasses = [];
    this.regions = [];
    this.themes = [];
    if (categoryDto?.regions?.length > 0) {
      categoryDto.regions.forEach(a => this.regions.push(a.id));
    }
    if (categoryDto?.themes?.length > 0) {
      categoryDto.themes?.forEach(a => this.themes.push(a.id));
    }
    if (categoryDto?.assetClasses?.length > 0) {
      categoryDto.assetClasses?.forEach(a => this.assetClasses.push(a.name));
    }

    if (this.type === ArticleType.INSIGHT) {
      this.mainCategories = Object.values(InsightMainCategory);
      this.strategies = categoryDto.strategies;
      this.models = categoryDto.models;
      if (this.models.length === 0 && this.mainCategories.includes(InsightMainCategory.PRODUCT)) {
        this.mainCategories.splice(this.mainCategories.indexOf(InsightMainCategory.PRODUCT), 1);
      }
    }

    if (this.type === ArticleType.RESEARCH) {
      this.mainCategories = Object.values(ResearchMainCategory);
      this.researchTypes = Object.values(ResearchType);
    }

    if (this.type === ArticleType.TUTORIAL) {
      this.mainCategories = Object.values(TutorialMainCategory);
    }
  }

  private setTags(groups: TagGroup[]) {
    this.tagGroups = groups;
    this.tagGroups.forEach(tg => {
      this.setTagsFromGroup(tg);
    });
  }

  private setTagsFromGroup(group: TagGroup) {
    group.tags.forEach(tag => {
      let tagLocal = tag.data.find(t => t.lang === this.translate.currentLang);
      if ( !tagLocal ) {
        tagLocal = tag.data.find(t => t.lang === 'en');
      }
      const existingTag = this.editingArticle?.data.tags.find(t => t.id === tag.id);
      if (!existingTag || !existingTag.id) {
        this.tagOptions.push(tagLocal.name);
      }
    });
  }

  getLocalFromLanguage(data: ArticleVersion<any>, lang: string) {
    let local = null;
    if (data != null) {
      data.locales.forEach(p => {
        if (p.lang === lang) {
          local = p;
        }
      });
    }
    return local;
  }

  private setupArticleLocales() {
    this.editingLocal = this.getLocalFromLanguage(this.editingArticle?.data, this.currentLanguage);
    this.pendingLocal = this.getLocalFromLanguage(this.pendingArticle?.data, this.currentLanguage);
    this.currentLocal = this.getLocalFromLanguage(this.currentArticle?.data, this.currentLanguage);
  }

  private setup4Eyes(article: Article<any>) {
    if (article) {
      this.article = article;
      if (this.versionNumber != null && this.isTutorial()) {
        this.currentArticle = (this.article as Tutorial).history.find(v => v.data.version === this.versionNumber);
        if (!this.currentArticle) {
          this.currentArticle = this.article.current;
          this.latestArticleVersion = null;
        } else {
          this.latestArticleVersion = this.article.current;
        }
      } else {
        this.currentArticle = this.article.current;
        this.latestArticleVersion = null;
      }

      if (this.latestArticleVersion) {
        this.pendingArticle = this.currentArticle;
        this.editingArticle = cloneDeep(this.pendingArticle);
      } else {
        this.pendingArticle = this.article.pending;
        this.editingArticle = cloneDeep(this.pendingArticle);
      }
      this.tabs = [];
      this.editingArticle.data.locales.forEach(l => {
        if (this.tabs.filter(t => t.name === l.lang).length === 0) {
          this.tabs.push(new TabLanguage(l.lang));
        }
      });
      this.currentLanguage = this.tabs[0] ? this.tabs[0].name : 'en';
    }
    this.setupArticleLocales();
    this.setMenuData();
  }

  private setMenuData() {
    this.navigationData.permission = this.permission;
    this.navigationData.interface = this;
    this.navigationData.currentVersion = this.article?.current;
    this.navigationData.pendingVersion = this.article?.pending;
    this.navigationData.fourEyeView = true;
    this.navigationData.pageTitle = this.buildPageTitle(this.article);
    this.menuService.setNavigationData(this.navigationData);

    // At least one approved version
    if (this.currentArticle && !this.subMenu.find(i => i.name === 'article.history')) {
      this.subMenu.push(...[{name: 'article.history'}, {name: 'article.complete'}]);
    }
  }

  private buildPageTitle(article: Article<any>) {
    let stringType = '';
    switch (this.type) {
      case ArticleType.TUTORIAL: stringType = 'tutorial'; break;
      case ArticleType.INSIGHT: stringType = 'insight'; break;
      case ArticleType.RESEARCH: stringType = 'research'; break;
    }
    let title = this.translate.instant('article.' + stringType + 's-title');
    if (article?.id) {
      title += ' › ' + this.getArticleTitle(article);
    } else {
      title +=  ' › ' + this.translate.instant('article.new-' + stringType);
    }
    return title;
  }

  private getArticleTitle(article: Article<any>) {
    let ret = '';
    if (!article?.pending?.data) {
      return ret;
    }

    ret = article.pending.data.locales.find(i => i.lang === this.currentLanguage)?.title;
    if (!ret) {
      ret = article.pending.data.locales.find(i => i.lang === 'en').title;
    }
    return ret;
  }

  private initNewArticle(): Observable<Article<any>> {
    let article = null;
    const locale = new ArticleLocal();
    locale.lang = 'en';
    switch (this.type) {
      case ArticleType.RESEARCH:
        article = new Research();
        article.pending = new Version<ResearchVersion>();
        article.pending.data = new ResearchVersion();
        article.pending.data.locales.push(locale as ResearchLocal);
        break;
      case ArticleType.INSIGHT:
        article = new Insight();
        article.pending = new Version<InsightVersion>();
        article.pending.data = new InsightVersion();
        article.pending.data.locales.push(locale as InsightLocal);
        if (this.isAdmin) {
          article.organisationId = this.organisationId;
        }
        break;
      case ArticleType.TUTORIAL:
        article = new Tutorial();
        article.pending = new Version<TutorialVersion>();
        article.pending.data = new TutorialVersion();
        article.pending.data.locales.push(locale);
        break;
    }
    return of(article);
  }

  private adminSavingValidation() {
    return (this.validateArticleForSave() && this.validateArticleForSubmission());
  }

  private validateArticleForSave(): boolean {
    let ret = true;
    this.editingArticle.data.locales.forEach(l => {
      if (UtilsService.manageString(l.description) === '') {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.invalid-description'), 'error'));
        ret = false;
      }
      if (UtilsService.manageString(l.title) === '') {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.invalid-title'), 'error'));
        ret = false;
      }

      if (this.type === ArticleType.INSIGHT) {
        ret = ret ? this.validateInsightForSave(l) : ret;
      }

      if (this.type === ArticleType.RESEARCH) {
        ret = ret ? this.validateResearchForSave(l) : ret;
      }

      if (this.type === ArticleType.TUTORIAL) {
        ret = ret ? this.validateTutorialsForSave(l) : ret;
      }
    });

    return ret;
  }

  private validateInsightForSave(l: InsightLocal) {
    let ret = true;
    if (UtilsService.manageString(l.externalUrl) === '') {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.invalid-external-url'), 'error'));
      ret = false;
    }
    if (!this.utilsService.isUrl(l.externalUrl) && l.externalUrl !== '' ) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.invalidate-external-url'), 'error'));
      ret = false;
    }
    if (this.editingArticle.data.endDate && (this.editingArticle.data.startDate > this.editingArticle.data.endDate)) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.date-comparison'), 'error'));
      ret = false;
    }

    return ret;
  }

  private validateResearchForSave(l: ResearchLocal) {
    let ret = true;
    if (UtilsService.manageString(l.externalUrl) === '' && UtilsService.manageString(l.document) === '') {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.invalid-external-url'), 'error'));
      ret = false;
    } else {
      if (UtilsService.manageString(l.externalUrl) !== '' && !this.utilsService.isUrl(l.externalUrl) && l.externalUrl !== '' ) {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.invalidate-external-url'), 'error'));
        ret = false;
      }
    }
    if (this.editingArticle.data.endDate && (this.editingArticle.data.startDate > this.editingArticle.data.endDate)) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.date-comparison'), 'error'));
      ret = false;
    }
    return ret;
  }

  private validateTutorialsForSave(l: ResearchLocal) {
    let ret = true;
    if (this.editingArticle.data.endDate && (this.editingArticle.data.startDate > this.editingArticle.data.endDate)) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.date-comparison'), 'error'));
      ret = false;
    }
    return ret;
  }


  private validateArticleForSubmission(): boolean {
    let ret = true;

    // TODO: Segreggate Research / Tutorials / Insight values.
    /*if (this.editingArticle.data.mainCategory === InsightMainCategory.ASSETALLOCATION &&
      !this.editingArticle.data.assetAllocationCategory) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.no-asset-allocation'), 'error'));
      ret = false;
    }*/

    if (this.editingArticle.data.assetCategory.length === 0 &&
      this.editingArticle.data.mainCategory !== InsightMainCategory.PRODUCT) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.no-asset-class'), 'error'));
      ret = false;
    }
    if (this.editingArticle.data.regionCategory.length === 0 &&
      this.editingArticle.data.mainCategory !== InsightMainCategory.PRODUCT) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.no-region'), 'error'));
      ret = false;
    }
    if (!this.editingArticle.data.mainCategory) {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.invalid-main-category'), 'error'));
      ret = false;
    }
    if (UtilsService.manageString(this.editingArticle.data.bannerImage) === '') {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.no-banner-image'), 'error'));
      ret = false;
    } else {
      const img = document.createElement('img');
      const imgUrl = this.editingArticle.data.bannerImage;
      img.setAttribute('src', imgUrl);
      if (img.width < 1200 || img.height < 500) {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.banner-image-dimensions'), 'error'));
        ret = false;
      }
    }
    if (UtilsService.manageString(this.editingArticle.data.thumbnailImage) === '') {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.no-thumb-image'), 'error'));
      ret = false;
    } else {
      const img = document.createElement('img');
      const imgUrl = this.editingArticle.data.bannerImage;
      img.setAttribute('src', imgUrl);
      if (img.width < 400 || img.height < 300) {
        this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.thumb-image-dimensions'), 'error'));
        ret = false;
      }
    }

    if (!this.editingArticle.data.startDate || this.editingArticle.data.startDate === '') {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article.error.invalid-start-date'), 'error'));
      ret = false;
    }

    return ret;
  }

  subMenuClick(event: any) {
    this.selectedTab = event;
  }

  onCPDChange(event: any) {
    (this.editingArticle.data as TutorialVersion).cpd = event;
    if (!event) {
      (this.editingArticle.data as TutorialVersion).expectedReadingTime = undefined;
    }
  }

  onExpectedReadingTimeChange(event: any) {
    (this.editingArticle.data as TutorialVersion).expectedReadingTime = event.value;
  }

  private getCompletedUsers(tutorial: Tutorial) {
    const completed = [];
    if (tutorial?.history?.length > 0) {
        for (const version of tutorial.history) {
          if (version?.data?.completed?.length  > 0) {
            const t: any[] = [...version.data.completed];
            t.map(u => {
              u.version = version.data.version;
              u.versionDate = version.reviewedDate;
            });
            completed.push(...t);
          }
        }
        if (tutorial?.current?.data?.completed?.length > 0) {
          const t2: any[] = [...tutorial.current.data.completed];
          t2.map(u => {
            u.version = tutorial.current.data.version;
            u.versionDate = tutorial.current.reviewedDate;
          });
          completed.push(...t2);
        }

    }
    return completed;
  }

  sortComplete(key) {
    if (!key) { return; }
    const newValue = this.completeHeaderSort[key] === ArticleDetailsComponent.SORT_DOWN ?
        ArticleDetailsComponent.SORT_UP :
        ArticleDetailsComponent.SORT_DOWN;
    const multiplicator = (newValue === ArticleDetailsComponent.SORT_DOWN ? -1 : 1);
    Object.keys(this.completeHeaderSort).forEach(k => this.completeHeaderSort[k] = ArticleDetailsComponent.SORT_DOWN);
    this.completeHeaderSort[key] = newValue;

    switch (key) {
      case 'name':
        this.completedUsers = this.completedUsers.sort((a, b) => a.name.localeCompare(b.name) * multiplicator);
        break;
      case 'email':
        this.completedUsers = this.completedUsers.sort((a, b) => a.email.localeCompare(b.email) * multiplicator);
        break;
      case 'date':
        this.completedUsers = this.completedUsers.sort((a, b) => moment(a.date).diff(b.date) * multiplicator);
        break;
      case 'version':
        this.completedUsers = this.completedUsers.sort((a, b) => {
          let v = a.version - b.version;
          if (v === 0) {
            v = moment(a.versionDate).diff(b.versionDate);
          }
          return v * multiplicator;
        });
        break;
    }
  }

  filterTable(searchString) {
    let cUsers = this.getCompletedUsers(this.article as Tutorial);
    if (searchString) {
      cUsers = cUsers.filter(user => {
        if (user.name.includes(searchString) || user.email.includes(searchString) || user.version.toString().includes(searchString)) {
          return true;
        }
        return moment(user.date).isSame(moment(searchString), 'day');
      });
    }
    this.completedUsers = cUsers;
  }

  openHistory(version: Version<TutorialVersion>) {
    if (version.data.version === this.article?.current?.data?.version) {
      this.router.navigate(['.'], {relativeTo: this.route, queryParams: {id: this.article.id}});
    } else {
      this.router.navigate(['.'], {relativeTo: this.route, queryParams: {id: this.article.id, version: version.data.version}});
    }
  }

  onDocumentAdded($event) {
    if (!(this.editingArticle.data as TutorialVersion).documents) {
      (this.editingArticle.data as TutorialVersion).documents = [];
    }
    (this.editingArticle.data as TutorialVersion).documents.push($event);
  }

  onDisplayMaterial(document: VestrataDocumentInfo) {
    this.docToViewId = document.id;
    this.viewDocViewer = true;
  }

  onDeleteMaterial(document: VestrataDocumentInfo) {
    this.utilsService.removeFromIterable((this.editingArticle.data as TutorialVersion)?.documents, document);
  }

  move(way: 'up' | 'down', index: number) {
    UtilsService.swap(way, (this.editingArticle.data as TutorialVersion)?.documents, index);
  }

  private getSectorsDropdownData(sectors: IndustrySector[]): DropdownItem[] {
    const items = [];
    for (const sector of sectors) {
      const item = new DropdownItem();
      item.name = sector.name;
      item.value = sector;
      if (sector.subSectors?.length > 0) {
        item.subItems = this.getSectorsDropdownData(sector.subSectors);
      }
      items.push(item);
    }
    return items;
  }

  onSectorsValueChanged(sectors: IndustrySector[]) {
    console.log(sectors);
  }
}
