import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ves-mode-btn',
  templateUrl: './mode-btn.component.html',
  styleUrls: ['./mode-btn.component.scss']
})
export class ModeBtnComponent implements OnInit {
  @Input() id: string;
  @Input() type: ModeInputType = ModeInputType.DISPLAY;
  @Input() locked = false;
  @Input() active = false;

  @Output() eachClick = new EventEmitter<boolean>();

  btnText = '';

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    // console.log(this.locked + ' ' + this.id);
    this.setBtnText();
    if (!this.id) { this.id = 'mode-button'; }
  }

  setBtnText() {
    switch (this.type) {
      case 0: {
        this.btnText = 'mode-btn.display';
        break;
      }
      case 1: {
        this.btnText = 'mode-btn.addnew';
        break;
      }
      case 2: {
        this.btnText = 'mode-btn.setup';
        break;
      }
      case 3: {
        this.btnText = 'mode-btn.library';
        break;
      }
      case 4: {
        this.btnText = 'mode-btn.current';
        break;
      }
      case 5: {
        this.btnText = 'mode-btn.pending';
        break;
      }
      case 6: {
        this.btnText = 'mode-btn.configuration';
        break;
      }
    }
  }

  btnClick() {
    // console.log('btn-click');
    this.eachClick.emit(true);
  }
}

export enum ModeInputType {
  DISPLAY,
  ADDNEW,
  SETUP,
  DASHBOARD,
  CURRENT,
  PENDING,
  CONFIGURATION
}
