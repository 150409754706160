import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@ngx-config/core';
import { Observable, of } from 'rxjs';
import { Currency } from '../../models/vestrata/Currency';
import { Organisation } from '../../models/vestrata/Organisation';
import { PartnerData } from '../../models/vestrata/Partner';
import {ChangeNotice, PortfolioModel, PortfolioModelVersion} from '../../models/vestrata/PortfolioModel';
import { Strategy } from '../../models/vestrata/Strategy';
import { ApiHttpService } from '../api.http.service';
import { VesConfService } from './conf.service';
import { StrategyData } from '../../models/vestrata/Strategy';
import {PartnerCountDashboard} from '../../models/vestrata/PartnerCountDashboard';
import {PortfolioModelListDto, StrategyPortfolioModelListDto} from '../../models/vestrata/PortfolioModelListDto';
import {ICostCalculatorInterface} from '../interface.services/ICostCalculatorInterface';
import {AllocationDto} from '../../models/vestrata/Allocation';
import {AssetClass} from '../../models/vestrata/MajorAssetClass';

@Injectable({
  providedIn: 'root',
})
export class PartnerService implements ICostCalculatorInterface {

  public serviceUrl: string;
  private globalCurrency: Currency[] = [];
  private globalAssetClasses: AssetClass[] = [];

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }



  getOrganisation(): Observable<Organisation> {
    return this.http.get<Organisation>(this.serviceUrl + 'organisation/mine', ApiHttpService.getHeaders());
  }

  // -------------------------------
  // STRATEGY MANAGEMENT
  // -------------------------------

  createStrategy(strategy: Strategy): Observable<Strategy> {
    return this.http.post<Strategy>(this.serviceUrl + 'strategy', strategy, ApiHttpService.getHeaders());
  }

  saveStrategy(strategy: StrategyData, strategyId: string): Observable<Strategy> {
    return this.http.put<Strategy>(this.serviceUrl + 'strategy/' + strategyId, strategy, ApiHttpService.getHeaders());
  }

  getStrategies(): Observable<Strategy[]> {
    return this.http.get<Strategy[]>(this.serviceUrl + 'strategy', ApiHttpService.getHeaders());
  }

  getStrategy(strategyId: string): Observable<Strategy> {
    return this.http.get<Strategy>(this.serviceUrl + 'strategy/' + strategyId, ApiHttpService.getHeaders());
  }

  removeStrategy(id: string) {
    return this.http.delete(this.serviceUrl + 'strategy/' + id, ApiHttpService.getHeaders());
  }

  submitStrategy(id: string): Observable<Strategy> {
    return this.http.post<Strategy>(this.serviceUrl + 'strategy/submit/' + id, null, ApiHttpService.getHeaders());
  }

  approveChanges(id: string): Observable<Strategy> {
    return this.http.post<Strategy>(this.serviceUrl + 'strategy/approve/' + id, null, ApiHttpService.getHeaders());
  }

  declineChanges(id: string, comment: string): Observable<Strategy> {
    return this.http.post<Strategy>(this.serviceUrl + 'strategy/decline/' + id, comment, ApiHttpService.getHeaders());
  }

  unlockChanges(id: string): Observable<Strategy> {
    return this.http.post<Strategy>(this.serviceUrl + 'strategy/unlock/' + id, null, ApiHttpService.getHeaders());
  }

  loadListFilters(): Observable<any> {
    return this.http.get<any>(this.serviceUrl  + 'strategy/load-filters', ApiHttpService.getHeaders());
  }

  // 4eyes Deletion
  submitDeletion(instanceId: string): Observable<Strategy> {
    return this.http.post<Strategy>(this.serviceUrl + 'strategy/delete/submit/' + instanceId,
      ApiHttpService.getHeaders());
  }
  approveDeletion(instanceId: string): Observable<void> {
    return this.http.post<void>(this.serviceUrl + 'strategy/delete/approve/' + instanceId,
      ApiHttpService.getHeaders());
  }
  declineDeletion(instanceId: string): Observable<Strategy> {
    return this.http.post<Strategy>(this.serviceUrl + 'strategy/delete/decline/' + instanceId,
      ApiHttpService.getHeaders());
  }
  // -------------------------------
  // PROFILE  MANAGEMENT
  // -------------------------------

  updatePartnerProfile(partnerData: PartnerData): Observable<Organisation> {
    return this.http.put<Organisation>(this.serviceUrl + 'partner', partnerData, ApiHttpService.getHeaders());
  }

  submitPartnerProfile(): Observable<Organisation> {
    return this.http.post<Organisation>(this.serviceUrl + 'partner/submit/', null, ApiHttpService.getHeaders());
  }

  approveProfileChanges(): Observable<Organisation> {
    return this.http.post<Organisation>(this.serviceUrl + 'partner/approve/', null, ApiHttpService.getHeaders());
  }

  declineProfileChanges(comment: string): Observable<Organisation> {
    return this.http.post<Organisation>(this.serviceUrl + 'partner/decline/', comment, ApiHttpService.getHeaders());
  }

  unlockProfileChanges(): Observable<Organisation> {
    return this.http.post<Organisation>(this.serviceUrl + 'partner/unlock/', null, ApiHttpService.getHeaders());
  }

  resetProfile(): Observable<Organisation> {
    return this.http.post<Organisation>(this.serviceUrl + 'partner/reset/', null, ApiHttpService.getHeaders());
  }

  // -------------------------------
  // STATIC DATA MANAGEMENT
  // -------------------------------

  getCurrencies(): Observable<Currency[]> {
    if (this.globalCurrency.length === 0) {
      return this.http.get<Currency[]>(this.serviceUrl + 'global/currency', ApiHttpService.getHeaders());
    } else {
      return of(this.globalCurrency);
    }
  }

  getAssetClasses(): Observable<AssetClass[]> {
    if (this.globalAssetClasses.length === 0) {
      return this.http.get<AssetClass[]>(this.serviceUrl + 'global/assetclass-tree', ApiHttpService.getHeaders());
    } else {
      return of(this.globalAssetClasses);
    }
  }


  // -------------------------------
  // MODEL PORTFOLIO MANAGEMENT
  // -------------------------------

  validatePortfolioModelVersion(key: string) {
    return this.http.post<PortfolioModel>(this.serviceUrl + 'portfoliomodel/versioning/' + key,
      ApiHttpService.getHeaders());
  }

  updateAllocations(allocDto: AllocationDto, key: string): Observable<PortfolioModel> {
    return this.http.post<PortfolioModel>(this.serviceUrl + 'portfoliomodel/' + key + '/allocations', allocDto,
      ApiHttpService.getHeaders());
  }

  getCostCalculator(portfolioModelVersion: PortfolioModelVersion, amount: number, months: number, portfolioModelId: string): Observable<any> {
    return this.http.post<any>(this.serviceUrl + 'portfoliomodel/costs/'
      + amount + '/' + months, portfolioModelVersion, ApiHttpService.getHeaders());
  }

  // NEW //

  getPortfolioModels(): Observable<PortfolioModelListDto[]> {
    return this.http.get<PortfolioModelListDto[]>(this.serviceUrl + 'portfoliomodel', ApiHttpService.getHeaders());
  }

  getPortfolioModelsByStrategy(strategyId: string): Observable<StrategyPortfolioModelListDto[]> {
    return this.http.get<StrategyPortfolioModelListDto[]>(this.serviceUrl + 'portfoliomodel/strategy/' + strategyId, ApiHttpService.getHeaders());
  }
  getPortfolioModelById(id: string): Observable<PortfolioModel> {
    return this.http.get<PortfolioModel>(this.serviceUrl + 'portfoliomodel/' + id, ApiHttpService.getHeaders());
  }

  createPortfolioModel(portfolioModelCreation: PortfolioModel): Observable<PortfolioModel> {
    return this.http.post<PortfolioModel>(this.serviceUrl + 'portfoliomodel',
      portfolioModelCreation, ApiHttpService.getHeaders());
  }

  savePortfolioModel(portfolioModelCreation: PortfolioModel): Observable<PortfolioModel> {
    return this.http.put<PortfolioModel>(this.serviceUrl + 'portfoliomodel',
      portfolioModelCreation, ApiHttpService.getHeaders());
  }

  submitPortfolioModel(pfmId: string, changeNotice: ChangeNotice): Observable<PortfolioModel> {
    return this.http.post<PortfolioModel>(this.serviceUrl + 'portfoliomodel/submit/' +
      pfmId, changeNotice, ApiHttpService.getHeaders());
  }

  approvePortfolioModel(pfmId: string): Observable<PortfolioModel> {
    return this.http.post<PortfolioModel>(this.serviceUrl + 'portfoliomodel/approve/' +
      pfmId, ApiHttpService.getHeaders());
  }

  declinePortfolioModel(pfmId: string, note: string): Observable<PortfolioModel> {
    return this.http.post<PortfolioModel>(this.serviceUrl + 'portfoliomodel/decline/' +
      pfmId, note, ApiHttpService.getHeaders());
  }

  unlockPortfolioModel(pfmId: string): Observable<PortfolioModel> {
    return this.http.post<PortfolioModel>(this.serviceUrl + 'portfoliomodel/unlock/' +
      pfmId, ApiHttpService.getHeaders());
  }

// 4eyes Deletion
  submitPfmDeletion(instanceId: string): Observable<PortfolioModel> {
    return this.http.post<PortfolioModel>(this.serviceUrl + 'portfoliomodel/delete/submit/' + instanceId,
      ApiHttpService.getHeaders());
  }
  approvePfmDeletion(instanceId: string): Observable<void> {
    return this.http.post<void>(this.serviceUrl + 'portfoliomodel/delete/approve/' + instanceId,
      ApiHttpService.getHeaders());
  }
  declinePfmDeletion(instanceId: string): Observable<PortfolioModel> {
    return this.http.post<PortfolioModel>(this.serviceUrl + 'portfoliomodel/delete/decline/' + instanceId,
      ApiHttpService.getHeaders());
  }

  // -------------------------------
  // USER MANAGEMENT
  // -------------------------------

  // -------------------------------
  // DASHBOARD MANAGEMENT
  // -------------------------------

  getDashboardCount(): Observable<PartnerCountDashboard> {
    return this.http.get<PartnerCountDashboard>(this.serviceUrl + 'dashboard/count', ApiHttpService.getHeaders());
  }




}
