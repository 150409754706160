import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {User, UserVersion} from '../../../models/vestrata/User';
import {Permission} from '../../../models/vestrata/Permission';

import {TranslateService} from '@ngx-translate/core';

import * as moment_ from 'moment';
import {UtilsService} from '../../../api/utils.service';
import {VersionStatus} from '../../../models/vestrata/Version';
import {UserService} from '../../../api/services/user.service';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {Portal} from '../../../models/vestrata/Portal';
import {AuthenticationService} from '../../../api/services/authentication.service';

const moment = moment_;

@Component({
  selector: 'ves-user-row',
  templateUrl: './user-row.component.html',
  styleUrls: ['./user-row.component.scss']
})
export class UserRowComponent implements OnInit {

  @Input()
  user: User;

  @Input()
  permission: Permission = new Permission();

  @Input()
  organisationId: string;

  @Input()
  isAdmin = false;

  @Input()
  embedded = false;

  currentLocked = false;
  editingLocked = false;
  enabled = true;

  currentUser: UserVersion = new UserVersion();
  pendingUser: UserVersion = new UserVersion();
  editingUser: UserVersion = new UserVersion();
  TwoFASet = false;

  @Output()
  viewEdit = new EventEmitter<boolean>();

  @Output()
  delete = new EventEmitter<boolean>();

  @Output()
  onLockChanged = new EventEmitter<boolean>();

  @Output() fourEyesDeleting = new EventEmitter<boolean>();
  @Output() onEnableChanged = new EventEmitter<boolean>();

  @Input() roles;

  @Input() ownUser = false;

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    // Locked means enable.
    // console.log('************* USER ROW ***************');
    // console.log(this.permission);
    // console.log(!this.isAdmin);
    // console.log(!this.permission || !this.permission.readwrite || !this.permission.approver);
    const userVersion = this.user.current ? this.user.current : this.user.pending;
    /*  USERS-V2 no unlock anymore.
      this.currentLocked = this.user.accountNonExpired && this.user.accountNonLocked;
     */
    this.currentLocked = true;
    this.editingLocked = this.user.accountNonExpired && this.user.accountNonLocked;
    this.enabled = userVersion?.data?.portalsEnabled?.length > 0;

    if (this.user.current) {
      this.currentUser = this.user.current.data;
      this.editingUser = JSON.parse(JSON.stringify(this.user.current.data));
    }
    if (this.user.pending) {
      this.pendingUser = this.user.pending.data;
      this.editingUser = JSON.parse(JSON.stringify(this.user.pending.data ? this.user.pending.data : this.user.current.data));
    }
  }

  viewEditClick() {
    this.viewEdit.emit(true);
  }

  deleteClick() {
    this.delete.emit(true);
  }

  enableClick(event: any) {
    this.onEnableChanged.emit(event);
  }


  get2FAMethod(): any {
    const url = '../../../../assets/images/components/components-15/';
    let fullUrl = '';
    if (this.user.twoFAData && this.user.twoFAData.length > 0) {
      this.user.twoFAData.forEach(factor => {
        if (factor.method === 'Face') {
          fullUrl = url + 'icon-face.svg';
        } else if (factor.method === 'Voice') {
          fullUrl = url + 'icon-voice.svg';
        } else {
          fullUrl = url + 'icon-face-unselected.svg';
        }
      });
    } else {
      fullUrl = url + 'icon-not-set.svg';
    }
    return fullUrl;
  }

  get2FAText(): string {
    let text = '';
    if (this.user.twoFAData && this.user.twoFAData.length > 0) {
      this.user.twoFAData.forEach(factor => {
        if (factor.method === 'Face') {
          text = this.translate.instant('user.face');
          this.TwoFASet = true;
        } else if (factor.method === 'Voice') {
          text = this.translate.instant('user.voice');
          this.TwoFASet = true;
        } else {
          text = this.translate.instant('user.not-set');
        }
      });
    } else {
      text = this.translate.instant('user.not-set');
    }
    return text;
  }

  isPartner(): boolean {
    let ret = false;
    let userVersion: UserVersion;
    if (this.currentUser) {
      userVersion = this.currentUser;
    } else {
      userVersion = this.pendingUser;
    }
    if (userVersion.portalsEnabled) {
      ret = userVersion.portalsEnabled.includes(Portal.PARTNER);
    } else {
      userVersion.organisations.forEach(org => {
        if (org.organisationId === this.organisationId) {
          if (org.partner) {
            ret = true;
          }
        }
      });
    }
    return ret;
  }

  isIps(): boolean {
    let ret = false;
    let userVersion: UserVersion;
    if (this.currentUser && this.currentUser.firstName) {
      userVersion = this.currentUser;
    } else {
      userVersion = this.pendingUser;
    }

    if (userVersion.portalsEnabled) {
      ret = userVersion.portalsEnabled.includes(Portal.IPS);
    } else {
      userVersion.organisations.forEach(org => {
        if (org.organisationId === this.organisationId) {
          if (org.ips) {
            ret = true;
          }
        }
      });
    }

    return ret;
  }

  isAdvisor(): boolean {
    let ret = false;
    let userVersion: UserVersion;
    if (this.currentUser) {
      userVersion = this.currentUser;
    } else {
      userVersion = this.pendingUser;
    }
    if (userVersion.portalsEnabled) {
      ret = userVersion.portalsEnabled.includes(Portal.ADVISOR);
    } else {
      userVersion.organisations.forEach(org => {
        if (org.organisationId === this.organisationId) {
          if (org.advisor) {
            ret = true;
          }
        }
      });
    }

    return ret;
  }

  getFormattedDate(date): any {
    return UtilsService.formatDate(date);
  }

  getStatus(): VersionStatus {
    let status = VersionStatus.APPROVED;
    if (!this.user.pending) {
      status = VersionStatus.APPROVED;
    } else {
      status = this.user.pending.status;
    }
    return status;
  }

  foureyesDelete(isApproving: boolean) {
    this.fourEyesDeleting.emit(isApproving);
  }

  onNotificationClick(user: User) {
    /*  USERS-V2 no unlock anymore.
        if (!this.currentLocked && this.isAdmin || this.permission && this.permission.approve) {
          this.onLockChanged.emit(true);
        }*/
  }
}
