import {APP_INITIALIZER, ModuleWithProviders, NgModule} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CommonModule, CurrencyPipe, registerLocaleData} from '@angular/common';
import { SharedLanguageMenuComponent } from './components/shared-language-menu/shared-language-menu.component';
import {FormsModule} from '@angular/forms';
import { SharedMainFooterComponent } from './components/shared-main-footer/shared-main-footer.component';
import {HttpClient} from '@angular/common/http';
import {ConfigLoader, ConfigService, ConfigStaticLoader, initializerFactory} from '@ngx-config/core';
import { SharedVerticalMenuComponent } from './components/shared-vertical-menu/shared-vertical-menu.component';
import { SharedLoaderComponent } from './components/shared-loader/shared-loader.component';
import { GallerySingleComponent } from './basic-components/components-1/gallery-single/gallery-single.component';
import { GallerySeveralComponent } from './basic-components/components-1/gallery-several/gallery-several.component';
import { ThumbnailComponent } from './basic-components/components-1/thumbnail/thumbnail.component';
import {SharedSnackbarComponent} from './components/shared-snackbar/shared-snackbar.component';
import { InputComponent } from './basic-components/components-3/input/input.component';
import {SearchBarComponent} from './basic-components/components-5/search-bar/search-bar.component';
import { VideoBoxComponent } from './basic-components/components-1/video-box/video-box.component';
import { FullBtnComponent } from './basic-components/components-4/full-btn/full-btn.component';
import { ThumbBtnComponent } from './basic-components/components-4/thumb-btn/thumb-btn.component';
import { CardBtnComponent } from './basic-components/components-4/card-btn/card-btn.component';
import { TooltipComponent } from './basic-components/components-4/tooltip/tooltip.component';
import { ToggleComponent } from './basic-components/components-5/toggle/toggle.component';
import { MoveComponent } from './basic-components/components-5/move/move.component';
import { LoadMoreComponent } from './basic-components/components-5/load-more/load-more.component';
import { FilterBarComponent } from './basic-components/components-5/filter-bar/filter-bar.component';
import { RadioComponent } from './basic-components/components-5/radio/radio.component';
import { TranslationTabsComponent } from './basic-components/components-3/translation-tabs/translation-tabs.component';
import { TopMenuComponent } from './basic-components/components-2/top-menu/top-menu.component';
import { SubMenuComponent } from './basic-components/components-2/sub-menu/sub-menu.component';
import { SharedPrimaryContactComponent } from './components/shared-primary-contact/shared-primary-contact.component';
import { SharedDialogComponent } from './components/shared-dialog/shared-dialog.component';
import { DropdownComponent } from './basic-components/components-3/dropdown/dropdown.component';
import { DatepickerComponent } from './basic-components/components-3/datepicker/datepicker.component';
import { MatDatepickerModule, MatNativeDateModule  } from '@angular/material';
import { QuillModule } from 'ngx-quill';
import { SharedOrgDomainComponent } from './components/shared-org-domain/shared-org-domain.component';
import { SharedCountriesSelectorComponent } from './components/shared-countries-selector/shared-countries-selector.component';
import { SliderRadioComponent } from './basic-components/components-8/slider-radio/slider-radio.component';
import {SharedMsFundReportComponent} from './components/shared-ms-fund-report/shared-ms-fund-report.component';
import { AssetsAllocationChartComponent } from './basic-components/charts/assets-allocation-chart/assets-allocation-chart.component';
import { PerformanceChartComponent } from './basic-components/charts/performance-chart/performance-chart.component';
import { TagLabelComponent } from './basic-components/unknown-components/tag-label/tag-label.component';
import { SharedUserListComponent } from './components/shared-user-list/shared-user-list.component';
import { UserRowComponent } from './components/shared-user-list/user-row/user-row.component';
import { CheckboxComponent } from './basic-components/components-13/checkbox/checkbox.component';
import {SharedMSXrayComponent} from './components/shared-ms-xray/shared-MS-xray.component';
import {SharedAllocationChangesComponent} from './components/shared-allocation-changes/shared-allocation-changes.component';
import {ModeBtnComponent} from './basic-components/components-4/mode-btn/mode-btn.component';
import {AdvancedAssetsAllocationChartComponent} from './basic-components/charts/advanced-assets-allocation-chart/advanced-assets-allocation-chart.component';
import {CategoryComponent} from './basic-components/components-7/category/category.component';
import {CostChartComponent} from './basic-components/charts/cost-chart/cost-chart.component';
import {LastReviewComponent} from './basic-components/components-7/last-review/last-review.component';
import {ShortContactComponent} from './basic-components/unknown-components/short-contact/short-contact.component';
import { SharedAccordionComponent } from './components/shared-accordion/shared-accordion.component';
import { ActionsMenuNewComponent } from './basic-components/components-2/actions-menu-new/actions-menu-new.component';
import { SharedInsightsListComponent } from './components/shared-insights/shared-insights-list/shared-insights-list.component';
import { SharedInsightsCardComponent } from './components/shared-insights/shared-insights-card/shared-insights-card.component';
import { SharedInsightsMaintenanceComponent } from './components/shared-insights/shared-insights-maintenance/shared-insights-maintenance.component';
import { InsightsRowComponent } from './components/shared-insights/shared-insights-list/insights-row/insights-row.component';
import { SharedInsightDetailsComponent } from './components/shared-insights/shared-insight-details/shared-insight-details.component';
import { SlidingBarComponent } from './basic-components/components-11/sliding-bar/sliding-bar.component';
import { BtnWorkflowComponent } from './basic-components/components-10/btn-workflow/btn-workflow.component';
import { TagComponent } from './basic-components/components-7/tag/tag.component';
import { AutoCompleteComponent } from './basic-components/components-3/auto-complete/auto-complete.component';
import {ReadOnlyModelConstructionComponent} from './components/readonly-model-construction/readonly-model-construction.component';
import {SharedModelCostsComponent} from './components/shared-model-costs/shared-model-costs.component';
import {SharedModelChangesComponent} from './components/shared-model-changes/shared-model-changes.component';
import {SharedModelPerformancesComponent} from './components/shared-model-performances/shared-model-performances.component';
import {SharedModelMetricsComponent} from './components/shared-model-metrics/shared-model-metrics.component';
import localeFr from '@angular/common/locales/fr';
import {SharedModelAllocationReadonlyComponent} from './components/shared-model-allocation-readonly/shared-model-allocation-readonly.component';
import {SharedModelMarketingReadonlyComponent} from './components/shared-model-marketing-readonly/shared-model-marketing-readonly.component';
import {SharedPartnerProfilePreviewComponent} from './components/shared-partner-profile-preview/shared-partner-profile-preview.component';
import {SharedStrategiesSliderComponent} from './components/shared-strategies-slider/shared-strategies-slider.component';
import { SharedInsightsDisplayComponent } from './components/shared-insights/shared-insights-display/shared-insights-display.component';
import { SharedInsightsSearchComponent } from './components/shared-insights/shared-insights-search/shared-insights-search.component';
import { SharedStrategyPreviewComponent } from './components/shared-strategy-preview/shared-strategy-preview.component';
import { SharedInsightsLatestComponent } from './components/shared-insights/shared-insights-display/shared-insights-latest/shared-insights-latest.component';
import { SharedInsightsViewComponent } from './components/shared-insights/shared-insights-view/shared-insights-view.component';
import {SharedDiscretionaryPartnerComponent} from './components/shared-discretionary-partner/shared-discretionary-partner.component';
import {SharedDiscretionaryPartnerCardComponent} from './components/shared-discretionary-partner/shared-discretionary-partner-card/shared-discretionary-partner-card.component';
import {SharedPortfolioModelsCardComponent} from './components/shared-discretionary-model-list/shared-portfolio-models-card/shared-portfolio-models-card.component';
import {SharedDiscretionaryModelListComponent} from './components/shared-discretionary-model-list/shared-discretionary-model-list.component';
import { SharedModelXrayComponent } from './components/shared-model-xray/shared-model-xray.component';
import { AudioPlayerComponent } from './basic-components/unknown-components/audio-player/audio-player.component';
import { FourEyesRowComponent } from './basic-components/unknown-components/four-eyes-row/four-eyes-row.component';
import { SharedTradingviewCalendarComponent } from './components/market-views/shared-trading-view/shared-tradingview-calendar/shared-tradingview-calendar.component';
import { SharedTradingviewMarketoverviewComponent } from './components/market-views/shared-trading-view/shared-tradingview-marketoverview/shared-tradingview-marketoverview.component';
import { SharedTradingviewAdvancedChartComponent } from './components/market-views/shared-trading-view/shared-tradingview-advanced-chart/shared-tradingview-advanced-chart.component';
import { SharedTradingviewForexRatesComponent } from './components/market-views/shared-trading-view/shared-tradingview-forex-rates/shared-tradingview-forex-rates.component';
import { SharedTradingviewScreenerComponent } from './components/market-views/shared-trading-view/shared-tradingview-screener/shared-tradingview-screener.component';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import { SharedSalesMeetingDisplayComponent } from './components/shared-sales-meeting-display/shared-sales-meeting-display.component';
import { SharedTradingviewSearchComponent } from './components/market-views/shared-trading-view/shared-tradingview-search/shared-tradingview-search.component';
import { MarketSelectorComponent } from './basic-components/components-18/market-selector/market-selector.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SalesMeetingSearchResultComponent } from './components/sales-meeting-search-result/sales-meeting-search-result.component';
import { SharedTradingviewStockmarketComponent } from './components/market-views/shared-trading-view/shared-tradingview-stockmarket/shared-tradingview-stockmarket.component';
import { SharedTradingviewSymbolInfoComponent } from './components/market-views/shared-trading-view/shared-tradingview-symbol-info/shared-tradingview-symbol-info.component';
import { SharedTradingviewFundamentalDataComponent } from './components/market-views/shared-trading-view/shared-tradingview-fundamental-data/shared-tradingview-fundamental-data.component';
import { SharedTradingviewCompanyProfileComponent } from './components/market-views/shared-trading-view/shared-tradingview-company-profile/shared-tradingview-company-profile.component';
import { SharedTradingviewTechnicalAnalysisComponent } from './components/market-views/shared-trading-view/shared-tradingview-technical-analysis/shared-tradingview-technical-analysis.component';
import { SharedAlertDispatcherComponent } from './components/shared-alert-dispatcher/shared-alert-dispatcher.component';
import { TwoLevelBarFilterComponent } from './basic-components/components-5/two-level-bar-filter/two-level-bar-filter.component';
import { SharedTradingviewForexHeatmapComponent } from './components/market-views/shared-trading-view/shared-tradingview-forex-heatmap/shared-tradingview-forex-heatmap.component';
import { SharedTradingviewMarketDataComponent } from './components/market-views/shared-trading-view/shared-tradingview-market-data/shared-tradingview-market-data.component';
import { FourEyesDeleteBtnComponent } from './basic-components/unknown-components/four-eyes-delete-btn/four-eyes-delete-btn.component';
import { ReadOnlyModelDetailsComponent } from './components/read-only-model-details/read-only-model-details.component';
import {SharedModelMpsReadonlyComponent} from './components/read-only-model-details/shared-model-mps-readonly/shared-model-mps-readonly.component';
import { SharedDiscretionaryStrategiesComponent } from './components/shared-discretionary-strategies/shared-discretionary-strategies.component';
import { DiscretionaryStrategyCardComponent } from './components/shared-discretionary-strategies/discretionary-strategy-card/discretionary-strategy-card.component';
import { PageCardComponent } from './basic-components/unknown-components/page-card/page-card.component';
import { InprogressComponent } from './basic-components/unknown-components/inprogress/inprogress.component';
import { SharedInstrumentSearchListComponent } from './components/shared-instrument-search-list/shared-instrument-search-list.component';
import { SharedReadOnlyImplementationVehicleComponent } from './components/shared-read-only-implementation-vehicle/shared-read-only-implementation-vehicle.component';
import { StatusComponent } from './basic-components/components-10/status/status.component';
import { StickerComponent } from './basic-components/components-10/sticker/sticker.component';
import { AssetClassComponent } from './components/asset-class/asset-class.component';
import {TradeIdeaCardComponent} from './components/shared-trade-idea-card/trade-idea-card.component';
import {TradeIdeaDisplayComponent} from './components/shared-trade-idea-display/trade-idea-display.component';
import {TradeIdeasLibraryComponent} from './components/shared-trade-ideas-library/trade-ideas-library.component';
import {SharedImplementationVehicleComponent} from './components/shared-implementation-vehicle/shared-implementation-vehicle.component';
import { DebounceClickDirective } from './basic-components/components-2/debounce-click.directive';
import { NavigationMenuComponent } from './basic-components/unknown-components/navigation-menu/navigation-menu.component';
import {NavigationActionsMenuComponent} from './basic-components/components-2/navigation-actions-menu/navigation-actions-menu.component';
import { SummaryCardComponent } from './basic-components/unknown-components/summary-card/summary-card.component';
import {SharedDocTypeTagComponent} from './components/shared-document-repository/shared-doc-type-tag/shared-doc-type-tag.component';
import {SharedDocumentChooserDialogComponent} from './components/shared-document-repository/shared-document-chooser-dialog/shared-document-chooser-dialog.component';
import {SharedDocumentDisplayListComponent} from './components/shared-document-repository/shared-document-display-list/shared-document-display-list.component';
import {SharedDocumentLibraryComponent} from './components/shared-document-repository/shared-document-library/shared-document-library.component';
import {SharedDocumentMaintenanceDialogComponent} from './components/shared-document-repository/shared-document-maintenance-dialog/shared-document-maintenance-dialog.component';
import {SharedUploadProcessButtonComponent} from './components/shared-document-repository/shared-upload-process-button/shared-upload-process-button.component';
import {initializeKeycloak} from './api/app.init';
import {KeycloakService} from 'keycloak-angular';
import { PaginatorComponent } from './basic-components/unknown-components/paginator/paginator.component';
import { SharedAlertListComponent } from './components/dashboard/shared-alert-list/shared-alert-list.component';
import { SharedNotifListComponent } from './components/dashboard/shared-notif-list/shared-notif-list.component';
import { SharedInstrumentDetailsComponent } from './components/shared-instruments/shared-instrument-details/shared-instrument-details.component';
import { SharedInstrumentCategorisationComponent } from './components/shared-instruments/shared-instrument-categorisation/shared-instrument-categorisation.component';
import { SharedInstrumentTargetMarketComponent } from './components/shared-instruments/shared-instrument-target-market/shared-instrument-target-market.component';
import { SharedInstrumentPerformanceComponent } from './components/shared-instruments/shared-instrument-performance/shared-instrument-performance.component';
import {SharedNotificationDispatcherComponent} from './components/shared-notification-dispatcher/shared-notification-dispatcher.component';
import {AdvisoryAllocationLevelChooserComponent} from './components/shared-advisory-model/advisory-allocation-level-chooser/advisory-allocation-level-chooser.component';
import {SharedAdvisoryModelAllocationComponent} from './components/shared-advisory-model/shared-advisory-model-maintenance/shared-advisory-model-allocation/shared-advisory-model-allocation.component';
import {SharedAdvisoryAllocationChangesComponent} from './components/shared-advisory-model/shared-advisory-model-maintenance/shared-advisory-allocation-changes/shared-advisory-allocation-changes.component';
import {SharedAdvisoryModelLibraryComponent} from './components/shared-advisory-model/shared-advisory-model-library/shared-advisory-model-library.component';
import {SharedAdvisoryModelChartsComponent} from './components/shared-advisory-model/shared-advisory-model-maintenance/shared-advisory-model-charts/shared-advisory-model-charts.component';
import {SharedAdvisoryModelConstructionComponent} from './components/shared-advisory-model/shared-advisory-model-maintenance/shared-advisory-model-construction/shared-advisory-model-construction.component';
import {SharedAdvisoryModelMaintenanceComponent} from './components/shared-advisory-model/shared-advisory-model-maintenance/shared-advisory-model-maintenance.component';
import {SharedAdvisoryModelChangesComponent} from './components/shared-advisory-model/shared-advisory-model-maintenance/shared-advisory-model-changes/shared-advisory-model-changes.component';
import {SharedAdvisoryModelCardComponent} from './components/shared-advisory-model/shared-advisory-model-card/shared-advisory-model-card.component';
import {MifidBenchmarksChartComponent} from './components/shared-benchmarks/mifid-benchmarks-chart/mifid-benchmarks-chart.component';
import {SharedCompletionRemainingComponent} from './basic-components/unknown-components/shared-completion-remaining/shared-completion-remaining.component';
import { SharedInstrumentListComponent } from './components/shared-instruments/shared-instrument-list/shared-instrument-list.component';
import { ProductTypeComponent } from './components/product-type/product-type.component';
import { InstrumentDataComponent } from './basic-components/components-3/instrument-data/instrument-data.component';
import { TargetMarketOptionComponent } from './components/target-market-option/target-market-option.component';
import { TargetMarketCheckComponent } from './components/target-market-check/target-market-check.component';
import { SharedPerformanceFilterComponent } from './components/shared-performance-filter/shared-performance-filter.component';
import {RecommendationsDetailsComponent} from './components/recommendations/recommendations-details/recommendations-details.component';
import {RecommendationsPerformanceComponent} from './components/recommendations/recommendations-performance/recommendations-performance.component';
import {RecommendationsSpecificationsComponent} from './components/recommendations/recommendations-specifications/recommendations-specifications.component';
import {RecommendationsLibraryComponent} from './components/recommendations/recommendations-library/recommendations-library.component';
import {RecommendationsHistoryComponent} from './components/recommendations/recommendations-history/recommendations-history.component';
import {ProductCatalogListComponent} from './components/product-catalog-list/product-catalog-list.component';
import {ProductCatalogViewComponent} from './components/product-catalog-view/product-catalog-view.component';
import { LatestTradeIdeasComponent } from './components/dashboard/latest-trade-ideas/latest-trade-ideas.component';
import { LatestRecommendationsComponent } from './components/dashboard/latest-recommendations/latest-recommendations.component';
import { MandatesComponent } from './components/dashboard/mandates/mandates.component';
import { DateCustomPipe } from './pipes/date-custom.pipe';
import { NumberCustomPipe } from './pipes/number-custom.pipe';
import { AmountPipe } from './pipes/amount.pipe';
import { UserMaintenanceComponent } from './components/user-maintenance/user-maintenance.component';
import { BadgeComponent } from './basic-components/unknown-components/badge/badge.component';
import { RightPanelComponent } from './basic-components/unknown-components/right-panel/right-panel.component';
import { UserPortalInfoComponent } from './basic-components/unknown-components/user-portal-info/user-portal-info.component';
import {SharedBenchmarkDetailsComponent} from './components/shared-benchmarks/shared-benchmark-details/shared-benchmark-details.component';
import {SharedBenchmarkLibraryComponent} from './components/shared-benchmarks/shared-benchmark-library/shared-benchmark-library.component';
import {SharedBenchmarkSelectionDialogComponent} from './components/shared-benchmarks/shared-benchmark-selection-dialog/shared-benchmark-selection-dialog.component';
import { DividendTableComponent } from './basic-components/unknown-components/dividend-table/dividend-table.component';
import { PerformanceTableComponent } from './basic-components/unknown-components/performance-table/performance-table.component';
import { NumberUpDownComponent } from './basic-components/unknown-components/number-up-down/number-up-down.component';
import {SharedRiskRankComponent} from './components/shared-product-risk/shared-risk-rank/shared-risk-rank.component';
import {SharedRiskNameComponent} from './components/shared-product-risk/shared-risk-name/shared-risk-name.component';
import { SharedRiskLevelComponent } from './components/shared-product-risk/shared-risk-level/shared-risk-level.component';
import { SharedInstrumentBannerComponent } from './components/shared-instruments/shared-instrument-banner/shared-instrument-banner.component';
import { AccountExceptionListComponent } from './basic-components/unknown-components/account-exception-list/account-exception-list.component';
import { SharedPRCMinMaxLevelComponent } from './components/shared-product-risk/shared-prc-min-max-level/shared-prc-min-max-level.component';
import { PercentCustomPipe } from './pipes/percent-custom.pipe';
import { TradeIdeaStatisticsChartComponent } from './basic-components/charts/trade-idea-statistics-chart/trade-idea-statistics-chart.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { SharedMarketingMaterialsCardComponent } from './components/shared-marketing-materials-card/shared-marketing-materials-card.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { SharedCurrencyBoxComponent } from './components/shared-currency-box/shared-currency-box.component';
import { ArticleDetailsComponent } from './components/article-details/article-details.component';
import { TutorialsListComponent } from './components/tutorials-list/tutorials-list.component';
import { ResearchListComponent } from './components/research-list/research-list.component';
import { TutorialsDisplayComponent } from './components/tutorials-display/tutorials-display.component';
import { ArticleDisplayViewComponent } from './components/article-display-view/article-display-view.component';
import { FlagComponent } from './basic-components/unknown-components/flag/flag.component';
import { TutorialRowComponent } from './components/tutorial-row/tutorial-row.component';
import { DocumentDisplayComponent } from './components/document-display/document-display.component';
import { MultivalueDropdownComponent } from './basic-components/unknown-components/multivalue-dropdown/multivalue-dropdown.component';
import { MultivalueSubitemComponent } from './basic-components/unknown-components/multivalue-subitem/multivalue-subitem.component';
import { SharedPartnersCardFilterComponent } from './components/shared-discretionary-model-list/shared-partners-card-filter/shared-partners-card-filter.component';
import { DividedPlaceholderComponent } from './basic-components/components-10/divided-placeholder/divided-placeholder.component';
import {SharedReviewSliderComponent} from './components/shared-review-slider/shared-review-slider.component';
import { SaaMaintenanceComponent } from './components/saa/saa-maintenance/saa-maintenance.component';
import { SaaAllocationsComponent } from './components/saa/saa-allocations/saa-allocations.component';
import { SaaChangesComponent } from './components/saa/saa-changes/saa-changes.component';
import { MarketDataComponent } from './components/market-views/market-data/market-data.component';
import { FixedIncomeChartComponent } from './components/market-views/fixed-income-chart/fixed-income-chart.component';
import { SaaChartsComponent } from './components/saa/saa-charts/saa-charts.component';
import { SharedProductSearchComponent } from './components/shared-product-search/shared-product-search.component';

export const configFactory = () => new ConfigStaticLoader();
registerLocaleData(localeFr);

@NgModule({
  declarations: [
    SharedLanguageMenuComponent, SharedMainFooterComponent,
    SharedVerticalMenuComponent, SharedLoaderComponent, GallerySingleComponent, GallerySeveralComponent, ThumbnailComponent,
    SearchBarComponent, InputComponent, VideoBoxComponent, SharedSnackbarComponent, FullBtnComponent,
    ThumbBtnComponent, CardBtnComponent, TooltipComponent, ToggleComponent, MoveComponent, LoadMoreComponent,
    FilterBarComponent, RadioComponent, TranslationTabsComponent, TopMenuComponent, SubMenuComponent,
    SharedPrimaryContactComponent, SharedDialogComponent, DropdownComponent, DatepickerComponent, SharedOrgDomainComponent,
    SharedCountriesSelectorComponent, SharedMsFundReportComponent, SliderRadioComponent, AudioPlayerComponent,
    AssetsAllocationChartComponent, PerformanceChartComponent, TagLabelComponent, SharedUserListComponent,
    SharedMSXrayComponent, UserRowComponent, CheckboxComponent, ModeBtnComponent,
    UserRowComponent, AdvancedAssetsAllocationChartComponent, CategoryComponent, CostChartComponent,
    SharedAllocationChangesComponent, LastReviewComponent, ShortContactComponent, UserRowComponent, CheckboxComponent,
    CheckboxComponent, SharedAccordionComponent, ActionsMenuNewComponent,
    SharedInsightsListComponent, SharedInsightsCardComponent, SharedInsightsMaintenanceComponent,
    InsightsRowComponent, SharedInsightDetailsComponent, SlidingBarComponent, BtnWorkflowComponent, TagComponent,
    AutoCompleteComponent, ReadOnlyModelConstructionComponent, SharedModelCostsComponent,
    SharedModelChangesComponent, SharedModelPerformancesComponent, SharedModelAllocationReadonlyComponent,
    SharedModelMetricsComponent, SharedModelMarketingReadonlyComponent,
    SharedPartnerProfilePreviewComponent, SharedStrategiesSliderComponent, SharedInsightsDisplayComponent,
    SharedInsightsSearchComponent, SharedInsightsLatestComponent, SharedStrategyPreviewComponent,
    SharedInsightsViewComponent, SharedDiscretionaryPartnerComponent, SharedDiscretionaryPartnerCardComponent,
    SharedPortfolioModelsCardComponent, SharedDiscretionaryModelListComponent, SharedInstrumentListComponent,
    SharedModelXrayComponent, AudioPlayerComponent, FourEyesRowComponent,  SharedTradingviewCalendarComponent,
    SharedTradingviewMarketoverviewComponent, SharedTradingviewAdvancedChartComponent,
    SharedTradingviewForexRatesComponent, SharedTradingviewScreenerComponent, SharedSalesMeetingDisplayComponent,
    SharedTradingviewSearchComponent, MarketSelectorComponent, TruncatePipe, SalesMeetingSearchResultComponent,
    SharedTradingviewStockmarketComponent, DateCustomPipe,
    SharedTradingviewSymbolInfoComponent, SharedTradingviewFundamentalDataComponent,
    SharedTradingviewCompanyProfileComponent, SharedTradingviewTechnicalAnalysisComponent,
    SharedAlertDispatcherComponent, SharedNotificationDispatcherComponent, TwoLevelBarFilterComponent, SharedModelMpsReadonlyComponent,
    SharedTradingviewForexHeatmapComponent, SharedTradingviewMarketDataComponent, FourEyesDeleteBtnComponent, ReadOnlyModelDetailsComponent,
    SharedDiscretionaryStrategiesComponent, DiscretionaryStrategyCardComponent, PageCardComponent, InprogressComponent,
    SharedInstrumentSearchListComponent, SharedReadOnlyImplementationVehicleComponent,
    StatusComponent, AssetClassComponent,
    StickerComponent, TradeIdeaCardComponent, TradeIdeaDisplayComponent, NavigationActionsMenuComponent,
    TradeIdeasLibraryComponent, SharedImplementationVehicleComponent, DebounceClickDirective, NavigationMenuComponent, SummaryCardComponent,
    SharedDocTypeTagComponent, SharedDocumentChooserDialogComponent, SharedDocumentDisplayListComponent, SharedDocumentLibraryComponent, SharedInstrumentDetailsComponent,
    SharedInstrumentCategorisationComponent, SharedInstrumentTargetMarketComponent, SharedInstrumentPerformanceComponent,
    SharedInstrumentListComponent, ProductTypeComponent, InstrumentDataComponent, TargetMarketOptionComponent,
    TargetMarketCheckComponent, SharedPerformanceFilterComponent,
    AdvisoryAllocationLevelChooserComponent, MifidBenchmarksChartComponent, SharedAdvisoryModelCardComponent,
    SharedAdvisoryModelLibraryComponent, SharedAdvisoryAllocationChangesComponent, SharedAdvisoryModelAllocationComponent,
    SharedAdvisoryModelChangesComponent, SharedAdvisoryModelChartsComponent, SharedAdvisoryModelConstructionComponent,
    SharedAdvisoryModelMaintenanceComponent, SharedCompletionRemainingComponent, RecommendationsDetailsComponent,
    RecommendationsHistoryComponent, RecommendationsLibraryComponent, RecommendationsPerformanceComponent,
    RecommendationsSpecificationsComponent, ProductCatalogListComponent, ProductCatalogViewComponent, LatestTradeIdeasComponent,
    LatestRecommendationsComponent, MandatesComponent, DateCustomPipe, NumberCustomPipe, AmountPipe,
    SharedDocumentMaintenanceDialogComponent, SharedUploadProcessButtonComponent, PaginatorComponent, SharedAlertListComponent, SharedNotifListComponent,
    UserMaintenanceComponent, BadgeComponent, RightPanelComponent, UserPortalInfoComponent,
    SharedBenchmarkDetailsComponent, SharedBenchmarkLibraryComponent, SharedBenchmarkSelectionDialogComponent,
    SharedRiskRankComponent, SharedRiskNameComponent, SharedRiskLevelComponent, AccountExceptionListComponent,
    SharedPRCMinMaxLevelComponent, DividendTableComponent, PerformanceTableComponent, NumberUpDownComponent, PercentCustomPipe,
    SharedInstrumentBannerComponent, TradeIdeaStatisticsChartComponent,
    ShortNumberPipe, SharedMarketingMaterialsCardComponent, DateAgoPipe, SharedCurrencyBoxComponent, SharedPartnersCardFilterComponent,
    SharedMarketingMaterialsCardComponent, DateAgoPipe, SharedCurrencyBoxComponent,
    ArticleDetailsComponent, TutorialsListComponent, ResearchListComponent, TutorialsDisplayComponent, ArticleDisplayViewComponent,
    FlagComponent, TutorialRowComponent, DocumentDisplayComponent, MultivalueDropdownComponent, MultivalueSubitemComponent, DividedPlaceholderComponent,
    SharedReviewSliderComponent, SaaMaintenanceComponent, SaaAllocationsComponent, SaaChangesComponent,
    SharedPRCMinMaxLevelComponent, DividendTableComponent, PerformanceTableComponent, NumberUpDownComponent,
    SharedInstrumentBannerComponent, MarketDataComponent, FixedIncomeChartComponent, SaaChartsComponent, ProductTypeComponent, SharedProductSearchComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    QuillModule.forRoot(),
    NgxDocViewerModule
  ],
  exports: [
    AudioPlayerComponent,
    SharedMainFooterComponent, SlidingBarComponent, BtnWorkflowComponent, ThumbnailComponent, SearchBarComponent,
    SharedVerticalMenuComponent, SharedLoaderComponent, SharedModelXrayComponent,
    SharedLanguageMenuComponent, GallerySingleComponent, GallerySeveralComponent,
    InputComponent, VideoBoxComponent, TranslationTabsComponent, ShortContactComponent, TagComponent,
    SharedSnackbarComponent, FullBtnComponent, ThumbBtnComponent, CardBtnComponent, TooltipComponent, ToggleComponent,
    MoveComponent, LoadMoreComponent, RadioComponent, FilterBarComponent, TopMenuComponent, SubMenuComponent,
    SharedPrimaryContactComponent, SharedDialogComponent, CostChartComponent, SharedAllocationChangesComponent, LastReviewComponent,
    MoveComponent, LoadMoreComponent, RadioComponent, FilterBarComponent, TagLabelComponent, AdvancedAssetsAllocationChartComponent,
    TopMenuComponent, SubMenuComponent, DropdownComponent, DatepickerComponent, MatDatepickerModule, AssetsAllocationChartComponent,
    PerformanceChartComponent, MatNativeDateModule, ActionsMenuNewComponent, SharedOrgDomainComponent,
    SharedCountriesSelectorComponent, SharedMsFundReportComponent, SharedUserListComponent,
    SliderRadioComponent, SharedMSXrayComponent, ModeBtnComponent, CategoryComponent, CheckboxComponent, SharedAccordionComponent,
    SharedInsightsListComponent, SharedInsightsMaintenanceComponent, AutoCompleteComponent,
    ReadOnlyModelConstructionComponent, SharedModelCostsComponent, SharedModelChangesComponent, SharedModelPerformancesComponent,
    SharedModelMetricsComponent, SharedModelAllocationReadonlyComponent, SharedModelMarketingReadonlyComponent,
    SharedPartnerProfilePreviewComponent, SharedStrategiesSliderComponent, SharedInsightsCardComponent, SharedStrategyPreviewComponent,
    SharedInsightsSearchComponent, SharedInsightsDisplayComponent,
    SharedDiscretionaryPartnerComponent, SharedDiscretionaryPartnerCardComponent, SharedPortfolioModelsCardComponent,
    SharedDiscretionaryModelListComponent, FourEyesRowComponent, SharedTradingviewCalendarComponent,
    SharedTradingviewMarketoverviewComponent, SharedTradingviewAdvancedChartComponent, SharedTradingviewForexRatesComponent,
    SharedTradingviewScreenerComponent, SharedInsightsViewComponent, SharedSalesMeetingDisplayComponent,
    TruncatePipe, SalesMeetingSearchResultComponent, DateCustomPipe, PercentCustomPipe,
    SharedTradingviewSearchComponent, SharedTradingviewStockmarketComponent,
    SharedTradingviewSymbolInfoComponent, SharedTradingviewFundamentalDataComponent,
    SharedTradingviewCompanyProfileComponent, SharedTradingviewTechnicalAnalysisComponent, SharedInsightsViewComponent,
    SharedAlertDispatcherComponent, SharedNotificationDispatcherComponent, SharedTradingviewForexHeatmapComponent,
    TwoLevelBarFilterComponent, FourEyesDeleteBtnComponent, ReadOnlyModelDetailsComponent,
    SharedTradingviewMarketDataComponent, SharedDiscretionaryStrategiesComponent, PageCardComponent, InprogressComponent,
    SharedInstrumentSearchListComponent, SharedReadOnlyImplementationVehicleComponent, StatusComponent,
    AssetClassComponent, StickerComponent, TradeIdeaCardComponent, TradeIdeaDisplayComponent, SummaryCardComponent,
    TradeIdeasLibraryComponent, SharedImplementationVehicleComponent, DebounceClickDirective, NavigationMenuComponent,
    AssetClassComponent, StickerComponent, TradeIdeaCardComponent, TradeIdeaDisplayComponent, NavigationActionsMenuComponent,
    SharedDocTypeTagComponent, SharedDocumentChooserDialogComponent, SharedDocumentDisplayListComponent, SharedDocumentLibraryComponent,
    SharedInstrumentListComponent, SharedInstrumentDetailsComponent,
    AdvisoryAllocationLevelChooserComponent, MifidBenchmarksChartComponent, SharedAdvisoryModelCardComponent,
    SharedAdvisoryModelLibraryComponent, SharedAdvisoryAllocationChangesComponent, SharedAdvisoryModelAllocationComponent,
    SharedAdvisoryModelChangesComponent, SharedAdvisoryModelChartsComponent, SharedAdvisoryModelConstructionComponent,
    SharedAdvisoryModelMaintenanceComponent, SharedCompletionRemainingComponent, RecommendationsDetailsComponent,
    RecommendationsHistoryComponent, RecommendationsLibraryComponent, RecommendationsPerformanceComponent,
    RecommendationsSpecificationsComponent, ProductCatalogListComponent, ProductCatalogViewComponent, LatestTradeIdeasComponent,
    LatestRecommendationsComponent, MandatesComponent, NumberCustomPipe, AmountPipe,
    SharedDocumentMaintenanceDialogComponent, SharedUploadProcessButtonComponent, PaginatorComponent, SharedAlertListComponent, SharedNotifListComponent,
    UserMaintenanceComponent, BadgeComponent, RightPanelComponent, UserPortalInfoComponent, InstrumentDataComponent, SharedPerformanceFilterComponent,
    SharedBenchmarkDetailsComponent, SharedBenchmarkLibraryComponent, SharedBenchmarkSelectionDialogComponent,
    SharedRiskRankComponent, SharedRiskNameComponent, SharedRiskLevelComponent, AccountExceptionListComponent, SharedPRCMinMaxLevelComponent, SharedInstrumentBannerComponent,
    TradeIdeaStatisticsChartComponent, ShortNumberPipe, SharedMarketingMaterialsCardComponent, DateAgoPipe, SharedCurrencyBoxComponent, SharedPartnersCardFilterComponent,
    ShortNumberPipe, SharedMarketingMaterialsCardComponent, DateAgoPipe, SharedCurrencyBoxComponent, ArticleDetailsComponent,
    TutorialsListComponent, ResearchListComponent, TutorialsDisplayComponent, FlagComponent, DividedPlaceholderComponent,
    SharedReviewSliderComponent, SaaMaintenanceComponent,
    SharedRiskRankComponent, SharedRiskNameComponent, SharedRiskLevelComponent, AccountExceptionListComponent,
    SharedPRCMinMaxLevelComponent, SharedInstrumentBannerComponent, MarketDataComponent, FixedIncomeChartComponent, ProductTypeComponent,
    SharedProductSearchComponent
  ],
  providers: [
    { provide: 'HTTP_CLIENT', useValue: HttpClient },
    TranslateService, MatDatepickerModule,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    CurrencyPipe
  ]
})

export class VestrataComponentsLibModule {

  static forRoot(configuredProvider: any = {
    provide: ConfigLoader,
    useFactory: configFactory
  }): ModuleWithProviders<VestrataComponentsLibModule> {
    return {
      ngModule: VestrataComponentsLibModule,
      providers: [
        configuredProvider,
        ConfigService,
        {
          provide: APP_INITIALIZER,
          useFactory: initializerFactory,
          deps: [ConfigService],
          multi: true
        }
      ]
    };
  }


  constructor(private translate: TranslateService) {
    translate.addLangs(['en', 'fr', 'it']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr|it/) ? browserLang : 'en');
  }

}
