import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {Strategy, StrategyLocal} from "../../models/vestrata/Strategy";
import {SharedSnackbarService} from "../shared-snackbar/services/shared-snackbar.service";

@Component({
  selector: 'app-shared-strategies-slider',
  templateUrl: './shared-strategies-slider.component.html',
  styleUrls: ['./shared-strategies-slider.component.scss']
})
export class SharedStrategiesSliderComponent implements OnInit, OnDestroy, OnChanges   {

  private _onDestroy = new Subject();

  @Input() strategiesList: Strategy[];
  @Input() strategyAlone = false;
  currentStrategy: Strategy;
  currentStrategyLocal: StrategyLocal;
  strategiesListLocal: StrategyLocal[] = [];

  currentStrategyIndex = 0;

  constructor(private router: Router,
              private snackbarService: SharedSnackbarService,
              private translateService: TranslateService) {
    this.translateService.onLangChange.pipe(takeUntil(this._onDestroy)).subscribe(event => {
      if (event.lang) {
        this.strategiesListLocal = [];
        this.currentStrategyIndex = 0;
        this.strategiesList.forEach(s => {
          if (s.current != null && s.current.data != null) {
            let foundLang = false;
            s.current.data.strategyLocal.forEach( sl => {
              if (sl.lang === this.translateService.currentLang) {
                this.strategiesListLocal.push(sl);
                foundLang = true;
              }
            });
            if (!foundLang) {
              s.current.data.strategyLocal.forEach( sl => {
                if (sl.lang === 'en') {
                  this.strategiesListLocal.push(sl);
                  foundLang = true;
                }
              });
            }
          }
        });
        this.currentStrategyLocal = this.strategiesListLocal[this.currentStrategyIndex];
      }
    });
  }

  ngOnInit() {
  }

  public ngOnChanges(changes: { [propKey: string]: SimpleChange  }) {

    if (this.strategiesList.length > 0) {

      this.strategiesList.forEach(s => {
        if (s.current != null && s.current.data != null) {
          s.current.data.strategyLocal.forEach( sl => {
            if (sl.lang === this.translateService.currentLang) {
              this.strategiesListLocal.push(sl);
            }
          });
        }
      });

      this.currentStrategyLocal = this.strategiesListLocal[this.currentStrategyIndex];
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }




  getStrategyImage(strategy: Strategy) {
    strategy.current.data.strategyLocal.forEach( sl => {
      if (sl.lang === this.translateService.currentLang) {
        return sl.imageUrl;
      }
    });
  }

  next() {
    this.currentStrategyIndex++;
    if (this.currentStrategyIndex > this.strategiesList.length - 1) {
      this.currentStrategyIndex = 0;
    }
    this.currentStrategyLocal = this.strategiesListLocal[this.currentStrategyIndex];

  }

  previous() {
    this.currentStrategyIndex--;
    if (this.currentStrategyIndex < 0) {
      this.currentStrategyIndex = this.strategiesList.length - 1;
    }
    this.currentStrategyLocal = this.strategiesListLocal[this.currentStrategyIndex];
  }

 /* openStrategy(strategy: Strategy) {
    this.router.navigate(['/' + RM_CLIENT_URL.OUR_PORTFOLIOS , { strategyID: strategy.id }]);
  }

  gotoAssetManager() {
    this.router.navigate(['/' + RM_CLIENT_URL.NEW_AM + '/' + this.currentStrategy.assetManager.id ]);
  }*/
}
