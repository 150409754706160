import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {SnackbarMessage} from './models/snackbar-message';
import {animate, style, transition, trigger} from '@angular/animations';
import {SharedSnackbarService} from './services/shared-snackbar.service';
import {takeUntil} from 'rxjs/operators';
import {UtilsService} from '../../api/utils.service';

@Component({
  selector: 'ves-shared-snackbar',
  templateUrl: './shared-snackbar.component.html',
  styleUrls: ['./shared-snackbar.component.scss'],
  animations: [
    trigger(
      'fadeInOut', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateY(100%)', opacity: 0}))
        ])
      ]
    )
  ]
})
export class SharedSnackbarComponent implements OnInit, OnDestroy {

  private _onDestroy = new Subject();
  private readonly infoDismissDelay = 3000;
  private readonly warningDismissDelay = 5000;

  messages: SnackbarMessage[] = [];
  type: 'message' | 'error';

  constructor(private snackbar: SharedSnackbarService,
              private utilsService: UtilsService) { }

  ngOnInit() {
    this.snackbar.newMessageEvent$.pipe(takeUntil(this._onDestroy)).subscribe(message => {
      let msgAlreadyExists = false;
      if (message) {
        if (this.messages) {
          this.messages.forEach(m => {
            if (m.message === message.message) {
              msgAlreadyExists = true;
            }
          });
        }
        if (message.allowDuplicate || !msgAlreadyExists) {
          this.messages.push(message);
          if (message.type === 'info') {
            setTimeout(() => {
              this.dismiss(message);
            }, this.infoDismissDelay);
          }

          if (message.type === 'warning') {
            setTimeout(() => {
              this.dismiss(message);
            }, this.warningDismissDelay);
          }
        }
      }
    });
  }

  dismissAll() {
    this.messages = [];
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  dismiss(message: SnackbarMessage) {
    this.utilsService.removeFromIterable(this.messages, message);
  }

  getImageByType(type: string): string {
    let src;
    switch (type) {
      case 'info':
        src = '../../../assets/images/components/components-16/icon-snackbar-info.svg';
        break;
      case 'warning':
        src = '../../../assets/images/components/components-16/icon-snackbar-warning.svg';
        break;
      case 'error':
        src = '../../../assets/images/components/components-16/icon-snackbar-error.svg';
        break;
    }
    return src;
  }
}
