import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NavigationData} from './navigation-menu.component';

@Injectable({
  providedIn: 'root'
})
export class NavigationMenuService {

  private _navigationData = new BehaviorSubject<NavigationData>(new NavigationData());
  readonly navigationDataChanged = this._navigationData.asObservable();

  constructor() {}

  setNavigationData(data: NavigationData) {
    this._navigationData.next(data);
  }

  resetNavigationData() {
    this.setNavigationData(new NavigationData()); // Reset navigation data on every url changes.
  }
}
