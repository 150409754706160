import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment_ from 'moment';
const moment = moment_;
import {forkJoin, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Moment} from 'moment';
import {ModelMetric, PortfolioModel} from '../../models/vestrata/PortfolioModel';
import {Permission} from '../../models/vestrata/Permission';
import {MetricsVersion} from '../../models/vestrata/MetricData';
import {Metric} from '../../models/vestrata/Metric';
import {TimePeriod} from '../../models/vestrata/TimePeriod';
import {MetricsService} from '../../api/services/metrics.service';


@Component({
  selector: 'app-shared-model-metrics',
  templateUrl: './shared-model-metrics.component.html',
  styleUrls: ['./shared-model-metrics.component.scss']
})
export class SharedModelMetricsComponent implements OnInit, OnChanges, OnDestroy {

  private readonly YEAR_PATTERN = /Y\d+/;
  private readonly MONTH_PATTERN = /M\d+/;
  private _onDestroy = new Subject();
  private readonly TOTAL_RETURN_ID = 'TR';

  @Input() model: PortfolioModel;
  @Input() permission: Permission;
  @Input() currentModelMetric: ModelMetric[];

  metrics: Metric[] = [];
  currentMetrics: ModelMetric[] = [];
  currentMetricData: MetricsVersion;
  currentAsOfDate: Moment;
  pendingMetricData: MetricsVersion;

  timePeriods: TimePeriod[] = [];
  trailingMetricsMonthsTimePeriod: TimePeriod[] = [];
  trailingMetricsYearsTimePeriod: TimePeriod[] = [];
  trailingMetricsOthersTimePeriod: TimePeriod[] = [];

  constructor(private metricsService: MetricsService,
              public translateService: TranslateService) {
  }

  private static filterTimePeriod(t1: TimePeriod, t2: TimePeriod) {
    return t1.id.localeCompare(t2.id, undefined, {numeric: true});
  }

  ngOnInit() {
    forkJoin(this.metricsService.getRiskMetrics(), this.metricsService.getTimePeriod()).pipe(takeUntil(this._onDestroy))
      .subscribe(values => {
        this.metrics = values[0];

        // Get current time period for metrics.
        this.timePeriods = values[1];
        this.setMetricsTimePeriods();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && changes.model.currentValue) {
      this.metricsService.getModelMetricData(this.model.id).pipe(takeUntil(this._onDestroy))
        .subscribe((values) => {

          // Get current metric values
          if (values) {
            this.currentMetricData = values.current ? values.current.data : undefined;
            this.pendingMetricData = values.pending ? values.pending.data : undefined;
            this.currentAsOfDate = moment(this.currentMetricData.asOfDate);
          }
        });
    }

    if (changes.currentModelMetric && changes.currentModelMetric.currentValue) {
      // Get current time period for metrics.
      this.setMetricsTimePeriods();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setMetricsTimePeriods() {
    if (this.currentModelMetric) {
      this.currentMetrics = this.currentModelMetric.filter(m => m.metricId !== this.TOTAL_RETURN_ID)
        .sort((mm1, mm2) => {
          const name1 = this.getLocalizedName(mm1.metricId, this.metrics, this.translateService.currentLang);
          const name2 = this.getLocalizedName(mm2.metricId, this.metrics, this.translateService.currentLang);
          return name1.localeCompare(name2);
        });
    }
    const timePeriods = [];
    for (const metric of this.currentMetrics) {
      timePeriods.push(...this.getTimePeriodForMetric(metric, this.timePeriods));
    }
    const timePeriodSets = Array.from(new Set(timePeriods));
    this.trailingMetricsMonthsTimePeriod = timePeriodSets.filter(item => item.type === TimePeriod.TRAILING && item.id.match(this.MONTH_PATTERN))
      .sort((t1, t2) => SharedModelMetricsComponent.filterTimePeriod(t1, t2));
    this.trailingMetricsYearsTimePeriod = timePeriodSets.filter(item => item.type === TimePeriod.TRAILING && item.id.match(this.YEAR_PATTERN))
      .sort((t1, t2) => SharedModelMetricsComponent.filterTimePeriod(t1, t2));
    this.trailingMetricsOthersTimePeriod = timePeriodSets.filter(item => item.type === TimePeriod.TRAILING && !(item.id.match(this.MONTH_PATTERN) || item.id.match(this.YEAR_PATTERN)));
  }

  getLocalizedName(id: string, list: any[], locale: string): string {
    let name = '';
    const item = list.find(i => i.id === id);
    if (item) {
      // Get current language or fallback to english
      const data = !!item.data.find(d => d.lang === locale) ?
        item.data.find(d => d.lang === locale) :
        item.data.find(d => d.lang === 'en');

      if (data) {
        name = data.name;
      }
    }
    return name;
  }

  getTimePeriodForMetric(metric: ModelMetric, periods: TimePeriod[]): TimePeriod[] {
    let tps: TimePeriod[] = [];
    if (metric) {
      tps = periods.filter(p => {
        return metric.trailingTimePeriods.find( tp => tp === p.id);
      });
    }

    return tps;
  }

  getValue(timePeriodId: string, metricId: string): string {
    let value;
    const metric = this.currentMetricData.trailingData.find(m => m.metricId === metricId);
    if (metric) {
      const timePeriod = metric.data ? metric.data.find(tp => tp.timePeriodId === timePeriodId) : undefined;
      value = timePeriod ? timePeriod.value : undefined;
    }
    return value;
  }
}

