import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Strategy, StrategyData, StrategyLocal} from '../../models/vestrata/Strategy';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Organisation} from '../../models/vestrata/Organisation';
import {StrategyPortfolioModelListDto} from '../../models/vestrata/PortfolioModelListDto';
import {InvestmentObjective, InvestmentObjectiveLocal} from '../../models/vestrata/InvestmentObjective';
import {AuthenticationService} from '../../api/services/authentication.service';
import {MARKETING_MATERIALS_TYPE} from '../../models/vestrata/TradeIdea';
import {finalize, take, takeUntil} from 'rxjs/operators';
import {CommonService} from '../../api/services/common.service';
import {NavigationMenuService} from '../../basic-components/unknown-components/navigation-menu/navigation-menu.service';
import {UtilsService} from '../../api/utils.service';
import {SharedSnackbarService} from '../shared-snackbar/services/shared-snackbar.service';
import {PartnerService} from '../../api/services/partner.service';
import {SharedLoaderService} from '../shared-loader/service/shared-loader.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationData} from '../../basic-components/unknown-components/navigation-menu/navigation-menu.component';
import {DocumentUploadTypes, VestrataDocument, VestrataDocumentInfo} from '../../models/vestrata/VestrataDocument';
import {TabLanguage} from '../../models/vestrata/TopMenuItem';
import {Version} from '../../models/vestrata/Version';
import {Permission} from '../../models/vestrata/Permission';
import {Subject} from 'rxjs';
import {IpsService} from '../../api/services/ips.service';
import {AdvisorService} from '../../api/services/advisor.service';

@Component({
  selector: 'ves-shared-strategy-preview',
  templateUrl: './shared-strategy-preview.component.html',
  styleUrls: ['./shared-strategy-preview.component.scss']
})
export class SharedStrategyPreviewComponent implements OnInit, OnDestroy {
  @Input() organisation: Organisation;
  @Input() strategyDataToView: StrategyData;
  @Input() strategyId: string;
  @Input() currentLanguage: string;
  @Input() portal: string;
  @Output() goBackEvent = new EventEmitter<boolean>();
  tabs: TabLanguage[] = [];
  previewPage = false;

  // Managing Sliders
  currentUrl: string;

  previewCurrentStrategyLocal: StrategyLocal;
  editingStrategyData: StrategyData;
  editingStrategy: Version<StrategyData>;
  permission: Permission;
  strategyToSave: Strategy = new Strategy();
  private _onDestroy = new Subject();

  strategyPortfolioModels: StrategyPortfolioModelListDto[];
  strategyCurrencyList: string[] = [];
  currentCurrency = '';
  strategyIoList: InvestmentObjectiveLocal[] = [];
  strategyAllocationList: any[] = [];

  selectedIOIndex = 0;
  marketingMaterials = [];
  viewFileDialog = false;
  docDialogMode = 'view';
  documents: VestrataDocumentInfo[];
  documentIdToView: string;
  tempUploadedDocument: VestrataDocument;
  allowedTypes: string[] = DocumentUploadTypes.all();
  portalService: any;
  private navigationData: NavigationData = new NavigationData();

  constructor(private translateService: TranslateService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private route: ActivatedRoute,
              private loader: SharedLoaderService,
              private partnerService: PartnerService,
              private snackbar: SharedSnackbarService,
              private utilsService: UtilsService,
              private authService: AuthenticationService,
              private menuService: NavigationMenuService,
              private common: CommonService,
              private ipsService: IpsService,
              private advisorService: AdvisorService) {
  }

  ngOnInit() {
    // this.permission = this.authService.getPermissions().partner.strategy;
    this.permission = {read: true, write: true, publish: true, approve: true};
    this.currentLanguage = this.translateService.currentLang;

    switch (this.portal) {
      case 'ips':
        this.portalService = this.ipsService.getStrategyById(this.strategyId);
        break;
      case 'partner':
        this.portalService = this.partnerService.getStrategy(this.strategyId);
        break;
      case 'advisor':
        this.portalService = this.advisorService.getStrategyById(this.strategyId);
        break;
      default:
        this.portalService = this.partnerService.getStrategy(this.strategyId);
        break;
    }

    this.loader.showFullLoader();
    this.portalService.pipe(takeUntil(this._onDestroy),
      take(1), finalize(() => this.loader.dismissLoader())).subscribe(s => {
      this.strategyToSave = s;
      this.setupLanguagesTabs();
      this.checkMarMaterialsType();
      console.log('strategyToSave', this.strategyToSave);
    });
    this.setMenuData();
  }

  showMarketingMaterials() {
    const localStrategyFromLen = this.getStrategyLocalFromLanguage(this.getVersion(this.strategyToSave)?.data, this.currentLanguage);
    console.log('localStrategyFromLen', localStrategyFromLen);
    return !!(localStrategyFromLen?.strategyReports.length || localStrategyFromLen?.riskDisclosures.length || localStrategyFromLen?.slides.length || localStrategyFromLen?.videoUrl);
  }

  getVersion(data) {
    return data.pending && data.pending.status ? data.pending : data.current;
  }

  private setupLanguagesTabs() {
    if (!this.strategyToSave || !this.getVersion(this.strategyToSave)?.data.strategyLocal) {
      return;
    }
    this.getVersion(this.strategyToSave)?.data.strategyLocal.forEach( item => this.tabs.push(new TabLanguage(item.lang)));
  }


  selectIO(selectedIOIndex: number) {
    this.selectedIOIndex = selectedIOIndex;
  }

  rankingClass(rank) {
    let color;
    if (rank > 3 && rank <= 5) {
      color = '#248ed9';
    } else if (rank > 5 && rank <= 7) {
      color = '#003057';
    } else if (rank > 7 && rank <= 10) {
      color = '#000000';
    } else {
      color = '#00c4b3';
    }
    return color;
  }

  receiveViewFileEvent($event) {
    if ($event[1]?.id) {
      this.docDialogMode = $event[0];
      this.documentIdToView = $event[1]?.id;
      this.viewFileDialog = $event[2];
    }
    if ($event[1]?.url) {
      this.docDialogMode = $event[0];
      this.documentIdToView = $event[1];
      this.viewFileDialog = $event[2];
    }
  }

  onCloseDocViewer() {
    this.viewFileDialog = false;
    this.documentIdToView = null;
    this.tempUploadedDocument = null;
  }

  checkMarMaterialsType() {
    let combinedArray: any[] = [];
    const localStrategyFromLen = this.getStrategyLocalFromLanguage(this.getVersion(this.strategyToSave)?.data, this.currentLanguage);
    combinedArray = localStrategyFromLen?.strategyReports.concat(localStrategyFromLen?.riskDisclosures);
    const materialsType: any[] = [combinedArray];

    if (combinedArray) {
      materialsType.forEach(el => {
        if (el.find(el => (el?.blobName?.includes('.pdf') || el?.name?.includes('.pdf')))) {
          el.forEach(e => this.marketingMaterials.push({doc: e, type: MARKETING_MATERIALS_TYPE.PDF}));
        }
        if (el.find(el => el?.blobName?.includes('.xlsx') || el?.name?.includes('.xlsx'))) {
          el.forEach(e => this.marketingMaterials.push({doc: e, type: MARKETING_MATERIALS_TYPE.EXCEL}));
        }
        if (el.find(el => el?.blobName?.includes('.docx') || el?.name?.includes('.docx'))) {
          el.forEach(e => this.marketingMaterials.push({doc: e, type: MARKETING_MATERIALS_TYPE.WORD}));
        }
        if (el.find(el => el?.blobName?.includes('.pptx') || el?.name?.includes('.pptx'))) {
          el.forEach(e => this.marketingMaterials.push({doc: e, type: MARKETING_MATERIALS_TYPE.POWERPOINT}));
        }
        if (el.find(el => el?.blobName?.includes('.mp3') || el?.name?.includes('.mp3'))) {
          el.forEach(e => this.marketingMaterials.push({doc: e, type: MARKETING_MATERIALS_TYPE.AUDIO}));
        }
        if (el.find(el => el?.blobName?.includes('.mp4') || el?.name?.includes('.mp4'))) {
          el.forEach(e => this.marketingMaterials.push({doc: e, type: MARKETING_MATERIALS_TYPE.VIDEO}));
        }
      });
    }

    if (localStrategyFromLen?.videoUrl && localStrategyFromLen.videoUrl?.includes('youtube.com')) {
      this.marketingMaterials.push({
        type: MARKETING_MATERIALS_TYPE.YOUTUBE,
        doc: {
          url: localStrategyFromLen?.videoUrl,
          effectiveDate: localStrategyFromLen?.videoEffectiveDate,
          contentType: MARKETING_MATERIALS_TYPE.YOUTUBE,
          name: MARKETING_MATERIALS_TYPE.YOUTUBE,
          type: MARKETING_MATERIALS_TYPE.YOUTUBE
        }
      });
    }
    if (localStrategyFromLen?.slides && localStrategyFromLen?.slides.length) {
      this.marketingMaterials.push({
        type: MARKETING_MATERIALS_TYPE.SLIDESHOW,
        doc: {
          url: localStrategyFromLen?.slides,
          contentType: MARKETING_MATERIALS_TYPE.SLIDESHOW,
          name: MARKETING_MATERIALS_TYPE.SLIDESHOW,
          type: MARKETING_MATERIALS_TYPE.SLIDESHOW
        }
      });
    }
    // Check doc type strategyReports
    localStrategyFromLen?.strategyReports.forEach(docType => {
      if (this.marketingMaterials.filter(el => el.doc === docType)) {
        this.marketingMaterials.find(e => e.doc === docType).docType = 'strategyReports';
      }
    });

    // Check doc type riskDisclosures
    localStrategyFromLen?.riskDisclosures.forEach(docType => {
      if (this.marketingMaterials.filter(el => el.doc === docType)) {
        this.marketingMaterials.find(e => e.doc === docType).docType = 'riskDisclosures';
      }
    });

    console.log('marketingMaterials', this.marketingMaterials);
  }

  getInvestmentObjectiveWithLocal(index: number, io: InvestmentObjective[]): InvestmentObjectiveLocal {
    let returnValue: InvestmentObjectiveLocal = new InvestmentObjectiveLocal();
    if (io != null && io.length > 0) {
      if (index < io.length) {
        if (io[index].info != null) {
          io[index].info.forEach(p => {
            if (p.lang === this.currentLanguage) {
              returnValue = p;
            }
          });
        }
      }
    }
    return returnValue;
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.menuService.resetNavigationData();
  }

  validateStrategyData(strategyData: StrategyData) {
    if (strategyData.strategyLocal == null) {
      strategyData.strategyLocal = [];
    }
    this.tabs = [];
    this.tabs.push(new TabLanguage('en'));

    strategyData.strategyLocal.forEach(l => {
      if (this.tabs.filter(t => t.name === l.lang).length === 0) {
        const lang = new TabLanguage(l.lang);
        this.tabs.push(lang);
      }
    });
    this.currentLanguage = 'en';
  }

  clickOnTranslation(lang: string) {
    this.currentLanguage = lang;
    this.langSelected();
  }

  getStrategyLocalFromLanguage(data: StrategyData, lang: string) {
    let local = null;
    if (data != null) {
      data.strategyLocal.forEach(p => {
        if (p.lang === lang) {
          local = p;
        }
      });
    }
    return local;
  }

  checkImageUrl(image: boolean) {
    if (!image) {
      return 'gallery-single-empty-new';
    } else {
      return null;
    }
  }

  getCurrentStrategyLocalFromLanguage(lang: string): StrategyLocal {
    let local: StrategyLocal = new StrategyLocal();
    if (this.editingStrategyData != null) {
      this.editingStrategyData.strategyLocal.forEach(p => {
        if (p.lang === lang) {
          local = p;
        }
      });
    }
    return local;
  }

  langSelected() {
    this.previewCurrentStrategyLocal = this.getCurrentStrategyLocalFromLanguage(this.currentLanguage);
    this.currentUrl = this.previewCurrentStrategyLocal.slides[0];
    this.previewPage = !this.previewPage;

    this.partnerService.getPortfolioModelsByStrategy(this.strategyToSave.id).subscribe(pms => {
      this.currentCurrency = '';
      this.strategyIoList = [];
      this.strategyAllocationList = [];
      this.strategyPortfolioModels = pms;
      this.strategyCurrencyList = [];
      if (this.previewCurrentStrategyLocal) {
        this.strategyCurrencyList = Array.from(new Set(this.strategyPortfolioModels.filter(s => s.current != null).map(s => s.currency)));
      } else {
        this.strategyCurrencyList = Array.from(new Set(this.strategyPortfolioModels.filter(s => s.current != null).map(s => s.currency)));
      }

      if (this.strategyCurrencyList.length > 0) {
        this.currentCurrency = this.strategyCurrencyList[0];
        // this.pmRefreshOnCurrency(this.currentCurrency);
      }
    });
    this.setMenuData();
  }

  getStrategyName(): string {
    if (!this.strategyToSave || !this.getVersion(this.strategyToSave)?.data?.strategyLocal) {
      return;
    }
    return this.common.getLocalFieldFromVersion(this.getVersion(this.strategyToSave)?.data, this.currentLanguage);
  }

  goBack() {
    this.goBackEvent.emit(true);
  }

  private setMenuData() {
    this.navigationData.interface = this;
    this.navigationData.permission = this.permission;
    this.navigationData.fourEyeView = false;
    this.navigationData.pageTitle = this.buildPageTitle();
    this.menuService.setNavigationData(this.navigationData);
  }

  private buildPageTitle(): string {
    let title = this.translateService.instant('am-strategy.title') + ' › ';
    if (this.strategyToSave && this.strategyToSave.id) {
      title += this.getStrategyName();
    } else {
      title += this.translateService.instant('am-strategy.new');
    }
    return title;
  }

  // isIpsOrAdvisor() {
  //   return !this.auth.getPermissions().partner ;
  // }
}
