import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ves-inprogress',
  templateUrl: './inprogress.component.html',
  styleUrls: ['./inprogress.component.scss']
})
export class InprogressComponent implements OnInit {
  @Input() navMenu: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
