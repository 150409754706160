import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Chart } from 'chart.js';
import {AssetClass} from '../../../models/vestrata/MajorAssetClass';
import {PartnerService} from '../../../api/services/partner.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AssetAllocation} from '../../../models/vestrata/PortfolioModelListDto';

@Component({
  selector: 'ves-assets-allocation-chart',
  templateUrl: './assets-allocation-chart.component.html',
  styleUrls: ['./assets-allocation-chart.component.scss']
})
export class AssetsAllocationChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  private _onDestroy = new Subject();
  @ViewChild('canvas') canvas: ElementRef;
  private chart: Chart;
  private colors: string[];

  @Input() allocations: AssetAllocation[];
  @Input() assetClasses: AssetClass[];

  @Input() showEmptyLabel = false;
  @Input() showChartKeys = true;
  @Input() centerText: string;

  @Output()  selectEvent = new EventEmitter<string>();

  // TODO: Create dedicated service for asset class gathering.
  constructor(private partner: PartnerService) { }

  ngOnInit() {
    console.log('allocation chart init');
    this.colors = this.allocations.map(aa => this.getColorByAssetClass(aa.assetClass));
    if (this.chart) {
      this.colors = this.allocations.map(aa => this.getColorByAssetClass(aa.assetClass));
      this.chart = this.initChart(this.allocations.map(aa => aa.assetClass), this.allocations.map(aa => aa.percentage), this.colors);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes && changes.allocations && changes.allocations.currentValue) {
      console.log('allocation chart changes');
      this.colors = this.allocations.map(aa => this.getColorByAssetClass(aa.assetClass));
      if (this.chart) {
        this.colors = this.allocations.map(aa => this.getColorByAssetClass(aa.assetClass));
        this.chart = this.initChart(this.allocations.map(aa => aa.assetClass), this.allocations.map(aa => aa.percentage), this.colors);
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.assetClasses) {
      this.partner.getAssetClasses().pipe(takeUntil(this._onDestroy)).subscribe(assets => {
        this.assetClasses = assets;
        if (this.allocations) {
          this.colors = this.allocations.map(aa => this.getColorByAssetClass(aa.assetClass));
          this.chart = this.initChart(this.allocations.map(aa => aa.assetClass), this.allocations.map(aa => aa.percentage), this.colors);
        }
      });
    } else {
      if (this.allocations) {
        this.colors = this.allocations.map(aa => this.getColorByAssetClass(aa.assetClass));
        this.chart = this.initChart(this.allocations.map(aa => aa.assetClass), this.allocations.map(aa => aa.percentage), this.colors);
      }
    }
  }

  initChart(labels: any[], values: any[], colors: string[]): Chart {
    const borderColors = values.map(v => '#ffffff');
    if (this.canvas == null) { return null; }
    return new Chart(this.canvas.nativeElement.getContext('2d'), {
      type: 'doughnut',
      data: {
        datasets: [{
          labels: labels,
          data: values,
          backgroundColor: colors,
          borderColor: borderColors,
          hoverBorderColor: borderColors,
          borderWidth: 4
        }]
      },
      options: {
        tooltips: {
          displayColors: true,
          callbacks: {
            label(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const index = tooltipItem.index;
              return dataset.labels[index] + ": " + dataset.data[index] + " %";
            },
          },
        }
      }
    });
  }

  getColorByAssetClass(assetClass: string): string {
    let color;
    if (this.assetClasses && this.assetClasses.length > 0) {
      const matchingClass = this.assetClasses.find(asset => asset.name === assetClass);
      if (matchingClass) {
        color = matchingClass.majorColor;
      }
    }
    return color;
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  majorClassSelected(event: any) {
    if (this.chart) {
      const element = this.chart.getElementAtEvent(event);
      if (element) {
        const currentMajorAssetClass = this.allocations[element[0]._index].assetClass;
        this.selectEvent.emit(currentMajorAssetClass);
      }
    }
  }
}
