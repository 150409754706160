import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {PerformanceFilter, PriceInfo} from '../../shared-performance-filter/shared-performance-filter.component';
import {PerformanceService} from '../../../api/services/performance.service';
import {finalize, takeUntil} from 'rxjs/operators';

import {forkJoin, Subject} from 'rxjs';

import {SharedLoaderService} from '../../shared-loader/service/shared-loader.service';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {SnackbarMessage} from '../../shared-snackbar/models/snackbar-message';
import {TranslateService} from '@ngx-translate/core';
import {PerformanceMetricDto} from "../../../models/vestrata/PerformanceMetric";
import {
  ProductRecommendationPerformance,
  ProductRecommendationVersion
} from "../../../models/vestrata/ProductRecommendation";
import {ProductRecommendationService} from "../../../api/services/product-recommendation.service";

@Component({
  selector: 'ves-shared-instrument-performance',
  templateUrl: './shared-instrument-performance.component.html',
  styleUrls: ['./shared-instrument-performance.component.scss']
})
export class SharedInstrumentPerformanceComponent implements OnInit, OnDestroy {
  private readonly DATE_FORMAT = 'YYYY-MM-DD';
  private initialValue = 10000;
  private _onDestroy = new Subject();

  private readonly PAST_PERFORMANCES_COLORS = ['#003057', '#248ED9'];
  mainColors = this.PAST_PERFORMANCES_COLORS;

  @Input() instrument: any;

  labels: string[];
  performanceFilter: PerformanceFilter;
  performance: any[];
  performanceMetricData: PerformanceMetricDto;
  priceInfo: PriceInfo;
  launchDate: string;

  // Reccommendations chart
  delta: any[] = [];
  deltaColours = ['#faaf3f'];
  @Input() instrumentRecomm: any;
  @Input() noTargetPrice: boolean;
  @Input() viewRecommendationChart: boolean;
  @Input() performanceVersion: ProductRecommendationVersion;
  @Input() y2Labels: string[] = [];
  @Input() yAxisLabels: string[] = [];
  @Input() maxYValues: number[] = [];

  constructor(private performanceService: PerformanceService,
              private loader: SharedLoaderService,
              private snackbar: SharedSnackbarService,
              private translate: TranslateService,
              private prService: ProductRecommendationService
  ) { }

  ngOnInit(): void {
    this.priceInfo = new PriceInfo();
    this.init();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  init() {
    this.labels = [];
    this.labels.push(this.translate.instant('instrument-details.growth.instrument') + ' > ' + this.instrument.name);
    this.performanceFilter = new PerformanceFilter();
    if (this.instrument && this.instrument.type === 'Fund') {
      this.labels.push(this.translate.instant('benchmarks.benchmark') + ' > ' + this.instrument.morningstar.morningstarBenchmark);
      // For recommendations performance
      if (this.viewRecommendationChart) {
        this.performanceFilter.startDate = this.performanceVersion.startDate;
        this.launchDate = this.performanceVersion.startDate;
      } else {
        this.performanceFilter.startDate = this.instrument.launchDate;
        this.launchDate = this.instrument.launchDate;
      }

    } else if (this.instrument && this.instrument.type === 'Debt') {

      // For recommendations performance
      if (this.viewRecommendationChart) {
        if (this.performanceVersion?.closedDate) {
          this.performanceFilter.startDate = this.performanceVersion?.closedDate;
          this.launchDate = this.performanceVersion?.closedDate;
        } else {
          this.performanceFilter.startDate = moment().add(-1, 'years').format(this.DATE_FORMAT);
          this.launchDate = moment().add(-10, 'years').format(this.DATE_FORMAT);
        }
      } else {
        if (this.instrument.issueDate) {
          this.performanceFilter.startDate = this.instrument.issueDate;
          this.launchDate = this.instrument.issueDate;
        } else {
          this.performanceFilter.startDate = moment().add(-1, 'years').format(this.DATE_FORMAT);
          this.launchDate = moment().add(-10, 'years').format(this.DATE_FORMAT);
        }
      }
    } else {
      this.performanceFilter.startDate = moment().add(-1, 'years').format(this.DATE_FORMAT);
      this.launchDate = moment().add(-10, 'years').format(this.DATE_FORMAT);
    }
    this.performanceFilter.category = 'growth';
    this.performanceFilter.endDate = moment().format((this.DATE_FORMAT));

    console.log('closedDate', this.performanceVersion?.closedDate)
    this.getPerformanceInfo();
  }

  onFilterChange(filter: PerformanceFilter) {
    this.performanceFilter = filter;
    this.getPerformanceInfo();
  }

  setLabelsRecomm() {
    this.labels.push(this.translate.instant('recommendations-performance.instrument-price') + '' + this.instrument?.name);
    this.labels.push(this.translate.instant('recommendations-performance.target-price'));
    this.y2Labels.push(this.translate.instant('recommendations-performance.delta-price') + ' (' +
      this.instrument.name + ' ' + this.translate.instant('recommendations-performance.vs')
      + ' ' + this.translate.instant('recommendations-performance.target-price') + ')' );
    this.yAxisLabels.push(this.translate.instant('recommendations-performance.price') + ' (' + this.instrument?.currency + ')');
    this.yAxisLabels.push(this.translate.instant('recommendations-performance.delta') + ' (%)');
    this.maxYValues.push( this.performanceVersion?.targetPrice * 1.5, 150);
  }

  setPerformanceRecomm(values: ProductRecommendationPerformance[]) {
    const chartStart = moment(this.performanceFilter.startDate).subtract(1, 'days').format();
    const instrument = [{date: chartStart, value: this.performanceVersion.recommendPrice}];
    const target = [{date: chartStart, value: this.performanceVersion.targetPrice}];
    const startDelta = (1 - ((this.performanceVersion.recommendPrice - this.performanceVersion.targetPrice) / this.performanceVersion.targetPrice)) * 100;
    const delta = [{date: chartStart, value: startDelta}];
    for (const v of values) {
      instrument.push({date: v.priceDate, value: v.instrumentPrice});
      target.push({date: v.priceDate, value: this.performanceVersion.targetPrice});
      delta.push({date: v.priceDate, value: v.delta });
    }
    this.performance = [instrument, target];
    this.delta = [delta];
  }

  getPerformanceInfoRecomm() {
      if (this.performanceVersion?.targetPrice) {
        this.prService.getRecommendationPerformance(this.instrument.id, this.performanceVersion.targetPrice, this.performanceFilter.startDate, this.performanceFilter.endDate)
          .pipe(takeUntil(this._onDestroy),
            finalize(() => this.loader.dismissLoader())).subscribe(rec => {
          this.setPerformanceRecomm(rec);
        }, err => {
          this.snackbar.push(new SnackbarMessage(this.translate.instant(err.error.message), 'error'));
        });
      }
  }

  getPerformanceInfo() {
    if (this.performanceFilter.category === 'growth') {
      this.performance = [];
        forkJoin([this.performanceService.getPerformance(PerformanceService.PERFORMANCE_TYPE_INSTRUMENT, this.instrument.id, this.initialValue, this.performanceFilter.startDate, this.performanceFilter.endDate, 'D'),
          this.performanceService.getPriceMinMax(this.getInstrumentId('ISIN'), this.performanceFilter.startDate, this.performanceFilter.endDate),
          this.performanceService.getPerformanceMetrics(PerformanceService.PERFORMANCE_TYPE_INSTRUMENT, this.instrument.id)])
          .pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe((data: any) =>  {
          if (data && data[0]) {
            this.performance = [data[0].instrumentValues, data[0].benchmarkValues];
          } else {
            this.performance = [];
          }
          if (data && data[1]) {
            this.priceInfo = data[1];
          }
          if (data && data[2]) {
            this.performanceMetricData = data[2];
          }
        }, err => {
          this.snackbar.push(new SnackbarMessage(this.translate.instant(err.error.message), 'error'));
        });
    } else if (this.performanceFilter.category === 'price') {
      // For recommendations performance
      if (this.viewRecommendationChart) {
        if (!this.noTargetPrice) {
            forkJoin([this.performanceService.getPriceHistory(this.getInstrumentId('ISIN'), this.performanceFilter.startDate, this.performanceFilter.endDate),
              this.performanceService.getPriceMinMax(this.getInstrumentId('ISIN'), this.performanceFilter.startDate, this.performanceFilter.endDate)])
              .pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe((data: any) =>  {
              if (data && data[0]) {
                this.performance = [data[0].priceValues];
              } else {
                this.performance = [];
              }
              if (data && data[1]) {
                this.priceInfo = data[1];
              }
            }, err => {
              this.snackbar.push(new SnackbarMessage(this.translate.instant(err.error.message), 'error'));
            });
          } else {
            this.setLabelsRecomm();
            this.getPerformanceInfoRecomm();
          }
      } else {
        forkJoin([this.performanceService.getPriceHistory(this.getInstrumentId('ISIN'), this.performanceFilter.startDate, this.performanceFilter.endDate),
          this.performanceService.getPriceMinMax(this.getInstrumentId('ISIN'), this.performanceFilter.startDate, this.performanceFilter.endDate)])
          .pipe(takeUntil(this._onDestroy), finalize(() => this.loader.dismissLoader())).subscribe((data: any) =>  {
          if (data && data[0]) {
            this.performance = [data[0].priceValues];
          } else {
            this.performance = [];
          }
          if (data && data[1]) {
            this.priceInfo = data[1];
          }
        }, err => {
          this.snackbar.push(new SnackbarMessage(this.translate.instant(err.error.message), 'error'));
        });
      }
    }
  }

  getInstrumentId(type) {
    return this.instrument.instrumentIds.find(a => a.idType === type).id;
  }

  getBenchMarkName() {
    if (this.instrument.type === 'Fund') {
      return this.instrument.morningstar.morningstarBenchmark;
    } else {
      return null;
    }
  }

}
