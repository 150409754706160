import { InstrumentInfo } from './Instrument/Instrument';
import {ChangeNotice} from './PortfolioModel';

export class Allocation {
  instrument: InstrumentInfo;
  allocation: number;
  driftedAllocation: number;

  // Used for 4Eyes process UI only.
  currentAllocation: number = 0;
  pendingAllocation: number = 0;
}

export class AllocationDto {
  allocations: Allocation[];
  changeNotice: ChangeNotice;
}


export class AllocationChanged {
  static readonly UP = 'up';
  static readonly DOWN = 'down';
  static readonly NEW = 'new';
  static readonly NOCHANGE = 'nochange';
  static readonly REMOVED = 'removed';

  static values(): string[] {
    return [AllocationChanged.DOWN, AllocationChanged.UP, AllocationChanged.NEW, AllocationChanged.REMOVED, AllocationChanged.NOCHANGE];
  }
}
