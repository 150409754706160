import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
  ElementRef,
  Input, OnChanges, SimpleChanges
} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ves-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy, OnChanges {

  @Input() type = 0;

  @Output()
  textOut = new EventEmitter<any>();

  @ViewChild('searchinputcontainer')
  searchinput: ElementRef;

  @ViewChild('searchimage')
  inputimage: ElementRef;

  _searchText: string;
  @Input() set searchText(v: string) {
    this._searchText = v;
  }
  get searchText() {
    return this._searchText;
  }

  @Output() searchTextChange = new EventEmitter();

  @Output() selectOption = new EventEmitter<SearchOption>();
  @Input() viewSearchId = false;
  @Input() options: SearchOption[] = [];
  @Input() viewSearchInput = false;

  filteredOptions: SearchOption[];
  isOpen = false;

  private _onDestroy = new Subject();

  constructor(private renderer: Renderer2,
              private translate: TranslateService) { }

  ngOnInit() {
    if (this.type === 1 && this.searchText && this.searchText.length > 0 ) {
      this.viewSearchInput = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.options) {
      this.filterOptions();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  onFocus() {
    this.renderer.addClass(this.searchinput.nativeElement, 'search-input-container-focus');
    this.renderer.addClass(this.inputimage.nativeElement, 'search-image-focus');
  }
  onFocusOut() {
    this.renderer.removeClass(this.searchinput.nativeElement, 'search-input-container-focus');
    this.renderer.removeClass(this.inputimage.nativeElement, 'search-image-focus');
    /*if (this._searchText.length === 0) {
      this.viewSearchInput = false;
    }*/
  }

  textChanged() {
    this.textOut.emit(this.searchText);
  }

  toggleSearch() {
    this.viewSearchInput = !this.viewSearchInput;
  }

  clearSearch() {
    this._searchText = '';
    this.isOpen = false;
    this.textOut.emit(this.searchText);
  }

  filterOptions() {
    if (this._searchText && this._searchText.length > 0) {
      this.isOpen = true;
      this.filteredOptions = [];
      this.filteredOptions = this.options;
    } else {
      this.isOpen = false;
    }
  }

  getTranslation(text) {
    return this.translate.instant(text);
  }

  onClick(item: SearchOption) {
    this.isOpen = false;
    this.selectOption.emit(item);
    this._searchText = item.text;
  }
  notFound() {
    this.isOpen = false;
  }
}

export class SearchOption {
  id: string;
  text: string;
  extras?: any ;
}
