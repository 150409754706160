import {SearchRequest} from '../SearchRequest';
import {InstrumentAnalytics} from './InstrumentAnalytics';
import {TargetMarket} from './Fund';
import {SixDocHubDocument} from '../SixDocHubDocument';
import {ProductRecommendation} from "../ProductRecommendation";
import {TradeIdea} from "../TradeIdea";
import {Research} from "../Research";
import {Insight} from "../Insight";

export class Instrument {

  id: string;

  type: InstrumentType;

  instrumentIds: InstrumentId[] = [];
  name: string;

  creationDate: string;
  updateDate: string;

  productType: string[] = [];
  cfiCode: string;

  countryOfIncorporation: string;

  assetClasses: string[] = [];
  currency: string;

  issuer: string;

  geographicalExposure: string;
  geographicalExposureExclusions: string[] = [];

  sector: string[] = [];

  figiData: FigiData;
  exchangeData: ExchangeData[] = [];

  sustainability: Sustainability;
  distributions: Distribution[];

  priip: boolean;
  ucits: boolean;
  //------Common SRI SRRI PRI ------
  summaryRiskIndicator: number;

  syntheticRiskAndRewardIndicator: number;

  productRiskIndicator: number;

  //--------------------------------

  status: string; // recommendation status

  aum: number;
  clientCount: string;

  // FOR UI Only
  isSelected  = false;
  targetMarket: TargetMarket;
  documents: SixDocHubDocument[];

  static getIdFromInstrumentIds(instrument: Instrument, refType: string): string {
    let isin = '';
    if (instrument.instrumentIds != null) {
      instrument.instrumentIds.forEach(insId => {
        if (insId.idType === refType) {
          isin = insId.id;
        }
      });
    }
    return isin;
  }

  static setIdInInstrumentIds(instrument: Instrument, idType: string, id: string) {
    let found = false;
    if (instrument.instrumentIds) {
      if (instrument.instrumentIds.length > 0) {
        instrument.instrumentIds.forEach(idItem => {
          if (idItem.idType === idType) {
            idItem.id = id;
            found = true;
          }
        });
      }
    }
    if (!found) {
      const newId = new InstrumentId();
      newId.id = id;
      newId.idType = idType;
      if (instrument.instrumentIds == null) {
        instrument.instrumentIds = [];
      }
      instrument.instrumentIds.push(newId);
    }
  }
}

export enum InstrumentType {
  CASH_TYPE = 'Cash',
  FUND_TYPE = 'Fund',
  EQUITY_TYPE = 'Equity',
  DEBT_TYPE = 'Debt',
  ETF_TYPE = 'ETF',
  DERIVATIVE_TYPE = 'Derivative'
}

export class InstrumentId {
  id: string;
  idType: string;
}

export class InstrumentInfo {
  id: string;
  instrumentIds: InstrumentId[];
  name: string;
  issuer: string; // Instrument issuer or provider
  type: string; // Cash, Fund, ETF, Equity, Bonds
  productType: string [] = []; // MS security Details api codes.
  cfiCode: string;
  countryOfIncorporation: string;
  assetClasses: string [];
  currency: string;
  maturity?: string;
  recommendationStatus?: string;
  aum: number;
  clientCount: number;

  constructor(instrument: Instrument) {
    this.id = instrument.id;
    this.instrumentIds = instrument.instrumentIds;
    this.name = instrument.name;
    this.issuer = instrument.issuer;
    this.type = instrument.type;
    this.productType = instrument.productType;
    this.cfiCode = instrument.cfiCode;
    this.countryOfIncorporation = instrument.countryOfIncorporation;
    this.assetClasses = instrument.assetClasses;
    this.currency = instrument.currency;
  }

  static getIdFromInstrumentIds(instrument: InstrumentInfo, refType: string): string {
    let isin = '';
    if (instrument.instrumentIds != null) {
      instrument.instrumentIds.forEach(insId => {
        if (insId.idType === refType) {
          isin = insId.id;
        }
      });
    }
    return isin;
  }
}

export class FigiData {
  name: string;
  shareClassFIGI: string;
  securityType: string;
  securityType2: string;
  marketSector: string;
}

export class ExchangeData {
  mic: string;
  primary = false;
  ticker: string;
  currency: string;
  figi: string;
}

export class Sustainability {
  environmental: string;
  social: string;
  governance: string;
}

export class InstrumentSearchFilter {
  searchRequest: SearchRequest;
  productTypes?: string[];
  assetClasses?: string[];
  providers?: string[];
}

export class InstrumentWithPriceDto {
  instrument: any;
  analytics: InstrumentAnalytics;
  lastPrice: LastPriceDto;
}

export class LastPriceDto {
  lastPriceDate: string;
  lastPrice: number;
  high: number;
  low: number;
  yearHigh: number;
  yearHighDate: string;
  yearLow: number;
  yearLowDate: string;
}

export class Distribution {
  exDate: string;
  payDate: string;
  amount: number;
  reinvestmentPrice: number;
  type: string;
  yield: number;
  currency: string;
}

export class InstrumentProdSearch {
  instrument: Instrument;
  productApproval: any; // ProductApprovalDto
  productRecommendation: ProductRecommendation;
  tradeIdeas: TradeIdea[];
  researches: Research[];
  insights: Insight[];
}
