import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'ves-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss']
})
export class SummaryCardComponent implements OnInit {

  @Input() summary: Summary;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goTo(url: string, params: SummaryParams[]) {
    if (url && !params) {
      this.router.navigate([url]);
    } else if (url && params && params.length > 0) {
      const formatedParams = {};
      params.forEach(p => {
        formatedParams[p.key] = p.value;
      });
      console.log(formatedParams);
      this.router.navigate([url], { queryParams: formatedParams });
    }
  }
}


export class Summary {
  img?: string;
  title: string;
  sections: SummarySection[];
}

export class SummarySection {
  title: string;
  items?: SummaryItem[];
}

export class SummaryItem {
  title: string;
  url: string;
  hasPermission = true;
  params?: SummaryParams[];
}

export class SummaryParams {
  key: string;
  value: any;
}

