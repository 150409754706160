import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';
import {MSInstrument} from '../../models/morningstar/MSInstrument';
import {MSScreener} from '../../models/morningstar/MSScreener';
import {MSSummaryCost} from '../../models/morningstar/MSSummaryCost';
import {ApiHttpService} from '../api.http.service';
import {VesConfService} from './conf.service';

@Injectable({
  providedIn: 'root',
})
export class MorningStarService {

  public confUrl: string;
  public serviceUrl: string;
  public msToken: string = null;

  constructor(private http: HttpClient,
              private confService: VesConfService) {
    this.serviceUrl = 'https://lt.morningstar.com/api/rest.svc/yy6k79q5ot/';
    this.confUrl = this.confService.getApiEndPoint();

  }

  getMorningStarToken(): Observable<any> {
    if (this.msToken == null) {
      return this.http.get<any[]>(this.confUrl + 'morningstar' , ApiHttpService.getHeaders());
    } else  {
      return  of(this.msToken);
    }
  }


  getInstrument(iSin: string): Observable<MSInstrument[]> {
    let url = '';
    url = this.serviceUrl + 'security_details/' + iSin +
      '?viewId=investaview&idtype=isin&languageId=en-GB&responseViewFormat=json';
    return this.http.get<MSInstrument[]>(url);
  }

  getInstrumentBySecId(secId: string): Observable<MSInstrument[]> {
    let url = '';
    url = this.serviceUrl + 'security_details/' +
      secId + '?viewId=investaview&idtype=secid&languageId=en-GB&responseViewFormat=json';
    return this.http.get<MSInstrument[]>(url);
  }

  getInstrumentScreener(iSin: string): Observable<MSScreener> {
    let url = '';
    url = this.serviceUrl + 'security/screener?outputType=json&version=1&page=1&pageSize=10&' +
      'securityDataPoints=ISIN|LegalName|PriceCurrency|SubscriptionSettlementDay|RedemptionSettlementDay&languageId=en-GB&filters=ISIN:IN:'
      + iSin + '&universeIds=FOCHE$$ALL|FOFRA$$BAS|FOLUX$$ALL|FOGBR$$ALL|FONLD$$ALL|FOITA$$ALL|FODEU$$ALL|FOBEL$$ALL';
    return this.http.get<MSScreener>(url);
  }

  getCostCalculator(config): Observable<MSSummaryCost[]> {
    let url = '';
    url = this.serviceUrl + 'investment_fees/calculate';
    return this.http.post<MSSummaryCost[]>(url, config);
  }

  getMSPortfolio(portfolio): Observable<any> {
    const url = this.serviceUrl + 'xray/json?languageId=en-GB&' +
      'portfolioDataPoints=UseMongoSecurities,RunInThread,BenchmarkId,BenchmarkName,HistoricalPerformanceSeries|TotalReturn,' +
      'PerformanceReturn|M0|M1|M2|M3|M6|M12|M36|M60|M120,UnderlyingHolding|200|HoldingId|SecurityId|SecurityType|Name|' +
      'MarketValue|Weight|Sector|SectorId|GlobalSectorId|ISIN|CountryId|Country|CurrencyId|CurrencyName|Symbol|M12&' +
      'holdingDataPoints=UseMongoSecurities%2CRunInThread%2CHoldingId%2CSecurityId%2CSymbol%2CSectorName%2CSecurityType%2C' +
      'HoldingType%2CUniverseId%2CName%2CISIN%2CPortfolioDate%2CInitialAmount%2CInitialWeight%2CMarketValue%2CWeight%2C' +
      'CurrencyId%2CStarRatingM255%2CMorningstarRiskM255%2CAnalystRatingScale%2CShowRiskMeasure%2COngoingCharge%2C' +
      'FundShareClassId%2CReturnM12%2CReturnM36%2CReturnM60%2CReturnM120%2CContributionReturn%2CUnits%2CExchangeCode%2CHoldingId%2CName';
    return this.http.post<any>(url, portfolio, ApiHttpService.getHeaders());
  }
}
