import {Component, OnInit} from '@angular/core';
import {
  Insight,
  InsightCategoriesDto,
  InsightMainCategory, InsightMongoSearch, InsightSearchCriteria
} from '../../../models/vestrata/Insight';
import {Observable} from 'rxjs';
import {InsightService} from '../../../api/services/insight.service';
import {SharedSnackbarService} from '../../shared-snackbar/services/shared-snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarMessage} from '../../shared-snackbar/models/snackbar-message';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedLoaderService} from '../../shared-loader/service/shared-loader.service';
import {CommonService} from '../../../api/services/common.service';
import {AuthenticationService} from '../../../api/services/authentication.service';
import {ArticlesDisplayComponent} from '../../articles-display/articles-display.component';
import {UtilsService} from '../../../api/utils.service';
import {Page} from '../../../models/vestrata/Page';
import {ArticleCategoryItem} from '../../../models/vestrata/Article';
import {NavigationMenuService} from '../../../basic-components/unknown-components/navigation-menu/navigation-menu.service';

@Component({
  selector: 'ves-shared-insights-display',
  templateUrl: '../../articles-display/articles-display.component.html',
  styleUrls: ['../../articles-display/articles-display.component.scss']
})
export class SharedInsightsDisplayComponent extends ArticlesDisplayComponent<Insight> implements OnInit {

  mainCategory: InsightMainCategory;
  filterCategories = new InsightCategoriesDto();
  filterCriteria = new InsightSearchCriteria();
  protected articleCategories: InsightCategoriesDto[];

  filterFeatured = false;

  categoriesMenu = [
    {name: 'article.main-categories.MARKET', category: InsightMainCategory.MARKET, isActive: true},
    // {name: 'article.main-categories.ASSETALLOCATION', category: InsightMainCategory.ASSETALLOCATION},
    {name: 'article.main-categories.THEMATIC', category: InsightMainCategory.THEMES},
    {name: 'article.main-categories.PRODUCT', category: InsightMainCategory.PRODUCT}
  ];

  // Observable
  $articleCategories = this.insightService.getInsightDisplayCategories();
  $articleSearch: Observable<Page<Insight>>;
  $articleLatestSearch: Observable<Page<Insight>>;

  // UI
  noArticleTranslation = 'no-insight';

  constructor(private insightService: InsightService,
              protected snackbar: SharedSnackbarService,
              protected translate: TranslateService,
              protected route: ActivatedRoute,
              protected authService: AuthenticationService,
              protected router: Router,
              protected loader: SharedLoaderService,
              protected common: CommonService,
              protected activatedRoute: ActivatedRoute,
              protected menuService: NavigationMenuService) {
    super(authService, router, route, common, activatedRoute, loader, translate, snackbar, menuService);
  }

  ngOnInit() {
    this.setPermission(this.authService.getPermissions()?.ips?.insights);
    super.ngOnInit();
  }

  setCategory(event?: any) {
    super.setCategory(event);
  }

  setFilterMenu() {
    this.filterMenu = [];

    const featured = {name: 'article-display.featured', isActive: this.filterFeatured };
    const all =   {name: 'article-display.all', isActive: this.filterAll };

    this.filterMenu.push(featured, all);

    if (this.filterCategories) {
      const assetAllocation = {
        name: 'article-display.asset-allocation', isActive: this.filterCriteria.assetAllocationCategory && this.filterCriteria.assetAllocationCategory !== 'all',
        filterField: UtilsService.getPropertyName(this.filterCriteria, f => f.assetAllocationCategory),
        isDropdown: true, options: this.getOptionItems(this.filterCategories.assetAllocationCategories),
        currentOption: this.filterCriteria.assetAllocationCategory
      };
      const assetClass = {
        name: 'article-display.asset-class', isActive: this.filterCriteria.assetCategory && this.filterCriteria.assetCategory !== 'all',
        filterField: UtilsService.getPropertyName(this.filterCriteria, f => f.assetCategory),
        isDropdown: true, options: this.getOptionItems(this.filterCategories.assetCategories),
        currentOption: this.filterCriteria.assetCategory
      };
      const partners = {name: 'article-display.partners', isActive: this.filterCriteria.partner &&  this.filterCriteria.partner !== 'all',
        filterField: UtilsService.getPropertyName(this.filterCriteria, f => f.partner),

        // @ts-ignore
        isDropdown: true, options: this.getOptionItems(this.filterCategories.partners, 'partners').sort((a,b)=> (a.label>b.label)*2-1),
        currentOption: this.filterCriteria.partner
      };

      const themes = {
        name: 'article-display.themes', isActive: this.filterCriteria.themeCategory && this.filterCriteria.themeCategory !== 'all',
        filterField: UtilsService.getPropertyName(this.filterCriteria, f => f.themeCategory),

        // @ts-ignore
        isDropdown: true, options: this.getOptionItems(this.filterCategories.themeCategories, 'theme').sort((a,b)=> (a.label>b.label)*2-1),
        currentOption: this.filterCriteria.themeCategory
      };
      const regions = {
        name: 'article-display.regions', isActive: this.filterCriteria.regionCategory && this.filterCriteria.regionCategory !== 'all',
        filterField: UtilsService.getPropertyName(this.filterCriteria, f => f.regionCategory),
        isDropdown: true, options: this.setFirstWordToUppercase(this.getOptionItems(this.filterCategories.regionCategories)),
        currentOption: this.filterCriteria.regionCategory
      };
      const strategies = {
        name: 'article-display.strategies', isActive: this.filterCriteria.strategyCategory && this.filterCriteria.strategyCategory !== 'all',
        filterField: UtilsService.getPropertyName(this.filterCriteria, f => f.strategyCategory),
        isDropdown: true, options: this.getOptionItems(this.filterCategories.strategyCategories, 'strategies'),
        currentOption: this.filterCriteria.strategyCategory
      };
      const models = {
        name: 'article-display.models', isActive: this.filterCriteria.modelCategory && this.filterCriteria.modelCategory !== 'all',
        filterField: UtilsService.getPropertyName(this.filterCriteria, f => f.modelCategory),
        isDropdown: true, options: this.getOptionItems(this.filterCategories.modelCategories, 'models'),
        currentOption: this.filterCriteria.modelCategory
      };
      // if (this.mainCategory === InsightMainCategory.ASSETALLOCATION) { this.filterMenu.push(assetAllocation); }
      if (this.mainCategory !== InsightMainCategory.PRODUCT) {
        this.filterMenu.push(assetClass, partners, themes, regions);
      } else {
        this.filterMenu.push(strategies, models);
      }
    }

    const date = {name: 'article-display.date', isActive: this.filterCriteria.startDate && this.filterCriteria.startDate !== 'all',
      filterField: UtilsService.getPropertyName(this.filterCriteria, f => f.startDate),
      isDropdown: true, options: this.getOptionItems(this.dateFilterOptions, 'date') ,
      currentOption: this.filterCriteria.startDate };

    this.filterMenu.push(date);
  }

  getOptionItems(options: any[], type?: string): any {
    const items = [];
    options.forEach(o => {
      let item: ArticleCategoryItem;
      if (type) {
        if (type === 'date') {
          item = {key: o, label: 'article-display.dates.' + o};
        } else if (type === 'theme') {
          item = {key: o, label: 'article-display.theme.' + o};
        } else if (type === 'models') {
          item = {key: o.id, label: o.strategyName + ' ' + o.investmentObjectiveName + ' ' + o.currency};
        } else if (type === 'strategies') {
          item = {key: o.id, label: o.name};
        } else if (type === 'partners') {
          item = {key: o.orgId, label: o.name};
        } else {
          console.log('UNKNOWN TYPE:' + type);
        }
        items.push(item);
      } else  {
        item = {key: o, label: o};
        items.push(item);
      }
    });
    return items;
  }

  subMenuClick(i) {
    const menuClicked = isNaN(i) ? this.filterMenu[i.item] : this.filterMenu[i];

    if (menuClicked.name === 'article-display.all') {
      this.filterAll = true;
      this.resetFilters();
    } else {
      this.filterAll = false;
      if (menuClicked.name === 'article-display.featured') {
        this.filterFeatured = !this.filterFeatured;
        this.filterCriteria.promoted = this.filterFeatured;
      } else {
        menuClicked.isActive = i.selection !== 'all';
        this.filterCriteria[menuClicked.filterField] = i.selection;
      }
    }

    this.setSearchCriteria();
  }

  resetFilters() {
    super.resetFilters();
    this.filterFeatured = false;
    this.filterCriteria.promoted = null;
    this.filterCriteria.assetAllocationCategory = null;
    this.filterCriteria.partner = null;
    this.filterCriteria.strategyCategory = null;
    this.filterCriteria.modelCategory = null;
  }

  latestSearch() {
    super.latestSearch();
    this.latestCriteria.criteria[InsightMongoSearch.curations] = {
      $elemMatch: {
        bankId: this.organisationId,
        enabled: true
      }
    };
  }

  setSearchCriteria() {
    super.setSearchCriteria();

    if (this.filterCriteria.partner && this.filterCriteria.partner !== 'all') {
      this.searchCriteria.criteria[InsightMongoSearch.searchOrg] = this.filterCriteria.partner;
    }

    if (this.filterFeatured) {
      this.searchCriteria.criteria[InsightMongoSearch.curations] = {
        $elemMatch: {
          bankId: this.filterCriteria.bankId,
          enabled: this.filterCriteria.enabled,
          promoted: this.filterCriteria.promoted
        }
      };
    } else {
      this.searchCriteria.criteria[InsightMongoSearch.curations] = {
        $elemMatch: {
          bankId: this.filterCriteria.bankId,
          enabled: this.filterCriteria.enabled
        }
      };
    }

    if (this.filterCriteria.assetAllocationCategory && this.filterCriteria.assetAllocationCategory !== 'all') {
      this.searchCriteria.criteria[InsightMongoSearch.assetAllocation] = { $eq: this.filterCriteria.assetAllocationCategory };
    }

    if (this.filterCriteria.strategyCategory && this.filterCriteria.strategyCategory !== 'all') {
      this.searchCriteria.criteria[InsightMongoSearch.strategy] = { $eq: this.filterCriteria.strategyCategory };
    }

    if (this.filterCriteria.modelCategory && this.filterCriteria.modelCategory !== 'all') {
      this.searchCriteria.criteria[InsightMongoSearch.model] = { $eq: this.filterCriteria.modelCategory };
    }
    this.searchCriteria.sorts.push({ property: 'curations.promoted', direction: -1});
    this.executeLoad();
  }

  executeLoad() {
    this.$articleSearch = this.insightService.getInsightsWithSearchCriteria(this.searchCriteria);
    this.$articleLatestSearch = this.insightService.getInsightsWithSearchCriteria(this.latestCriteria);
    super.executeLoad();
  }

  openArticle(insight: Insight) {
    const locales = insight?.current?.data.locales;
    this.urlToView = locales.find(i => i.lang === this.translate.currentLang) ?
      locales.find(i => i.lang === this.translate.currentLang).externalUrl :
      locales.find(i => i.lang === 'en').externalUrl;
    if (insight.embed) {
      this.viewArticle = true;
    } else {
      this.snackbar.push(new SnackbarMessage(this.translate.instant('article-display.open-new-tab'), 'info'));
      console.log(this.urlToView);
      window.open(this.urlToView, '_blank');
    }
    if (window.analytics) {
      window.analytics.track("Insight Display", {
        insightID: insight.id,
        insightAuthor: insight?.current.submittedBy,
        insightOrgID: insight.organisationId,
        insightOrgName: insight.organisationName,
      });
    }
  }

  protected categoryChanged() {
    super.categoryChanged();
    this.filterCriteria.bankId = this.organisationId;
    this.filterCriteria.enabled = true;
    this.setSearchCriteria();
  }
}
