import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FourEyeComponent} from '../../4eye-component';
import {VersionStatus} from '../../../models/vestrata/Version';

@Component({
  selector: 'ves-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends FourEyeComponent<boolean> implements OnInit, OnChanges {

  @Input() id: string;
  @Input() approverMode = false;
  @Input() blocked = false;
  @Input() lockOverride = false;

  constructor() { super(); }

  ngOnInit() {

    this.checkLocked();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.checkLocked();
    }
  }

  checkLocked() {
    this.locked = this.pendingStatus ?
      this.pendingStatus === VersionStatus.REQUEST :
      false;

    if (this.approverMode && !this.permission.approve) {
      this.locked = true;
    }
  }

  equals(a: boolean, b: boolean): boolean {
    return a === b;
  }

  checkUncheck() {
    this._editingValue = !this._editingValue;
    this.editingValueChange.emit(this._editingValue);
  }

}
