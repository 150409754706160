export abstract class PortalPermissions {
  enabled = true;

  setPermission(key: string) {
    const feature = key.split('.')[0];
    const role = key.split('.')[1];

    if (feature && role) {
      if (!this[feature]) {
        this[feature] = new Permission();
      }
      this[feature][role] = true;
    }
  }
}

export class PartnerPermission extends PortalPermissions {
  profile: Permission;
  strategy: Permission;
  model: Permission;
  metrics: Permission;
  modelApproval: Permission;
  instruments: Permission;
  insights: Permission;
  users: Permission;
  apiKeys: Permission;
  platformSettings: Permission;
  userAdmin: Permission;
  // NEW
  modelDistribution: Permission;
}

export class IpsPermission extends PortalPermissions {
  // NEW
  advisory: Permission;
  advisoryPortfolioModel: Permission;
  alternatives: Permission;
  bookOfBusiness: Permission;
  businessIntelligence: Permission;
  campaignManager: Permission;
  discretionary: Permission;
  documentRepository: Permission;
  dueDiligence: Permission;
  esg: Permission;
  governance: Permission;
  investmentPerformance: Permission;
  insights: Permission;
  managementReporting: Permission;
  marketOverview: Permission;
  ongoingSuitability: Permission;
  performanceMonitor: Permission;
  performanceTracker: Permission;
  platformSettings: Permission;
  productCatalogue: Permission;
  recommendations: Permission;
  regulatoryReporting: Permission;
  research: Permission;
  saaAdherence: Permission;
  salesMeeting: Permission;
  salesPerformance: Permission;
  securityMaster: Permission;
  taaAdherence: Permission;
  tradeIdeas: Permission;
  tutorials: Permission;
  userAdmin: Permission;
}

export class AdvisorPermission extends PortalPermissions {
  discretionary: Permission;
  advisory: Permission;
  alternatives: Permission;
  insights: Permission;
  customers: Permission;
  cioHouseView: Permission;
  platformSettings: Permission;
  userAdmin: Permission;
}

export class Permission {
  read = false;
  write = false;
  approve = false;
  publish = false;
}
