import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'ves-shared-insights-view',
  templateUrl: './shared-insights-view.component.html',
  styleUrls: ['./shared-insights-view.component.scss']
})
export class SharedInsightsViewComponent implements OnInit {

  @Input() url: string;

  @Output() closeInsight = new EventEmitter<boolean>();

  private readonly officeDocExtensions = ['pptx', 'docx', 'xlsx', 'ppt', 'doc', 'xls'];
  private readonly googleDocExtensions = ['pdf', 'jpg', 'jpeg'];

  constructor(public sanitizer: DomSanitizer) { }

  sanitizedUrl: SafeResourceUrl;

  ngOnInit() {
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    window.scroll(0, 0);
  }

  closeClick() {
    this.closeInsight.emit(true);
  }

  openOnWeb() {
    window.open(this.url, '_blank');
    this.closeInsight.emit(true);
  }

  getViewerType(url: string) {

    const extension = url.split('.').pop().substring(0, 5).toLowerCase();
    let ret = 'object';
    if (this.googleDocExtensions.includes(extension)) {
      ret = 'google';
    } else if (this.officeDocExtensions.includes(extension)) {
      ret = 'office';
    }
    console.log('url:' + this.url);
    console.log('type:' + ret);
    return ret;
  }
}
