import { NgModule } from '@angular/core';
import {VesConfService, VestrataComponentsLibModule} from 'vestrata-components-lib';
import {ConfigLoader, ConfigModule, ConfigService} from '@ngx-config/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ConfigHttpLoader} from '@ngx-config/http-loader';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {QuillModule} from 'ngx-quill';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {CanDeactivateGuard} from "./guards/can-deactivate.guard";
import {KeycloakAngularModule} from 'keycloak-angular';

registerLocaleData(localeFr);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new MultiTranslateHttpLoader(httpClient, [
    { prefix: './assets/i18n/partner/', suffix: '.json'},
    { prefix: './assets/i18n/components/', suffix: '.json'}]);
}

export function configFactory(httpClient: HttpClient): ConfigLoader {
  return new ConfigHttpLoader(httpClient, './assets/config.json');
}

@NgModule({
  declarations: [],
  imports: [
    SharedModule,
    KeycloakAngularModule,
    HttpClientModule,
    ConfigModule.forRoot({
      provide: ConfigLoader,
      useFactory: configFactory,
      deps: [HttpClient]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    VestrataComponentsLibModule.forRoot(
      {
        provide: ConfigLoader,
        useFactory: configFactory,
        deps: [HttpClient]
      }
    ),
    QuillModule.forRoot()
  ],
  exports: [
    VestrataComponentsLibModule,
    QuillModule,
    BrowserAnimationsModule
  ],
  providers: [
    VesConfService,
    ConfigService,
    CanDeactivateGuard
  ]
})
export class CoreModule { }
