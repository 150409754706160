import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@ngx-config/core';
import {VesConfService} from './conf.service';
import {interval, Observable} from 'rxjs';
import {ApiHttpService} from '../api.http.service';
import {Alert} from '../../models/vestrata/Alert';
import {Router} from '@angular/router';
import {flatMap} from 'rxjs/internal/operators';
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public serviceUrl: string;

  constructor(private http: HttpClient,
              private config: ConfigService,
              private confService: VesConfService,
              private router: Router) {
    this.serviceUrl = this.confService.getApiEndPoint();
  }

  getAlerts(): Observable<Alert[]> {
    return this.http.get<Alert[]>(this.serviceUrl + 'alert/alerts' , ApiHttpService.getHeaders());
  }

  startPeriodicAlerts(seconds: number): Observable<Alert[]> {
    return  interval(seconds * 1000)
      .pipe(flatMap(x => this.getAlerts()));
  }

  getAlertAction(alert: Alert, portal: string) {
    const portalMapping = this.getGlobalMapping(alert)[portal];
    const category = portalMapping.find(cat => cat.category === alert.category);
    const action = category ?  category.subCategories.find(subCat => subCat.subCategory === alert.subCategory) : null;
    return action;
  }
  takeAlertAction(alert: Alert, portal: string) {
    const action = this.getAlertAction(alert, portal);
    if (!action) {
      return;
    }
    this.router.navigate(action.command, action.extras);
  }

  getGlobalMapping(alert: Alert) {
    return {
      ips : [
        {category: 'CURATION', subCategories: [
            {subCategory: 'INSIGHTS', command: ['market-views/library/insights/library']}
          ]
        },
        {category: 'VALIDATION', subCategories: [
            {subCategory: 'FEES',  command: ['settings/fee-schedule']},
            {subCategory: 'SALES_MEETING_CATEGORY',  command: ['settings/platform-settings/meeting-categories']},
            {subCategory: 'TRADE_IDEA_CATEGORY',  command: ['settings/platform-settings/trade-idea-categories']},
            {subCategory: 'APPROVAL_PROCESS',  command: ['settings/platform-settings/approval-process']},
            {subCategory: 'PERFORMANCE_SETTING',  command: ['settings/platform-settings/governance-alerts']},
            {subCategory: 'TEAMS',  command: ['settings/platform-settings/teams']},
            {subCategory: 'INSIGHTS',  command: ['/market-views/library/insights/details'] , extras: {queryParams: {id: alert.relatedId}}},
            {subCategory: 'TUTORIALS',  command: ['/market-views/library/tutorials/details'] , extras: {queryParams: {id: alert.relatedId}}},
            {subCategory: 'COMPANY_PROFILE',  command: ['account/me']},
            {subCategory: 'USERS',  command: ['account/users/details'], extras: {queryParams: { id: alert.relatedId }}},
            {subCategory: 'TRADE_IDEA',  command: ['market-views/house-view/trade-ideas/details'], extras: {queryParams: {id: alert.relatedId}}},
            {subCategory: 'RECOMMENDATION',  command: ['market-views/house-view/recommendations/details'], extras: {queryParams: {id: alert.relatedId}}},
            {subCategory: 'ADVISORY_MODEL',  command: ['solutions/advisory/models/details'], extras: {queryParams: {id: alert.relatedId}}},
            {subCategory: 'ADVISORY_MODEL_DELETION',  command: ['solutions/advisory/models']},
            {subCategory: 'SETTINGS',  command: ['settings/platform-settings']},
            {subCategory: 'PRODUCT_CATALOG',  command: ['governance/product-catalog/approved-products/details'], extras: {queryParams: {poa: alert.relatedId}}},
            {subCategory: 'SAA',  command: ['market-views/house-view/saa']}

          ]
        },
        {category: 'PRODUCT', subCategories: [
            {subCategory: 'INSIGHTS', command: ['market-views/library/insights/display']},
            {subCategory: 'TUTORIALS', command: ['market-views/library/tutorials/display']},
            {subCategory: 'MODEL_PORTFOLIO',  command: ['solutions/discretionary/models']},
            {subCategory: 'STRATEGY',  command: ['solutions/discretionary/strategies']},
            {subCategory: 'PRODUCT_CATALOG_RELATED',  command: ['governance/product-catalog/approved-products/details'], extras:
                {queryParams: {relatedProduct: alert.relatedId, productType: 'instrument' }}}
          ]
        },
      ],
      advisor: [
        {category: 'PRODUCT', subCategories: [
            {subCategory: 'INSIGHTS',  command: ['insights']},
            {subCategory: 'MODEL_PORTFOLIO',  command: ['solutions/discretionary/models']},
            {subCategory: 'STRATEGY',  command: ['solutions/discretionary/strategies']},
            {subCategory: 'SAA_TAA',  command: ['/invest/house-views/saa-taa']},
            {subCategory: 'PRODUCT_CATALOG',  command: ['/invest/products/product-catalogue/details'], extras: {queryParams: {poa: alert.relatedId}}},
            {subCategory: 'RECOMMENDATION',  command: ['/invest/products/recommendations/details'], extras: {queryParams: {id: alert.relatedId}}},
            {subCategory: 'SALES_MEETING',  command: ['/invest/house-views/sales-meeting'], extras: {queryParams: {id: alert.relatedId}}},
            {subCategory: 'TRADE_IDEA',  command: ['/invest/house-views/trade-ideas/display'], extras: {queryParams: {id: alert.relatedId}}}
          ]
        }
      ],
      admin: [
        {category: 'CURATION', subCategories: [
          {subCategory: 'INSIGHTS', command: ['insights/library']}
        ]
        },
        {category: 'VALIDATION', subCategories: [
            {subCategory: 'INSIGHTS',  command: ['insights/library']}
          ]
        },
        {category: 'PRODUCT', subCategories: [
            {subCategory: 'MODEL_PORTFOLIO',  command: ['solutions/discretionary/model-details', { id: alert.relatedId }]},
            {subCategory: 'STRATEGY',  command: ['solutions/discretionary/models']}
          ]
        }
      ],
      partner: [
        {category: 'VALIDATION', subCategories: [
            {subCategory: 'INSIGHTS',  command: ['insights/library/details'], extras: {queryParams: {id: alert.relatedId}}},
            {subCategory: 'METRICS',  command: ['portfolio-models/metrics-main', { id: alert.relatedId } ]},
            {subCategory: 'MODEL_PORTFOLIO',  command: ['portfolio-models/model-details', { id: alert.relatedId } ]},
            {subCategory: 'STRATEGY',  command: ['strategies/strategy', { id: alert.relatedId }]},
            {subCategory: 'COMPANY_PROFILE',  command: ['account/profile']},
            {subCategory: 'USERS',  command: ['account/users/details'], extras: {queryParams: { id: alert.relatedId }}}
          ]
        },
        {category: 'PRODUCT', subCategories: [
            {subCategory: 'METRICS',  command: ['portfolio-models/model-details', { id: alert.relatedId } ]},
            {subCategory: 'MODEL_PORTFOLIO',  command: ['portfolio-models/model-details', { id: alert.relatedId } ]},
          ]
        },
      ]
    };
  }

}
