import {Version} from './Version';
import {InsightMainCategory, Tag} from './Insight';
import {Region} from './Region';
import {Theme} from './Theme';
import {AssetClass} from './MajorAssetClass';

export class Article<V extends ArticleVersion<ArticleLocal>> {
  id: string;
  organisationId: string;
  organisationName: string;
  current: Version<V>;
  pending: Version<V>;
}

export class ArticleVersion<L extends ArticleLocal> {
  locales: L[] = [];

  thumbnailImage: string;
  bannerImage: string;

  startDate = '';
  endDate = '';

  mainCategory: string;
  assetAllocationCategory: ArticleAssetAllocation;
  regionCategory: string[] = [];
  assetCategory?: string[] = [];
  themeCategory?: string[] = [];
  tags: Tag [] = [];
}

export class ArticleLocal {
  lang = '';
  title = '';
  description = '';
}

export enum ArticleAssetAllocation {
  SAA = 'SAA',
  TAA = 'TAA'
}

export enum ArticleType {
  INSIGHT,
  RESEARCH,
  TUTORIAL
}

export class ArticleCategoryDto {
  regions: Region[] = [];
  themes: Theme[] = [];
  assetClasses: AssetClass[] = [];
  strategies: ArticleStrategyDto[] = [];
  models: ArticleModelDto[] = [];
}

export class ArticleStrategyDto {
  id: string;
  name: string;
}

export class ArticleModelDto {
  id: string;
  strategyId: string;
  strategyName: string;
  investmentObjectiveName: string;
  currency: string;
}

export class ArticleMongoSearch {
  static readonly main = 'current.data.mainCategory';
  static readonly startDate = 'current.data.startDate';
  static readonly endDate = 'current.data.endDate';
  static readonly asset = 'current.data.assetCategory';
  static readonly theme = 'current.data.themeCategory';
  static readonly region = 'current.data.regionCategory';
}

export class ArticleSearchCriteria {
  mainCategory: ArticleMainCategory;
  regionCategory = 'all';
  themeCategory = 'all';
  startDate: string;
  assetCategory = 'all';
}

export class ArticleCategoriesDto {
  mainCategory: ArticleMainCategory;
  assetAllocationCategories: string[] = [];
  assetCategories: string[] = [];
  themeCategories: string[] = [];
  regionCategories: string[] = [];
}

export class ArticleMainCategory {}

export class ArticleCategoryItem {
  key: string;
  label: string;
}
