import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import { Chart } from 'chart.js';
import {PartnerService} from '../../../api/services/partner.service';
import {takeUntil} from 'rxjs/operators';
import {AssetClass} from '../../../models/vestrata/MajorAssetClass';
import {Allocation} from '../../../models/vestrata/Allocation';
import {InstrumentInfo} from '../../../models/vestrata/Instrument/Instrument';
import {CommonService} from '../../../api/services/common.service';
import {UtilsService} from '../../../api/utils.service';

@Component({
  selector: 'ves-advanced-assets-allocation-chart',
  templateUrl: './advanced-assets-allocation-chart.component.html',
  styleUrls: ['./advanced-assets-allocation-chart.component.scss']
})
export class AdvancedAssetsAllocationChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy  {

  private _onDestroy = new Subject();
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('minorCanvas') minorCanvas: ElementRef;
  private chart: Chart;
  private minorChart: Chart;

  private colors: string[];

  @Input() allocations: Allocation[];
  chartAllocations: ChartAllocationDto[];
  @Input() assetClasses: AssetClass[];
  currentMajorAssetClass: string;
  allocationTotal: number;
  instrumentTypes: {type: string, allocation: number}[];

  constructor(private partner: PartnerService, private common: CommonService,
              private utils: UtilsService) { }

  ngOnInit() {
     console.log('-------- ALLLOCATIONCHART -------------');
     console.log(this.allocations);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allocations && changes.allocations.currentValue) {
      this.allocationTotal = this.getAllocationsTotal(this.allocations);
      this.chartAllocations = this.getChartAllocations(this.allocations);
      this.instrumentTypes = this.getInstrumentTypes(this.allocations);
      this.colors = this.chartAllocations.map(ca => this.getColorByMajorAssetClass(ca.majorAssetClass));

      if (this.chart) {
        this.chart = this.initChart(this.chartAllocations.map(ca => ca.allocation), this.chartAllocations.map(ca => ca.majorAssetClass), this.colors);
        this.currentMajorAssetClass = this.chartAllocations[0].majorAssetClass;
      }

      if (this.minorChart) {
        const currentMajorData = this.chartAllocations.find(ca => ca.majorAssetClass === this.currentMajorAssetClass);
        const minorColors = currentMajorData.minorAssetClassAllocations.map((minor, index) => {
          return this.getMinorAssetClassColor(this.currentMajorAssetClass, index, currentMajorData.minorAssetClassAllocations.length);
        });
        this.minorChart = this.initMinorChart(currentMajorData.minorAssetClassAllocations.map(minor => minor.allocation),
          currentMajorData.minorAssetClassAllocations.map(minor => minor.assetClass),
          minorColors);
      }
    }
  }

  ngAfterViewInit(): void {
    this.partner.getAssetClasses().pipe(takeUntil(this._onDestroy)).subscribe(assets => {
      this.assetClasses = assets;
      if (this.chartAllocations) {
        this.currentMajorAssetClass = this.chartAllocations[0].majorAssetClass;
        this.colors = this.chartAllocations.map(ca => this.getColorByMajorAssetClass(ca.majorAssetClass));
        this.chart = this.initChart(this.chartAllocations.map(ca => ca.allocation), this.chartAllocations.map(ca => ca.majorAssetClass), this.colors);

        const currentMajorData = this.chartAllocations.find(ca => ca.majorAssetClass === this.currentMajorAssetClass);
        const minorColors = currentMajorData.minorAssetClassAllocations.map((minor, index) => {
          return this.getMinorAssetClassColor(this.currentMajorAssetClass, index, currentMajorData.minorAssetClassAllocations.length);
        });
        this.minorChart = this.initMinorChart(currentMajorData.minorAssetClassAllocations.map(minor => minor.allocation),
          currentMajorData.minorAssetClassAllocations.map(minor => minor.assetClass),
          minorColors);
      }
    });
  }

  initChart(values: any[], chartLabels: string[], colors: string[]): Chart {
    const borderColors = values.map(v => '#ffffff');
    if (this.canvas == null) {
      return null;
    } else {
      return new Chart(this.canvas.nativeElement.getContext('2d'), {
        type: 'doughnut',
        data: {
          datasets: [{
            labels: chartLabels,
            data: values,
            backgroundColor: colors,
            borderColor: borderColors,
            hoverBorderColor: borderColors,
            borderWidth: 4
          }],
        },
        options: {
          tooltips: {
            displayColors: true,
            callbacks: {
              label(tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const index = tooltipItem.index;
                return dataset.labels[index] + ': ' + dataset.data[index] + ' %';
              },
            },
          }
        }
      });
    }
  }

  initMinorChart(values: any[], chartLabels: string[], colors: string[]): Chart {
    const borderColors = values.map(v => '#ffffff');
    return new Chart(this.minorCanvas.nativeElement.getContext('2d'), {
      type: 'doughnut',
      data: {
        datasets: [{
          labels: chartLabels,
          data: values,
          backgroundColor: colors,
          borderColor: borderColors,
          hoverBorderColor: borderColors,
          borderWidth: 4
        }]
      },
      options: {
        cutoutPercentage: 80,
        tooltips: {
          displayColors: true,
          callbacks: {
            label(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const index = tooltipItem.index;
              return dataset.labels[index] + ': ' + dataset.data[index] + ' %';
            },
          },
        }
      }
    });
  }

  getColorByMajorAssetClass(assetClass: string): string {
    let color;
    if (this.assetClasses && this.assetClasses.length > 0) {
      const matchingClass = this.assetClasses.find(asset => asset.name === assetClass);
      if (matchingClass) {
        color = matchingClass.majorColor;
      }
    }
    return color;
  }

  majorClassSelected(event: any) {
    if (this.chart) {
      const element = this.chart.getElementAtEvent(event);
      if (element) {
        this.currentMajorAssetClass = this.chartAllocations[element[0]._index].majorAssetClass;
        const currentMajorData = this.chartAllocations.find(ca => ca.majorAssetClass === this.currentMajorAssetClass);
        const minorColors = currentMajorData.minorAssetClassAllocations.map((minor, index) => {
          return this.getMinorAssetClassColor(this.currentMajorAssetClass, index, currentMajorData.minorAssetClassAllocations.length);
        });
        this.minorChart = this.initMinorChart(currentMajorData.minorAssetClassAllocations.map(minor => minor.allocation),
          currentMajorData.minorAssetClassAllocations.map(minor => minor.assetClass),
          minorColors);
      }
    }
  }

  private getChartAllocations(allocations: Allocation[]): ChartAllocationDto[] {
    const chartAllocations: ChartAllocationDto[] = [];
    for (const allocation of allocations) {
      const majorAssetClass = this.common.getMajorAssetClassFromInstrument(allocation.instrument);
      const item = {
        instrument: allocation.instrument,
        allocation: allocation.allocation
      };

      const existingMajorClass = chartAllocations.find(ca => ca.majorAssetClass === majorAssetClass);
      if (existingMajorClass) {
        existingMajorClass.instruments.push(item);
        existingMajorClass.allocation = +existingMajorClass.allocation.toFixed(2) + +item.allocation.toFixed(2);
      } else {
        const newMajorClass = new ChartAllocationDto();
        newMajorClass.majorAssetClass = majorAssetClass;
        newMajorClass.instruments = [item];
        newMajorClass.allocation = +item.allocation.toFixed( 2);
        chartAllocations.push(newMajorClass);
      }
    }

    console.log('CHART-ALLOCATIONS-BEFORE', chartAllocations);
    for ( const ca of chartAllocations) {
      ca.minorAssetClassAllocations = this.getMinorAssetClassAllocations(ca);
    }

    return this.sortByAllocation(chartAllocations);
  }


  getCurrentMajorAssetClassTotal() {
    const major = this.chartAllocations.find(al => al.majorAssetClass === this.currentMajorAssetClass);
    return major ? major.allocation : 0;
  }

  private getInstrumentTypes(allocations: Allocation[]): {type: string, allocation: number}[] {
    const types: {type: string, allocation: number}[] = [];
    for (const allocation of allocations) {
      const existingProductType = types.find(pt => pt.type === allocation.instrument.type);
      if (existingProductType) {
        existingProductType.allocation += +allocation.allocation;
      } else {
        types.push({type: allocation.instrument.type, allocation: +allocation.allocation});
      }
    }
    return types;
  }

  private getAllocationsTotal(allocations: Allocation[]): number {
    let total = 0;
    for (const allocation of allocations) {
      total += +allocation.allocation;
    }
    return total;
  }

  private getMinorAssetClassAllocations(allocations: ChartAllocationDto): any[] {
    const minorAssetClassAllocations: {assetClass: string, allocation: number}[] = [];
    let i = 0;
    for (const instrumentAllocation of allocations.instruments ) {
      const existingMinorClass = minorAssetClassAllocations
        .find(as => as.assetClass === this.common.getMinorAssetClassFromInstrument((instrumentAllocation.instrument)));
      if (existingMinorClass) {
        existingMinorClass.allocation += +instrumentAllocation.allocation;
        existingMinorClass.allocation = +existingMinorClass.allocation.toFixed( 2);
      } else {
        const minorClass = this.common.getMinorAssetClassFromInstrument(instrumentAllocation.instrument);
        minorAssetClassAllocations.push(
          {
            assetClass: (minorClass ? minorClass : 'other'),
            allocation: +instrumentAllocation.allocation.toFixed( 2)
          });
      }
      i++;
    }
    return this.sortByAllocation(minorAssetClassAllocations);
  }

  getMinorAssetClassColor(majorAssetClass: string, index: number, length: number): string {
    const major = this.assetClasses.find(ma => ma.name === majorAssetClass);
    return this.getMinorAssetClassColorFromHueAndIndex(this.utils.hexToHsl(major ? major.majorColor : '#003057'), index, length)
  }

  private getMinorAssetClassColorFromHueAndIndex(hueObject: any, index: number, length: number): string {
    const step = 20;
    const shift: number = Math.round((length - 1) / 2  * step);
    const saturation: number = hueObject.sat + shift - (index * step);
    const lightness: number = hueObject.light - shift + (index * step);
    return 'hsl(' + hueObject.hue + ', ' + saturation + '%, ' + lightness + '%)';
  }

  private sortByAllocation(data: any[]): any[] {
    return data.sort((e1, e2) => e2.allocation - e1.allocation);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}

class ChartAllocationDto {
  majorAssetClass: string;
  allocation: number;
  instruments: { instrument: InstrumentInfo, allocation: number }[];
  minorAssetClassAllocations: {assetClass: string, allocation: number}[];
}

